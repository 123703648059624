<template>
    <span>Mensaje</span>
  
    <!--
    <Textarea  @pointerdown.stop="" @dblclick.stop="" v-model="selectedMsg" rows="5" cols="30" />
    <Dropdown @pointerdown.stop="" @dblclick.stop=""  v-model="selectedMsgType" :options="selectedMsgTypeData" optionLabel="description" optionValue="id" placeholder="Select a City" class="w-full md:w-14rem" />
-->
    <div style="display: flex;">
        <textarea style="resize: none;" @pointerdown.stop="" @dblclick.stop="" id="w3review" name="msgText" rows="4" cols="50" v-model="selectedMsg"
    class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full "    
    ></textarea>
            <i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" :class="{ 'fa fa-subscript': true, 'text-green-500': hasExpression(MessageNodeConst.VAR_MSG_IN) }"
                @click="showExpression(MessageNodeConst.VAR_MSG_IN,'Mensaje a mostrar')"></i>
        </div>


    <br />
    <span>Tipo</span>    
    <Dropdown @pointerdown.stop="" @dblclick.stop=""  v-model="selectedMsgType" :options="selectedMsgTypeData" optionValue="id" optionLabel="description" placeholder="Selecciona una opción" class="w-full md:w-56" 
   @change="data.onChange({ key: MessageNodeConst.VAR_MSGTYPE_IN, data: selectedMsgType })"/>        
 
    <br />
    <span>Modal</span>    
    <Dropdown @pointerdown.stop="" @dblclick.stop=""  v-model="selectedMsgModalType" :options="selectedMsgModalTypeData" optionValue="id" optionLabel="description" placeholder="Selecciona una opción" class="w-full md:w-56" 
    @change="data.onChange({ key: MessageNodeConst.VAR_MSGMODAL_IN, data: selectedMsgModalType })"/>    

    <br />
    <span>Modo Contador</span>    
    <Dropdown @pointerdown.stop="" @dblclick.stop="" filter v-model="selectedMsgCounterType" :options="selectedMsgModalTypeData" optionValue="id" optionLabel="description" placeholder="Selecciona una opción" class="w-full md:w-56" 
    @change="data.onChange({ key: MessageNodeConst.VAR_MSGCOUNTER_IN, data: selectedMsgCounterType })"
    />    
</template>
<script lang="ts">

import {  defineComponent, onBeforeUnmount, onMounted, ref, watch,getCurrentInstance } from 'vue';
import debounce from 'lodash.debounce';
import HelperCustomComponentControls from '../../../functions/helperCustomComponentControls';
import MessageNodeConst from '../constants/MessageNodeConst';
import Textarea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown'
import PrimeVue from 'primevue/config'
import HelperPrime from '../../../../../../../common/infrastructure/funciones/HelperPrime';
//import PrimeVue from "primevue/config";
export default defineComponent({
    name: 'custommsgcontrol',
    components: {
        Dropdown,
        Textarea,
        //PrimeVue
    },
    props: {
        data: Object
    },
    setup(props) {


        
        const app = getCurrentInstance()        
        app?.appContext.app.component('Dropdown',Dropdown)
        app?.appContext.app.use(PrimeVue, HelperPrime.getPrimeOptions())
        const { showExpression,hasExpression } = HelperCustomComponentControls(props.data);
        const selectedMsg = ref('');
        const selectedMsgType = ref(1);
        const selectedMsgModalType = ref(true);
        const selectedMsgCounterType = ref(false);
        const selectedMsgTypeData = ref([
            {id:1, description:'Info'},
            {id:2, description:'Error'},
            {id:3, description:'Aviso'},
            {id:4, description:'Correcto'},
            {id:5, description:'Pregunta'},
        ]);

        const selectedMsgModalTypeData = ref([
            {id:true, description:'Si'},
            {id:false, description:'No'},

        ]);


        let debounceUpdateData: any;
        const debounceTime = 1200;
       
    
        
  const initDebounce = () => {
    debounceUpdateData = debounce((data: any, start: boolean = false) => {
        if (props.data){
            props.data.onChange({ key: MessageNodeConst.VAR_MSG_IN, data: selectedMsg.value })        
        }
    }, debounceTime);
  }

  watch(
    selectedMsg, (newvalue, oldvalue) => {
          if (oldvalue !== newvalue && oldvalue !== undefined) {
              debounceUpdateData(newvalue, true);            
          }
        },
        {
          flush: 'post',
          //immediate: true, 
          deep: true
        }
      )


        onMounted(() => {
            if (props.data) {
                selectedMsg.value = props.data[MessageNodeConst.VAR_MSG_IN]
                selectedMsgType.value = props.data[MessageNodeConst.VAR_MSGTYPE_IN] ?? 1
                selectedMsgModalType.value = props.data[MessageNodeConst.VAR_MSGMODAL_IN] ?? true
                selectedMsgCounterType.value = props.data[MessageNodeConst.VAR_MSGCOUNTER_IN] ?? false

            }
            initDebounce();
        })


        onBeforeUnmount(() => {
            debounceUpdateData.cancel();
        })
        
        return {
            selectedMsg,
            selectedMsgType,
            selectedMsgTypeData,
            selectedMsgModalTypeData,
            selectedMsgModalType,
            selectedMsgCounterType,
            showExpression,
            MessageNodeConst,
            hasExpression
        };
    },
});
</script>
<style scoped></style>
