import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'
import { IServiceWorkFlowActivityConnection } from './interface/IServiceWorkFlowActivityConnection'
import type { IWorkFlowActivityConnectionRepository } from '../domain/repositorry/IWorkFlowActivityConnectionRepository'
import { IapWorkFlowActivityConnection } from '../domain/service/iapWorkFlowActivityConnection'





@injectable()
export class WorkFlowActivityConnectionService implements IServiceWorkFlowActivityConnection {
  private readonly repo: IWorkFlowActivityConnectionRepository

  public constructor (@inject(TYPES.WORKFLOWACTIVITY_REPOSITORY) _repo: IWorkFlowActivityConnectionRepository) {
    this.repo = _repo
  }

 
  async add (workflowActivityId:number, data: IapWorkFlowActivityConnection[]): (Promise<IapWorkFlowActivityConnection[]>) {
    return await this.repo.add(workflowActivityId, data);
  }

  async delete (data: IapWorkFlowActivityConnection): (Promise<boolean>) {
    return await this.repo.delete(data);
  }
   
  async getById(id: number) :Promise<IapWorkFlowActivityConnection> {
    return await this.repo.getById(id)
  }

  
}