<template>
    <DocumentViewer v-if="showViewer && doc" :visible="showViewer" @update:Visibility="showViewer=false" :document="doc"
        :canvas="canvas" />
</template>
<script lang="ts">

import { defineComponent, ref } from 'vue';
//import { getRfpAnswerRequest } from '../../../../interfaces/nostromo/service/getRfpAnswerRequest'
import { useStore } from 'vuex';
//import HelperDocument from '../../../../funciones/HelperDocument';
//import RfpDocumentoDescarga from '../../../../modelos/documento/RfpDocumentoDescarga';
//import { getRfpDocumentsRequest } from '../../../../interfaces/nostromo/service/getRfpDocumentsRequest';
//import HelperRfp from '../../../../funciones/HelperRfp';
import DocumentViewer from './DocumentViewer.vue';
import HelperLoading from '../../../../funciones/HelperLoading';


export default defineComponent({
    name: 'documentosolicitudes',
    components: {
        DocumentViewer
    },
    setup() {
        const store = useStore();
        const doc = ref({});
        const canvas = ref();
        const showViewer = ref(false);

        
        function documentoRespuesta(rfpid:string, questionid:string, answerid:string) {
        
        /*    HelperLoading.showLoading();
            const request: Partial<getRfpAnswerRequest> = {
                sessionID: store.getters.sessionID,
                all: false,
                rfpId: rfpid,
                questionId: questionid,
                withDocuments: true,
                answerId: answerid,
            };

            HelperRfp.getRfpAnswers(request as any)
                .then(data => {

                    if (data.error == false) {
                        const datos = data.rfpAnswers[0].document;
                        descargaFichero(datos);
                    }
                }).finally(() => {
                    HelperLoading.hideLoading();
                }
                );
                */
                

        }


        const descargarDocumento = (documentId:string, withDocument = true) => {

            /*
            HelperLoading.showLoading();
            HelperDocument.getDocument(store.getters.sessionID, documentId, withDocument).then((response:any) => {
                if (!response.error) {
                    descargaFichero(response.document);
                }
            }).finally(() => {
                HelperLoading.hideLoading();
            });
            */
        }

        
       

        const descargaFichero = (datos:any) => {
            doc.value = datos;
            showViewer.value = true;
            /*
            const type = (datos.contentType || datos.contentType == '' ? 'application/octet-stream' : datos.contentType);
            var fileURL = "data:" + type + ";base64," + datos.content;
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', datos.fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            */

        }

        const setCanvas = (datos:any) => {
            canvas.value = datos;
            showViewer.value = true;
        }

        return {
            doc,
            showViewer,
            documentoRespuesta,
            //documentosSolicitud,
            descargarDocumento,
            descargaFichero,
            canvas,
            setCanvas

        };

    },

});
</script>
<style scoped>

</style>