import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_SelectButton = _resolveComponent("SelectButton")!
  const _component_CustomValidate = _resolveComponent("CustomValidate")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.getPropertyBooleanValue(_ctx.SelectButtonTypeConst.DEBUG, _ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.dataValue && (_ctx.getPropertyBooleanValue(_ctx.SelectButtonTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW)))
      ? _withDirectives((_openBlock(), _createBlock(_component_SelectButton, {
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.SelectButtonTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.SelectButtonTypeConst.STYLE)),
          class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.SelectButtonTypeConst.CLASS) ?? '']: true, 'p-invalid': _ctx.v$.vmodel.$error && _ctx.submitted, 'p-readonly': _ctx.getPropertyBooleanValue(_ctx.SelectButtonTypeConst.READONLY) }),
          name: _ctx.getPropertyValue(_ctx.SelectButtonTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.SelectButtonTypeConst.DISABLED) || (!_ctx.canEdit),
          visible: _ctx.getPropertyBooleanValue(_ctx.SelectButtonTypeConst.VISIBLE),
          placeholder: _ctx.getPropertyValue(_ctx.SelectButtonTypeConst.PLACEHOLDER),
          readonly: _ctx.getPropertyBooleanValue(_ctx.SelectButtonTypeConst.READONLY) || !_ctx.canDoOperation(_ctx.ObjectGroupConst.EDIT),
          required: _ctx.getPropertyBooleanValue(_ctx.SelectButtonTypeConst.REQUIRED),
          tabIndex: _ctx.getPropertyNumberValue(_ctx.SelectButtonTypeConst.TABINDEX),
          multiple: _ctx.getPropertyBooleanValue(_ctx.SelectButtonTypeConst.MULTIPLE),
          options: _ctx.dataValue,
          optionLabel: _ctx.getOptionLabel(),
          optionValue: _ctx.getOptionValue(),
          optionDisabled: _ctx.getOptionDisabled(),
          modelValue: _ctx.arrayModel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.arrayModel) = $event)),
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setArrayToVModel()))
        }, {
          option: _withCtx((slotProps) => [
            _renderSlot(_ctx.$slots, "option", {
              slotProps: { data: slotProps.option, index: slotProps.index, control: _ctx.ControlTypeConst.CTSELECTBUTTON }
            })
          ]),
          default: _withCtx(() => [
            _createTextVNode(" > ")
          ]),
          _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "visible", "placeholder", "readonly", "required", "tabIndex", "multiple", "options", "optionLabel", "optionValue", "optionDisabled", "modelValue"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.SelectButtonTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.SelectButtonTypeConst.VISIBLE))
      ? (_openBlock(), _createBlock(_component_CustomValidate, {
          key: 2,
          submitted: _ctx.submitted,
          "onUpdate:submitted": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.submitted) = $event)),
          vObject: _ctx.v$,
          "onUpdate:vObject": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v$) = $event))
        }, null, 8 /* PROPS */, ["submitted", "vObject"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}