/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import HelperLoading from "../../../../../common/infrastructure/funciones/HelperLoading";
import { Container } from "inversify";
import { IapWorkFlowActivity } from "../../domain/service/iapWorkFlowActivity";
import { IServiceWorkFlowActivity } from "../../application/interface/IServiceWorkFlowActivity";
import { TYPES } from "../../../../../common/domain/types";
import { IServiceWorkFlowActivityConnection } from "../../application/interface/IServiceWorkFlowActivityConnection";
import { IapWorkFlowActivityConnection } from "../../domain/service/iapWorkFlowActivityConnection";
import { IapWorkFlow } from "../../domain/service/iapWorkFlow";
import { IapWorkFlowActivityVariable } from "../../domain/service/iapWorkFlowActivityVariable";
import { Dictionary } from "../../../expression/domain/dictionary";
import { IServiceWorkFlowActivityVariable } from "../../application/interface/IServiceWorkFlowActivityVariable";
import { IapWorkFlowActivityControl } from "../../domain/service/iapWorkFlowActivityControl";
import { IServiceWorkFlowActivityControl } from "../../application/interface/IServiceWorkFlowActivityControl";
import HelperUtils from "../../../../../common/infrastructure/funciones/HelperUtils";


export default function HelperWorkFlow
  (
    wf: IapWorkFlow, srvContainer: Container | undefined, store: any
  ) {


  const buildWorkFlowVariables = (data: any) => {
    const variables: Array<IapWorkFlowActivityVariable> = []
    data.inputNames.forEach((input: Dictionary<String, String>) => {
      variables.push({
        id: 0,
        workFlowActivityId: 0,
        name: input.key,
        defaultValue: input.value,
        isInput: true,
        fcr: new Date(),
        ucr: store.getters.getCurrentUser.id,
        group:HelperUtils.newGuid(),

      } as IapWorkFlowActivityVariable)
    });

    data.outputNames.forEach((input: Dictionary<String, String>) => {
      variables.push({
        id: 0,
        workFlowActivityId: 0,
        name: input.key,
        defaultValue: input.value,
        isInput: false,
        fcr: new Date(),
        ucr: store.getters.getCurrentUser.id,
        group:HelperUtils.newGuid(),

      } as IapWorkFlowActivityVariable)
    });

    return variables;
  }

  const buildWorkFlowControls = (data: any) => {
    const variables: Array<IapWorkFlowActivityControl> = []    

    data.controls.forEach((input: Dictionary<String, String>) => {
      variables.push({
        id: 0,
        workFlowActivityId: 0,
        name: input.key,
        value: input.value,
        localizable: false,
        group:HelperUtils.newGuid(),

      } as IapWorkFlowActivityControl)
    });


    return variables;
  }

  const addWorkFlowActivity = async (dataInput: any) : Promise<boolean> => {
    
    return await new Promise<boolean>((resolve) => {
      if (srvContainer) {

        const data: Partial<IapWorkFlowActivity> = {
          id: 0,
          workFlowId: wf?.id ?? 0,
          typeWorkFlowId: dataInput.type,
          name: dataInput.name,
          label: dataInput.label,
          color: dataInput.color,
          left: dataInput.left,
          top: dataInput.top,
          isInvalid: dataInput.isInvalid,
          canDelete: dataInput.canDelete,        
          fcr: new Date(),
          ucr: store.getters.getCurrentUser.id,
          iapWorkFlowActivityVariables: buildWorkFlowVariables(dataInput),
          iapWorkFlowActivityControls: buildWorkFlowControls(dataInput),
          group:HelperUtils.newGuid(),
  
        }
  
        const dataToSave = JSON.parse(JSON.stringify(data));
        const _srv = srvContainer.get<IServiceWorkFlowActivity>(TYPES.WORKFLOWACTIVITY_REPOSITORY)
        HelperLoading.showSaving()
        _srv.add(dataToSave as any).then(response => {
          if (response) {
            wf?.iapWorkFlowActivities.push(response)
          }
        })
          .finally(() => {
            HelperLoading.hideSaving()
            resolve(true);
          })
      }
      else{
        resolve(true);
      }
    })
   
  }

  const deleteWorkFlowActivity = (dataInput: IapWorkFlowActivity) => {
    if (srvContainer) {

      const dataToSave = JSON.parse(JSON.stringify(dataInput));
      const _srv = srvContainer.get<IServiceWorkFlowActivity>(TYPES.WORKFLOWACTIVITY_REPOSITORY)
      HelperLoading.showSaving()
      _srv.delete(dataToSave as any).then(response => {
        if (response) {
          wf.iapWorkFlowActivities = wf?.iapWorkFlowActivities.filter(x => x.id !== dataInput.id);
        }
      })
        .finally(() => {
          HelperLoading.hideSaving()
        })
    }
  }

  const updateWorkFlowActivity = (dataInput: IapWorkFlowActivity) => {

    if (srvContainer) {
      const dataToSave = JSON.parse(JSON.stringify(dataInput));
      const _srv = srvContainer.get<IServiceWorkFlowActivity>(TYPES.WORKFLOWACTIVITY_REPOSITORY)
      HelperLoading.showSaving()
      _srv.update(dataToSave as any).then(response => {
        if (response) {
          const _act = wf?.iapWorkFlowActivities.find(x => x.id == dataInput.id)
          if (_act) {
            Object.assign(_act, response)
          }


        }
      })
        .finally(() => {
          HelperLoading.hideSaving()
        })
    }
  }


  const updateWorkFlowActivityVariable = (dataInput: IapWorkFlowActivityVariable[]) => {

    if (srvContainer) {
      const dataToSave = JSON.parse(JSON.stringify(dataInput));
      const _srv = srvContainer.get<IServiceWorkFlowActivityVariable>(TYPES.WORKFLOWACTIVITYVAR_REPOSITORY)
      HelperLoading.showSaving()
      _srv.update(dataToSave as any).then(response => {
        if (response) {
          /*const _act = wf?.iapWorkFlowActivities.find(x => x.id == dataInput.id)
          if (_act) {
            Object.assign(_act, response)
          }
         */

        }
      })
        .finally(() => {
          HelperLoading.hideSaving()
        })
    }
  }


  const updateWorkFlowActivityControl = async (dataInput: IapWorkFlowActivityControl) : Promise<boolean> => {
    
      return await new Promise<boolean>((resolve) => {
        if (srvContainer) {
          const dataToSave = JSON.parse(JSON.stringify(dataInput));
          const _srv = srvContainer.get<IServiceWorkFlowActivityControl>(TYPES.WORKFLOWACTIVITYCONTROL_REPOSITORY)
          HelperLoading.showSaving()
          _srv.update(dataToSave as any).then(response => {
            if (response) {
              /*const _act = wf?.iapWorkFlowActivities.find(x => x.id == dataInput.id)
              if (_act) {
                Object.assign(_act, response)
              }
             */    
    
            }
          })
            .finally(() => {
              HelperLoading.hideSaving()
              resolve(true);
            })
        }
        else{
          resolve(true);
        }
      });

   
  }



  const addWorkFlowActivityControl = (dataInput: IapWorkFlowActivityControl) => {

    if (srvContainer) {
      const dataToSave = JSON.parse(JSON.stringify(dataInput));
      const _srv = srvContainer.get<IServiceWorkFlowActivityControl>(TYPES.WORKFLOWACTIVITYCONTROL_REPOSITORY)
      HelperLoading.showSaving()
      _srv.add(dataToSave as any).then(response => {
        if (response) {
          /*const _act = wf?.iapWorkFlowActivities.find(x => x.id == dataInput.id)
          if (_act) {
            Object.assign(_act, response)
          }
         */

        }
      })
        .finally(() => {
          HelperLoading.hideSaving()
        })
    }
  }

  const deleteWorkFlowActivityControl = (dataInput: IapWorkFlowActivityControl) => {

    if (srvContainer) {
      const dataToSave = JSON.parse(JSON.stringify(dataInput));
      const _srv = srvContainer.get<IServiceWorkFlowActivityControl>(TYPES.WORKFLOWACTIVITYCONTROL_REPOSITORY)
      HelperLoading.showSaving()
      _srv.delete(dataToSave as any).then(response => {
        if (response) {
          /*const _act = wf?.iapWorkFlowActivities.find(x => x.id == dataInput.id)
          if (_act) {
            Object.assign(_act, response)
          }
         */

        }
      })
        .finally(() => {
          HelperLoading.hideSaving()
        })
    }
  }



  const addWorkFlowActivityConnection = (activitySourceId: number, activityTargetId: number, dataInput: IapWorkFlowActivityConnection[]) => {

    if (srvContainer) {

      const dataToSave = JSON.parse(JSON.stringify(dataInput));
      const _srv = srvContainer.get<IServiceWorkFlowActivityConnection>(TYPES.WORKFLOWACTIVITYCON_REPOSITORY)
      HelperLoading.showSaving()
      _srv.add(activitySourceId, dataToSave as any).then(response => {
        if (response) {
          [activitySourceId, activityTargetId].forEach(activityId => {
            const _act = wf?.iapWorkFlowActivities.find(x => x.id == activityId)

            if (_act) {

              let variable = _act.iapWorkFlowActivityVariables.find(x => x.id == dataInput[0].workFlowActivityVariableIdInput);
              if (variable) {
                variable.iapWorkFlowActivityConnectionWorkFlowActivityVariableIdInputNavigations = variable.iapWorkFlowActivityConnectionWorkFlowActivityVariableIdInputNavigations.concat(response);
                //variable.iapWorkFlowActivityConnectionWorkFlowActivityVariableIdOutputNavigations?.concat(response);              
              }

              variable = _act.iapWorkFlowActivityVariables.find(x => x.id == dataInput[0].workFlowActivityVariableIdOutput);
              if (variable) {
                //variable.iapWorkFlowActivityConnectionWorkFlowActivityVariableIdInputNavigations?.concat(response);
                variable.iapWorkFlowActivityConnectionWorkFlowActivityVariableIdOutputNavigations = variable.iapWorkFlowActivityConnectionWorkFlowActivityVariableIdOutputNavigations.concat(response);
              }

            }

          })






        }
      })
        .finally(() => {
          HelperLoading.hideSaving()
        })
    }
  }

  const removeWorkFlowActivityConnection = (activitySourceId: number, activityTargetId: number, dataInput: IapWorkFlowActivityConnection) => {

    if (srvContainer) {
      const dataToSave = JSON.parse(JSON.stringify(dataInput));
      const _srv = srvContainer.get<IServiceWorkFlowActivityConnection>(TYPES.WORKFLOWACTIVITYCON_REPOSITORY)
      HelperLoading.showSaving()
      _srv.delete(dataToSave as any).then(response => {
        if (response) {
          [activitySourceId, activityTargetId].forEach(activityId => {
            const _act = wf?.iapWorkFlowActivities.find(x => x.id == activityId)

            if (_act) {
              let variable = _act.iapWorkFlowActivityVariables.find(x => x.id == dataInput.workFlowActivityVariableIdInput);
              if (variable) {
                variable.iapWorkFlowActivityConnectionWorkFlowActivityVariableIdInputNavigations = variable.iapWorkFlowActivityConnectionWorkFlowActivityVariableIdInputNavigations?.filter(x => x.id !== dataInput.id && x.externalId !== dataInput.externalId) ?? [];
                //variable.iapWorkFlowActivityConnectionWorkFlowActivityVariableIdOutputNavigations= variable.iapWorkFlowActivityConnectionWorkFlowActivityVariableIdOutputNavigations?.filter(x=> x.id !== dataInput.id &&  x.externalId !== dataInput.externalId) ?? [];             
              }

              variable = _act.iapWorkFlowActivityVariables.find(x => x.id == dataInput.workFlowActivityVariableIdOutput);
              if (variable) {
                //variable.iapWorkFlowActivityConnectionWorkFlowActivityVariableIdInputNavigations = variable.iapWorkFlowActivityConnectionWorkFlowActivityVariableIdInputNavigations?.filter(x=> x.id !== dataInput.id &&   x.externalId !== dataInput.externalId) ?? [];
                variable.iapWorkFlowActivityConnectionWorkFlowActivityVariableIdOutputNavigations = variable.iapWorkFlowActivityConnectionWorkFlowActivityVariableIdOutputNavigations?.filter(x => x.id !== dataInput.id && x.externalId !== dataInput.externalId) ?? [];
              }
            }

          })

        }
      })
        .finally(() => {
          HelperLoading.hideSaving()
        })
    }
  }

  return {
    addWorkFlowActivity,
    deleteWorkFlowActivity,
    removeWorkFlowActivityConnection,
    addWorkFlowActivityConnection,
    updateWorkFlowActivity,
    updateWorkFlowActivityVariable,
    updateWorkFlowActivityControl,
    addWorkFlowActivityControl,
    deleteWorkFlowActivityControl


  };
}
