import Dexie, { type EntityTable } from 'dexie';

interface Browsing {
  id: number;
  path: string;
  date: Date;
  userId: number;
  applicationId: number;
  applicationVersion: number;
  parentId: number | null;
  data:any | null,
  caption:string |null
}

const db = new Dexie('BuilderDatabase') as Dexie & {
  browsing: EntityTable<
    Browsing,
    'id' // primary key "id" (for the typings only)
  >;
};

// Schema declaration:
db.version(1).stores({
    browsing: '++id, path, date, userId, applicationId, applicationVersion, parentId,data,caption' // primary key "id" (for the runtime!)
});

export type { Browsing };
export { db };