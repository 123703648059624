<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
            
    <span class="p-float-label wrap-input100 validate-input"  :key="componentKey">
        <!--<Dropdown v-if="paths.length > 1" v-model="selectedPathId"  style="width: 250px;" @change="onChangePath()"
                        :options="paths" :showClear="true" optionValue="id" optionLabel="path"
                        placeholder="" @before-show="loadHistory" filter showClear/>-->

        <p>
            <a><img height="16" src="https://intrasoft.plan.io/assets/images/oauth/azureactivedirectory-8038ed1fa53af2b7bbca32c41cb7042c0a7b44fbcbebf4d4eab749f396b5a0ca.png"></a> {{ microsoftName }} <a @click="loginMicrosoft"> {{ microsoftLogin }} </a><br>
            <a><img height="16" src="https://intrasoft.plan.io/assets/images/oauth/google_oauth2-fe78cb53dd314bb9dcd0fd4c4bb7614836acae6c90662d153038b2976e44609e.png"></a> {{ googleName }} <a @click="loginGoogle"> {{ googleLogin }} </a><br>
            <!--<a class="login-alternative small github" rel="nofollow" data-method="post" href="https://intrasoft.plan.io/oauth/connect_with/github"><img class="grayscale" height="16" alt="Enable login with GitHub" src="/assets/images/oauth/github-42d3d1aad629581e1da8af36992588cf9ec7ed2fe5d303833ddce81ca7b63edd.png"></a>  <a rel="nofollow" data-method="post" href="https://intrasoft.plan.io/oauth/connect_with/github">Enable login with GitHub</a><br>
            <a class="login-alternative small linkedin" rel="nofollow" data-method="post" href="https://intrasoft.plan.io/oauth/connect_with/linkedin"><img class="grayscale" height="16" alt="Enable login with LinkedIn" src="/assets/images/oauth/linkedin-1379fe67af5e752e52672093ab201205192c622fd3d9f0a9adfa256a2c8f7a60.png"></a>  <a rel="nofollow" data-method="post" href="https://intrasoft.plan.io/oauth/connect_with/linkedin">Enable login with LinkedIn</a><br>
            <a class="login-alternative small bitbucket" rel="nofollow" data-method="post" href="https://intrasoft.plan.io/oauth/connect_with/bitbucket"><img class="grayscale" height="16" alt="Enable login with Bitbucket" src="/assets/images/oauth/bitbucket-a435a69d492a89ba6856a4810e270fd9f70519a11a7472c87973f4fb89427fa5.png"></a>  <a rel="nofollow" data-method="post" href="https://intrasoft.plan.io/oauth/connect_with/bitbucket">Enable login with Bitbucket</a><br>-->
        </p>
    </span>
</template>
<script lang="ts">

import { MessageType } from '@ilinium/shared/src/common/infrastructure/servicios/MessageService';
import { shallowRef, defineComponent, onMounted, ref, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import Environment from "@ilinium/shared/src/common/infrastructure/funciones/environment";
import SummaryDebug from '../../shared/SummaryDebug.vue';
import { useRouter } from 'vue-router';
import { AuthenticationResult, AccountInfo } from "@azure/msal-browser";
import { msalInstance, initializeMsalInstance, authMicrosoft } from "../../../../../../builderMaster/auth/infrastructure/component/authConfig";
import HelperLoading from '@ilinium/shared/src/common/infrastructure/funciones/HelperLoading';
import { TYPES } from '@ilinium/shared/src/common/domain/types';
import { IServiceUser } from '@ilinium/shared/src/entidades/builderMaster/user/application/IServiceUser';
import { MessageService } from '@ilinium/shared/src/common/infrastructure/servicios'
import { Mutations } from '@ilinium/shared/src/common/infrastructure/almacen/enums/StoreEnums'
import HelperApplication from '@ilinium/shared/src/entidades/builder/application/infrastructure/functions/helperApplication';
import CatalogConfigurationConst from '@ilinium/shared/src/entidades/builder/catalog/domain/const/CatalogConfigurationConst';

declare const google: any;

export default defineComponent({
    name: 'dynamicsigninwith',
    components: {
        SummaryDebug,
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },
        
    },
    setup(props, context) {
        
        const router = useRouter();
        const store = useStore();
        const microsoftName = shallowRef('');
        const googleName = shallowRef('');
        const microsoftLogin = shallowRef('Activar login con Microsoft');
        const googleLogin = shallowRef('Activar login con Google');
        const appCfg = store.getters.getAppConfig;
        const { getAppKeyValue } = HelperApplication(undefined);
        const googAppId:string = getAppKeyValue(CatalogConfigurationConst.GOOGLE_APPID,Environment.ENVIRONMENT??'',appCfg)??'';

        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded, resolveExpressions, baseOnBeforeUnmount
            ,fieldKey,fieldKeyComponentDataSourceId ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);
        
        let isLoading = ref<boolean>(false);

        const loginGoogle = async () => {
            console.log('loginGoogle called'); // Verifica si esta línea se ejecuta
            if (typeof google === 'undefined') {
                console.error('Google Identity Services not loaded');
                return;
            }
            
            
            if (googleName.value == '') { // Login
                if (typeof google === 'undefined') {
                    console.error('Google Identity Services not loaded');
                    return;
                }

                google.accounts.id.initialize({
                    client_id: googAppId,
                    ux_mode: 'popup', // Puedes usar 'redirect' si prefieres una redirección en lugar de un popup
                    scope: 'profile email', // Añade los scopes necesarios
                    callback: handleCredentialResponse
                });

                google.accounts.id.prompt(); // Mostrar el prompt de inicio de sesión
            }
            else { //logout
                try {
                    await setGoogleAccount(null);
                    googleName.value = '';
                    googleLogin.value = 'Activar login con Google'
                } catch (error) {
                    console.error("Error al cerrar sesión", error);
                    MessageService.showToast(MessageType.Error, '', 'No se ha podido vincular la cuenta.')
                }
            }
        };

        async function handleCredentialResponse(response: any) {
            console.log('ID Token: ', response.credential);
            // Procesar el token de ID aquí
            try {
                var token = response.credential;
                const base64Url = token.split('.')[1]; // El payload del JWT está en la segunda parte del token
                const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                if (response && response.credential) {
                    await setGoogleAccount((JSON.parse(jsonPayload)).email);
                    googleName.value = (JSON.parse(jsonPayload)).email ? 'Google (' + (JSON.parse(jsonPayload)).email + ')' : '';
                    googleLogin.value = 'Desactivar'
                }

            } catch (error) {
                console.error("Error al iniciar sesión", error);
            }
        }

       const loginMicrosoft = async () => {
            if (microsoftName.value == '') { // Login
                var authenticatedAccount = await authMicrosoft();
                if (authenticatedAccount && authenticatedAccount.idToken) {
                    await setMicrosoftAccount(authenticatedAccount.username);
                    microsoftName.value = authenticatedAccount.username ? 'Microsoft (' + authenticatedAccount.username + ')' : '';
                    microsoftLogin.value = 'Desactivar'
                }

            }
            else { //logout
                try {
                     // Cierra sesión en todas las cuentas activas
                    const accounts = msalInstance.getAllAccounts();
                    for (const account of accounts) {
                        try {
                            await msalInstance.logoutPopup({ account });
                        } catch (error) {
                            console.error("Error al cerrar sesión en la cuenta", account, error);
                        }
                    }

                    await setMicrosoftAccount(null);
                    microsoftName.value = '';
                    microsoftLogin.value = 'Activar login con Microsoft'
                } catch (error) {
                    console.error("Error al cerrar sesión", error);
                    MessageService.showToast(MessageType.Error, '', 'No se ha podido vincular la cuenta.')
                }
            }
        };

        const setMicrosoftAccount = async (microsoftAccount: string | null) => {
            HelperLoading.showLoading()
            return await new Promise<void>((resolve, reject) => {
                if (props.container) {
                    let user = store.getters.getCurrentUser;

                    const _srvAuth = props.container.get<IServiceUser>(TYPES.USR_REPOSITORY)
                    _srvAuth.updateMicrosoftAccount(user.id, microsoftAccount)
                    .then((response: any) => {
                        store.commit(Mutations.SET_MICROSOFTACCOUNT, microsoftAccount);
                        HelperLoading.hideLoading()
                        MessageService.showToast(MessageType.Correcto, '', microsoftAccount ? 'Se ha vinculado la cuenta correctamente.' : 'Se ha desvinculado la cuenta correctamente.')
                        resolve(response)
                    })
                    .catch(() => {
                        MessageService.showToast(MessageType.Error, '', 'No se ha podido vincular la cuenta.')
                        HelperLoading.hideLoading()
                        reject()
                    })
                }
            })
        };

        const setGoogleAccount = async (googleAccount: string | null) => {
            HelperLoading.showLoading()
            return await new Promise<void>((resolve, reject) => {
                if (props.container) {
                    let user = store.getters.getCurrentUser;

                    const _srvAuth = props.container.get<IServiceUser>(TYPES.USR_REPOSITORY)
                    _srvAuth.updateGoogleAccount(user.id, googleAccount)
                    .then((response: any) => {
                        store.commit(Mutations.SET_GOOGLEACCOUNT, googleAccount);
                        HelperLoading.hideLoading()
                        MessageService.showToast(MessageType.Correcto, '', googleAccount ? 'Se ha vinculado la cuenta correctamente.' : 'Se ha desvinculado la cuenta correctamente.')
                        resolve(response)
                    })
                    .catch(() => {
                        MessageService.showToast(MessageType.Error, '', 'No se ha podido vincular la cuenta.')
                        HelperLoading.hideLoading()
                        reject()
                    })
                }
            })
        };

        onMounted(async () => {
            await initializeMsalInstance();
            // Verificar si el usuario ya está autenticado al cargar la aplicación
            const account = msalInstance.getAllAccounts()[0];
            /*if (account) {
                microsoftName.value = 'Microsoft (' + account.username + ')';
                microsoftLogin.value = 'Desactivar'
            }
            else*/ 
            if (store.getters.getCurrentUser.microsoftAccount) {
                microsoftName.value = 'Microsoft (' + store.getters.getCurrentUser.microsoftAccount + ')';
                microsoftLogin.value = 'Desactivar'
            }
            if (store.getters.getCurrentUser.googleAccount) {
                googleName.value = 'Google (' + store.getters.getCurrentUser.googleAccount + ')';
                googleLogin.value = 'Desactivar'
            }
        })

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })

        return {
            BaseControlTypeConst,
            ObjectGroupConst,
            getPropertyBooleanValue,
            vmodel,
            componentKey,
            loaded,
            microsoftName,
            googleName,
            microsoftLogin,
            googleLogin,
            loginMicrosoft,
            loginGoogle
        };
    },
});
</script>
<style scoped></style>
