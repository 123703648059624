import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id", "name", "disabled", "visible", "placeholder", "height", "width", "viewBox"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG, _ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? _withDirectives((_openBlock(), _createElementBlock("svg", {
          ref: "svgcontrol",
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.SvgTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.SvgTypeConst.STYLE)),
          class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.SvgTypeConst.CLASS) ?? '']: true, 'customrequired': _ctx.getPropertyBooleanValue(_ctx.SvgTypeConst.REQUIRED) }),
          name: _ctx.getPropertyValue(_ctx.SvgTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.SvgTypeConst.DISABLED),
          visible: _ctx.getPropertyBooleanValue(_ctx.SvgTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW),
          placeholder: _ctx.getPropertyValue(_ctx.SvgTypeConst.PLACEHOLDER),
          height: _ctx.getPropertyNumberValue(_ctx.SvgTypeConst.HEIGHT),
          width: _ctx.getPropertyNumberValue(_ctx.SvgTypeConst.WIDTH),
          viewBox: _ctx.getPropertyValue(_ctx.SvgTypeConst.VB)
        }, null, 14 /* CLASS, STYLE, PROPS */, _hoisted_1)), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.SvgTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}