<template>
       <SummaryDebug v-if="getPropertyBooleanValue(LookUpEditorTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <LookUpEditor v-if="isVisible" :container="container" 
        :id="getPropertyValue(LookUpEditorTypeConst.ID)"
        :key="componentKey"
        v-tooltip="getPropertyValue(LookUpEditorTypeConst.TOOLTIP)" 
        :class="{ [getPropertyValue(LookUpEditorTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted }"
        :name="getPropertyValue(LookUpEditorTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(LookUpEditorTypeConst.DISABLED) || !canDoOperation(ObjectGroupConst.EDIT)"
        :visible="getPropertyBooleanValue(LookUpEditorTypeConst.VISIBLE)"
        :placeholder="getPropertyValue(LookUpEditorTypeConst.PLACEHOLDER)"
        :filterConditions="initialfilterConditions.concat(filterConditions).concat(additionalfilterConditions) ?? []"
        :dataSourceLookUp="dsLookUp" :lookUpDesc="description"
        :dialogWidth="getPropertyValue(LookUpEditorTypeConst.DIALOGWIDTH)"
        :lkStyle="getPropertyValue(LookUpEditorTypeConst.STYLE)"
        :showSearch="getPropertyBooleanValue(LookUpEditorTypeConst.SHOWSEARCH)"
        :showClear="getPropertyBooleanValue(LookUpEditorTypeConst.SHOWCLEAR)"
        :autoFill="getPropertyBooleanValue(LookUpEditorTypeConst.AUTOFILL)"
        :catalogsData="catalogs" :hasDetail="hasDetail" :hasViewCommand="hasViewCommand" :hasSaveCommand="hasSaveCommand"
        :isAddOnTheFly="isAddOnTheFly" :encrusted="getPropertyBooleanValue(LookUpEditorTypeConst.ENCRUSTED)" v-model="vmodel" 
         @change:SelectedLookUpData="selectedLookUpData"  @change:Document="$emit('click:refreshData')"
        :Component="Component"
        :rowData="getRowData"
        @view:Details="openDetail()" @view:NewRegister="openNewRegistration()">
        <template #dialoghead>
                    <slot name="dialoghead"></slot>
        </template>
    </LookUpEditor>
    <CustomValidate v-if="isVisible" v-model:submitted="submitted" v-model:vObject="v$" />
    <Dialog v-if="hasDetail" ref="opDetails" v-model:visible="opViewDetails" modal header=" "
        styleClass="mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6"
        contentStyleClass="border-round-bottom border-top-1 surface-border p-0" :closable="true" :maximizable="true">
        <slot name="detail"></slot>

    </Dialog>

    <Dialog ref="opNewR" v-model:visible="opNewRegister" modal header=" " styleClass="mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6"
        contentStyleClass="border-round-bottom border-top-1 surface-border p-0" :closable="true" :maximizable="true">

        <div v-if="isDynamicAddOnTheFly && !hasSaveCommand">
            <NewObjectRegister  :container="container" :dataSourceLookUp="dsLookUp"
            :idObjeto="CatalogObjectTypeConst.LOOKUP" :catalogsData="catalogs"
            :objetoId="dsLookUp?.id" @refreshData="doRefreshData"></NewObjectRegister>
        </div>
        <div v-else>
            <slot v-if="layoutAddOntheFly && layoutAddOntheFly.get(layoutAddOntheFly?.id ,'children')?.length > 0  && !hasSaveCommand" name="addonthefly"></slot>
            <Message v-else severity="warn" :closable="false">No tiene nada definido para el slot "Add on the fly"</Message>
        </div>
        <template #footer v-if="!isDynamicAddOnTheFly && layoutAddOntheFly && layoutAddOntheFly.get(layoutAddOntheFly?.id ,'children')?.length > 0  && !hasSaveCommand">
            <Button id="save" label="Guardar" icon="pi pi-save"
                class="mr-2  p-button-rounded p-button-primary p-button-outlined" @click="saveCustomForm" />
        </template>
    </Dialog>

    <DocumentViewer v-if="showViewerDocument && selectedDoc" :visible="showViewerDocument"
        @update:Visibility="showViewerDocument = false; selectedDoc = null" :document="selectedDoc" :canvas="canvas" />

</template>
<script lang="ts">


import { defineComponent, onMounted, ref, onBeforeUnmount, onUnmounted, watch, computed  } from 'vue';
import { LocalService, MessageService } from '../../../../../../../common/infrastructure/servicios';
import { MessageType } from '../../../../../../../common/infrastructure/servicios/MessageService';
import { IapComponentDataSource } from '../../../../../component/domain/iapComponentDataSource';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { IapComponentAttribute } from '../../../../../component/domain/iapComponentAttribute';
import LookUpEditorTypeConst from '../../../../domain/Constants/LookUpEditorTypeConst';
import { SearchData } from '../../../../../search/domain/search';
import CatalogObjectTypeConst from '../../../../../catalog/domain/const/CatalogObjectTypeConst';
import CatalogExpConst from '../../../../../catalog/domain/const/CatalogExpConst';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';
import HelperUtils from '../../../../../../../common/infrastructure/funciones/HelperUtils';
import ExpressionNomenclatorConst from '../../../../../expression/domain/const/ExpressionNomenclatorConst';
import CustomValidate from '../../shared/CustomValidate.vue';
import { InteractionEvent } from '../../../../../interaction/domain/interactionEvent';
import InteractionConst from '../../../../../interaction/domain/interactionConst';
import EventBusCustom from '../../../../../../../common/infrastructure/event-bus-custom';
import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';
import { useStore } from 'vuex';
import { Dictionary } from '../../../../../expression/domain/dictionary';
import NewObjectRegister from '../../../../../crud/infrastructure/search/NewObjectRegister.vue';
import DataSourceComp from '../../../../../crud/infrastructure/functions/dataSourceComp';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import ControlTypeConst from '../../../../domain/Constants/ControlTypeConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import DocumentViewer from '../../../../../../../common/infrastructure/componentes/base/common/visor/DocumentViewer.vue';
import HelperLoading from '../../../../../../../common/infrastructure/funciones/HelperLoading';
import { IServiceDocument } from '../../../../../../builderDocument/document/application/IServiceDocument';
import { TYPES } from '../../../../../../../common/domain/types';
import DataBaseTypeConst from '../../../../../../../common/domain/constantes/DataBaseTypeConst';
import OperationDataTypeConst from '../../../../../../../common/domain/constantes/OperationDataTypeConst';
import EventConst from '../../../../../../../common/domain/constantes/EventConst';
import DataSourceConst from '../../../../../crud/infrastructure/functions/dataSourceConst';
import FiltroBusquedaConst from '../../../../../../../common/domain/constantes/FiltroBusquedaConst';
import { IapExpression } from '../../../../../expression/domain/iapExpression';
import { IapDataSourceField } from '../../../../../datasource/domain/iapDataSourceField';
import LookUpHelper from '../../../../../crud/infrastructure/functions/lookUpHelper';
import CatalogTypeConst from '@ilinium/shared/src/common/domain/constantes/CatalogTypeConst';
import helperCatalog from '@ilinium/shared/src/entidades/builder/catalog/infrastructure/helper/helperCatalog';
import { IapCatalog } from '@ilinium/shared/src/entidades/builder/catalog/domain/iapCatalog';

export default defineComponent({
    name: 'dynamic_look_up',
    components: {
        CustomValidate,
        NewObjectRegister,
        SummaryDebug,
        DocumentViewer
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },       
        slotProps: {
            type: Object,
            default: () => ({})
        },
       
        hasDetail: {
            type: Boolean,
            default: false
        },      
        lookUpComps: {
            type: Object as () => {
                compId: number;
                iapComponentAttribute: IapComponentAttribute | null;
            }[],
            default: () => ({})
        },
 

    },
    setup(props, context) {
        //

        const store = useStore();
        const filterExpression = ref(false);
        const filterConditions = ref<Partial<SearchData>[]>([]);
        const opDetails = ref();
        const opNewR = ref();
        const dsLookUp = ref();
        const opViewDetails = ref(false);
        const opNewRegister = ref(false);
        const description=ref();
        const additionalfilterConditions = ref<Partial<SearchData>[]>([]);
        const initialfilterConditions = ref<Partial<SearchData>[]>([]);
        const showViewerDocument = ref(false);
        const selectedDoc = ref();
        const canvas = ref();
        

        const { vmodel,canDoOperation, lookUpData, getPropertyValue, getPropertyId, getPropertyBooleanValue, loaded,  resolveExpressions,
            baseOnMounted, baseOnBeforeUnmount, v$, submitted, isValidData,  getDsLookUpId, getDsFieldsLookUp, getDsLookUp,
            fieldKey, fieldKeyComponentDataSourceId, getComponentDataSourceFromModelValue,  getDsLookUpDesc, 
            processEventComponent, processEvent, lookUpFilter, cleanLookUpFilters, getLayoutComponent, 
            doOperationDataMassive,newRegisterLookUp ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);

        const { getDsCatalogs} = LookUpHelper();

        let catalogs:IapCatalog[]=[];

        const { getFieldKeyColumn } = DataSourceComp(props.container as any, props, context.emit , {}, {} as any, [], store, {} as any)
        const layoutAddOntheFly = getLayoutComponent(props.Component, LookUpEditorTypeConst.LAYOUT_ADDONTHEFLY);

        const isVisible = computed(() => {
            return loaded.value && filterExpression.value && getPropertyBooleanValue(LookUpEditorTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)
        })


        const hasViewCommand = computed(() => {

            const propsIdCmd = getPropertyId(LookUpEditorTypeConst.CMDVIEW) ?? '';
            if (propsIdCmd) {
                return (props.Component.workFlows?.find(x => x.idObjeto == CatalogObjectTypeConst.ATTRCOMP && x.objetoId == propsIdCmd && x.active == true) != undefined)
            }
            return false;

        })
        const hasSaveCommand = computed(() => {

            const propsIdCmd = getPropertyId(LookUpEditorTypeConst.CMDSAVE) ?? '';
            if (propsIdCmd) {
                return (props.Component.workFlows?.find(x => x.idObjeto == CatalogObjectTypeConst.ATTRCOMP && x.objetoId == propsIdCmd && x.active == true) != undefined) && canDoOperation(ObjectGroupConst.EDIT)
            }
            return false;

        })

        const isDynamicAddOnTheFly = computed(() => {
            return dsLookUp.value?.addNewvaluesOnTheFly && !layoutAddOntheFly && canDoOperation(ObjectGroupConst.ADD);
        })

        const isAddOnTheFly = computed(() => {
            return dsLookUp.value?.addNewvaluesOnTheFly || layoutAddOntheFly;
        })

        const getRowData = computed(()=>{
            const modelvalue = getPropertyValue(LookUpEditorTypeConst.MODELVALUE)
            if (modelvalue){
                const cdsId = Number(modelvalue.split('#').at(-1) ?? '-1')
                return props.Component.get(-1,'iapComponentDataSources')?.find(x=> x.id == cdsId)?.dataSource?.items?.find(i => i !== undefined)
            }
            return undefined;
        })


       
        const selectedLookUpData = (data: any) => {
            lookUpData.value = data;
            informOthersLookUpsComps(data);
            processEventComponent(CatalogEventConst.CHANGE)
        }

        const informOthersLookUpsComps = (data: any) => {

            const cds = getComponentDataSourceFromModelValue();
            const actualDsf = getPropertyValue(LookUpEditorTypeConst.MODELVALUE);
            let actualDsfId = "";
            let compsLookUp: number[] = [];
            if (actualDsf) {
                actualDsfId = actualDsf.split('#')[0];
            }
            if (cds) {
                props.lookUpComps.forEach(lk => {

                    if (lk.iapComponentAttribute && lk.iapComponentAttribute.value) {
                        const ids = lk.iapComponentAttribute.value.split('#');
                        const dataSourceFieldId = ids[0];
                        const componentDataSourceId = parseInt(ids[1]);

                        if (cds.id == componentDataSourceId && dataSourceFieldId != actualDsfId) {
                            const dsfConfig = cds.dataSource.iapDataSourceFields.find(dsf => dsf.id == dataSourceFieldId);
                            if (dsfConfig) {
                                if (dsfConfig.dataSourceLookUp && dsfConfig.dataSourceLookUp.iapDataSourceLookUpFieldMaps) {
                                    const fieldMapsFiltered = dsfConfig.dataSourceLookUp?.iapDataSourceLookUpFieldMaps.filter(fm => fm.isFieldFiltered);

                                    fieldMapsFiltered.forEach(fm => {
                                        if (fm.dataSourceFieldIdTarget == actualDsfId) {
                                            compsLookUp.push(lk.compId);
                                        }
                                    });
                                }

                            }
                        }
                    }
                });
            }

            informChangesEvent(compsLookUp, data);
        }

        const informChangesEvent = async (compsLookUp: number[], dataChanged: any) => {

            if (compsLookUp.length > 0) {
                var promises = new Array();

                compsLookUp.filter((value, index, array) => array.indexOf(value) === index).forEach((comp: number) => {

                    promises.push(createPromiseLookUpComps(comp, dataChanged));
                });


                await Promise.all(promises).then((responses: InteractionEvent[]) => {

                    if (responses.length > 0 && responses.filter(x => x.interactionResult).length > 0) {

                    }

                })
            }

        }

        const getDsfByTableAndField = (tableField: string, cds: IapComponentDataSource | undefined | null): IapDataSourceField | undefined => {
            //const fieldsValue = tableField.split('.');


            const fields = getDsFieldsLookUp();

            const lookUpId = getDsLookUp()?.id;


            if (fields && !HelperCommon.isNullOrWhitespace(tableField)) {
                const dsf = fields.find(dsf => '[' + ExpressionNomenclatorConst.EXPNOM_DSLK + '.' + (lookUpId ? lookUpId : 0) + '.' + dsf.id + ']' == tableField);

                if (dsf) {
                    return dsf;
                }

            }

        }

        function isValidLength(arr: any[]): boolean {
            return arr.length % 3 === 0;
        }

        const updateDependenciesAction = (data:any) => {
            const newFilterConditions = setNewFiltersConditions(data);
            createNewFilterConditions(newFilterConditions);

                    //limpiamos filtros dependientes
                    //mi filtro
                    vmodel.value = null;
                    //filtros de mi dependencias
                    const ids = getPropertyValue(LookUpEditorTypeConst.MODELVALUE);

                    if (ids) {
                        const dataSourceFieldId = ids.split('#')[0];
                        cleanLookUpFilters([dataSourceFieldId]);
                    }
        };


        watch(
            loaded, (newvalue, oldvalue) => {
                if (oldvalue !== newvalue && newvalue == true && !filterExpression.value) {
                    initialFilterLookUpConditions();
                    resolveExpConditions();
                }
            },
            {
                flush: 'post',
                //immediate: true, 
                deep: true
            }
        )

        watch(
            lookUpFilter, (newvalue, oldvalue) => {
                if (oldvalue !== newvalue) {
                    updateDependenciesAction(newvalue)
                }
            },
            {
                flush: 'post',
                //immediate: true, 
                deep: true
            }
        )

        watch(() =>newRegisterLookUp.value, (newValue, oldValue) => {
            
            if(newValue!=oldValue){
                updateVModelNewRegister(newRegisterLookUp.value);
            } 
        }, { immediate: true, deep: true });


        const setNewFiltersConditions = (data: any): Dictionary<String, String>[] => {

            let dic: Dictionary<String, String>[] = ([]);

            const fieldMapsFiltered = dsLookUp.value.iapDataSourceLookUpFieldMaps.filter((fm: any) => fm.isFieldFiltered);

            Object.keys(data).forEach(f => {
                if (f.includes(DataSourceConst.LOOKUPID + DataSourceConst.DELIMITERFIELDS) && !f.includes(DataSourceConst.DELIMITERFIELDFORMAT)) {
                    const fieldTargetLookUp = f.replace((DataSourceConst.LOOKUPID + DataSourceConst.DELIMITERFIELDS), '');
                    const fieldMap = fieldMapsFiltered.find(x => x.dataSourceFieldIdTargetLookUp == fieldTargetLookUp);

                    if (fieldMap) {
                        dic.push({
                            key: fieldMap.dataSourceFieldIdSource,
                            value: data[f]
                        } as any)
                    }

                }
            });
            return dic;

        }

        /*Filtro condiciones del look up cuando se actualiza el valor de mi padre. En lookup dependencias*/
        const createNewFilterConditions = (dic: Dictionary<String, String>[]) => {

            const newFilterConditions = ref<Partial<SearchData>[]>([]);

            dic.forEach(dicElement => {
                const dsfSource = dsLookUp.value.dataSource.iapDataSourceFields.find(x => x.id == dicElement.key);

                if (dsfSource) {

                    const filter = {
                        id:HelperUtils.newGuid().toString().replaceAll('-',''),
                        fieldId: dsfSource.id,
                        filter: FiltroBusquedaConst.FILTROBUSQUEDA_IGUAL,
                        value: dicElement.value.toString(),
                        valueList: (dsfSource.catalogTypeId ? [dicElement.value.toString()] : null),
                        valueBool: null,
                        valueNumber: null,
                        valueDateTime: null,
                        rangeValue: null,
                        rangeNumber: null,
                        rangeDateTime: null

                    }

                    if (initialfilterConditions.value?.find(x => x.fieldId == dsfSource.id)) {
                        const index = initialfilterConditions.value.findIndex(x => x.fieldId == dsfSource.id);

                        if (index > -1) {
                            initialfilterConditions.value[index]= filter as any;
                        }
                    }
                    else {
                        newFilterConditions.value.push(filter as any);
                    }

                }
            })

            additionalfilterConditions.value = newFilterConditions.value as any;

        }

        /*filtro de look up tras resolver expresiones*/
        const resolveExpConditions = async () => {


            const cds = getComponentDataSourceFromModelValue();
            const componentDataSourceId = cds?.id;
            const dataSourceFieldId = cds?.dataSource?.iapDataSourceFields?.find(dsf => dsf.dataSourceLookUpId == getDsLookUpId())?.id;


            if (cds) {
                const compsrefs = [cds.componentId] //JSON.parse(LocalService.getValue(LocalService.COMPONENTS_CTRL + (props?.rootParentId ?? -1).toString()) ?? '[]')


                var promises = new Array();

                compsrefs.filter((value, index, array) => array.indexOf(value) === index).forEach((comp: number) => {

                    promises.push(createPromiseExpression(comp));
                });

                let exps: IapExpression[] = [];

                await Promise.all(promises).then((responses: InteractionEvent[]) => {

                    if (responses.length > 0 && responses.filter(x => x.interactionResult).length > 0) {
                        exps = responses.filter(x => x.interactionResult).flatMap(x => x.interactionResult).filter(x => x.idTypeExpression == CatalogExpConst.EXP_FILTERLK)
                    }

                })



                if (exps.filter(x => x).length > 0) {
                    const result = resolveExpressions(CatalogObjectTypeConst.DSF, [dataSourceFieldId, componentDataSourceId].join('#'), CatalogExpConst.EXP_FILTERLK, exps.filter(x => x));

                    if (result) {
                        const resultArr = result.split('#');

                        if (resultArr && isValidLength(resultArr))

                            for (let i = 0; i < resultArr.length; i += 3) {

                                const dsf = getDsfByTableAndField(resultArr[i], cds);

                                if (dsf) {

                                    const filter = {
                                        id:HelperUtils.newGuid().toString().replaceAll('-',''),
                                        fieldId: dsf.id,
                                        filter: resultArr[i + 1],
                                        value: resultArr[i + 2],
                                        valueList: (dsf.catalogTypeId ? [resultArr[i + 2]] : null),
                                        valueBool: null,
                                        valueNumber: null,
                                        valueDateTime: null,
                                        rangeValue: null,
                                        rangeNumber: null,
                                        rangeDateTime: null

                                    }
                                    // metemos nuevo filtro
                                    filterConditions.value.push(filter as any);

                                }



                            }

                    }
                }


                filterExpression.value = true;
            }


        }


        const createPromiseExpression = async (compId: number): Promise<InteractionEvent> => {


            return await new Promise<InteractionEvent>((resolve, reject) => {
                var data: Partial<InteractionEvent> = {
                    typeId: InteractionConst.CALL_FUNCTION,
                    objectName: 'getcurrentexpr()'

                }
                const keyComponentEventBus = props.Component.formKey + props.Component.rootParentId.toString() + compId.toString() + EventConst.INTERACTION;


                var doCallbackOk = (response: InteractionEvent) => {
                    resolve(response);
                }

                EventBusCustom.emit(keyComponentEventBus, { data: data, callBackResponse: doCallbackOk });


            });


        };

        const createPromiseLookUpComps = async (compId: number, dataChanged: any): Promise<InteractionEvent> => {


            return await new Promise<InteractionEvent>((resolve, reject) => {
                var data: Partial<InteractionEvent> = {
                    typeId: InteractionConst.CALL_FUNCTION,
                    objectName: 'updatefilters()',
                    objectValue: dataChanged

                }
                const keyComponentEventBus = props.Component.formKey + props.Component.rootParentId.toString() + compId.toString() + EventConst.INTERACTION;


                var doCallbackOk = (response: InteractionEvent) => {
                    resolve(response);
                }

                EventBusCustom.emit(keyComponentEventBus, { data: data, callBackResponse: doCallbackOk });


            });

        }

        /*Filtro condiciones del look up. Filtrar inicialmente por el valor de mi padre*/
        const initialFilterLookUpConditions = () => {

            const dsc = getComponentDataSourceFromModelValue();

            if (dsc && dsc.autoLoad && dsc.dataSource?.items?.length > 0) {
                const first = dsc.dataSource.items?.find((x: any) => x !== undefined);

                const fieldMapsFiltered = dsLookUp.value.iapDataSourceLookUpFieldMaps.filter((fm: any) => fm.isFieldFiltered);
                const fieldsLookUp = dsLookUp.value.dataSource.iapDataSourceFields;

                fieldMapsFiltered.forEach(fm => {
                    const dsfLk = fieldsLookUp.find(x => x.id == fm.dataSourceFieldIdSource);
                    const dsfPrinc = dsc.dataSource.iapDataSourceFields?.find(x => x.id == fm.dataSourceFieldIdTarget)
                    const fieldName = getFieldKeyColumn(dsfPrinc);

                    if (first[fieldName] != null) {
                        const filter = {
                            id:HelperUtils.newGuid().toString().replaceAll('-',''),
                            fieldId: dsfLk.id,
                            filter: FiltroBusquedaConst.FILTROBUSQUEDA_IGUAL,
                            value: first[fieldName].toString(),
                            valueList: (dsfLk.catalogTypeId ? [first[fieldName].toString()] : null),
                            valueBool: null,
                            valueNumber: null,
                            valueDateTime: null,
                            rangeValue: null,
                            rangeNumber: null,
                            rangeDateTime: null

                        }

                        // metemos nuevo filtro
                        initialfilterConditions.value.push(filter as any);
                    }




                });



            }
        }



        const openDetail = () => {            
            
            if (dsLookUp.value?.idControlType == ControlTypeConst.CTDOCUMENT && dsLookUp.value?.parameters && vmodel.value?.length > 0){
                if (props.container) {
                    const docId = vmodel.value[0]['value']
                    const cfg = JSON.parse(dsLookUp.value?.parameters ?? '[]') as Dictionary<string,string>[]
                    const source = cfg.find(x=> x.value == 'documentConnection')?.key ?? DataBaseTypeConst.DOCUMENTOS_MODEL

                    HelperLoading.showLoading();
                    const _srv = props.container.get<IServiceDocument>(TYPES.STORAGE_REPOSITORY)
                    _srv.getById(docId, props.Component.id as any, source,true).then(response => {

                        if (response) {
                            selectedDoc.value = response;
                            showViewerDocument.value = true;
                        }
                    }).finally(() => {
                        HelperLoading.hideLoading();
                    })
                }
            }
            else{
                if (props.hasDetail) {
                            opViewDetails.value = true;
                        }
                        else {
                            processEvent(CatalogObjectTypeConst.ATTRCOMP, getPropertyId(LookUpEditorTypeConst.CMDVIEW) ?? '');
                        }

            }


        }

        const openNewRegistration = () => {
            
            if(isDynamicAddOnTheFly.value && !hasSaveCommand.value){
                opNewRegister.value = true;
            }
            else if(!isDynamicAddOnTheFly.value && !hasSaveCommand.value){
                openCustomNew();
            }
            else{
                processEvent(CatalogObjectTypeConst.ATTRCOMP, getPropertyId(LookUpEditorTypeConst.CMDSAVE) ?? '');
            }
            
        }


        const doRefreshData = (data:any) => {
            opNewRegister.value = false;
            if(data){
                updateVModelNewRegister(data);
            }
            
        }

        const updateVModelNewRegister=(data:any)=>{
            const newRegister=data.flatMap(x=>JSON.parse(x.resultData))
            const fields=dsLookUp.value?.dataSource?.iapDataSourceFields;
            const fieldMaps = dsLookUp.value?.iapDataSourceLookUpFieldMaps?.filter((fm: any) => fm.isFieldLinked);
            const _vmodel=vmodel.value;
            const fieldDesc=fields?.find(x=>x.id==dsLookUp.value?.idFieldDescription);

            _vmodel.forEach(dicElement=>{

                const fieldMap=fieldMaps.find(x=>x.dataSourceFieldIdTarget=dicElement.key);
                if(fieldMap){
                    const dsfField=fields.find(x => x.id == fieldMap.dataSourceFieldIdSource);

                    newRegister.forEach(result=>{
                        if(result[dsfField?.tableName+DataSourceConst.DELIMITERFIELDS+dsfField?.field]){
                        dicElement.value=result[dsfField?.tableName+DataSourceConst.DELIMITERFIELDS+dsfField?.field];
                    }
                    });
                  
                }
             
                
            });

            newRegister.forEach(result=>{
                        if(result[fieldDesc?.tableName+DataSourceConst.DELIMITERFIELDS+fieldDesc?.field]){
                            description.value=result[fieldDesc?.tableName+DataSourceConst.DELIMITERFIELDS+fieldDesc?.field];
                    }
             });

            vmodel.value=_vmodel;

        }

        const saveCustomForm = async () => {
            

            if (layoutAddOntheFly && layoutAddOntheFly.get(layoutAddOntheFly?.id ,'children')?.length > 0) {
                const dsc = layoutAddOntheFly.get(layoutAddOntheFly?.id ,'children');


                var promises = new Array();
                let canDo = false;
                const validableControls=dsc.filter(x=>ControlTypeConst.VALIDABLE_CONTROLS.includes(x.idType));
                
                validableControls.forEach(comp => {

                    promises.push(createPromiseValidation(comp.id));
                });

                await Promise.all(promises).then((responses: InteractionEvent[]) => {
                    canDo = !(responses.filter(x => !x.interactionResult).length > 0)
                })



                if (canDo) {
                    
                    const dsToInsert = layoutAddOntheFly.get(layoutAddOntheFly?.id ,'children')[0]?.iapComponentDataSources.filter(x => x !== undefined);
                    if (dsToInsert) {
                        
                        const filteredAddInsert = dsToInsert.filter(cds =>
                            cds.iapComponentDataSourceFieldConfigurations.some(field => field.add === true)
                        );

                        if (filteredAddInsert.length == 0) {
                            MessageService.showMessage(MessageType.Error, "", "El origen de datos no tiene ninguna configuración para añadir registros");
                        }
                        else {
                            doOperationDataMassive(filteredAddInsert, layoutAddOntheFly.get(layoutAddOntheFly?.id ,'children')[0].expressions,OperationDataTypeConst.INSERT,filteredAddInsert.length >0).then(response => {
                                if (response) {
                                    MessageService.showToast(MessageType.Correcto, "", 'Registro creado correctamente.')
                                    opNewRegister.value = false
                                }
                            })
                        }

                    }
                }

            }
        }

        const createPromiseValidation = async (compId: number): Promise<InteractionEvent> => {

            return await new Promise<InteractionEvent>((resolve, reject) => {
                var data: Partial<InteractionEvent> = {
                    typeId: InteractionConst.CALL_FUNCTION,
                    objectName: 'isValidData()'

                }
                const keyComponentEventBus = props.Component.formKey + props.Component.rootParentId.toString() + compId.toString() + EventConst.INTERACTION;


                var doCallbackOk = (response: InteractionEvent) => {
                    resolve(response);
                }

                EventBusCustom.emit(keyComponentEventBus, { data: data, callBackResponse: doCallbackOk });


            });


        };

        const openCustomNew = () => {
           
            if (layoutAddOntheFly && layoutAddOntheFly.get(layoutAddOntheFly?.id ,'children')?.length > 0) {
                const dsToInsert = layoutAddOntheFly.get(layoutAddOntheFly?.id ,'children')[0]?.iapComponentDataSources.filter(x => x !== undefined);
                if (dsToInsert) {
                    const filteredAddInsert = dsToInsert.filter(cds =>
                            cds.iapComponentDataSourceFieldConfigurations.some(field => field.add === true)
                        );
                    if (filteredAddInsert.length == 0) {
                        MessageService.showMessage(MessageType.Error, "", "El origen de datos no tiene ninguna configuración para añadir registros");
                    }
                    else {
                        const { insertEmptyRowToDataSource } = DataSourceComp(props.container as any, props, {}, {}, dsToInsert[0], [] as any, store)
                        insertEmptyRowToDataSource(layoutAddOntheFly.get(layoutAddOntheFly.id ,'children')[0].expressions,filteredAddInsert);
                        opNewRegister.value = true
                    }
                }
            }
      
        }



        onMounted(() => {
            EventBusCustom.on(props.Component.formKey + props.Component.rootParentId.toString() + props.Component.id.toString() + '_selectedLookUpData',selectedLookUpData)
            baseOnMounted();
            dsLookUp.value = getDsLookUp();
            if(dsLookUp.value.idControlType == ControlTypeConst.CTSEARCH){
                catalogs=helperCatalog.getCatalogTypes(getDsCatalogs(dsLookUp.value).concat([CatalogTypeConst.FILTROBUSQUEDA])).flatMap(x=>x.iapCatalogs)
            }
            
            description.value=getDsLookUpDesc();

        })

        onBeforeUnmount(() => {            
            opDetails.value = null;
            opNewR.value = null;
            dsLookUp.value = null;
            description.value = null;
            selectedDoc.value = null;
            canvas.value = null;

            EventBusCustom.off(props.Component.formKey + props.Component.rootParentId.toString() + props.Component.id.toString() + '_selectedLookUpData',selectedLookUpData)
            baseOnBeforeUnmount();
        })

       


        return {
          
            MessageService,
            MessageType,           
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            loaded,
            LookUpEditorTypeConst,
            getDsLookUpId,
            getDsLookUp,
            selectedLookUpData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            filterConditions,
            filterExpression,
            isVisible,
            getDsLookUpDesc,
            opDetails,
            opViewDetails,
            openDetail,
            isValidData,
            submitted,
            v$,
            hasViewCommand,
            dsLookUp,

            lookUpFilter,
            additionalfilterConditions,
            initialfilterConditions,
            isDynamicAddOnTheFly,
            openNewRegistration,
            opNewRegister,
            opNewR,
            CatalogObjectTypeConst,
            doRefreshData,
            isAddOnTheFly,
            layoutAddOntheFly,
            saveCustomForm,
            openCustomNew,
            hasSaveCommand,
            description,
            ObjectGroupConst
            ,canDoOperation
            ,showViewerDocument
            ,selectedDoc
            ,canvas
            ,getRowData
            ,componentKey
            ,catalogs

        };
    },
});
</script>
<style scoped></style>
