<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
            
        <div :key="componentKey" class="user-info" style="margin-left:10px"  @click="salir">
            <a href="#" class="layout-topbar-icon" v-tooltip.bottom="{ value: 'Salir' }">
                <i class="topbar-icon pi pi-sign-out"></i>
            </a>
        </div>
</template>
<script lang="ts">


import { defineComponent,  onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import { useRouter } from 'vue-router';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';

export default defineComponent({
    name: 'dynamiclogout',
    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },   
        slotProps: {
            type: Object,
            default: () => ({})
        },
    },
    setup(props, context) {
        
        const router = useRouter();
        const store = useStore();
        
        const { vmodel, getPropertyBooleanValue, loaded, baseOnBeforeUnmount
            ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);
        

       
        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })
        
        const salir = () => {
            HelperCommon.salir(props.container as Container, router)
        };

        return {
            BaseControlTypeConst,
            getPropertyBooleanValue,
            vmodel,
            salir,
            componentKey,
            loaded
        };
    },
});
</script>
<style scoped></style>
