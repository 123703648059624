<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"

            />
            
    <Tag v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(TagTypeConst.ID)" v-tooltip="getPropertyValue(TagTypeConst.TOOLTIP)"
        :style="getPropertyValue(TagTypeConst.STYLE)" :class="getPropertyValue(TagTypeConst.CLASS)"
        :name="getPropertyValue(TagTypeConst.NAME)" :disabled="getPropertyBooleanValue(TagTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(TagTypeConst.VISIBLE)" :placeholder="getPropertyValue(TagTypeConst.PLACEHOLDER)"
        :value="getPropertyValue(TagTypeConst.VALUE)" :rounded="getPropertyBooleanValue(TagTypeConst.ROUNDED)"
        :icon="getPropertyValue(TagTypeConst.ICON)" :severity="getCatalogValue(TagTypeConst.SEVERITY)" v-model="vmodel"
        @click="processEventComponent(CatalogEventConst.CLICK)">        
    </Tag>
</template>
<script lang="ts">


import { defineComponent, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import TagTypeConst from '../../../../domain/Constants/TagTypeConst';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';

export default defineComponent({
    name: 'dynamic_tag',
    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
      
        slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, loaded, resolveExpressions, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue, getCatalogValue, 
            baseOnBeforeUnmount,fieldKey,fieldKeyComponentDataSourceId ,componentKey,processEventComponent
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);



     
        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })


        return {    
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            TagTypeConst,
            getPropertyNumberValue,
            loaded,
            BaseControlTypeConst,
            getCatalogValue,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
            ,processEventComponent
            ,CatalogEventConst
        };
    },
});
</script>
<style scoped></style>
