import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: DataSourceTableAliasInputType!){
    dataSourceTableAliasMutation{
        update(data:$data){
            id,
            dataSourceId,
            tableName,
            tableAlias,
            shortDescription,
            idIcon,
            join,
            isInput,
            fcr,
            ucr,
            uum,
            fum
        }
    }
}
`);
