<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"

            />
            
    <Toolbar v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(BaseControlTypeConst.ID)" v-tooltip="getPropertyValue(BaseControlTypeConst.TOOLTIP)"
        :style="getPropertyValue(BaseControlTypeConst.STYLE)" :class="getPropertyValue(BaseControlTypeConst.CLASS)"
        :name="getPropertyValue(BaseControlTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(BaseControlTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(BaseControlTypeConst.VISIBLE)"
        :placeholder="getPropertyValue(BaseControlTypeConst.PLACEHOLDER)" v-model="vmodel">
    </Toolbar>
</template>
<script lang="ts">


import { defineComponent, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_tool_bar',
    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },   
        slotProps: {
            type: Object,
            default: () => ({})
        },


    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue, loaded, resolveExpressions, baseOnBeforeUnmount 
            ,fieldKey,fieldKeyComponentDataSourceId,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);




        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })


        return {
      

            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            BaseControlTypeConst,
            getPropertyNumberValue,
            loaded,
            resolveExpressions,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
