import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';

import {GET_BY_OBJECTID, GET_BY_IDOBJECT } from './query/objectGroupQuery';
import { UPDATE, ADD, GRANTACCESS, GRANTACCESS_GROUP } from './mutation/objectGroupMutation';
import { IapObjectGroup } from '../domain/iapObjectGroup';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { IObjectGroupRepository } from '../domain/IObjectGroupRepository';




@injectable()
export class ObjectGroupApolloClientRepository implements IObjectGroupRepository {
  private apolloClient : MyApolloClient
  private apolloClientId: string
  constructor (apolloInstance: MyApolloClient, _apolloClientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.apolloClientId = _apolloClientId
  }
  

  async update (dataInput: IapObjectGroup): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{data:dataInput}, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.menuDirectiveMutation)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IapObjectGroup): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD,{data:dataInput}, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.menuDirectiveMutation)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  
  async getByIdObject (applicationId:number, applicationVersion:number, idObject:string): (Promise<IapObjectGroup[]>) {
    return new Promise<IapObjectGroup[]>(async (resolve, reject) => {
      
      const result = this.apolloClient.executeQuery(GET_BY_OBJECTID, { applicationId: applicationId,applicationVersion:applicationVersion, idObject:idObject }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          
          HelperUtils.handleDatesApollo(value) 
          resolve(value.objectGroupQuery.getByIdObject)
        }
        catch(e) {
          
          reject()
        }
      });
    });
  }


  async getByObjectId (applicationId:number, applicationVersion:number, idObject:string,objectId:string): (Promise<IapObjectGroup[]>) {
    return new Promise<IapObjectGroup[]>(async (resolve, reject) => {
      
      const result = this.apolloClient.executeQuery(GET_BY_IDOBJECT, { applicationId: applicationId,applicationVersion:applicationVersion, idObject:idObject, objectId:objectId }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          
          HelperUtils.handleDatesApollo(value) 
          resolve(value.objectGroupQuery.getByObjectId)
        }
        catch(e) {
          
          reject()
        }
      });
    });
  }


  async grantAccessToObjectGroup (objectId: string, objectGroup: IapObjectGroup, objectType: string, applicationId: number, applicationVersion: number): (Promise<Boolean>) {
    return new Promise<Boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GRANTACCESS,
        { objectId: objectId, objectGroup: objectGroup, objectType: objectType, applicationId: applicationId, applicationVersion: applicationVersion }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          //HelperUtils.handleDatesApollo(value) 
          resolve(value)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


  async grantAccessToObjectGroups (objectId: string, objectGroup: IapObjectGroup[], objectType: string, applicationId: number, applicationVersion: number): (Promise<Boolean>) {
    return new Promise<Boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GRANTACCESS_GROUP,
        { objectId: objectId, objectGroup: objectGroup, objectType: objectType, applicationId: applicationId, applicationVersion: applicationVersion }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          //HelperUtils.handleDatesApollo(value) 
          resolve(value)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

}




