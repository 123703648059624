import BaseControlTypeConst from "./BaseControlTypeConst"

export default class ToogleButtonTypeConst extends BaseControlTypeConst {
  
  static ONLABEL='onLabel'
  static OFFLABEL='offLabel'
  static ICONPOS='iconPos'
  static TABINDEX='tabindex'
  static ONICON='onIcon'
  static OFFICON='offIcon'
  
    
  }
  