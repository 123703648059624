import BaseControlTypeConst from "./BaseControlTypeConst"

export default class CardTypeConst extends BaseControlTypeConst {
  
    static LAYOUT_CONTENT='layouttemp-card-content'
    static LAYOUT_FOOTER='layouttemp-card-footer'
    static LAYOUT_HEADER='layouttemp-card-header'
    static LAYOUT_SUBTITLE='layouttemp-card-subtitle'
    static LAYOUT_TITLE='layouttemp-card-title'
  
    
  }