<template>
  <Panel toggleable class="m-1" :collapsed="true">
    <template #header>
      <div class="flex items-center gap-2">
        <i class="pi pi-wrench" style="font-size: 2rem; color: #04aa6d"></i>
        <span class="font-bold">{{ "Debug " + data.name }}</span>
      </div>
    </template>
    <Message :closable="false" v-tooltip.focus.bottom="{ value: 'Valor del componente' }">{{ vmodel }}</Message>
    <Panel toggleable class="m-1" :collapsed="true">
      <template #header>
        <div class="flex items-center gap-2">
          <i class="pi pi-lock" style="color: #04aa6d"></i>
          <span class="font-bold">Permisos</span>
        </div>
      </template>
      <div class="flex flex-wrap gap-4 justify-center ml-4">
        <Message :severity="formatLabelSecurity(ObjectGroupConst.ADD)" :closable="false">Añadir</Message>
        <Message :severity="formatLabelSecurity(ObjectGroupConst.EDIT)" :closable="false">Editar</Message>
        <Message :severity="formatLabelSecurity(ObjectGroupConst.DELETE)" :closable="false">Eliminar</Message>
        <Message :severity="formatLabelSecurity(ObjectGroupConst.VIEW)" :closable="false">Ver</Message>
        <Message :severity="formatLabelSecurity(ObjectGroupConst.DETAIL)" :closable="false">Listar</Message>
        <Message :severity="formatLabelSecurity(ObjectGroupConst.EXPORT)" :closable="false">Exportar</Message>
        <Message :severity="formatLabelSecurity(ObjectGroupConst.EXECUTE)" :closable="false">Ejecutar</Message>    
    </div>
      </Panel>
    <Panel toggleable class="m-1" :collapsed="true">
      <template #header>
        <div class="flex items-center gap-2">
          <i class="pi pi-wrench" style="color: #04aa6d"></i>
          <span class="font-bold">Atributos</span>
        </div>
      </template>
      <table class="m-1 summarydebug">
        <thead>
          <th>Variable</th>
          <th>Valor</th>
        </thead>
        <tbody>         
          <tr v-for="(data, i) in data.iapComponentAttributes" :key="i">
            <td>{{ data.name }}</td>
            <td>{{ data.value }}</td>
          </tr>
        </tbody>
      </table>
    </Panel>
    <Panel v-if="dictionaryData?.length > 0" toggleable class="m-1" :collapsed="true"  >
      <template #header>
        <div class="flex items-center gap-2">
          <i class="pi pi-wrench" style="color: #04aa6d"></i>
          <span class="font-bold">Diccionario</span>

          <i class="pi pi-refresh ml-2" @click="keyDic++" i></i>

        </div>
      </template>
      <table :key="keyDic" class="m-1 summarydebug">
        <thead>
          <th>Clave</th>
          <th>Valor</th>
        </thead>
        <tbody>          
          <tr v-for="(data, i) in dictionaryData" :key="i">
            <td>{{ data.key }}</td>
            <td>{{ data.value }}</td>
          </tr>
        </tbody>
      </table>
    </Panel>
  </Panel>
</template>
<script lang="ts">
import { defineComponent, computed, shallowRef } from "vue";
import { LocalService } from "../../../../../../common/infrastructure/servicios";
import WidgetComponentDataTree from "../../../../designer/domain/widgetComponent";
import ComponentDataForm from "../../../../designer/domain/ComponentDataForm";
import { useStore } from "vuex";
import HelperSecurity from "../../../../../../common/infrastructure/funciones/HelperSecurity";
import ObjectGroupConst from "../../../../../../common/domain/constantes/ObjectGroupConst";

export default defineComponent({
  name: "summary_debug",
  props: {
    data: {
      type: Object as () => ComponentDataForm ,
      default: () => ({}),
    },
    vmodel: Object as () => any,
  
  },
  setup(props) {
    const keyDic = shallowRef(1);
    const store = useStore()
    const user = store?.getters.getCurrentUser;
    const dictionaryData = computed(() => {
      
      const localData = LocalService.getValue(
        props.data.formKey +
          LocalService.COMPONENTS_EXP +
          (props.data.rootParentId ?? -1).toString()
      );
      const data = JSON.parse(localData ?? "[]");
      return data
     

      //return []
    });


    
    const formatLabelSecurity = (action:string) => {
      
      const resu = HelperSecurity.canDoOperation(user,(props.data?.objectGroups ?? []),action)
         if (resu){
          return 'success'
         }
         else{
          return 'error'
         }
    }


    

    return {
      keyDic,
      dictionaryData,
      formatLabelSecurity,
      ObjectGroupConst
    };
  },
});
</script>
<style scoped>
.summarydebug {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.summarydebug td,
.summarydebug th {
  border: 1px solid #ddd;
  padding: 8px;
}

.summarydebug tr:nth-child(even) {
  background-color: #f2f2f2;
}

.summarydebug tr:hover {
  background-color: #ddd;
}

.summarydebug th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
</style>
