import HelperCommon from "./HelperCommon";

const isValidIBAN = (iban: string): boolean => {
    try {
        if (HelperCommon.isNullOrWhitespace(iban)) {
            return false;
        }
        let IBAN = iban.toUpperCase(); // IN ORDER TO COPE WITH THE REGEX BELOW
        if (HelperCommon.isNullOrWhitespace(iban) || IBAN.length < 20) {
            return false;
        } else if (!/^[A-Z0-9]/.test(IBAN)) {
            return false;
        } else {
            IBAN = IBAN.replace(/\s/g, '');
            const bank = iban.substring(4, IBAN.length - 4) + IBAN.substring(0, 4);
            const asciiShift = 55;
            let sb = '';
            for (const c of bank) {
                let v: number;
                if (/[A-Z]/.test(c)) {
                    v = c.charCodeAt(0) - asciiShift;
                } else {
                    v = parseInt(c, 10);
                }
                sb += v.toString();
            }
            const checkSumString = sb;
            let checksum = parseInt(checkSumString.substring(0, 1), 10);
            for (let i = 1; i < checkSumString.length; i++) {
                const v = parseInt(checkSumString.substring(i, i + 1), 10);
                checksum *= 10;
                checksum += v;
                checksum %= 97;
            }
            return (checksum === 1);
        }
    } catch (error) {
        return false;
    }
}



const  isValidNIF = (documentId: string): boolean => {

    if (HelperCommon.isNullOrWhitespace(documentId)) {
        return false;
    }

    let aux: string | null = null;
    documentId = documentId.trim();
    if (documentId.length !== 9) { return false; }
    documentId = documentId.toUpperCase();
    aux = documentId.substring(0, documentId.length - 1);
    if (aux.length > 7 && isNumeric(aux)) {
        aux = calculateNIF(aux);
    } else {
        return false;
    }
    return (documentId === aux);
}

function calculateNIF(strA: string): string {
    const cCADENA = "TRWAGMYFPDXBNJZSQVHLCKE";
    const cNUMEROS = "0123456789";

    let a = 0;
    let b = 0;
    let c = 0;
    let NIF = 0;
    let sb: string[] = [];

    strA = strA.trim();
    if (strA.length === 0) return "";

    // Keep only the numbers
    for (let i = 0; i < strA.length; i++) {
        if (cNUMEROS.indexOf(strA[i]) > -1) sb.push(strA[i]);
    }

    strA = sb.join('');
    a = 0;
    NIF = parseInt(strA, 10);
    do {
        b = Math.floor(NIF / 24);
        c = NIF - (24 * b);
        a = a + c;
        NIF = b;
    } while (b !== 0);

    b = Math.floor(a / 23);
    c = a - (23 * b);
    return strA + cCADENA.charAt(c);
}

function isNumeric(value: string): boolean {
    return /^\d+$/.test(value);
}



const  isValidCIF = (documentId: string): boolean => {
    
    if (HelperCommon.isNullOrWhitespace(documentId)) {
        return false;
    }

    documentId = documentId.replace(/-/g, "").trim();
    if (documentId.length !== 9) { return false; }
    const letrasCodigo = ["J", "A", "B", "C", "D", "E", "F", "G", "H", "I"];
    const LetraInicial = documentId[0];
    // Error #23456 - La primera letra de un CIF es siempre una letra
    if (isNumeric(LetraInicial)) {
        return false;
    }
    const DigitoControl = documentId[documentId.length - 1];
    const n = documentId.substring(1, documentId.length - 1);
    let sumaPares = 0;
    let sumaImpares = 0;
    let sumaTotal = 0;
    let retVal = false;

    for (let i = 0; i < n.length; i++) {
        let aux = parseInt(n[i], 10);

        if ((i + 1) % 2 === 0) {
            sumaPares += aux;
        } else {
            aux *= 2;
            sumaImpares += SumaDigitos(aux);
        }
    }
    sumaTotal += sumaPares + sumaImpares;
    let unidades = sumaTotal % 10;
    if (unidades !== 0) unidades = 10 - unidades;

    switch (LetraInicial) {
        case "A":
        case "B":
        case "E":
        case "H":
            retVal = DigitoControl === unidades.toString();
            break;
        case "K":
        case "P":
        case "Q":
        case "S":
            retVal = DigitoControl === letrasCodigo[unidades];
            break;
        default:
            retVal = (DigitoControl === unidades.toString()) || (DigitoControl === letrasCodigo[unidades]);
            break;
    }
    return retVal;
}

export function SumaDigitos(digitos: number): number {
    const sNumero = digitos.toString();
    let suma = 0;

    for (let i = 0; i < sNumero.length; i++) {
        const aux = parseInt(sNumero[i], 10);
        suma += aux;
    }
    return suma;
}

const  isValidNIE = (documentId: string): boolean =>{
    
    if (HelperCommon.isNullOrWhitespace(documentId)) {
        return false;
    }
    
    documentId = documentId.trim();
    if (documentId.length !== 9) { return false; }
    const AllLetters = "XYZ";
    const letter = documentId[0].toUpperCase();
    
    switch (letter) {
        case "X":
            documentId = "0" + documentId.substring(1);
            break;
        case "Y":
            documentId = "1" + documentId.substring(1);
            break;
        case "Z":
            documentId = "2" + documentId.substring(1);
            break;
    }
    if (!AllLetters.includes(letter)) {
        return false;
    }
    return isValidNIF(documentId);
}








export default {
    isValidIBAN,
    isValidNIF,
    isValidNIE,
    isValidCIF
}