import BaseControlTypeConst from "./BaseControlTypeConst"

export default class ChartTypeConst extends BaseControlTypeConst {

    static TYPE= 'type'
    static DATA= 'data'
    static OPTIONS= 'options'
    static PLUGINS= 'plugins'
    static WIDTH= 'width'
    static HEIGHT= 'height'
    
    
  }