import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: ModeloComponentDataSourceFieldConfigurationUserInputType!){
    ComponentDataSourceFieldConfigurationUserMutation{
        updateComponentDataSourceFieldConfigurationUser(data:$data)
    }
}
`);

export const ADD = gql(`
mutation($data: ModeloComponentDataSourceFieldConfigurationUserInputType!){
    ComponentDataSourceFieldConfigurationUserMutation{
        addComponentDataSourceFieldConfigurationUser(data:$data)
    }
}
`);



