import { IapDataSourceLookUp } from '../../../datasource/domain/iapDataSourceLookUp';


export default function LookUpHelper() {

    const getDsCatalogs=(lk:IapDataSourceLookUp):string[]=>{
        const dsCatalogName = lk.dataSource.iapDataSourceFields.filter(x => x.catalogTypeId != null);
        return dsCatalogName && dsCatalogName.length > 0
                ? dsCatalogName.flatMap(x => x.catalogTypeId).filter((id): id is string => id !== null)
                : [];
    }



  return {
    getDsCatalogs
      
  };
}


