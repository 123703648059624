import BaseControlTypeConst from "./BaseControlTypeConst"

export default class DropDownTypeConst extends BaseControlTypeConst {

    static OPTIONS = 'options'
    static OPTIONLABEL = 'optionLabel'
    static OPTIONVALUE = 'optionValue'
    static OPTIONDISABLED = 'optionDisabled'
    static OPTIONGROUPLABEL = 'optionGroupLabel'
    static OPTIONGROUPCHILDREN = 'optionGroupChildren'
    static FILTER = 'filter'
    static FILTERPLACEHOLDER = 'filterPlaceholder'
    static DATAKEY = 'dataKey'
    static CLEAR = 'showClear'
    static READONLY = 'readonly'

    static DS = 'dataSource'
    static CATALOG_TYPE_ID = 'catalogType'
    static OPTION_FILE = 'optionsFile'
}
