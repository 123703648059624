import { decompress } from 'compress-json'
import { Dictionary } from "../../../expression/domain/dictionary";

   //@ts-ignore:disable-next-line
let dic: Dictionary<number, object>[] = ([/* REPLACE */]);


const getComponentData = (componentId: number ) => {
    const comp = dic.find(x=> x.key == componentId);
    if (comp){
        return decompress(comp.value as any);
    }
    else{
        return [];
    }
  }

const decompressComp=(comp:any)=>{
      if (comp){
        return decompress(comp as any);
      }
      else{
          return [];
      }
}

export default {
    getComponentData,
    decompressComp
  
  }
  