<template>
        <SummaryDebug v-if="getPropertyBooleanValue(AutocompleteTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
            
    <AutoComplete v-if="loaded && getPropertyBooleanValue(AutocompleteTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(AutocompleteTypeConst.ID)" v-tooltip="getPropertyValue(AutocompleteTypeConst.TOOLTIP)"
        :style="getPropertyValue(AutocompleteTypeConst.STYLE)"
        :class="{ [getPropertyValue(AutocompleteTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted, 'p-readonly': getPropertyBooleanValue(AutocompleteTypeConst.READONLY) }"
        :name="getPropertyValue(AutocompleteTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(AutocompleteTypeConst.DISABLED) && !canDoOperation(ObjectGroupConst.EDIT)"
        :visible="getPropertyBooleanValue(AutocompleteTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :placeholder="getPropertyValue(AutocompleteTypeConst.PLACEHOLDER)" 
        :suggestions="filteredData"
        :field="getPropertyValue(AutocompleteTypeConst.FIELD)" 
        :optionLabel="fieldSearch"
        :optionDisabled="getPropertyValue(AutocompleteTypeConst.OPTIONDISABLED)"
        :optionGroupLabel="getPropertyValue(AutocompleteTypeConst.OPTIONGROUPLABEL)"
        :optionGroupChildren="getPropertyValue(AutocompleteTypeConst.OPTIONGROUPCHILDREN)" 
        :scrollHeight="getPropertyValue(AutocompleteTypeConst.SCROLLHEIGHT)"
        :dropdown="getPropertyBooleanValue(AutocompleteTypeConst.DROPDOWN)"
        :dropdownMode="getCatalogValue(AutocompleteTypeConst.DROPDOWNMODE)"
        :autoHighlight="getPropertyBooleanValue(AutocompleteTypeConst.AUTOHIGHLIGHT)"
        :multiple="getPropertyBooleanValue(AutocompleteTypeConst.MULTIPLE)"
        :loading="getPropertyBooleanValue(AutocompleteTypeConst.LOADING)"
        :dataKey="getPropertyValue(AutocompleteTypeConst.DATAKEY)"
        :minLength="getPropertyNumberValue(AutocompleteTypeConst.MINLENGTH)"
        :delay="getPropertyNumberValue(AutocompleteTypeConst.DELAY)"
        :appendTo="getPropertyValue(AutocompleteTypeConst.APPENDTO)"
        :forceSelection="getPropertyBooleanValue(AutocompleteTypeConst.FORCESELECTION)"
        :completeOnFocus="getPropertyBooleanValue(AutocompleteTypeConst.COMPLETEONFOCUS)"
        :inputId="getPropertyValue(AutocompleteTypeConst.INPUTID)"
        :inputStyle="getPropertyValue(AutocompleteTypeConst.INPUTSTYLE)"
        :inputClass="getPropertyValue(AutocompleteTypeConst.INPUTCLASS)"
        :inputProps="getInputStyle"
        :panelStyle="getPropertyValue(AutocompleteTypeConst.PANELSTYLE)"
        :panelClass="getPropertyValue(AutocompleteTypeConst.PANELCLASS)"
        :panelProps="getPropertyValue(AutocompleteTypeConst.PANELPROPS)"
        :dropdownIcon="getPropertyValue(AutocompleteTypeConst.DROPDOWNICON)"
        :dropdownClass="getPropertyValue(AutocompleteTypeConst.DROPDOWNCLASS)"
        :loadingIcon="getPropertyValue(AutocompleteTypeConst.LOADINGICON)"
        :removeTokenIcon="getPropertyValue(AutocompleteTypeConst.REMOVETOKENICON)"
        :virtualScrollerOptions="getPropertyValue(AutocompleteTypeConst.VIRTUALSCROLLEROPTIONS)"
        :autoOptionFocus="getPropertyBooleanValue(AutocompleteTypeConst.AUTOOPTIONFOCUS)"
        :selectOnFocus="getPropertyBooleanValue(AutocompleteTypeConst.SELECTONFOCUS)"
        :searchLocale="getPropertyValue(AutocompleteTypeConst.SEARCHLOCALE)"
        :searchMessage="getPropertyValue(AutocompleteTypeConst.SEARCHMESSAGE)"
        :selectionMessage="getPropertyValue(AutocompleteTypeConst.SELECTIONMESSAGE)"
        :emptySelectionMessage="getPropertyValue(AutocompleteTypeConst.EMPTYSELECTIONMESSAGE)"
        :emptySearchMessage="getPropertyValue(AutocompleteTypeConst.EMPTYSEARCHMESSAGE)"
        :tabindex="getPropertyNumberValue(AutocompleteTypeConst.TABINDEX)"
        :ariaLabel="getPropertyValue(AutocompleteTypeConst.ARIALABEL)"
        :ariaLabelledby="getPropertyValue(AutocompleteTypeConst.ARIALABELLEDBY)"
        v-model="vmodel"
        @complete="search">


        <template #content="slotProps">
            <slot name="content"
                :slotProps="{ data: slotProps.items, styleClass: slotProps.styleClass, control: ControlTypeConst.CTAUTOCOMPLETE}"></slot>
        </template>

        <template #header>
            <slot name="header"></slot>
        </template>
        <slot name="content"></slot>

        <template #footer>
            <slot name="footer"></slot>
        </template>

        <template #empty>
            <slot name="empty"></slot>
        </template>

        <template #optiongroup="slotProps">
            <slot name="optiongroup"
                :slotProps="{ data: slotProps.option,index:slotProps.index, control: ControlTypeConst.CTAUTOCOMPLETE}"></slot>
        </template>

        <template #option="slotProps">
            <slot name="option"
                :slotProps="{ data: slotProps.option,index:slotProps.index, control: ControlTypeConst.CTAUTOCOMPLETE}"></slot>
        </template>


    </AutoComplete>
    <div></div>
    <CustomValidate v-if="loaded && getPropertyBooleanValue(AutocompleteTypeConst.VISIBLE)" v-model:submitted="submitted"
        v-model:vObject="v$" />
</template>

<script lang="ts">

import { defineComponent, onMounted, ref, onUnmounted,computed, shallowRef, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import AutocompleteTypeConst from '../../../../domain/Constants/AutocompleteTypeConst';
import ControlTypeConst from '../../../../domain/Constants/ControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import CustomValidate from '../../shared/CustomValidate.vue';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_autocomplete',
    components: {
        CustomValidate,
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {
        //
        
        const store = useStore();
        const data = ref();
        const filteredData = ref();
        const fieldSearch = shallowRef('');
        const { vmodel, getPropertyValue, getPropertyBooleanValue, getCatalogValue, getPropertyNumberValue, loaded, resolveExpressions, baseOnMounted,  baseOnBeforeUnmount, v$, submitted, canEdit, isValidData
            , fieldKey, fieldKeyComponentDataSourceId, getComponentDataSourceItemsFromId, getOptionLabelAutocomplete
            ,canDoOperation,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store)


        
        const getInputStyle = computed(() => {

            let inputStyleObj:any={};
            
            if(getPropertyBooleanValue(AutocompleteTypeConst.READONLY) ){
                inputStyleObj.readonly=true;
            }
            
            return inputStyleObj;
        })

        const search = (event: any) => {

            setTimeout(() => {
                if (!event.query.trim().length) {
                    filteredData.value = [...data.value];
                } else {
                    if (data.value) {
                        filteredData.value = data.value.filter((x: any) => {
                            if (fieldSearch.value) {
                                return x[fieldSearch.value].toLowerCase().startsWith(event.query.toLowerCase());
                            }

                        });
                    }

                }
            }, 250);
        }

        onMounted(() => {

            data.value = getComponentDataSourceItemsFromId(AutocompleteTypeConst.SUGGESTIONS);
            fieldSearch.value = getOptionLabelAutocomplete();

        })


        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
            data.value = null;
            filteredData.value = null;
            fieldSearch.value = '';
        })

        onUnmounted(() => {
            
        })

        return {           
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            AutocompleteTypeConst,
            loaded,
            v$,
            submitted,
            canEdit,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            getPropertyNumberValue,
            getCatalogValue,
            filteredData,
            search,
            fieldSearch,
            ControlTypeConst,
            getInputStyle,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
