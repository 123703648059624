import { useCookies } from 'vue3-cookies'

export const NOSTROMO_BROKER = 'nostromo.broker' as string
export const SEGURPASS = 'segurpassId' as string
export const COOKIE_REDIRECT_URL = 'NostromoRedirectAfterLogin' as string
export const COOKIE_DOBLEFACTOR = 'dobleFactorOk' as string
export const SITE_ID = 'nostromo.site' as string

const { cookies } = useCookies()
// const $cookies = VueCookies(),

/**
 * @description get value cookie
 */
export const setCookie = (key: string, value: string, duration: number): void => {
  cookies.set(key, value, duration, '/', '', true)
  // $cookies.set('nostromo.broker', store.getters.currentBrokerId, 60 * 60 * 24 * 30); // caduca en un mes
  // $cookies.set('segurpassId', store.getters.currentUser?.id, 60 * 60 * 24 * 5); // caduca en 5 dias
}

export const getCookie = (key: string): string | null => {
  return cookies.get(key)
  // $cookies.set('nostromo.broker', store.getters.currentBrokerId, 60 * 60 * 24 * 30); // caduca en un mes
  // $cookies.set('segurpassId', store.getters.currentUser?.id, 60 * 60 * 24 * 5); // caduca en 5 dias
}

/**
 * @description remove value form localStorage
 */
export const destroy = (key: string): void => {
  document.cookie = key +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';    
  cookies.remove(key);
};

/**
* @description remove value form localStorage
*/
export const destroyAll = (): void => {
  const cookiesList =[NOSTROMO_BROKER, SEGURPASS, COOKIE_REDIRECT_URL];

  cookiesList.forEach(name => {
      document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';    
      cookies.remove(name);
  });
};

export default { NOSTROMO_BROKER, SEGURPASS, COOKIE_REDIRECT_URL, SITE_ID, COOKIE_DOBLEFACTOR, setCookie, getCookie, destroy, destroyAll }
