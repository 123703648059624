
<template>

    <SummaryDebug v-if="getPropertyBooleanValue(OrganizationChartTypeConst.DEBUG,Component)"
                :data="Component"
                :vmodel="vmodel"
                />
        <OrganizationChart  
            v-if ="loaded && chartvalues && (getPropertyBooleanValue(OrganizationChartTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW))" 
            :key="componentKey"
            :id="getPropertyValue(OrganizationChartTypeConst.ID)" 
            v-tooltip="getPropertyValue(OrganizationChartTypeConst.TOOLTIP)"
            :style="getPropertyValue(OrganizationChartTypeConst.STYLE)"
            :class="{[getPropertyValue(OrganizationChartTypeConst.CLASS) ?? '']: true}"
            :name="getPropertyValue(OrganizationChartTypeConst.NAME)"
            
            
            :value = "chartvalues"
            :collapsible="getPropertyBooleanValue(OrganizationChartTypeConst.COLLAPSIBLE)"
            :selectionMode = "getSelectionMode()"
  
            :expandedKeys = "expandedKey ?? undefined" 
            :selectionKeys = "selectedKey ?? undefined" 
  
            @node-expand="onNodeExpand"
            @node-collapse="onNodeCollapse"
            @node-select="onNodeSelect"
            @node-unselect="onNodeUnSelect"
            @update:expanded-keys="updateExpandedKeys"
            @update:selection-keys="updateSelectionKeys"
            v-model="arrayModel"
            @change ="setSelectedKeyToVModel()"
            @contextmenu="onRightClick"
            >
          
            <template v-if="getPropertyValue(OrganizationChartTypeConst.TEMPLATETYPE) == CatalogOrgChart.COUNTRY" #country="slotProps">
                <div class="flex flex-col items-center">
                    <img :alt="slotProps.node.label" src="https://primefaces.org/cdn/primevue/images/flag/flag_placeholder.png" :class="`w-2rem flag flag-${slotProps.node.data}`" @error="noImageUrl($event)"/>
                    <div class="mt-4 font-medium text-lg">{{ slotProps.node.label }}</div>
                </div>
            </template>

            <template v-else-if="getPropertyValue(OrganizationChartTypeConst.TEMPLATETYPE) == CatalogOrgChart.PERSON" #person="slotProps">
            <div class="flex flex-col">
                <div class="flex flex-col items-center">
                    <img :alt="slotProps.node.data.name" :src="slotProps.node.data.image" class="mb-4 w-12 h-12" @error="noImageUrl($event)"/>
                    <span class="font-bold mb-2">{{ slotProps.node.data.name }}</span>
                    <span>{{ slotProps.node.data.title }}</span>
                </div>
            </div>
        </template>

            <template v-else #default="slotProps">
                <span>{{ slotProps.node.label }}</span>
            </template>


        </OrganizationChart>
        
        <ContextMenu ref="menu" :model="menuItems" />
        <div v-if="false" class="row">
            <div class="col-md-12">
                <label style="color:red;padding-left: 5px;">vmodel:  {{ vmodel == null ? 'null' : vmodel }} </label><br/>
                <label style="color:blue;padding-left: 5px;" for="">arrayModel:  {{ arrayModel == null ? 'null' : arrayModel }} </label><br/>
                <label style="color:orangered;padding-left: 5px;">SelectedKey:  {{ selectedKey == null ? 'null' : selectedKey }} </label><br/>
                <label style="color:green;padding-left: 5px;">ExpandedKey:  {{ expandedKey == null ? 'null' : expandedKey }} </label>
            </div>
        </div>
        
        
    </template>
    
    <script lang="ts">
    
    import { defineComponent, onMounted, ref, onBeforeUnmount, shallowRef,watchEffect } from 'vue';
    import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
    import OrganizationChartTypeConst from '../../../../domain/Constants/OrganizationChartTypeConst';
    import { Container } from 'inversify';
    import WidgetComponentDataTree from '../../../../../designer/domain/ComponentDataForm';
    import ControlTypeConst from '../../../../domain/Constants/ControlTypeConst';
    import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';
    import { useStore } from 'vuex';	
    import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
    import SummaryDebug from '../../shared/SummaryDebug.vue';
import { OrganizationChartNode } from 'primevue/organizationchart';
import CatalogOrgChart from '../../../../../catalog/domain/const/CatalogOrgChart';
    
    export default defineComponent({
        name: 'dynamic_organization_chart',
        emits: ['node-expand', 'node-collapse', 'update:expandedKeys', 'update:selectionKeys', 'node-select', 'node-unselect'],
        components: {
            SummaryDebug
        },
        props:
        {
            container: {
                type: Object as () => Container
            },
            Component: {
                type: Object as () => WidgetComponentDataTree,
                default: () => ({})
            },
    
            slotProps: {
                type: Object,
                default: () => ({}),      
        },
        },
        setup(props, context) {
            const arrayModel = ref([]);
            const store = useStore();
    
            const setVModelToArray = () =>{    
                arrayModel.value = (vmodel.value ?? '').split(',').filter(x => x !== '') as []
            }
    
            const { vmodel, canDoOperation, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue
                , getPropertyValueObjectOrFile, getCatalogValue
                , loaded, baseOnMounted, baseOnBeforeUnmount, canEdit
                , isValidData, fieldKey, fieldKeyComponentDataSourceId, processEventComponent 
                ,componentKey,getLayoutComponent,buildMenu
            } = ComponentCommonRender(props.Component,props.slotProps, props.container,store,setVModelToArray)        
    
            
            const expandedKey = ref(null);
            const selectedKey = ref(null);
            const chartvalues =shallowRef<OrganizationChartNode| undefined> (undefined);
            const menu = ref();
            const menuItems = shallowRef([]);
            const layoutMenu = getLayoutComponent(props.Component, OrganizationChartTypeConst.LAYOUT_MENU);
            if (layoutMenu && layoutMenu.get(layoutMenu.id,'children')?.length > 0) {
                menuItems.value = buildMenu(layoutMenu.get(layoutMenu.id,'allchildren') ?? []) as any;
                
            }
    
    
            const onRightClick = (event: any) => {
                if (menuItems.value.length>0){
                    menu.value.show(event);
                    event.stopPropagation();
                }
                
                
            };
    
            const setSelectedKeyToVModel = () => {
                if ((Object.keys(selectedKey.value ?? {})).length > 0) {
    
                    if (getSelectionMode() == 'single'){
                        vmodel.value = Object.keys(selectedKey.value ?? {})[0]
                    }
                    else{
                        vmodel.value = JSON.stringify((Object.keys(selectedKey.value ?? {})).join(','))
                    }
                    
                }
                else {
                    vmodel.value = null
                }
                processEventComponent(CatalogEventConst.CHANGE)
            }
    
            function noImageUrl(event: any) {
                const appPublic = process.env.VUE_APP_PUBLIC_PATH ?? '/';
                let src = `${appPublic}layout/images/pages/no-image.png`;
                event.target.src = src;
            }
    
          
    
    
    
            const getValue = () => {
                
                getPropertyValueObjectOrFile(OrganizationChartTypeConst.NODEVALUE, OrganizationChartTypeConst.DATAFILEVALUE, OrganizationChartTypeConst.DATASOURCEVALUE,null, false).
                then((response:any) => {
                    if (response){
                        chartvalues.value =  response as OrganizationChartNode;
                    }
                })
                
    
            }
    
    
            const getSelectionMode = () : "multiple" | "single" => {                
                const value = getCatalogValue(OrganizationChartTypeConst.SELECTIONMODE)
                const mode: "multiple" | "single" = value as "multiple"  | "single";
                return mode ?? 'single';
            }
            
    
            const onNodeExpand = (node : any) => {
                context.emit('node-expand', node);
            }
            const onNodeCollapse = (node : any) => {
                context.emit('node-collapse', node);
            }
            const onNodeSelect = (node : any) => {
                context.emit('node-select', node);
            }
            const onNodeUnSelect = (node : any) => {
                context.emit('node-unselect', node);
            }
            const updateExpandedKeys = (key : any) => {
                expandedKey.value = key;
                context.emit('update:expandedKeys', key);
            }
            const updateSelectionKeys = (key : any) => {
                selectedKey.value = key;
    
                arrayModel.value = [];
                if ((Object.keys(selectedKey.value ?? {})).length > 0) {
                    (Object.keys(selectedKey.value ?? {})).forEach(element => {
                        arrayModel.value.push(element as never);
                    });
                }
    
                setSelectedKeyToVModel();
                context.emit('update:selectionKeys', key);
            }
    
    
            onMounted(() => {
                baseOnMounted();
                watchEffect(() => {
                if (loaded.value == true) {
                    getValue();
                }
            })
            })
    
            onBeforeUnmount(() => {
                expandedKey.value = null;
                selectedKey.value = null;        
                menu.value = null;
                menuItems.value.length = 0;
                chartvalues.value= undefined;
                baseOnBeforeUnmount();
            })
         
    
    
            return {
       
                getPropertyValue,
                getPropertyBooleanValue,
                vmodel,
                OrganizationChartTypeConst,
                ControlTypeConst,
                getPropertyNumberValue,
                loaded,
                canEdit,
                isValidData,
                fieldKey,
                fieldKeyComponentDataSourceId,
                processEventComponent,
                CatalogEventConst,
                ObjectGroupConst,
                canDoOperation,
                getValue,
                getSelectionMode,
                onNodeExpand,
                onNodeCollapse,
                onNodeSelect,
                onNodeUnSelect,
                updateExpandedKeys,
                updateSelectionKeys,
                selectedKey,
                expandedKey,
                arrayModel,
                setSelectedKeyToVModel,
                componentKey,
                menu,
                menuItems,
                onRightClick,
                CatalogOrgChart,
                noImageUrl,
                chartvalues
            };
        },
    });
    </script>
    <style scoped></style>
    