<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <ColorPicker v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" :key="componentKey"
        :id="getPropertyValue(ColorPickerTypeConst.ID)" v-tooltip="getPropertyValue(ColorPickerTypeConst.TOOLTIP)"
        :style="getPropertyValue(ColorPickerTypeConst.STYLE)"
        :class="{ [getPropertyValue(ColorPickerTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted,'p-readonly': getPropertyBooleanValue(ColorPickerTypeConst.READONLY) }"
        :name="getPropertyValue(ColorPickerTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(ColorPickerTypeConst.DISABLED) || (!canEdit)"
        :visible="getPropertyBooleanValue(ColorPickerTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" :placeholder="getPropertyValue(ColorPickerTypeConst.PLACEHOLDER)"
        :defaultColor="getPropertyValue(ColorPickerTypeConst.DEFAULTCOLOR)" 
        :inline="getPropertyBooleanValue(ColorPickerTypeConst.INLINE)"
        :format="getCatalogValue(ColorPickerTypeConst.FORMAT)"
        :tabindex="getPropertyValue(ColorPickerTypeConst.TABINDEX)"
        :autoZIndex="getPropertyBooleanValue(ColorPickerTypeConst.AUTOZINDEX)" 
        :baseZIndex="getPropertyNumberValue(ColorPickerTypeConst.BASEZINDEX)"
        :panelClass="getPropertyValue(ColorPickerTypeConst.PANELCLASS)"
        :unstyled="getPropertyBooleanValue(ColorPickerTypeConst.UNSTYLED)"
        :readonly="getPropertyBooleanValue(ColorPickerTypeConst.READONLY) || !canDoOperation(ObjectGroupConst.EDIT)"
        v-model="vmodel">
    </ColorPicker>
    <CustomValidate v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE)" v-model:submitted="submitted"
        v-model:vObject="v$" />
</template>
<script lang="ts">


import { defineComponent, onMounted, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import ColorPickerTypeConst from '../../../../domain/Constants/ColorPickerTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import CustomValidate from '../../shared/CustomValidate.vue';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_password',
    components: {
        CustomValidate,
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },       
        slotProps: {
            type: Object,
            default: () => ({})
        },
      
    },
    setup(props, context) {
        //

        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue, loaded, resolveExpressions, getCatalogValue,
            baseOnBeforeUnmount, baseOnMounted, v$, submitted, canEdit, isValidData
            , fieldKey, fieldKeyComponentDataSourceId,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);



        onMounted(() => {

            baseOnMounted();

        })

        onBeforeUnmount(() => {

            baseOnBeforeUnmount();
        })


      


        return {         
            getPropertyValue,
            getPropertyBooleanValue,
            getPropertyNumberValue,
            vmodel,
            ColorPickerTypeConst,
            loaded,
            BaseControlTypeConst,
            getCatalogValue,
            v$,
            submitted,
            canEdit,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
