import BaseControlTypeConst from "./BaseControlTypeConst"

export default class InputSwitchTypeConst extends BaseControlTypeConst {

 
    static TRUEVALUE='trueValue'
    static FALSEVALUE='falseValue'
    static INPUTID='inputId'
    static READONLY = 'readonly'

}
