<template>
       <SummaryDebug v-if="getPropertyBooleanValue(DialogTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <Dialog v-if="loaded && getPropertyBooleanValue(DialogTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(DialogTypeConst.ID)" 
         v-tooltip="getPropertyValue(DialogTypeConst.TOOLTIP)"
        :style="getPropertyValue(DialogTypeConst.STYLE)" 
        :class="getPropertyValue(DialogTypeConst.CLASS)"
        :name="getPropertyValue(DialogTypeConst.NAME)" 
        :disabled="getPropertyBooleanValue(DialogTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(DialogTypeConst.VISIBLE)"
        :placeholder="getPropertyValue(DialogTypeConst.PLACEHOLDER)" 
        :header="getPropertyValue(DialogTypeConst.HEADER)"
        :footer="getPropertyValue(DialogTypeConst.FOOTER)"
        :modal="getPropertyBooleanValue(DialogTypeConst.MODAL)"
        :contentStyle="getPropertyValue(DialogTypeConst.CONTENTSTYLE)"
        :contentClass="getPropertyValue(DialogTypeConst.CONTENTCLASS)"
        :contentProps="getPropertyValue(DialogTypeConst.CONTENTPROPS)"
        :rtl="getPropertyBooleanValue(DialogTypeConst.RTL)"
        :closable="getPropertyBooleanValue(DialogTypeConst.CLOSABLE)"
        :dismissableMask="getPropertyBooleanValue(DialogTypeConst.DISMISSABLEMASK)"
        :closeOnEscape="getPropertyBooleanValue(DialogTypeConst.CLOSEONESCAPE)"
        :showHeader="getPropertyBooleanValue(DialogTypeConst.SHOWHEADER)"
        :blockScroll="getPropertyBooleanValue(DialogTypeConst.BLOCKSCROLL)"
        :baseZIndex="getPropertyNumberValue(DialogTypeConst.BASEZINDEX)"
        :autoZIndex="getPropertyBooleanValue(DialogTypeConst.AUTOZINDEX)"
        :position="getCatalogValue(DialogTypeConst.POSITION)"
        :maximizable="getPropertyBooleanValue(DialogTypeConst.MAXIMIZABLE)"
        :breakpoints="getPropertyValue(DialogTypeConst.BREAKPOINTS)"
        :draggable="getPropertyBooleanValue(DialogTypeConst.DRAGGABLE)"
        :keepInViewPort="getPropertyBooleanValue(DialogTypeConst.KEEPINVIEWPORT)"
        :minX="getPropertyNumberValue(DialogTypeConst.MINX)"
        :minY="getPropertyNumberValue(DialogTypeConst.MINY)"
        :closeIcon="getPropertyValue(DialogTypeConst.CLOSEICON)"
        :maximizeIcon="getPropertyValue(DialogTypeConst.MAXIMIZEICON)"
        :minimizeIcon="getPropertyValue(DialogTypeConst.MINIMIZEICON)"
        :unstyled="getPropertyBooleanValue(DialogTypeConst.UNSTYLED)"
         v-model="vmodel">  
         
         
         <template #header>
            <slot name="header"></slot>
        </template>
        <slot name="content"></slot>

        <template #footer>
            <slot name="footer"></slot>
        </template>

        <template #closeicon>
            <slot name="closeicon"></slot>
        </template>

        <template #maximizeicon>
            <slot name="maximizeicon"></slot>
        </template>


    </Dialog>
</template>
<script lang="ts">


import { defineComponent, onMounted, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import DialogTypeConst from '../../../../domain/Constants/DialogTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_dialog',
    components: {
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },       
        slotProps: {
            type: Object,
            default: () => ({})
        },
      

    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue,getPropertyNumberValue, loaded, 
             resolveExpressions, getCatalogValue, baseOnBeforeUnmount,fieldKey,fieldKeyComponentDataSourceId,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);




        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })


        return {          
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            DialogTypeConst,
            loaded,
            getCatalogValue,
            fieldKey,
            fieldKeyComponentDataSourceId,
            getPropertyNumberValue,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey

        };
    },
});
</script>
<style scoped></style>
