import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_codemirror = _resolveComponent("codemirror")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", {
      style: _normalizeStyle([{}, _ctx.getPropertyValue(_ctx.BaseControlTypeConst.STYLE)])
    }, [
      (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
        ? (_openBlock(), _createBlock(_component_codemirror, {
            key: _ctx.componentKey,
            modelValue: _ctx.vmodel,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vmodel) = $event)),
            placeholder: _ctx.getPropertyValue(_ctx.BaseControlTypeConst.PLACEHOLDER),
            style: { height: '100%' },
            disabled: _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DISABLED) && !_ctx.canDoOperation(_ctx.ObjectGroupConst.EDIT),
            autofocus: true,
            "indent-with-tab": true,
            "tab-size": 4,
            extensions: _ctx.extensions,
            onReady: _ctx.handleReady,
            onUpdate: _ctx.handleStateUpdate
          }, null, 8 /* PROPS */, ["modelValue", "placeholder", "disabled", "extensions", "onReady", "onUpdate"]))
        : _createCommentVNode("v-if", true)
    ], 4 /* STYLE */)
  ], 64 /* STABLE_FRAGMENT */))
}