import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_CustomTabView = _resolveComponent("CustomTabView")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? _withDirectives((_openBlock(), _createBlock(_component_CustomTabView, {
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.TabViewTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.TabViewTypeConst.STYLE)),
          class: _normalizeClass(_ctx.getPropertyValue(_ctx.TabViewTypeConst.CLASS)),
          name: _ctx.getPropertyValue(_ctx.TabViewTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.TabViewTypeConst.DISABLED),
          visible: _ctx.getPropertyBooleanValue(_ctx.TabViewTypeConst.VISIBLE),
          placeholder: _ctx.getPropertyValue(_ctx.TabViewTypeConst.PLACEHOLDER),
          lazy: _ctx.getPropertyBooleanValue(_ctx.TabViewTypeConst.LAZY),
          scrollable: _ctx.getPropertyBooleanValue(_ctx.TabViewTypeConst.SCROLLABLE),
          selectOnFocus: _ctx.getPropertyBooleanValue(_ctx.TabViewTypeConst.SELECTONFOCUS),
          activeIndex: _ctx.activeIndex,
          "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeIndex) = $event)),
          tabindex: _ctx.getPropertyNumberValue(_ctx.TabViewTypeConst.TABINDEX),
          onTabChange: _ctx.doChangeTab
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "content", {}, undefined, true),
            (_ctx.getPropertyBooleanValue(_ctx.TabViewTypeConst.ISDYNAMIC))
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.itemTabs, (tab, index) => {
                  return (_openBlock(), _createBlock(_component_TabPanel, {
                    key: tab.value2 + '_' + index
                  }, {
                    header: _withCtx(() => [
                      _createElementVNode("span", null, "Componente: " + _toDisplayString(tab.value2), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */))
                }), 128 /* KEYED_FRAGMENT */))
              : _createCommentVNode("v-if", true)
          ]),
          _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "visible", "placeholder", "lazy", "scrollable", "selectOnFocus", "activeIndex", "tabindex", "onTabChange"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.TabViewTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}