<template>
    <span>Opción</span>
    <Dropdown @pointerdown.stop="" @dblclick.stop="" filter v-model="selectedOption" :options="[
        {id:'cat', description: 'Catálogos'},
        {id:'allimages', description: 'Imágenes'}
    ]" optionValue="id"
     optionLabel="description" placeholder="Selecciona una opción" class="w-full md:w-56 mb-1" 
    @change="changeOption()"/>   
   
</template>
<script lang="ts">

import {  defineComponent, onMounted, ref,getCurrentInstance } from 'vue';
import { Container } from 'inversify';
import { TYPES } from '../../../../../../../common/domain/types';
import HelperLoading from '../../../../../../../common/infrastructure/funciones/HelperLoading';
import CatalogObjectTypeConst from '../../../../../catalog/domain/const/CatalogObjectTypeConst';
import { IServiceComponent } from '../../../../../component/application/IServiceComponent';
import { IapComponent } from '../../../../../component/domain/iapComponent';
import CacheNodeConst from '../constants/CacheNodeConst';
import Dropdown from 'primevue/dropdown'
import PrimeVue from 'primevue/config'
import HelperPrime from '../../../../../../../common/infrastructure/funciones/HelperPrime';

export default defineComponent({
    name: 'customcachecontrol',

    props: {
        data: Object
    },
    setup(props) {

        const app = getCurrentInstance()        
        app?.appContext.app.component('Dropdown',Dropdown)
        app?.appContext.app.use(PrimeVue, HelperPrime.getPrimeOptions())
        const selectedOption = ref('cat');
        const dtComponentsInfo = ref<IapComponent[]>([]);


        const changeOption = () => {
            if (props.data) {
                props.data.onChange({ key: CacheNodeConst.VAR_OPTION, data: selectedOption.value })
                
            }
        }


    

        const loadComponentsTree = () => {
            if (props.data && props.data.container) {
                HelperLoading.showLoading()
                const _srv = (props.data.container as Container).get<IServiceComponent>(TYPES.COMPONENT_REPOSITORY)

                _srv.getSimpleTreeById(props.data.applicationId ?? -1, props.data.applicationVersion ?? -1, props.data.rootComponentId ?? -1,true ).then((response: IapComponent[]) => {
                    dtComponentsInfo.value =response.map((x: IapComponent) => ({ ...x, customDesc: '(' + x?.id + ') - ' + x?.name })) as any;

                })
                    .finally(() => {
                        HelperLoading.hideLoading()
                    })
            }
        }



       

        onMounted(() => {            
            loadComponentsTree();
            if (props.data) {
                
                selectedOption.value = props.data[CacheNodeConst.VAR_OPTION] ?? 'cat'               
            }

        })

        return {
            selectedOption,
            changeOption,
            dtComponentsInfo,          
            CatalogObjectTypeConst,
            CacheNodeConst
        };
    },
});
</script>
<style scoped></style>
