

export const messages = {
    locale: 'en',
    messages: {
        es: {          
          bar:{
            title:'Buscar',
            cli: 'Clientes',
            pol: 'Pólizas',
            rec: 'Recibos',
            sin: "Siniestros",
            rie: 'Riesgos',
            sup: 'Suplementos'
          },
          btn:{
            buscar:'Buscar',
            limpiar:'Limpiar Filtros',
            cerrar:'Cerrar Pestañas',
            nuevoreg:'Nuevo',
            import:'Importar',
            guardar:'Guardar',
            saveSearch: 'Guardar búsqueda',
            updateMassive:'Actualización masiva'
          },
          tooltip:{
            cerrar:"Cierra las demás pestañas a la derecha.",
            saveSearch: "Guarda los criterios actuales de búsqueda"
          }
      },
      en: {
        bar:{
          title:'Search',
          cli: 'Client',
          pol: 'Policy',
          rec: 'Receipt',
          sin: "Sinister",
          rie: 'Risk',
          sup: 'Supplements'
        },
          btn:{
            buscar:'Search',
            limpiar:'Clear filters',
            cerrar:'Close Tabs',
            saveSearch: 'Save search',
            updateMassive:' Update Massive'
          },
          tooltip:{
            cerrar:"Close the other tabs on the right.",
            saveSearch: "Save actual search"
          }
      },     
    }
  }

  