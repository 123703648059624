import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';

import { IApplicationConfigurationRepository } from '../domain/IApplicationConfigurationRepository';

import { GET_ALL_APPLICATIONCONFIGURATION,GET_APPLICATION_BY_ID,GET_APPLICATION_BY_ID_TYPES } from './query/applicationConfigurationQuery';
import { IapApplicationConfiguration } from '../domain/iapApplicationConfiguration';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { ADD, UPDATE,DELETE } from './mutation/applicationConfigurationMutation';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class ApplicationConfigurationApolloClientRepository implements IApplicationConfigurationRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId=ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }
  

  async delete (id:number): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(DELETE, {id:id}, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationConfigurationMutation.deleteApplicationConfiguration)
        }
        catch(e) {
          reject()
        }
      });
    });
  }



  async add (dataInput: IapApplicationConfiguration): (Promise<IapApplicationConfiguration>) {
    return new Promise<IapApplicationConfiguration>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD, { data: dataInput }, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationConfigurationMutation.addApplicationConfiguration)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async update (dataInput: IapApplicationConfiguration): (Promise<IapApplicationConfiguration>) {
    return new Promise<IapApplicationConfiguration>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{data: dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationConfigurationMutation.updateApplicationConfiguration)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async getAll (maxregs: number | undefined): (Promise<IapApplicationConfiguration[]>) {
    return new Promise<IapApplicationConfiguration[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery( GET_ALL_APPLICATIONCONFIGURATION, { maxregs: maxregs }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationConfigurationQuery.getAll)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getByApplicationId ( applicationId: number, applicationVersion: number): (Promise<IapApplicationConfiguration[]>) {
    return new Promise<IapApplicationConfiguration[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_APPLICATION_BY_ID, { applicationId: applicationId, version: applicationVersion }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationConfigurationQuery.getByApplicationId)
        }
        catch(e) {
          reject()
        }
      });
      
    });
  }

  async getByAppAndTypes ( applicationId: number, applicationVersion: number,types:string[]): (Promise<IapApplicationConfiguration[]>) {
    return new Promise<IapApplicationConfiguration[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_APPLICATION_BY_ID_TYPES, { applicationId: applicationId, version: applicationVersion,types:types }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationConfigurationQuery.getByAppAndTypes)
        }
        catch(e) {
          reject()
        }
      });
      
    });
  }



}


