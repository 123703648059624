import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: ExpressionInputType!){
    expressionMutation{
        updateExpression(data:$data){
            id
            applicationId
            applicationVersion
            objetoId
            idObjeto   
            idObjetoRoot
            objetoIdRoot
            idTypeExpression
            group
            fcr
            ucr
            uum
            fum
        }
    }
}
`);

export const ADD = gql(`
mutation($data: ExpressionInputType!){
    expressionMutation{
        addExpression(data:$data){
            id
            applicationId
            applicationVersion
            objetoId
            idObjeto  
            idObjetoRoot
            objetoIdRoot
            idTypeExpression
            group
            fcr
            ucr
            uum
            fum
        }
    }
}
`);


export const DELETE = gql(`
mutation($id: ID!){
    expressionMutation{
        deleteExpression(id:$id)
    }
}
`);




