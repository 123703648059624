<template>
    <span>Texto a Encriptar</span>
      
    <div style="display: flex;">
        <textarea style="resize: none;" @pointerdown.stop="" @dblclick.stop="" id="w3review" name="msgText" rows="4" cols="50" v-model="selectedData"
    class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full "    
    ></textarea>
            <i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" :class="{ 'fa fa-subscript': true, 'text-green-500': hasExpression(EncryptComponentNodeConst.VAR_DATA) }"
                @click="showExpression(EncryptComponentNodeConst.VAR_DATA,'Datos a encriptar')"></i>
        </div>

        <br />
        <span>URL Encoding </span>
        <input type="checkbox" id="modal" name="modal" @pointerdown.stop="" @dblclick.stop=""
            v-model="selectedEncodeUrl" @change="changeEncode">
        <i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" :class="{ 'fa fa-subscript': true, 'text-green-500': hasExpression(EncryptComponentNodeConst.VAR_ENCODEURL) }"
            @click="showExpression(EncryptComponentNodeConst.VAR_ENCODEURL, 'Encode Data')"></i>
            <br />    
</template>
<script lang="ts">

import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import debounce from 'lodash.debounce';
import HelperCustomComponentControls from '../../../functions/helperCustomComponentControls';
import EncryptComponentNodeConst from '../constants/EncryptComponentNodeConst';


export default defineComponent({
    name: 'custommsgcontrol',
    components: {
    },
    props: {
        data: Object
    },
    setup(props) {

        const { showExpression, hasExpression } = HelperCustomComponentControls(props.data);
        const selectedData = ref('');
        const selectedEncodeUrl = ref(true);
      

        let debounceUpdateData: any;
        const debounceTime = 1200;
       
    
        
  const initDebounce = () => {
    debounceUpdateData = debounce((data: any, start: boolean = false) => {
        if (props.data){
            props.data.onChange({ key: EncryptComponentNodeConst.VAR_DATA, data: selectedData.value })        
        }
    }, debounceTime);
  }

  watch(
    selectedData, (newvalue, oldvalue) => {
          if (oldvalue !== newvalue && oldvalue !== undefined) {
              debounceUpdateData(newvalue, true);            
          }
        },
        {
          flush: 'post',
          //immediate: true, 
          deep: true
        }
      )


      const changeEncode = () =>{
        if (props.data) {
                props.data.onChange({ key: EncryptComponentNodeConst.VAR_ENCODEURL, data: selectedEncodeUrl.value })
            }
      }

        onMounted(() => {
            if (props.data) {
                selectedData.value = props.data[EncryptComponentNodeConst.VAR_DATA]                
                selectedEncodeUrl.value = props.data[EncryptComponentNodeConst.VAR_ENCODEURL] ?? true
            }
            initDebounce();
        })


        onBeforeUnmount(() => {
            debounceUpdateData.cancel();
        })
        
        return {
            selectedData,            
            selectedEncodeUrl,
            showExpression,
            EncryptComponentNodeConst,
            changeEncode,
            hasExpression
        };
    },
});
</script>
<style scoped></style>
