import BaseControlTypeConst from "./BaseControlTypeConst"

export default class TextAreaTypeConst extends BaseControlTypeConst {
  
    static AUTORESIZE='autoresize'
    static COLS='cols'
    static ROWS='rows'
    static TYPE = 'type'  

    static MIC = 'mic'  
    static COPYTOCLIPBOARDB = 'copyToClipboard'  
    static READONLY = 'readonly'
    
  }
  