<script setup>
import { ref, watch, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { getAccessToken, initializeMsalInstance, msalInstance } from '@ilinium/shared/src/entidades/builderMaster/auth/infrastructure/component/authConfig';

const emit = defineEmits(['send:message', 'navigate']);
const props = defineProps({
    mails: {
        type: Array,
        default: () => []
    },
    emailId: {
        type: String,
        default: () => []
    },
    groupId: {
        type: String,
        default: () => []
    },
});

const hasMicrosoftSession = ref(false);
const mail = ref({});
const newMail = ref({
    message: ''
});
const route = useRoute();
const router = useRouter();

const getMail = async () => {
    return props.mails.find((mail) => mail.id === props.emailId);
};

watch(
    () => props.mails,
    async () => {
        mail.value = await getMail();
    },
    { immediate: true }
);

const sendMail = () => {
    const sendMail = {
        ...mail.value,
        ...newMail.value,
        id: generateId(),
        sent: true,
        archived: false,
        trash: false,
        spam: false,
        starred: false,
        important: false,
        date: generateDate()
    };
    emit('send:message', sendMail);
    router.push('/apps/mail/sent');
};

const generateId = () => {
    let text = '';
    let possible = '0123456789';

    for (let i = 0; i < 5; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
};

const generateDate = () => {
    return new Date().toDateString().split(' ').slice(1, 4).join(' ');
};

const goBack = () => {
    navigate({ routerLink: 'inbox' });
};

const navigate = (item) => {
    const targetSection = item.routerLink || 'compose';
    emit('navigate', targetSection); // Emitimos el evento
};

const openCenteredPopup = (url, title, width, height) => {
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);

    // Abre una ventana emergente centrada con un tamaño específico
    return window.open(
        url, 
        title, 
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=${width}, height=${height}, top=${top}, left=${left}`
    );
};

const openOutlookCompose = (draftId, isGroup) => {
    // URL para abrir un borrador específico usando su ID
    if (!isGroup) {
        const draftUrl = `https://outlook.office365.com/owa/?ItemID=${draftId}&exvsurl=1&viewmodel=ReadMessageItem`;

        // Abrir en una ventana centrada con dimensiones específicas
        openCenteredPopup(draftUrl, 'Redactar Borrador', 800, 600);
    }
    else {
        const groupMailUrl = `https://outlook.office.com/mail/group/${props.groupId}/item/${draftId}`;
        //const groupMailUrl = `https://outlook.office365.com/owa/?path=/group/${props.groupId}/mail/${draftId}`;

        // Abrir en una ventana centrada con dimensiones específicas
        openCenteredPopup(groupMailUrl, 'Redactar Borrador de Grupo', 800, 600);
    }
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

onMounted(async () => {
    await initializeMsalInstance();

    const account = msalInstance.getAllAccounts()[0];
    if (account) {
        hasMicrosoftSession.value = true;
    }
});

</script>

<template>
    <div>
        <div class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-5 pt-5 md:pt-0 gap-4 md:border-top-none border-top-1 surface-border">
            <div class="flex align-items-center md:justify-content-start">
                <Button type="button" icon="pi pi-chevron-left" class="md:mr-3" text plain @click="goBack()"></Button>
                <Avatar v-if="mail && mail.image" :image="'/demo/images/avatar/' + mail.image" size="large" shape="circle" class="border-2 surface-border"></Avatar>
                <div class="flex flex-column mx-3">
                    <span class="block text-900 font-bold text-lg">{{ mail?.from?.emailAddress?.name ? mail?.from?.emailAddress?.name : mail?.uniqueSenders[0] }}</span>
                    <span v-if="mail?.toRecipients" class="block text-900 font-semibold">
                        Para: {{ mail?.toRecipients?.map(recipient => recipient.emailAddress.name).join(', ') }}
                    </span>
                </div>
            </div>
            <div class="flex align-items-center justify-content-end column-gap-3 px-4 md:px-0">
                <span class="text-900 font-semibold white-space-nowrap mr-auto">{{ formatDate(mail?.receivedDateTime ? mail?.receivedDateTime : mail?.lastDeliveredDateTime) }}</span>
                <Button type="button" icon="pi pi-reply" class="flex-shrink-0" text plain></Button>
                <Button type="button" icon="pi pi-ellipsis-v" class="flex-shrink-0" text plain></Button>
            </div>
        </div>
        <div class="surface-border border-1 border-round p-4">
            <div class="text-900 font-semibold text-lg mb-3">{{ mail?.subject ? mail?.subject : mail?.topic }}</div>
            <div class="line-height-3 mt-0 mb-3" v-html="mail?.body?.content ? mail?.body?.content : mail?.preview"></div>

            <Editor
                v-model="newMail.message"
                :pt="{
                    content: { style: { height: '250px' } }
                }"
            ></Editor>

            <div class="flex column-gap-3 justify-content-end mt-3">
                <Button type="button" outlined icon="pi pi-image"></Button>
                <Button type="button" outlined icon="pi pi-paperclip"></Button>
                <Button type="button" icon="pi pi-send" label="Enviar" @click="sendMail()"></Button>
                <Button v-if="hasMicrosoftSession" type="button" class="h-3rem w-full sm:w-auto" icon="pi pi-microsoft" label="Office365" style="margin-right:3px;"
                            @click="openOutlookCompose(emailId, mail.subject == undefined)"></Button>
            </div>
        </div>
    </div>
</template>
