<template>
       <SummaryDebug v-if="getPropertyBooleanValue(ChartBarTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <Chart v-if="loaded && getPropertyBooleanValue(ChartBarTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(ChartBarTypeConst.ID)" 
        v-tooltip="getPropertyValue(ChartBarTypeConst.TOOLTIP)"
        :style="getPropertyValue(ChartBarTypeConst.STYLE)"
        :class="{ [getPropertyValue(ChartBarTypeConst.CLASS) ?? '']: true }"
        :name="getPropertyValue(ChartBarTypeConst.NAME)" 
        :disabled="getPropertyBooleanValue(ChartBarTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(ChartBarTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :placeholder="getPropertyValue(ChartBarTypeConst.PLACEHOLDER)" 
         type="bar"
        :plugins="getPropertyValue(ChartBarTypeConst.PLUGINS)" 
        :width="getPropertyNumberValue(ChartBarTypeConst.WIDTH)"
        :height="getPropertyNumberValue(ChartBarTypeConst.HEIGHT)" 
        :data="chartData" 
        :options="chartOptions"
        v-model="vmodel">
        <!--- :type="getCatalogValue(ChartBarTypeConst.TYPE)"
            :label="getPropertyValue(ChartBarTypeConst.LABEL)"
            :backgroundColor="getPropertyValue(ChartBarTypeConst.BACKGROUNDCOLOR)"
            :borderColor="getPropertyValue(ChartBarTypeConst.BORDERCOLOR)"
            :borderWidth="getPropertyNumberValue(ChartBarTypeConst.BORDERWIDTH)"
            :textColor="getPropertyValue(ChartBarTypeConst.TEXTCOLOR)"
            :textColorHorizontalData="getPropertyValue(ChartBarTypeConst.TEXTCOLORHORIZONTALDATA)"
            :textColorVerticalData="getPropertyValue(ChartBarTypeConst.TEXTCOLORVERTICALDATA)"
            :beginAtZero="getPropertyBooleanValue(ChartBarTypeConst.BEGINATZERO)"
            :surfaceHorizontalBorder="getPropertyValue(ChartBarTypeConst.SURFACEHORIZONTALBORDER)"
            :surfaceVerticalBorder="getPropertyValue(ChartBarTypeConst.SURFACEVERTICALBORDER)"
            :labelData="getCatalogValue(ChartTypeConst.LABELDATA)"
            --->
    </Chart>
</template>
<script lang="ts">


import { defineComponent, onMounted, ref, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import ChartBarTypeConst from '../../../../domain/Constants/ChartBarTypeConst';
import ChartLabelDataConst from '../../../../domain/Constants/ChartLabelDataConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import DataSourceComp from '../../../../../crud/infrastructure/functions/dataSourceComp';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';

export default defineComponent({
    name: 'dynamic_chart_bar',
    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, loaded, getPropertyValue, getPropertyBooleanValue, getCatalogValue, getPropertyNumberValue, baseOnBeforeUnmount, fieldKey, fieldKeyComponentDataSourceId,
            getComponentDataSourceItemsFromId, getComponentDataSource ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);
        const { getFieldFromKeyCol } = DataSourceComp(props.container as any, props, context.emit , {}, {} as any, [], store, {} as any)
        const chartData = ref();
        const backgroundColor = ref();
        const borderColor = ref();
        const chartOptions = ref();
        const data = ref();
        const values = ref();
        const labels = ref();

      
        const setChartData = () => {
            return {
                labels: labels.value,
                datasets: [
                    {
                        label: getPropertyValue(ChartBarTypeConst.LABEL),
                        data: values.value,
                        backgroundColor: backgroundColor.value,
                        borderColor: borderColor.value,
                        borderWidth: getPropertyNumberValue(ChartBarTypeConst.BORDERWIDTH)
                    }
                ]
            };
        };




        const setChartOptions = () => {

            return {
                plugins: {
                    legend: {
                        labels: {
                            color: getPropertyValue(ChartBarTypeConst.TEXTCOLOR)//color de la etiqueta
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: getPropertyValue(ChartBarTypeConst.TEXTCOLORHORIZONTALDATA)/*color de Q1, Q2*/
                        },
                        grid: {
                            color: getPropertyValue(ChartBarTypeConst.SURFACEHORIZONTALBORDER)/*color de las líneas del gráfico*/
                        }
                    },
                    y: {
                        beginAtZero: getPropertyBooleanValue(ChartBarTypeConst.BEGINATZERO),
                        ticks: {
                            color: getPropertyValue(ChartBarTypeConst.TEXTCOLORVERTICALDATA)/*Color del data*/
                        },
                        grid: {
                            color: getPropertyValue(ChartBarTypeConst.SURFACEVERTICALBORDER)
                        }
                    }
                }
            };
        }


        const getShortDescColumnName = (columnName: any) => {
            

            const cds = getComponentDataSource(ChartBarTypeConst.DATA);

            if (cds && cds.dataSource.iapDataSourceFields) {

                const dataBaseId = cds?.dataSource?.iapDataSourceDataBase?.idBaseDatos;

                const dsf = getFieldFromKeyCol(columnName,false,cds.dataSource.iapDataSourceFields)??getFieldFromKeyCol(columnName,true,cds.dataSource.iapDataSourceFields);

                if (dsf) {
                    return dsf.shortDescription ?? dsf.description ?? (HelperCommon.isNullOrWhitespace( dsf.tableName)?dsf.fieldAlias:dsf.field);
                }


            }

            return columnName;
        }


        const getObjectFormatted = (_data: any) => {

            let newObject: any = {};

            for (var prop in _data) {
                if (_data.hasOwnProperty(prop)) {
                    newObject[getShortDescColumnName(prop)] = _data[prop];
                }
            }

            return newObject;
        }


        const getFirstColumnRowValues = (_data: any, colPosition: number) => {
            const rowValues =
                _data.map((obj: any) => {
                    const row = Object.values(obj)[colPosition];
                    return row;
                });


            return rowValues;
        }




        onMounted(() => {

            data.value = getComponentDataSourceItemsFromId(ChartBarTypeConst.DATA);

            if (data.value) {

                if (getCatalogValue(ChartBarTypeConst.LABELDATA) == ChartLabelDataConst.COL) {
                    const valueFormatted = getObjectFormatted(data.value[0]);
                    values.value = Object.values(valueFormatted);
                    labels.value = Object.keys(valueFormatted);
                }
                else if (getCatalogValue(ChartBarTypeConst.LABELDATA) ==ChartLabelDataConst.ROW) {

                    labels.value = getFirstColumnRowValues(data.value, 0);
                    values.value = getFirstColumnRowValues(data.value, 1);

                }

            }
            backgroundColor.value = getPropertyValue(ChartBarTypeConst.BACKGROUNDCOLOR)?.split(';');
            borderColor.value = getPropertyValue(ChartBarTypeConst.BORDERCOLOR)?.split(';');
            chartData.value = setChartData();
            chartOptions.value = setChartOptions();



        })

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
            chartData.value = null;
            backgroundColor.value = null;
            borderColor.value = null;
            chartOptions.value = null;
            data.value = null;
            values.value = null;
            labels.value = null;
        })

        return {           
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            ChartBarTypeConst,
            getPropertyNumberValue,
            loaded,
            BaseControlTypeConst,
            fieldKey,
            fieldKeyComponentDataSourceId,
            chartData,
            chartOptions,
            getCatalogValue,
            ObjectGroupConst,
            canDoOperation,
            componentKey


        };
    },
});
</script>
<style scoped></style>
