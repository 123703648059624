<template>
    <SummaryDebug v-if="getPropertyBooleanValue(SelectButtonTypeConst.DEBUG, Component)" :data="Component"
        :vmodel="vmodel" />
    <SelectButton
        v-if="loaded && dataValue && (getPropertyBooleanValue(SelectButtonTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW))"
        :key="componentKey" :id="getPropertyValue(SelectButtonTypeConst.ID)"
        v-tooltip="getPropertyValue(SelectButtonTypeConst.TOOLTIP)"
        :style="getPropertyValue(SelectButtonTypeConst.STYLE)"
        :class="{ [getPropertyValue(SelectButtonTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted, 'p-readonly': getPropertyBooleanValue(SelectButtonTypeConst.READONLY) }"
        :name="getPropertyValue(SelectButtonTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(SelectButtonTypeConst.DISABLED) || (!canEdit)"
        :visible="getPropertyBooleanValue(SelectButtonTypeConst.VISIBLE)"
        :placeholder="getPropertyValue(SelectButtonTypeConst.PLACEHOLDER)"
        :readonly="getPropertyBooleanValue(SelectButtonTypeConst.READONLY) || !canDoOperation(ObjectGroupConst.EDIT)"
        :required="getPropertyBooleanValue(SelectButtonTypeConst.REQUIRED)"
        :tabIndex="getPropertyNumberValue(SelectButtonTypeConst.TABINDEX)"
        :multiple="getPropertyBooleanValue(SelectButtonTypeConst.MULTIPLE)" :options="dataValue"
        :optionLabel="getOptionLabel()" :optionValue="getOptionValue()" :optionDisabled="getOptionDisabled()"
        v-model="arrayModel" @change="setArrayToVModel()">
        >

        <template #option="slotProps">
            <slot name="option"
                :slotProps="{ data: slotProps.option, index: slotProps.index, control: ControlTypeConst.CTSELECTBUTTON }">
            </slot>
        </template>

    </SelectButton>
    <CustomValidate v-if="loaded && getPropertyBooleanValue(SelectButtonTypeConst.VISIBLE)"
        v-model:submitted="submitted" v-model:vObject="v$" />

</template>
<script lang="ts">


import { defineComponent, onMounted, ref, onBeforeUnmount, onUnmounted, shallowRef,watchEffect } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import SelectButtonTypeConst from '../../../../domain/Constants/SelectButtonTypeConst';
import { Container } from 'inversify';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import ControlTypeConst from '../../../../domain/Constants/ControlTypeConst';
import CustomValidate from '../../shared/CustomValidate.vue';
import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import { isArray } from '@apollo/client/utilities';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_select_button',
    components: {
        CustomValidate,
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {
        const store = useStore();
        const arrayModel = ref<any>();

        const setVModelToArray = () => {
            const IsSingle = !getPropertyBooleanValue(SelectButtonTypeConst.MULTIPLE);

            if (IsSingle) {
                if ((vmodel.value ?? '') !== '') {
                    //var result : any[] = []
                    //result.push(vmodel.value as any)
                    //arrayModel.value = result as []
                    arrayModel.value = vmodel.value;
                }
                else { vmodel.value = null; arrayModel.value = [] as any[]; }
            }
            else {
                arrayModel.value = (vmodel.value ?? '').split(',').filter(x => x !== '') as []
            }
        }

        const cbVModel = (vmodelInput:any) =>{
            
            if (vmodelInput){
                const newData = (vmodelInput?.replace(/\"/g,'') ?? '').split(',').filter(x=> x!== '')
                if (arrayModel.value.toString() !== newData.toString()){
            
                    arrayModel.value = newData
                }
                
            }else{
                arrayModel.value = [];
            }
            
        }


        const { vmodel, canDoOperation, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue
            , getPropertyValueObjectOrFile
            , loaded, baseOnMounted, baseOnBeforeUnmount, v$, submitted
            , canEdit, isValidData, fieldKey, fieldKeyComponentDataSourceId
            , processEventComponent, componentKey
        } = ComponentCommonRender(props.Component, props.slotProps, props.container, store, setVModelToArray,cbVModel);

        const dataValue = shallowRef<any[] | undefined>(undefined)





        const setArrayToVModel = () => {
            const IsSingle = !getPropertyBooleanValue(SelectButtonTypeConst.MULTIPLE);

            if (arrayModel.value == null || arrayModel.value == undefined) {
                vmodel.value = null; arrayModel.value = [] as any[];
            } else {
                if (IsSingle) {

                    if ((arrayModel.value ?? '') !== '') {
                        //vmodel.value = '"' + arrayModel.value as string + '"';
                        //var result : any[] = []
                        //result.push(arrayModel.value as string)
                        vmodel.value = arrayModel.value;
                    }
                    else { vmodel.value = null; arrayModel.value = [] as any[]; }
                }
                else {
                    if (arrayModel.value.length > 0) {
                        vmodel.value = arrayModel.value.join(',')
                    } else { vmodel.value = null; arrayModel.value = [] as any[]; }
                }
            }
            processEventComponent(CatalogEventConst.CHANGE)
        }

        const getOptions = () => {

            //const optValue = getPropertyValue(SelectButtonTypeConst.OPTIONVALUE) ?? ''
            getPropertyValueObjectOrFile(SelectButtonTypeConst.OPTIONS, SelectButtonTypeConst.DATAFILEVALUE, SelectButtonTypeConst.DATASOURCEVALUE,null, true).then((opciones: any) => {
                if (opciones == null) return undefined;
                if (Array.isArray(opciones)) {
                    dataValue.value = opciones; //.map(x=> x[optValue]);
                }
                else {
                    opciones = opciones?.replace(/\'/g, '\"');
                    const items = JSON.parse(opciones)
                    dataValue.value = items;
                }

            })

        }

        const getOptionLabel = (): string | undefined => {
            return getPropertyValue(SelectButtonTypeConst.OPTIONLABEL) ?? undefined;
        }
        const getOptionValue = (): string | undefined => {
            return getPropertyValue(SelectButtonTypeConst.OPTIONVALUE) ?? undefined;
        }
        const getOptionDisabled = (): string | undefined => {
            return getPropertyValue(SelectButtonTypeConst.OPTIONDISABLED) ?? undefined;
        }

        onMounted(() => {
            baseOnMounted();
            watchEffect(() => {
                if (loaded.value == true) {
                    getOptions();
                }
            })
            
        })

        onBeforeUnmount(() => {
            dataValue.value = undefined;
            baseOnBeforeUnmount();
        })

        return {
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            SelectButtonTypeConst,
            ControlTypeConst,
            getPropertyNumberValue,
            loaded,
            v$,
            submitted,
            canEdit,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            processEventComponent,
            CatalogEventConst,
            ObjectGroupConst,
            canDoOperation,
            dataValue,
            getOptionLabel,
            getOptionValue,
            getOptionDisabled,
            arrayModel,
            setArrayToVModel,
            componentKey
        };
    },
});
</script>
<style scoped></style>
