import BaseControlTypeConst from "./BaseControlTypeConst"

export default class AvatarTypeConst extends BaseControlTypeConst {
  
    static LABEL = 'label'
    static ICON = 'icon'
    static IMAGE = 'image'
    static SIZE = 'size'
    static SHAPE = 'shape'
  
    
  }
  