/* eslint-disable @typescript-eslint/explicit-function-return-type */
import HelperCommon from '../funciones/HelperCommon'

export default function fechaComp () {
  function formateaFecha (value: string | number | Date) {
    return HelperCommon.formateaFecha(value)
  }

  function formateaFechaHoraLarga (value: string | number | Date) {
    return HelperCommon.formateaFechaHoraLarga(value)
  }

  function formateaFechaHora (value: string | number | Date) {
    return HelperCommon.formateaFechaHora(value,true)
  }


  return {
    formateaFecha,
    formateaFechaHoraLarga,
    formateaFechaHora
  }
}
