/* eslint-disable @typescript-eslint/no-extraneous-class */
export default class ObjectGroupConst {
    
    // las propiedades de objectgroup
  
    static ADD = 'add'
    static EDIT = 'edit'

    static DETAIL = 'list'
    static DELETE = 'delete'
    static VIEW = 'view'
  
    static IMPORT = 'import'
    static EXPORT ='export'
    static EXECUTE ='execute'
  
  }