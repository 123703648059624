import BaseControlTypeConst from "./BaseControlTypeConst"

export default class CrudTableTypeConst extends BaseControlTypeConst {
  
    static SHOWBTNSEARCH='showBtnSearch'
    static SHOWBTNCLEAR='showBtnClear'
    static SHOWBTNCLOSETABS='showBtnCloseTabs'
    static EDITALLEXCEPTKEYS='editAllExceptKeys'
    static MAXREGS='maxRegs'
    static CANADDNEWITEM='canAddNewItem'
    static CANIMPORT='canImport'
    static CANEXPORT='canExport'
    
    static SHOWACTIONMENU='showActionMenu'
    static SHOWSEARCH='showSearch'
    static ADVANCEDSEARCH='advancedSearch'
    static SHOWACTIONDETAIL='showActionDetail'
    static SHOWPAGINATE='showPaginate'  
    static SHOWSAVESEARCH='showSaveSearch'  
    static CANDELETEITEM='canDeleteItem'
    static SELECTEDROW='selectedRow'
    static LAZY='lazy'
    static LAZYSCROLL='lazyScroll'
    static ROWCLASS='rowClass'
    static ROWSTYLE='rowStyle'
    static SELECTEDMULTIPLEROW='selectedMultipleRow'
    static LAYOUT_DETAIL ='layouttemp-crudtbl-detail'
    static LAYOUT_HEADER ='layouttemp-crudtbl-header'
    static LAYOUT_HEADER_BUTTONS ='layouttemp-crudtbl-headerbtns'
    static LAYOUT_ADDNEW ='layouttemp-crudtbl-new'
    static LAYOUT_ACTIONS_MENU ='layouttemp-crudtbl-menu'    
    static LAYOUT_SEARCH_CONTROLS ='layouttemp-crudtbl-controlssearch'    
    static LAYOUT_BUTTONS_RESULT ='layouttemp-crudtbl-buttonsresult'    
    
    static LAYOUT_BUTTONS_SEARCH ='layouttemp-crudtbl-buttonssearch'
    
    static ROWGROUPMODE = 'rowGroupMode'
    static GROUPROWSBY = 'groupRowsBy'
    static EXPANDABLEROWGROUPS = 'expandableRowGroups'


    static CANUPDATEMASSIVE='canUpdateMassive'
    static CANSELECTMULTIPLE='canSelectMultiple'
    static CANCLEARALLFILTER='canClearAllFilters'
    static SHOWHEADERDETAIL='showHeaderDetail'  
    static COLLAPSIBLE='collapsible'  
    static SEARCHCOMPONENTID='searchComponentId'  

    static SETUP_IMP ='setupImport'
    
    // propiedades para los tabs
    static SHOWTABMODE = 'showTabMode'
    static SHOWTABMODERESULT = 'showResultTabMode'
    static TABHEADER = 'tabHeader'


    //propiedades para los filtros.
    static FILTERDISPLAY= 'filterDisplay'

    // propiedades del layout
    static LAYOUT_DETAIL_COMMAND_DETAIL='commandViewDetail'  

    // comandos 

    static CMD_AFTER_IMP= 'commandAfterImport'
    static CMD_BEFORE_IMP= 'commandBeforeImport'


  }
  