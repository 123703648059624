import { toRefs, reactive } from 'vue';
import store from '../../../../../common/infrastructure/almacen';
import { Mutations } from '../../../../../common/infrastructure/almacen/enums/StoreEnums';
import { IapApplicationConfiguration } from '../../../../builder/application/domain/iapApplicationConfiguration';
import { AppLayoutConfig } from '../../../../builder/template/infrastructure/prime/AppLayoutConfig';

import { IServiceUserOption } from '../../../../../../src/entidades/builderMaster/user/application/IServiceUserOption';
import { IapmUserOption } from '../../../../../../src/entidades/builderMaster/user/domain/iapmUserOption';
import { TYPES } from '../../../../../common/domain/types';

import { Container } from 'inversify';
import TemplateTypeConst from '@ilinium/shared/src/common/domain/constantes/TemplateTypeConst'
import Environment from '../../../../../common/infrastructure/funciones/environment';
import HelperApplication from '@ilinium/shared/src/entidades/builder/application/infrastructure/functions/helperApplication';
import CatalogConfigurationConst from '../../../catalog/domain/const/CatalogConfigurationConst';



const layoutConfig = reactive({
    template:'',
    //scale: 14,
    scale: store.getters.getCurrentUser?.iapmUserOptions?.find((x:any) =>x.optionId == 'AppLayoutScale')?.value ?? 14,
    menuMode: 'static',
    //menuTheme: 'colorScheme',
    menuTheme: 'primaryColor',
    colorScheme: 'light',
    inputStyle: 'outlined',
    ripple: false,
    componentTheme: 'indigo',
    topbarTheme: 'indigo',
    menuProfilePosition: 'end',
    RTL: false,
    wrapperMode: 'boxed'
});

export function useLayoutBuilder () {
    async function changeAppLayoutTemplate(applicationId: number, applicationVersion:number, container: Container): Promise<void> {
        var appLayoutCfg:AppLayoutConfig = {template:'', scale:0, menuMode:'', menuTheme:'', colorScheme:'', inputStyle:'', ripple:false, componentTheme:'', topbarTheme:'', menuProfilePosition:'', RTL:false, wrapperMode:''};
        var appUserLayoutOptions:IapmUserOption[] = [];

        appUserLayoutOptions = await getAppLayoutConfig(applicationId, applicationVersion, container);
    
        // Configuración Layout del usuario
        appLayoutCfg = getUserLayoutConfig(appUserLayoutOptions);
    
        // Añadir la configuración Layout al storage
        store.commit(Mutations.SET_APPLAYOUTCONFIG, appLayoutCfg) 

        setLayoutConfig ();
        
        // Actualizar el Template configurado (Aqui soporta refresco de pagina Ctrl-F5)
        changeAppTemplate();

    }

    function setLayoutConfig () : void {
        var appLayoutCfg = store.getters.getAppLayoutConfig as AppLayoutConfig;

        layoutConfig.template = appLayoutCfg.template;
        layoutConfig.scale = appLayoutCfg.scale;
        layoutConfig.menuMode = appLayoutCfg.menuMode;
        layoutConfig.menuTheme = appLayoutCfg.menuTheme;
        layoutConfig.colorScheme = appLayoutCfg.colorScheme;
        layoutConfig.inputStyle = appLayoutCfg.inputStyle;
        layoutConfig.ripple = appLayoutCfg.ripple;
        layoutConfig.componentTheme = appLayoutCfg.componentTheme;
        layoutConfig.topbarTheme = appLayoutCfg.topbarTheme;
        layoutConfig.menuProfilePosition = appLayoutCfg.menuProfilePosition;
        layoutConfig.RTL = appLayoutCfg.RTL;
        layoutConfig.wrapperMode = appLayoutCfg.wrapperMode;
    }

    function changeAppTemplate () : void {
        var appLayoutCfg = store.getters.getAppLayoutConfig as AppLayoutConfig;
        changeTemplateLayout(appLayoutCfg.template, appLayoutCfg.componentTheme, appLayoutCfg.colorScheme, appLayoutCfg.menuTheme);
        changeTemplateTheme(appLayoutCfg.template, appLayoutCfg.componentTheme, appLayoutCfg.colorScheme, appLayoutCfg.menuTheme);
    }

    
    const changeTemplateLayout = (template: string, theme: string, colorScheme: string, menuTheme: string) => {
        const layoutLink = document.getElementById('layout-css');
        const layoutHref = layoutLink?.getAttribute('href');
        const path = process.env.VUE_APP_PUBLIC_PATH;
        var newHref = layoutHref;

        switch (template) {
            case TemplateTypeConst.PRIME_PRESTIGE:
                    newHref = path + '/prestige/layout/css/layout-' + (menuTheme ?? theme) + '.css';
                    break;
            case TemplateTypeConst.PRIME_PRESTIGEIS:
                    newHref = path + '/prestige_intrasoft/layout/css/layout.css';
                    break;
            case TemplateTypeConst.PRIME_PRESTIGE_INTRASOFT:
                newHref = path + '/prestige_intrasoft/layout/css/layout.css';
                break;
            case TemplateTypeConst.PRIME_ULTIMA:
                newHref = path + '/ultima/layout/preloading.css';
                break;
            case TemplateTypeConst.PRIME_APOLLO:
                newHref = path + '/apollo/layout/preloading.css';
                break;
            default:
                break;
        }

        newHref = newHref?.replace('//','/');        

        replaceLink(layoutLink, newHref, () => {
            //store.commit(Mutations.SET_APPLAYOUTCONFIG, appLayoutCfg);
        });
    };

    const changeTemplateTheme = (template: string, theme: string, colorScheme: string, menuTheme: string) => {

        const themeLink = document.getElementById('theme-css');
        const themeHref = themeLink?.getAttribute('href');

        const path = process.env.VUE_APP_PUBLIC_PATH;
        var newHref = themeHref;

        switch (template) {
            case TemplateTypeConst.PRIME_PRESTIGE:
                newHref = path + '/prestige/theme/theme-' + theme + '.css';
                break;
            case TemplateTypeConst.PRIME_PRESTIGEIS:
                newHref = path + '/prestige_intrasoft/theme/theme.css';
                break;
            case TemplateTypeConst.PRIME_PRESTIGE_INTRASOFT:
                newHref = path + '/prestige_intrasoft/theme/theme.css';
                break;
            case TemplateTypeConst.PRIME_ULTIMA:
                newHref = path + '/ultima/theme/theme-' + colorScheme + '/' + theme + '/theme.css';
                break;
            case TemplateTypeConst.PRIME_APOLLO:
                newHref = path + '/apollo/theme/theme-' + colorScheme + '/' + theme + '/theme.css'
                break;
            default:
                break;
        }

        newHref = newHref?.replace('//','/');

        replaceLink(themeLink, newHref, () => {
            //store.commit(Mutations.SET_APPLAYOUTCONFIG, appLayoutCfg);
        });
    };
    
    async function getAppLayoutConfig (applicationId: number, applicationVersion:number, container:Container) : Promise<IapmUserOption[]> {
        var appUserLayoutOptions:IapmUserOption[] = []
        var user = store.getters.getCurrentUser
      
       if (!user.iapmUserOptions || user.iapmUserOptions.length == 0) {
          const _srvUsrOpt = container?.get<IServiceUserOption>(TYPES.USER_OPTION_SERVICE);
          await _srvUsrOpt?.getFilteredUserOptions(applicationId, applicationVersion, user.id, 'AppLayout')
            .then((response: any) => {
              appUserLayoutOptions = response;
            })
            .finally(() => {
            });
        } else {
          appUserLayoutOptions = user.iapmUserOptions
        }
        return appUserLayoutOptions;
    }

    async function setAppLayoutConfig (appLayoutCfg:AppLayoutConfig, container:Container) : Promise<void> {
        var appUserLayoutOptions:IapmUserOption[] = [];
        const user = store.getters.getCurrentUser;

        const applicationId = Environment.APPID;
        const applicationVersion = Environment.APPVERSION;

        const data: IapmUserOption[] = buildAppConfigData(user.id, appLayoutCfg);
        const _srvUsrOpt = container?.get<IServiceUserOption>(TYPES.USER_OPTION_SERVICE);
        await _srvUsrOpt?.setUserOptionsData(applicationId, applicationVersion, data)
        .then((response: any) => {
            //appUserLayoutOptions = response.data;
            appUserLayoutOptions = response;
        })
        .finally(() => {
            // Layout Scale on Ctrl-F5
            user.iapmUserOptions.filter((x:any) =>x.optionId == 'AppLayoutScale')[0].value = appUserLayoutOptions.filter(x =>x.optionId == 'AppLayoutScale')[0].value;
        });   
    }
 
     async function resetAppLayoutConfig (applicationId:number, applicationVersion:number, container:Container | undefined) : Promise<void> {
        const appLayoutCfg:AppLayoutConfig = {template:'', scale:14, menuMode:'static', menuTheme:'primaryColor', colorScheme:'light', inputStyle:'outlined', ripple:false, componentTheme:'indigo', topbarTheme:'', menuProfilePosition:'', RTL:false, wrapperMode:''};
        const data: IapmUserOption[] = buildAppConfigData(0, appLayoutCfg);

        // Resetear las opciones de configuración del Layout para todos los usuarios !!!!
        resetOptions(applicationId, applicationVersion, data, container);

    }

   const resetOptions = (applicationId:number, applicationVersion:number, data:IapmUserOption[], container:Container | undefined) : void => {
        const _srvUsrOpt = container?.get<IServiceUserOption>(TYPES.USER_OPTION_SERVICE);
        _srvUsrOpt?.resetUserOptionsData(applicationId, applicationVersion, data)
        .then((response: any) => {
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
        });   
    }

    const buildAppConfigData = (userId:number, appLayoutCfg:AppLayoutConfig): IapmUserOption[] => {
        const data: IapmUserOption[] = [];
        var option:IapmUserOption 
        var optionValue:string

        const LayoutCfg = toRefs(appLayoutCfg);

        //Template
        optionValue = LayoutCfg.template.value.toString();
        option = {userId:userId, optionId:'AppLayoutTemplate', value:optionValue};
        data.push(option);

        //Scale
        optionValue = LayoutCfg.scale.value.toString();
        if (optionValue == 'NaN') { optionValue ='14';}
        option = {userId:userId, optionId:'AppLayoutScale', value:optionValue};
        data.push(option);

        //MenuMode
        optionValue = LayoutCfg.menuMode.value.toString();
        option = {userId:userId, optionId:'AppLayoutMenuMode', value:optionValue};
        data.push(option);

        //MenuTheme
        optionValue = LayoutCfg.menuTheme.value.toString();
        option = {userId:userId, optionId:'AppLayoutMenuTheme', value:optionValue};
        data.push(option);

        //ColorScheme
        optionValue = LayoutCfg.colorScheme.value.toString();
        option = {userId:userId, optionId:'AppLayoutColorScheme', value:optionValue};
        data.push(option);

        //InputStyle
        optionValue = LayoutCfg.inputStyle.value.toString();
        option = {userId:userId, optionId:'AppLayoutInputStyle', value:optionValue};
        data.push(option);

        //Ripple
        optionValue = LayoutCfg.ripple.value.toString();
        option = {userId:userId, optionId:'AppLayoutRipple', value:optionValue};
        data.push(option);

        //ComponentTheme
        optionValue = LayoutCfg.componentTheme.value.toString();
        option = {userId:userId, optionId:'AppLayoutComponentTheme', value:optionValue};
        data.push(option);

        //TopBarTheme
        optionValue = LayoutCfg.topbarTheme.value.toString();
        option = {userId:userId, optionId:'AppLayoutTopBarTheme', value:optionValue};
        data.push(option);

        //MenuProfilePosition
        optionValue = LayoutCfg.menuProfilePosition.value.toString();
        option = {userId:userId, optionId:'AppLayoutMenuProfilePosition', value:optionValue};
        data.push(option);

        //RTL
        optionValue = LayoutCfg.RTL.value.toString();
        option = {userId:userId, optionId:'AppLayoutRTL', value:optionValue};
        data.push(option);

        //WrapperMode
        optionValue = LayoutCfg.wrapperMode.value.toString();
        option = {userId:userId, optionId:'AppLayoutWrapperMode', value:optionValue};
        data.push(option);

        return data;
    };

    const replaceLink = (linkElement:any, href:any, onComplete:any) => {
        if (!linkElement || !href) {
            return;
        }

        const id = linkElement.getAttribute('id');
        const cloneLinkElement = linkElement.cloneNode(true);

        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');

        linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

        cloneLinkElement.addEventListener('load', () => {
            linkElement.remove();

            const element = document.getElementById(id); // re-check
            element && element.remove();

            cloneLinkElement.setAttribute('id', id);
            onComplete && onComplete();
        });
    };

   
    function getUserLayoutConfig(appUserLayoutOptions:IapmUserOption[]): AppLayoutConfig {
        var appLayoutCfg:AppLayoutConfig = {template:'', scale:0, menuMode:'', menuTheme:'', colorScheme:'', inputStyle:'', ripple:false, componentTheme:'', topbarTheme:'', menuProfilePosition:'', RTL:false, wrapperMode:''};

        // Inicializar Layout
        var appCfg:IapApplicationConfiguration[] = store.getters.getAppConfig;
        const layoutTemplate = getLayoutTemplateAppConfig (appCfg);
        appLayoutCfg = initLayoutConfig(layoutTemplate);

        // Cargar configuración Layout base
        appLayoutCfg.template = getLayoutTemplateAppConfig(appCfg);
        appLayoutCfg.colorScheme = getLayoutThemeAppConfig(appCfg);
        appLayoutCfg.componentTheme = getLayoutStyleAppConfig(appCfg);

        // Cargar configuración Layout del usuario
        if (appUserLayoutOptions.length > 0) {
            for (let idx = 0; idx < appUserLayoutOptions.length; idx++) {
                const userOption = appUserLayoutOptions[idx];

                //User Template
                if (userOption.optionId == 'AppLayoutTemplate') {
                    appLayoutCfg.template = userOption.value ?? appLayoutCfg.template;
                }

                //User Scale
                if (userOption.optionId == 'AppLayoutScale') {
                    //appLayoutCfg.scale = parseInt(userOption.value ?? appLayoutCfg.scale.toString());
                    appLayoutCfg.scale = Number(userOption.value ?? appLayoutCfg.scale.toString());
                }

                //User MenuMode
                    if (userOption.optionId == 'AppLayoutMenuMode') {
                    appLayoutCfg.menuMode = userOption.value ?? appLayoutCfg.menuMode;
                }

                //User MenuTheme
                if (userOption.optionId == 'AppLayoutMenuTheme') {
                    appLayoutCfg.menuTheme = userOption.value ?? appLayoutCfg.menuTheme;
                }

                //User ColorScheme
                if (userOption.optionId == 'AppLayoutColorScheme') {
                    appLayoutCfg.colorScheme = userOption.value ?? appLayoutCfg.colorScheme;
                }

                //User InputStyle
                if (userOption.optionId == 'AppLayoutInputStyle') {
                    appLayoutCfg.inputStyle = userOption.value ?? appLayoutCfg.inputStyle;
                }

                //User Ripple
                if (userOption.optionId == 'AppLayoutRipple') {
                    appLayoutCfg.ripple=false;
                    if (userOption.value == 'true') {
                        appLayoutCfg.ripple = true;
                    }
                }

                //User ComponentTheme
                if (userOption.optionId == 'AppLayoutComponentTheme') {
                    appLayoutCfg.componentTheme = userOption.value ?? appLayoutCfg.componentTheme;
                }

                //User TopBarTheme
                if (userOption.optionId == 'AppLayoutTopBarTheme') {
                    appLayoutCfg.topbarTheme = userOption.value ?? appLayoutCfg.topbarTheme;
                }

                //User MenuProfilePosition
                if (userOption.optionId == 'AppLayoutMenuProfilePosition') {
                    appLayoutCfg.menuProfilePosition = userOption.value ?? appLayoutCfg.menuProfilePosition;
                }

                //User RTL
                if (userOption.optionId == 'AppLayoutRTL') {
                    appLayoutCfg.RTL=false;
                    if (userOption.value == 'true') {
                        appLayoutCfg.RTL = true;
                    }
                }

                //User WrapperMode
                if (userOption.optionId == 'AppLayoutWrapperMode') {
                    appLayoutCfg.wrapperMode = userOption.value ?? appLayoutCfg.wrapperMode;
                }

            }
        }    
        
        return appLayoutCfg;
    }

    function initLayoutConfig(layoutTemplate:string): AppLayoutConfig {
        var appLayoutCfg:AppLayoutConfig = 
            {
                template:layoutTemplate, 
                scale:14, 
                menuMode:'static', 
                menuTheme:'colorScheme', 
                colorScheme:'light', 
                inputStyle:'outlined', 
                ripple:false, 
                componentTheme:'indigo', 
                topbarTheme:'', 
                menuProfilePosition:'', 
                RTL:false,
                wrapperMode:''
            };
             
        switch (appLayoutCfg.template) {
            case TemplateTypeConst.PRIME_PRESTIGE:
                appLayoutCfg.template = 'prestige';
                appLayoutCfg.menuMode = 'horizontal';
                appLayoutCfg.wrapperMode = 'boxed';
                appLayoutCfg.inputStyle = 'outlined';
                appLayoutCfg.ripple = true;

                appLayoutCfg.colorScheme = 'color';
                appLayoutCfg.menuTheme = 'blue';

                appLayoutCfg.componentTheme = 'blue';
                
                appLayoutCfg.scale = 0;
                appLayoutCfg.topbarTheme = '';
                appLayoutCfg.menuProfilePosition = '';
                appLayoutCfg.RTL = false;
                
                break;
            case TemplateTypeConst.PRIME_PRESTIGEIS:
                appLayoutCfg.template = 'prestigeis';
                break;                
            case TemplateTypeConst.PRIME_PRESTIGE_INTRASOFT:
                appLayoutCfg.template = 'prestige-intrasoft';
                break;
            case TemplateTypeConst.PRIME_ULTIMA:
                appLayoutCfg.template = 'ultima';
                appLayoutCfg.menuTheme = 'light';
                appLayoutCfg.topbarTheme = 'indigo';
                appLayoutCfg.menuProfilePosition = 'end';
                break;
            case TemplateTypeConst.PRIME_APOLLO:
                appLayoutCfg.template = 'apollo';
                appLayoutCfg.menuTheme = 'primaryColor';
                appLayoutCfg.topbarTheme = '';
                appLayoutCfg.menuProfilePosition = '';
                break;
        }

        return appLayoutCfg;
    }

    function getLayoutTemplateAppConfig (appCfg:IapApplicationConfiguration[]): string {
        //AppConfig Template
        const { getAppConfigObj } = HelperApplication(undefined)
        var template:string='apollo';
        let appCfgTemplate =getAppConfigObj(CatalogConfigurationConst.TEMPLATE,Environment.ENVIRONMENT??'',appCfg);
        if (appCfgTemplate) {
            template = appCfgTemplate.keyValue ?? 'apollo';  
            if (template) {
                template = template.replace('templarend-prime','');
            }
        }
        return template;
    }

    function getLayoutThemeAppConfig (appCfg:IapApplicationConfiguration[]): string {
        //AppConfig Theme
        const { getAppConfigObj } = HelperApplication(undefined)
        var theme:string='light'
        let appCfgTheme = getAppConfigObj(CatalogConfigurationConst.TEMPLATETHEME,Environment.ENVIRONMENT??'',appCfg);
        if (appCfgTheme) {
            theme = appCfgTheme.keyValue ?? 'light';  
            if (theme) {
                theme = theme.substring(theme.lastIndexOf('-') + 1);
            }
        }
        return theme;
    }

    function getLayoutStyleAppConfig (appCfg:IapApplicationConfiguration[]): string {
        const { getAppConfigObj } = HelperApplication(undefined)
        //AppConfig Style
        var style:string='indigo'
        let appCfgStyle = getAppConfigObj(CatalogConfigurationConst.TEMPLATETHEMESTYLE,Environment.ENVIRONMENT??'',appCfg);
        if (appCfgStyle) {
            style = appCfgStyle.keyValue ?? 'indigo';  
            if (style) {
                style = style.substring(style.lastIndexOf('-') + 1);
            }
        }
        return style;
    }
 
    return {
        layoutConfig,
        changeAppLayoutTemplate,
        changeAppTemplate,
        setAppLayoutConfig,
        resetAppLayoutConfig,
        getLayoutTemplateAppConfig,
        getLayoutThemeAppConfig,
        getLayoutStyleAppConfig
    }
}