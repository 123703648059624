<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"

            />
    <FullCalendar
        :key="componentKey"
        ref='fullCalendar'
        v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :id="getPropertyValue(FullCalendarTypeConst.ID)" v-tooltip="getPropertyValue(FullCalendarTypeConst.TOOLTIP)"
        :style="getPropertyValue(FullCalendarTypeConst.STYLE)"
        :class="{ [getPropertyValue(FullCalendarTypeConst.CLASS) ?? '']: true }"
        :name="getPropertyValue(FullCalendarTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(FullCalendarTypeConst.DISABLED) || (!canEdit)"            
        :options="options">
       
    </FullCalendar>


    <Dialog v-model:visible="eventDialog" styleClass="mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6" 
          :modal="true" :closable="true" :maximizable="true" >
        <template #header>
            <span class="text-900 font-semibold text-xl">{{ clickedEvent.title }}</span>
        </template>

        <NewRegister v-if="!hasCustomViewComponent && !hasCustomEditComponent" :container="container" :compDataSource="datasource" :catalogosData="catalogos"
            :rootParentId="Component.rootParentId" :Component="Component" :formKey="Component.formKey"
            :canUpdate="canUpdateRow" :canDelete="canUpdateRow" :recordToUpdate="recordToUpdate"
            @refreshData="eventDialog = false; loadEventsData()" />

        <FormBuilder v-else-if="componentInputId>0"  :container="container" :componentId="componentInputId" :applicationId="Component.applicationId"
            :applicationVersion="Component.applicationVersion" :attrsInput="componentAttributesList" :canDestroy="true" @close="eventDialog = false; loadEventsData()"/>                

        
    </Dialog>

</template>
<script lang="ts">


import { defineComponent, onMounted, ref, onBeforeUnmount, onUnmounted, computed, watch } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import FullCalendarTypeConst from '../../../../domain/Constants/FullCalendarTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';
import { Dictionary } from '../../../../../expression/domain/dictionary';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';

import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

import esLocale from '@fullcalendar/core/locales/es';
import HelperUtils from '../../../../../../../common/infrastructure/funciones/HelperUtils';


import NewRegister from '../../../../../crud/infrastructure/search/NewRegister.vue';
import helperCatalog from '../../../../../catalog/infrastructure/helper/helperCatalog';
import { IapCatalog } from '../../../../../catalog/domain/iapCatalog';
import CatalogTypeConst from '../../../../../../../common/domain/constantes/CatalogTypeConst';
import { fieldDefinition } from '../../../../../dataupdate/domain/fieldDefinition';
import DataSourceComp from '../../../../../crud/infrastructure/functions/dataSourceComp';
import { SearchData } from '../../../../../search/domain/search';
import DataSourceUtilComp from '../../../../../crud/infrastructure/functions/dataSourceUtilComp';
import SqlTypesConst from '../../../../../../../common/domain/constantes/SqlTypesConst';
import OperationDataTypeConst from '../../../../../../../common/domain/constantes/OperationDataTypeConst';
import { DataUpdateOperation } from '../../../../../dataupdate/domain/dataUpdateOperation';
import { dataUpdate } from  '../../../../../dataupdate/domain/dataUpdate';
import HelperOperationData from "../../../../../../../common/infrastructure/funciones/HelperOperationData";
import CatalogObjectTypeConst from '../../../../../catalog/domain/const/CatalogObjectTypeConst';
import { IapWorkFlow } from '../../../../../workflow/domain/service/iapWorkFlow';
import EventBusCustom from '../../../../../../../common/infrastructure/event-bus-custom';
import EventConst from '../../../../../../../common/domain/constantes/EventConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';	
import { IapComponentAttribute } from '../../../../../component/domain/iapComponentAttribute';
import { IapComponentDataSource } from '../../../../../component/domain/iapComponentDataSource';

export default defineComponent({
    name: 'dynamic_full_calendar',
    components: {
        FullCalendar,
        NewRegister,
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },       
        slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {
        //
        const datasource = ref<IapComponentDataSource>();
        const store = useStore();
        const { vmodel, canDoOperation, getPropertyValue,getPropertyId,getPropertyBooleanValue, loaded,  resolveExpressions, baseOnMounted, baseOnBeforeUnmount, v$, submitted, canEdit, isValidData
            , fieldKey, fieldKeyComponentDataSourceId, sendUbmChat, sendMail, resolveDatasource,processEvent,setPropertyValueByName,updateDictionary
            ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);
        const { getFieldKeyColumn } = DataSourceComp(props.container as any, props, context.emit , {}, {} as any, [], store, {} as any)
        const dataSearchFilter = ref<Partial<SearchData>[]>([]);
        const catalogos = ref<IapCatalog[]>([]);
        const eventDialog = ref(false);
        const clickedEvent = ref();
        const changedEvent = ref({ title: '', start: null, end: '', allDay: null });
        const events = ref([]);
        const submittedNew = ref(false);
        const fullCalendar = ref(null);
        let calApi = null;
        const keyComponentCalendar = props.Component.formKey + props.Component.rootParentId.toString() + props.Component.id.toString() + HelperUtils.newGuid() + EventConst.SHOWCOMPONENT;
        

        const canUpdateRow= ref(false);
        const recordToUpdate = ref();
        const componentInputId = ref(-1);
        const componentAttributesList = ref<IapComponentAttribute[]>([]);


        const options = ref({
            plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin,listPlugin],
            initialDate: new Date(),// '2023-01-01',
            //timeZone: 'UTC',
            locale: esLocale,
            initialEvents: [],
            initialView: getPropertyValue(FullCalendarTypeConst.DV) ??  'dayGridMonth',
            customButtons: {
                id:'refreshcalbutton',
                refreshButton: {
                text: 'Actualizar',
                hint:'Recargar eventos',
                //icon:'pi pi-spin pi-spinner',
                click: function() {
                    loadEventsData()
                }
                }
            },
            headerToolbar: {
                left: 'prev,next',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek,refreshButton' //,listWeek
            },
            editable: true,
            selectable: true,
            selectMirror: true,
            dayMaxEvents: true,
            select: (e: any) => handleDateSelect(e),
            eventClick: (e: any) => handleEventClick(e),
            eventChange: (e: any) => handleEventChange(e),
            //eventsSet: (e: any) =>  handleEvents(e),
            //events: events.value
            /*eventsSet: this.handleEvents
            /* you can update a remote database when these fire:
            eventAdd:
            eventChange:
            eventRemove:
            */
           events:[]
        } as any);



        const hasCustomViewComponent = computed(()=>{
            const attrId = getPropertyId(FullCalendarTypeConst.CMD_ADD)
            if (attrId){
                
                return props.Component.workFlows?.find((x: IapWorkFlow) => x.idObjeto == CatalogObjectTypeConst.ATTRCOMP && x.objetoId == attrId) !== undefined
            }
            return false
        })

        const hasCustomEditComponent = computed(()=>{

            const attrId = getPropertyId(FullCalendarTypeConst.CMD_EDIT)
            if (attrId){
                
                return props.Component.workFlows?.find((x: IapWorkFlow) => x.idObjeto == CatalogObjectTypeConst.ATTRCOMP && x.objetoId == attrId) !== undefined
            }
            return false
        })

        const isLoaded = computed(() => {
            
            return loaded.value && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)
        })


        const handleEventChange = (selectInfo: any) =>{
            
            
            const record = selectInfo.event.extendedProps.currentRecord
            let fieldMap = getObjectFieldMap('start')
            if (fieldMap)
            {
                record[fieldMap] = selectInfo.event.start
            }

            fieldMap = getObjectFieldMap('end')
            if (fieldMap)
            {
                record[fieldMap] = selectInfo.event.end
            }
            
            saveData(record)
        }

        const handleDateSelect = (selectInfo: any) => {
            
            //calApi = selectInfo.view.calendar


            clickedEvent.value = {
                //id: HelperUtils.newGuid(),
                title: 'Nuevo Evento',
                //description: '',
                //start: selectInfo.startStr,
                //end: selectInfo.endStr,
                //allDay: selectInfo.allDay,
                //add: true
            }
            canUpdateRow.value = false
            
            if (hasCustomEditComponent.value){
                
                setPropertyValueByName(FullCalendarTypeConst.DATAVALUE, '')                    
                processEvent(CatalogObjectTypeConst.ATTRCOMP, getPropertyId(FullCalendarTypeConst.CMD_ADD) ?? '',null,keyComponentCalendar)
            }
            else{
                eventDialog.value = true
            }

            
            /*let calendarApi = selectInfo.view.calendar
            
            calendarApi.unselect() // clear date selection
            
            
            calendarApi.addEvent({
                id: HelperUtils.newGuid(),
                title: 'Nuevo Evento',
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
            })
            */

        }

        const handleEventClick = (clickInfo: any) => {
            

           
            //eventDialog.value = true
            /*
            if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
            clickInfo.event.remove()
            }
            */
            if (clickInfo?.event) {
                
                clickedEvent.value = {
                    id: clickInfo.event.id,
                    title: clickInfo.event.title,
                    //description: clickInfo.event.description,
                    //start: clickInfo.event.start,
                    //end: clickInfo.event.end,
                    //allDay: clickInfo.event.allDay,
                    //add: false
                }
                
                recordToUpdate.value = clickInfo.event.extendedProps.currentRecord
                canUpdateRow.value = true
                if (hasCustomViewComponent.value){
                    setPropertyValueByName(FullCalendarTypeConst.DATAVALUE, clickInfo.event.id)
                    processEvent(CatalogObjectTypeConst.ATTRCOMP, getPropertyId(FullCalendarTypeConst.CMD_EDIT) ?? '',null,keyComponentCalendar)
            }
            else{
                eventDialog.value = true
            }
            }
        }

        
     
        const convertToEvent = (data: []) => {
            
            events.value=[]
            const _fields = getPropertyValue(FullCalendarTypeConst.FIELDS_DEF);
            const _dsmap = getPropertyValue(FullCalendarTypeConst.DS_MAP);
            const dsmap = (JSON.parse(_dsmap ?? "[]") ?? []) as Dictionary<string, string>[]
            const fields = (JSON.parse(_fields ?? "[]") ?? []) as fieldDefinition[];

            if (dsmap.length > 0 && fields.length > 0 && datasource.value) {
                const dbname = datasource.value?.dataSource?.iapDataSourceDataBase?.idBaseDatos
                const _columnsIds =
                
                datasource.value?.dataSource.iapDataSourceFields.filter(x => x.dataSourceTableAliasId == null).map(x => ({
                id: x.id + '#'+ datasource.value?.id,
                field: getFieldKeyColumn(x,true)
            }))
                .concat(
                    datasource.value?.dataSource.iapDataSourceFields.filter(x => x.dataSourceTableAliasId != null).map(x => ({
                        id: x.id+ '#'+ datasource.value?.id,
                        field: getFieldKeyColumn(x)
                    }))

                )

               
                
                
                data.forEach(d => {
                    const event = {} as any
                    fields.forEach(f => {
                        
                        const dsfKey = dsmap.find(x => x.value == f.id)?.key
                        if (dsfKey) {

                            const fieldName = _columnsIds.find(x=> x.id == dsfKey)?.field
                            if (fieldName){
                                
                                    event[f.name] = d[fieldName] ;                              
                            }
                            
                        }
                    })
                    event.extendedProps = {
                        currentRecord: d
                    }
                     //@ts-ignore:disable-next-line
                events.value.push(event)
                })

               


            }

            
            options.value.initialEvents = events.value

        }

     
        const getObjectFieldMap = (fieldEvtName:string,) => {
            
           
            const _fields = getPropertyValue(FullCalendarTypeConst.FIELDS_DEF);
            const _dsmap = getPropertyValue(FullCalendarTypeConst.DS_MAP);
            const dsmap = (JSON.parse(_dsmap ?? "[]") ?? []) as Dictionary<string, string>[]
            const fields = (JSON.parse(_fields ?? "[]") ?? []) as fieldDefinition[];

            if (dsmap.length > 0 && fields.length > 0 && datasource.value) {
                const dbname = datasource.value?.dataSource?.iapDataSourceDataBase?.idBaseDatos
                const _columnsIds =
                
                datasource.value?.dataSource.iapDataSourceFields.filter(x => x.dataSourceTableAliasId == null).map(x => ({
                id: x.id + '#'+ datasource.value?.id,
                field: getFieldKeyColumn(x,true)
            }))
                .concat(
                    datasource.value?.dataSource.iapDataSourceFields.filter(x => x.dataSourceTableAliasId != null).map(x => ({
                        id: x.id+ '#'+ datasource.value?.id,
                        field: getFieldKeyColumn(x)
                    }))

                )
                     
                const field = fields.find(x=> x.name == fieldEvtName)
                if (field){
                    const dsfKey = dsmap.find(x => x.value == field.id)?.key
                    if (dsfKey) {
                        return _columnsIds.find(x=> x.id == dsfKey)?.field
                                                       
                    }
                
                }

            }

           

        }

        const loadEventsData = () => {
            
            
            if (datasource.value) {
                
                datasource.value.dataSource.items=null;
                resolveDatasource().then(result => {
                    
                    if (result) {
                        
                        convertToEvent(datasource.value?.dataSource.items ?? [])
                        
                        
                        if (fullCalendar.value) {
                            //@ts-ignore:disable-next-line
                            calApi = fullCalendar.value.getApi();
                            if (calApi){
                              //@ts-ignore:disable-next-line
                              calApi.getEvents().forEach((evt:any) =>{
                                evt.remove()
                              })
                              
                              options.value.initialEvents.forEach((evt:any) =>
                              {
                                //@ts-ignore:disable-next-line
                                calApi.addEvent(evt)
                              })
                              
                              
                              //calApi.refetchEvents();
                            }
                        }
                       
                                
                    }
                })
            }           
        }

        const saveData = (_data:any) => {
            
            if (datasource.value){
                const { getPrimaryKeys } = DataSourceUtilComp(datasource.value, store)
                
                const dbname = datasource.value?.dataSource?.iapDataSourceDataBase?.idBaseDatos

                const _columnsIds =
                    datasource.value?.dataSource.iapDataSourceFields.filter(x => x.dataSourceTableAliasId != null && !x.primaryKey).map(x => ({
                        id: x.id,
                        field: getFieldKeyColumn(x),
                        tableName:(HelperCommon.isNullOrWhitespace(x.tableNameAlias ?? '') ? x.tableName: x.tableNameAlias ),
                        mustSerialize: (x.sqlType == SqlTypesConst.BIT) ?? false,
                        mustToString:(x.sqlType == SqlTypesConst.MONEY) ?? false
                    }))

                

                const tableName = _columnsIds.find(x=> x.tableName !== '')?.tableName ?? ''; //dsf?.tableName ?? '';
                const pks = getPrimaryKeys(datasource.value.dataSource, _data as any, tableName as any)
              
                const ValuesToUpdate = _columnsIds.map(c =>({
                    dataBase: dbname,
              table: tableName as any,
              field: c.field,
              value: c.mustSerialize ? JSON.stringify(_data[c.field]) as any :c.mustToString? _data[c.field].toString():_data[c.field] as any,//JSON.stringify(newValue) as any
                })) 

          const dataUpdate: dataUpdate = {
            valores: ValuesToUpdate as any,
            claves: pks,
            tipoOperacion: OperationDataTypeConst.UPDATE,
            id: HelperUtils.newGuid()
          };

          
          const doOperationTransaction = dataUpdate.valores.length > 1;
          const dataInputRequest:DataUpdateOperation={
            componentId:datasource.value.componentId,
            componentDataSourceId:datasource.value.id,
            dataSourceId:datasource.value.dataSourceId,
            parameters:[],
            data:[dataUpdate]
        }
           HelperOperationData.doOperationData(props.container,datasource.value.applicationId,datasource.value.applicationVersion, [dataInputRequest], doOperationTransaction).then(result =>{
            if(result){
                
            }
           });
            
        }
    }

        const initData = () => {
            
            if (loaded.value && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)) {
                if (props.Component?.id) {
                    datasource.value = props.Component.iapComponentDataSources.find(x => x !== undefined)

                    var catalogoTipoIds = datasource.value?.dataSource.iapDataSourceFields.filter(x => x.catalogTypeId).map(x => x.catalogTypeId) ?? [];
                    catalogoTipoIds.push(CatalogTypeConst.FILTROBUSQUEDA);
                    const data = helperCatalog.getAllCatalogApp();
                    catalogos.value = data.filter(x => x.iapCatalogs).flatMap(x => x.iapCatalogs)
                    convertToEvent(datasource.value?.dataSource.items ?? [])


                }
            }
        }

        watch(
            isLoaded, (oldvalue, newvalue) => {
                
                //if (oldvalue == true && (newvalue ?? false) == true) {
                    initData()
                //}
            },
            {
                flush: 'post',
                immediate: true, 
                deep: true
            }
        )

        const doComponentCalendarEvent = (evt: { componentId: number, header: string, modal: boolean, attrs: IapComponentAttribute[], callBackResponse: any }) =>{
            componentInputId.value = evt.componentId;
                        componentAttributesList.value = evt.attrs;
                        if (clickedEvent.value && evt.header)
                        {
                            clickedEvent.value.title  = evt.header
                        }
                        eventDialog.value = true

                        if (evt.callBackResponse) {
                            evt.callBackResponse(true)
                        }
        }

        onMounted(() => {
            
            if (hasCustomViewComponent.value || hasCustomEditComponent.value)
            {            
                // para los modales o formularios de detalle
                EventBusCustom.on(keyComponentCalendar,doComponentCalendarEvent);
            }
            baseOnMounted();

        })

        onBeforeUnmount(() => {

           catalogos.value.length = 0;
           clickedEvent.value =null;           
           events.value =[];                   
           fullCalendar.value = null;
           calApi = null;        
            componentAttributesList.value.length = 0;
            options.value = null;


            if (hasCustomViewComponent.value || hasCustomEditComponent.value)
            {            
                // para los modales o formularios de detalle
                EventBusCustom.off(keyComponentCalendar, doComponentCalendarEvent)
            }
            baseOnBeforeUnmount();
        })

        return {

            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            FullCalendarTypeConst,
            loaded,
            BaseControlTypeConst,
            v$,
            submitted,
            canEdit,

            HelperCommon,

            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            , canDoOperation
            , eventDialog
            , clickedEvent
            , changedEvent
            , options
            , events
            , submittedNew
            , loadEventsData
            , catalogos
            , datasource
            ,fullCalendar            
            ,canUpdateRow
            ,recordToUpdate
            ,hasCustomViewComponent
            ,hasCustomEditComponent
            ,componentInputId
            ,componentAttributesList
            ,componentKey


        };
    },
});
</script>
<style scoped lang="scss">

:deep(.fc-header-toolbar) {
    .fc-button {
        line-height: 1;
        min-height: 2.07rem;
    }
    .fc-listWeek-button{
    background: #ffffff !important;
    border: 1px solid #cbd5e1 !important;
    color: #1e293b !important;
    //transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s  !important;
}
.fc-refreshButton-button{
    background: #ffffff !important;
    border: 1px solid #cbd5e1 !important;
    color: #1e293b !important;
    //transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s  !important;
}

}


</style>