<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <Password v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :KEY="componentKey"
        :id="getPropertyValue(PasswordTypeConst.ID)" v-tooltip="getPropertyValue(PasswordTypeConst.TOOLTIP)"
        :style="getPropertyValue(PasswordTypeConst.STYLE)"
        :class="{ [getPropertyValue(PasswordTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted,'p-readonly': getPropertyBooleanValue(PasswordTypeConst.READONLY) }"
        :name="getPropertyValue(PasswordTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(PasswordTypeConst.DISABLED) || (!canEdit)"
        :visible="getPropertyBooleanValue(PasswordTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :placeholder="getPropertyValue(PasswordTypeConst.PLACEHOLDER)"
        :promptLabel="getPropertyValue(PasswordTypeConst.PROMPTLABEL)"
        :mediumRegex="getPropertyValue(PasswordTypeConst.MEDIUMREGEX)"
        :strongRegex="getPropertyValue(PasswordTypeConst.STRONGREGEX)"
        :weakLabel="getPropertyValue(PasswordTypeConst.WEAKLABEL)"
        :mediumLabel="getPropertyValue(PasswordTypeConst.MEDIUMLABEL)"
        :strongLabel="getPropertyValue(PasswordTypeConst.STRONGLABEL)" 
        :feedback="getPropertyBooleanValue(PasswordTypeConst.FEEDBACK)"
        :toggleMask="getPropertyBooleanValue(PasswordTypeConst.TOGGLEMASK)" v-model="vmodel" 
        :readonly="getPropertyBooleanValue(PasswordTypeConst.READONLY)">
    </Password>
    <CustomValidate v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE)" v-model:submitted="submitted"
        v-model:vObject="v$" />
</template>
<script lang="ts">


import { defineComponent, onMounted, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import PasswordTypeConst from '../../../../domain/Constants/PasswordTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import CustomValidate from '../../shared/CustomValidate.vue';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_password',
    components: {
        CustomValidate,
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },       
        slotProps: {
            type: Object,
            default: () => ({})
        },        
    },
    setup(props, context) {
        //
        ;	
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded,  getCatalogValue,
            baseOnBeforeUnmount, baseOnMounted, v$, submitted, canEdit, isValidData
            , fieldKey, fieldKeyComponentDataSourceId ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);



        onMounted(() => {

            baseOnMounted();

        })

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })


        return {         
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            PasswordTypeConst,
            loaded,
            BaseControlTypeConst,
            getCatalogValue,
            v$,
            submitted,
            canEdit,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
