import { toRefs, reactive, computed, onMounted } from 'vue';
import { useLayoutBuilder } from '../../../../../../entidades/builder/template/infrastructure/prime/LayoutBuilder.ts'
//@ts-ignore:disable-next-line
import { container } from '../../../../../../../../Builder/src/infrastructure/container.ts' // '../../../../../container';

/*
const layoutConfig = reactive({
    ripple: false,
    inputStyle: 'outlined',
    menuMode: 'static',
    menuTheme: 'colorScheme',
    colorScheme: 'light',
    theme: 'indigo',
    scale: 14
});
*/


const { layoutConfig, setAppLayoutConfig } = useLayoutBuilder();

const layoutState = reactive({
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    sidebarActive: false,
    anchored: false,
    overlaySubmenuActive: false,
    profileSidebarVisible: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
    activeMenuItem: null
});

export function useLayout() {
    const layoutProperty = ({name:'',value:''});

    async function setLayoutConfig (layoutProperty) {
        var appLayoutCfg = {
            template:'apollo', 
            componentTheme:layoutProperty.name=='componentTheme' ? layoutProperty.value : layoutConfig.componentTheme, 
            scale:layoutProperty.name=='scale' ? Number(layoutProperty.value) : Number(layoutConfig.scale), 
            menuMode:layoutProperty.name=='menuMode' ? layoutProperty.value : layoutConfig.menuMode, 
            menuTheme:layoutProperty.name=='menuTheme' ? layoutProperty.value : layoutConfig.menuTheme, 
            colorScheme:layoutProperty.name=='colorScheme' ? layoutProperty.value : layoutConfig.colorScheme, 
            inputStyle:layoutProperty.name=='inputStyle' ? layoutProperty.value : layoutConfig.inputStyle, 
            ripple:layoutProperty.name=='ripple' ? layoutProperty.value : layoutConfig.ripple,
            topbarTheme:layoutProperty.name=='topbarTheme' ? layoutProperty.value : layoutConfig.topbarTheme, 
            menuProfilePosition:layoutProperty.name=='menuProfilePosition' ? layoutProperty.value : layoutConfig.menuProfilePosition, 
            RTL:layoutProperty.name=='RTL' ? layoutProperty.value : layoutConfig.RTL,
            wrapperMode:layoutProperty.name=='wrapperMode' ? layoutProperty.value : layoutConfig.wrapperMode
        }
        await setAppLayoutConfig(appLayoutCfg, container);
    }

    onMounted(() => {
    });

    const setScale = (scale) => {
        layoutConfig.scale = scale;
    };

    const setActiveMenuItem = (item) => {
        layoutState.activeMenuItem = item.value || item;
    };

    const onMenuToggle = () => {
        if (layoutConfig.menuMode === 'overlay') {
            layoutState.overlayMenuActive = !layoutState.overlayMenuActive;
        }

        if (window.innerWidth > 991) {
            layoutState.staticMenuDesktopInactive = !layoutState.staticMenuDesktopInactive;
        } else {
            layoutState.staticMenuMobileActive = !layoutState.staticMenuMobileActive;
        }
    };

    const onProfileSidebarToggle = () => {
        layoutState.profileSidebarVisible = !layoutState.profileSidebarVisible;
    };

    const onConfigSidebarToggle = () => {
        layoutState.configSidebarVisible = !layoutState.configSidebarVisible;
    };

    const isSidebarActive = computed(() => layoutState.overlayMenuActive || layoutState.staticMenuMobileActive || layoutState.overlaySubmenuActive);

    const isDarkTheme = computed(() => layoutConfig.darkTheme);

    const isDesktop = computed(() => window.innerWidth > 991);

    const isSlim = computed(() => layoutConfig.menuMode === 'slim');
    const isSlimPlus = computed(() => layoutConfig.menuMode === 'slim-plus');

    const isHorizontal = computed(() => layoutConfig.menuMode === 'horizontal');

    return {
        layoutConfig: toRefs(layoutConfig),
        layoutState: toRefs(layoutState),
        setScale,
        onMenuToggle,
        isSidebarActive,
        isDarkTheme,
        setActiveMenuItem,
        onProfileSidebarToggle,
        onConfigSidebarToggle,
        isSlim,
        isSlimPlus,
        isHorizontal,
        isDesktop,
        setLayoutConfig
    };
}
