<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
            
    <Timeline v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(TimeLineTypeConst.ID)" v-tooltip="getPropertyValue(TimeLineTypeConst.TOOLTIP)"
        :style="getPropertyValue(TimeLineTypeConst.STYLE)" :class="getPropertyValue(TimeLineTypeConst.CLASS)"
        :name="getPropertyValue(TimeLineTypeConst.NAME)" :disabled="getPropertyBooleanValue(TimeLineTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(TimeLineTypeConst.VISIBLE)"
        :placeholder="getPropertyValue(TimeLineTypeConst.PLACEHOLDER)" 
        :value="getPropertyValue(TimeLineTypeConst.VALUE)"
        :align="getCatalogValue(TimeLineTypeConst.ALIGN)" 
        :layout="getCatalogValue(TimeLineTypeConst.LAYOUT)"
        :datakey="getPropertyValue(TimeLineTypeConst.DATAKEY)"
        v-model="vmodel"
         >

        <template #content="slotProps">
            <slot name="content"
                :slotProps="{ data: slotProps.item, index: slotProps.index, control: ControlTypeConst.CTTIMELINE }"></slot>
        </template>


         <template #opposite="slotProps">
            <slot name="opposite"
                :slotProps="{ data: slotProps.item, index: slotProps.index, control: ControlTypeConst.CTTIMELINE }"></slot>
        </template>

        <template #marker="slotProps">
            <slot name="marker"
                :slotProps="{ data: slotProps.item, index: slotProps.index, control: ControlTypeConst.CTTIMELINE }"></slot>
        </template>
        <template #connector>
            <slot name="connector"
                :slotProps="{ data: slotProps.item, index: slotProps.index, control: ControlTypeConst.CTTIMELINE }"></slot>
        </template> 

    </Timeline>
</template>
<script lang="ts">


import { defineComponent,  onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import TimeLineTypeConst from '../../../../domain/Constants/TimeLineTypeConst';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import ControlTypeConst from '../../../../domain/Constants/ControlTypeConst';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({

    name: 'dynamic_time_line',
    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },

           

    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, loaded, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue, getCatalogValue, 
            baseOnBeforeUnmount,fieldKey,fieldKeyComponentDataSourceId ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);



       
        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })

        return {
 
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            TimeLineTypeConst,
            getPropertyNumberValue,
            loaded,
            BaseControlTypeConst,
            ControlTypeConst,
            getCatalogValue,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
