<template>
       <SummaryDebug v-if="getPropertyBooleanValue(CardTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <Card v-if="loaded && getPropertyBooleanValue(CardTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"  :key="componentKey"
        :id="getPropertyValue(CardTypeConst.ID)" v-tooltip="getPropertyValue(CardTypeConst.TOOLTIP)"
        :style="getPropertyValue(CardTypeConst.STYLE)"
        :class="{ [getPropertyValue(CardTypeConst.CLASS) ?? '']: true, 'customrequired': getPropertyBooleanValue(CardTypeConst.REQUIRED) }"
        :name="getPropertyValue(CardTypeConst.NAME)" :disabled="getPropertyBooleanValue(CardTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(CardTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" :placeholder="getPropertyValue(CardTypeConst.PLACEHOLDER)"
        v-model="vmodel" >

        <slot name="content"></slot>


        <template #header>
            <slot name="header"></slot>
        </template>

        <template #content>
            <slot name="content"></slot>
        </template>

        <template #footer>
            <slot name="footer"></slot>
        </template>

        <template #subtitle>
            <slot name="subtitle"></slot>
        </template>

        <template #title>
            <slot name="title"></slot>
        </template>



    </Card>
</template>
<script lang="ts">


import { defineComponent,  onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import CardTypeConst from '../../../../domain/Constants/CardTypeConst';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_card',
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },       
        slotProps: {
            type: Object,
            default: () => ({})
        },      
   
    },
    setup(props, context) {
        //        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded, 
            baseOnBeforeUnmount,fieldKey,fieldKeyComponentDataSourceId 
            ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);



        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })


        return {         
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            CardTypeConst,

            loaded,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
