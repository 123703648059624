import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../common/domain/types'
import type { IEncryptRepository } from '../domain/IEncryptRepository';
import { IServiceEncrypt } from './IServiceEncrypt';
import { GenerateOptions } from '../../../common/infrastructure/security';
import { Dictionary } from '../../builder/expression/domain/dictionary';



@injectable()
export class ServiceEncrypt implements IServiceEncrypt {
  private readonly repo: IEncryptRepository

  public constructor (@inject(TYPES.ENCRYPT_REPOSITORY) _repo: IEncryptRepository) {
    this.repo = _repo
  }  
  async decrypt (data: string) : Promise<string>{
    return await this.repo.decrypt(data)
  }
  async encrypt (data: string) : Promise<string>{
    return await this.repo.encrypt(data)
  }

  async generateKey (optionType: number, options: GenerateOptions | null, hash: boolean) : Promise<Dictionary<string,string>>{
    return await this.repo.generateKey(optionType, options, hash)
  }
  
}
