<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel" 
            />
            
        <Button icon="pi pi-cog" style="color:var(--primary-color);" text rounded severity="secondary" @click="onConfigButtonClick"></Button>

        <AppConfig :key="componentKey" :templateConfig="templateConfig" :templateSimple="templateSimple" />
</template>
<script lang="ts">

import { defineComponent, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';
import AppConfig from '../../../../../../../common/infrastructure/componentes/base/common/layout/AppConfig.vue';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import { useRouter } from 'vue-router';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import { useLayout } from '../../../../../../../common/infrastructure/componentes/base/common/composables/layout';
import HelperApplication from '@ilinium/shared/src/entidades/builder/application/infrastructure/functions/helperApplication';
import CatalogConfigurationConst from '@ilinium/shared/src/entidades/builder/catalog/domain/const/CatalogConfigurationConst';
import Environment from '@ilinium/shared/src/common/infrastructure/funciones/environment';

export default defineComponent({
    name: 'dynamicsetup',
    components: {
        SummaryDebug,
        AppConfig,
        useLayout
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },       
        slotProps: {
            type: Object,
            default: () => ({})
        },
    },
    setup(props, context) {
        
        const router = useRouter();
        const store = useStore();
        const { onMenuToggle, onProfileSidebarToggle, onConfigSidebarToggle } = useLayout();

        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded, resolveExpressions, baseOnBeforeUnmount
            ,fieldKey,fieldKeyComponentDataSourceId ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);

        const onConfigButtonClick = () => {
            onConfigSidebarToggle();
        };

        const appCfg = store.getters.getAppConfig
        const { getAppKeyValue } = HelperApplication(undefined)

        let templateConfig = (getAppKeyValue(CatalogConfigurationConst.TEMPLATECONFIG,Environment.ENVIRONMENT??'',appCfg) === "true");
        let templateSimple = (getAppKeyValue(CatalogConfigurationConst.TEMPLATECONFIGSIMPLE,Environment.ENVIRONMENT??'',appCfg) === "true");

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })

        return {
    
            BaseControlTypeConst,
            vmodel,
            getPropertyBooleanValue,
            onConfigButtonClick,
            templateConfig,
            templateSimple,
            componentKey,     
            loaded       
        };
    },
});
</script>
<style scoped></style>
