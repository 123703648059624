export default class ComponentTypeConst {

    static KEEP_ALIVE = 'keepAlive'
    static APP_CACHE = 'KeepCache'
    static SEARCH_FORM = 'searchForm'
    static CMD_AFTER_SAVE = 'commandAfterSave'
    static CMD_BEFORE_SAVE = 'commandBeforeSave'
    static CMD_AFTER_CANCEL = 'commandAfterCancel'
    static CMD_BEFORE_CANCEL = 'commandBeforeCancel'
    
}