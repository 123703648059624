import { ClassicPreset } from 'rete';
import { AreaPlugin } from 'rete-area-plugin';
import { AreaExtra, Schemes } from '../rete/helperRete';

import { LocalService } from '../../../../../../../common/infrastructure/servicios';
import { Router } from 'vue-router';
import { Container } from 'inversify';
import { SwitchControl } from '../controls/switchControl';
import { IapComponent } from '../../../../../component/domain/iapComponent';
import CatalogObjectTypeConst from '../../../../../catalog/domain/const/CatalogObjectTypeConst';
import { IapWorkFlowActivityControl } from '../../../../domain/service/iapWorkFlowActivityControl';
import CatalogExpConst from '../../../../../catalog/domain/const/CatalogExpConst';
import { ExpresionEngine } from '../../../../../expression/infrastructure/helper/expressionEngine';
import { IapWorkFlowActivity } from '../../../../domain/service/iapWorkFlowActivity';
import EventConst from '../../../../../../../common/domain/constantes/EventConst';
import SwitchNodeConst from '../constants/SwitchNodeConst';
import OperationDataTypeConst from '../../../../../../../common/domain/constantes/OperationDataTypeConst';
import HelperUtils from '../../../../../../../common/infrastructure/funciones/HelperUtils';
import { DataflowEngine } from 'rete-engine';
import HelperCommon from '@ilinium/shared/src/common/infrastructure/funciones/HelperCommon';

export class SwitchNode extends ClassicPreset.Node<
    { ejecutar: ClassicPreset.Socket, dataInput: ClassicPreset.Socket },
    { option1: ClassicPreset.Socket, option2: ClassicPreset.Socket, option3: ClassicPreset.Socket, option4: ClassicPreset.Socket, option5: ClassicPreset.Socket, default: ClassicPreset.Socket },
    { value: SwitchControl }
> {
    height = 800;
    width = 300;
    //private value: Number;
    private response0: boolean = false;
    private response1: boolean = false;
    private response2: boolean = false;
    private response3: boolean = false;
    private response4: boolean = false;

    private keyEventComponent: string;
    private area: AreaPlugin<Schemes, AreaExtra>;
    private updateNode: any;
    private rdControlId: '';


    private getNodeInternalData: any;
    private showExpression: any;
    private router: Router;
    private container: Container | undefined;
    private rootComponentId: number;
    private currentComponentId: number;
    private store: any;
    private activity: IapWorkFlowActivity | undefined;
    private componentData: IapComponent[]

    private formKey: String;
    private currentElementKey: string;
    private dataflow: DataflowEngine<Schemes>

    //constructor(area: AreaPlugin<Schemes, AreaExtra>, socket: ClassicPreset.Socket,  public applicationId: number, public applicationVersion: number, public variableComponentInput: number, public variableComponentHeaderInput: string, public variableComponentModalInput: boolean, public variableComponentParametersInput: [], updateNode: any = undefined) {
    constructor(area: AreaPlugin<Schemes, AreaExtra>, socket: ClassicPreset.Socket, public formKeyInput: String, public currentElementKeyInput: string, public rootComponentInputId: number, public currentComponentInputId: number, public applicationId: number, public applicationVersion: number, public variableConditionInput0: string, public variableConditionInput1: string, public variableConditionInput2: string, public variableConditionInput3: string, public variableConditionInput4: string, updateNode: any = undefined, getNodeInternalData: any = undefined, showExpressionFunction: any = undefined, router: Router, container: Container | undefined, storeInput: any, itemActivity: IapWorkFlowActivity | undefined, dataflow: DataflowEngine<Schemes>, componentDataInput: IapComponent[]) {
        super("Switch");

        this.area = area;
        this.updateNode = updateNode;
        this.router = router;

        this.getNodeInternalData = getNodeInternalData;
        this.showExpression = showExpressionFunction;
        this.container = container;
        this.rootComponentId = rootComponentInputId;
        this.currentComponentId = currentComponentInputId;
        this.store = storeInput;
        this.activity = itemActivity;
        this.keyEventComponent = (rootComponentInputId ?? -1).toString() + '_' + EventConst.SHOWCOMPONENT;
        this.componentData = componentDataInput;
        this.formKey = formKeyInput;
        this.currentElementKey = currentElementKeyInput;
        this.dataflow = dataflow;

        const dsControl = new SwitchControl(formKeyInput, rootComponentInputId, currentComponentInputId, applicationId, applicationVersion, variableConditionInput0, variableConditionInput1, variableConditionInput2, variableConditionInput3, variableConditionInput4, container, storeInput, itemActivity, this.updateData, this.getNode, this.showExp);

        this.rdControlId = (dsControl as any).id;

        this.addInput("ejecutar", new ClassicPreset.Input(socket, "Ejecutar", true));
        this.addControl(
            "value",
            dsControl

            //new ClassicPreset.InputControl("text", { initial })
        );

        this.addInput("dataInput", new ClassicPreset.Input(socket, "DataInput"));



        //area.update("control",dsControl.id)
        this.addOutput("option1", new ClassicPreset.Output(socket, "Option 1", true));
        this.addOutput("option2", new ClassicPreset.Output(socket, "Option 2", true));
        this.addOutput("option3", new ClassicPreset.Output(socket, "Option 3", true));
        this.addOutput("option4", new ClassicPreset.Output(socket, "Option 4", true));
        this.addOutput("option5", new ClassicPreset.Output(socket, "Option 5", true));
        this.addOutput("default", new ClassicPreset.Output(socket, "Default", true));
        



    }

    showExp = (evt: any) => {
        if (this.showExpression) {
            return this.showExpression(evt)
        }
        return null;
    }

    getNode = (key: string) => {
        if (this.getNodeInternalData) {
            return this.getNodeInternalData(this.id, key, true, false)
        }
        return null;
    }

    updateData = (evt: any) => {

        //this.value = evt
        //@ts-ignore:disable-next-line
        this.controls.value[evt.key] = evt.data;
        //this.controls[evt.key].valueConnection = evt.data;

        this.area.update("control", this.rdControlId)


        if (this.updateNode) {
            this.updateNode(this.id, evt.key, JSON.stringify(evt.data), (evt?.operation ?? OperationDataTypeConst.UPDATE))
        }


    }

    resolveExpressions = () => {

        const currentComp = this.componentData.find(x => x.id == this.currentComponentId)
        const WfData = currentComp?.workFlows?.find(w => w.id == this.activity?.workFlowId)

        this.activity?.iapWorkFlowActivityControls?.forEach((wcf: IapWorkFlowActivityControl) => {

            if (currentComp && wcf) {


                let keyControl = CatalogObjectTypeConst.ATTRCOMP + '#' + WfData?.objetoId + '#' + wcf.id.toString();

                let exps = currentComp.expressions?.filter(x =>
                    x.idObjeto == CatalogObjectTypeConst.WFAC
                    && x.idTypeExpression == CatalogExpConst.EXP_SET
                    && x.iapExpressionDetails?.length > 0
                    && x.objetoId == keyControl);

                if ((exps?.length ?? 0) == 0) {
                    keyControl = CatalogObjectTypeConst.EVTCOMP + '#' + WfData?.objetoId + '#' + wcf.id.toString();
                    exps = currentComp.expressions?.filter(x =>
                        x.idObjeto == CatalogObjectTypeConst.WFAC
                        && x.idTypeExpression == CatalogExpConst.EXP_SET
                        && x.iapExpressionDetails?.length > 0
                        && x.objetoId == keyControl);

                }

                if (exps?.length > 0) {
                    exps?.every(exp => {
                        if (exp.iapExpressionDetails?.length > 0) {

                            const localData = LocalService.getValue(this.formKey + LocalService.COMPONENTS_EXP + (this.rootComponentId ?? -1).toString());
                            const data = HelperUtils.jsonParse(localData, [])
                            let resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, this.store)
                            //resu = resu?.toString();
                            if (resu) {
                                if (Object.keys(resu).length == 0) {
                                    resu = resu?.toString();
                                }
                            }
                            switch (wcf.name) {
                                case SwitchNodeConst.VAR_COND_IN0:
                                    // code block
                                    this.response0 = HelperUtils.booleanify(resu)
                                    break;
                                case SwitchNodeConst.VAR_COND_IN1:
                                    // code block
                                    this.response1 = HelperUtils.booleanify(resu)
                                    break;
                                case SwitchNodeConst.VAR_COND_IN2:
                                    // code block
                                    this.response2 = HelperUtils.booleanify(resu)
                                    break;
                                case SwitchNodeConst.VAR_COND_IN3:
                                    // code block
                                    this.response3 = HelperUtils.booleanify(resu)
                                    break;
                                case SwitchNodeConst.VAR_COND_IN4:
                                    // code block
                                    this.response4 = HelperUtils.booleanify(resu)
                                    break;

                                default:
                                // code block
                            }

                        }
                    })

                }






            }


        })

        //const wcf =  this.activity?.iapWorkFlowActivityControls.find(x=> x.name == DisplayComponentNodeConst.VAR_PARAM_IN);

    }

    private fillInputData(data: any) {
        if (data) {

            if (!HelperCommon.isNullOrWhitespace(this.variableConditionInput0)) {
                //@ts-ignore:disable-next-line
                const keyParam = this.variableConditionInput0.replaceAll('#', '');
                this.response0 = HelperUtils.booleanify(data[keyParam]  ?? 'false');
                

            }

            if (!HelperCommon.isNullOrWhitespace(this.variableConditionInput1)) {
                //@ts-ignore:disable-next-line
                const keyParam = this.variableConditionInput1.replaceAll('#', '');
                this.response1 =  HelperUtils.booleanify(data[keyParam]  ?? 'false');

            }
            if (!HelperCommon.isNullOrWhitespace(this.variableConditionInput2)) {
                //@ts-ignore:disable-next-line
                const keyParam = this.variableConditionInput2.replaceAll('#', '');
                this.response2 = HelperUtils.booleanify(data[keyParam]  ?? 'false');


            }
            if (!HelperCommon.isNullOrWhitespace(this.variableConditionInput3)) {
                //@ts-ignore:disable-next-line
                const keyParam = this.variableConditionInput3.replaceAll('#', '');
                this.response3 = HelperUtils.booleanify(data[keyParam]  ?? 'false');


            }
            if (!HelperCommon.isNullOrWhitespace(this.variableConditionInput4)) {
                //@ts-ignore:disable-next-line
                const keyParam = this.variableConditionInput4.replaceAll('#', '');
                this.response4 = HelperUtils.booleanify(data[keyParam]  ?? 'false');
            }


        }
    }

    async execute(_: never, forward: (output: "option1" | "option2" | "option3" | "option4" | "option5" | "default" ) => void) {
        const inputs = (await this.dataflow.fetchInputs(this.id)) as {
            dataInput: any;
        };

        if (inputs && inputs?.dataInput && inputs.dataInput?.length == 1) {
            let auxData: any = null;
            const data = inputs.dataInput[0]
            this.fillInputData(data);

        }

        // resolvemos las expresiones
        // vamos a buscar las expresiones si las hubiera


        this.resolveExpressions();


        
        if (this.response0) {
            forward("option1");
        }
        else {
            if (this.response1) {
                forward("option2");
            }
            else {
                if (this.response2) {
                    forward("option3");
                }
                else {
                    if (this.response3) {
                        forward("option4");
                    }
                    else {
                        if (this.response4) {
                            forward("option5");
                        }
                        else {
                            forward("default");
                        }

                    }

                }

            }

        }




    }




    data() {
        return {
            option1: { option1: this.response0 },
            option2: { option2: this.response1 },
            option3: { option3: this.response2 },
            option4: { option4: this.response3 },
            option5: { option5: this.response4 },
            default: { default: true },

        };
    }
}