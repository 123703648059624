import gql from 'graphql-tag';


export const DELETE = gql(`
mutation($id: Int!, $componentId:Int!, $source:String!){
    documentMutation{
        deleteDocument(id:$id, componentId:$componentId, source:$source)
    }
}
`);

export const UPDATE = gql(`
mutation($data: StorageDocumentInputType!, $content: ID, $componentId:Int!, $source:String!){
    documentMutation{
        updateDocument(data:$data, content:$content, componentId:$componentId, source:$source){
            id
            idOld
            idObjeto
            objetoId
            fileName
            contentType
            entityMainIntegracionId
            documentTypeId
            externalStorageId
            storageConfigurationId
            urlLink
            markForDelete
            description
            visibility
            fcr
            ucr
            fum
            uum
            ucrName
            uumName
        }
    }
}
`);

export const ADD = gql(`
mutation($data: StorageDocumentInputType!, $content: ID!, $componentId:Int!, $source:String!, $integrate:Boolean!, $integrationTypeId:String!){
    documentMutation{
        addDocument(data:$data, content:$content,componentId:$componentId, source:$source, integrate:$integrate, integrationTypeId:$integrationTypeId){
            id
            idOld
            idObjeto
            objetoId
            fileName
            contentType
            entityMainIntegracionId
            documentTypeId
            externalStorageId
            storageConfigurationId
            urlLink
            markForDelete
            description
            visibility
            fcr
            ucr
            fum
            uum
            ucrName
            uumName
        }
    }
}
`);



