import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: ApplicationConfigurationInputType!){
    applicationConfigurationMutation{
        updateApplicationConfiguration(data:$data){
            id,
            applicationId,
            applicationVersion,
            idKeyType,
            keyValue,
            idEnvironmentType,
            keyBinaryValue,
            order,
            fcr,
            ucr,
            uum,
            fum
        }
    }
}
`);

export const ADD = gql(`
mutation($data: ApplicationConfigurationInputType!){
    applicationConfigurationMutation{
        addApplicationConfiguration(data:$data){
            id,
            applicationId,
            applicationVersion,
            idKeyType,
            idEnvironmentType,
            keyValue,
            keyBinaryValue,
            order,
            fcr,
            ucr
        }
    }
}
`);

export const DELETE = gql(`
mutation($id: Int!){
    applicationConfigurationMutation{
        deleteApplicationConfiguration(id:$id)
    }
}
`);




