import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../common/infrastructure/funciones/HelperApolloClient';

import { IEncryptRepository } from '../domain/IEncryptRepository';

//import { UPDATE } from './mutation/applicationMutation';
import ApolloConst from '../../../common/domain/constantes/Apollo/ApolloConst';
import { DECRYPT, ENCRYPT, GENK } from './mutation/encryptMutation';
import HelperUtils from '../../../common/infrastructure/funciones/HelperUtils';
import { GenerateOptions } from '../../../common/infrastructure/security';
import { Dictionary } from '../../builder/expression/domain/dictionary';



@injectable()
export class EncryptApolloClientRepository implements IEncryptRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId=ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }
  async decrypt (data: string): (Promise<string>) {
    return new Promise<string>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(DECRYPT, { data: data }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.encryptMutation.decrypt)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async encrypt (data: string): (Promise<string>) {
    return new Promise<string>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(ENCRYPT, { data: data }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.encryptMutation.encrypt)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async generateKey (optionType: number, options: GenerateOptions | null, hash: boolean) : Promise<Dictionary<string,string>>{
    return new Promise<Dictionary<string,string>>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GENK, { optionType: optionType, options:options, hash:hash }, this.clientId)
       
       watch(result, (value: any) => {
         try {
           HelperUtils.handleDatesApollo(value) 
           resolve(value.encryptMutation.generateKey)
         }
         catch(e) {
           reject()
         }
       });
     });
  }





}


