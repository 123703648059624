import { Dictionary } from "../../domain/dictionary";
import {
  Expression,
  EvaluateOptions,
  ParameterArgs,
  FunctionArgs  
} from "ncalcjs";
import { IapExpressionDetail } from "../../domain/iapExpressionDetail";
import { MessageService } from "../../../../../common/infrastructure/servicios";
import { MessageType } from "../../../../../common/infrastructure/servicios/MessageService";
import helperFunctionsEngine from "./helperFunctionsEngine";
import { Store } from 'vuex';

export abstract class ExpresionEngine {
  public static resolveExpression(
    inputExpresion: string,
    data: Dictionary<String, Object>[],
    store: Store<any>,
  ): any {   
    
    const e = new Expression(inputExpresion, EvaluateOptions.RoundAwayFromZero);
    if (e.HasErrors()) {
      console.error("Error en expresión" + inputExpresion);
      console.log(e.errors);
    }
    data?.forEach((param) => {
      e.EvaluateParameter[param.key as any] = (arg: ParameterArgs) => {
        arg.Result =
          data.find((x) => x.key == param.key)?.value ?? null;
      };
    });
    
    e.EvaluateFunction["getPropertyValue"]  = (args: FunctionArgs) => helperFunctionsEngine.getPropertyValue(args)
    e.EvaluateFunction["falso"] = (args: FunctionArgs) => helperFunctionsEngine.falso(args)
    e.EvaluateFunction["verdadero"] = (args: FunctionArgs) => helperFunctionsEngine.verdadero(args)
    e.EvaluateFunction["tamcadena"] = (args: FunctionArgs) => helperFunctionsEngine.tamcadena(args)
    e.EvaluateFunction["Subcadena"] = (args: FunctionArgs) => helperFunctionsEngine.Subcadena(args)
    e.EvaluateFunction["Guid"] = (args: FunctionArgs) => helperFunctionsEngine.Guid(args)
    e.EvaluateFunction["YearDiff"]= (args: FunctionArgs) => helperFunctionsEngine.YearDiff(args)
    e.EvaluateFunction["MonthDiff"] = (args: FunctionArgs) => helperFunctionsEngine.MonthDiff(args)
    e.EvaluateFunction["DayDiff"] = (args: FunctionArgs) => helperFunctionsEngine.DayDiff(args)
    e.EvaluateFunction["ConvertirMoneda"]= (args: FunctionArgs) => helperFunctionsEngine.ConvertirMoneda(args)
    e.EvaluateFunction["ConvertirNumero"] = (args: FunctionArgs) => helperFunctionsEngine.ConvertirNumero(args)
    e.EvaluateFunction["in"] = (args: FunctionArgs) => helperFunctionsEngine._in(args)
    e.EvaluateFunction["Rango"]= (args: FunctionArgs) => helperFunctionsEngine.Rango(args)
    e.EvaluateFunction["LoopForTemplateFile"] = (args: FunctionArgs) => helperFunctionsEngine.LoopForTemplateFile(args)
    e.EvaluateFunction["addday"] = (args: FunctionArgs) => helperFunctionsEngine.addday(args)
    e.EvaluateFunction["AddYear"] = (args: FunctionArgs) => helperFunctionsEngine.AddYear(args)
    e.EvaluateFunction["addmonth"] = (args: FunctionArgs) => helperFunctionsEngine.addmonth(args)
    e.EvaluateFunction["GetDay"] = (args: FunctionArgs) => helperFunctionsEngine.GetDay(args)
    e.EvaluateFunction["getyear"] = (args: FunctionArgs) => helperFunctionsEngine.getyear(args)
    e.EvaluateFunction["GetMonth"] = (args: FunctionArgs) => helperFunctionsEngine.GetMonth(args)
    e.EvaluateFunction["catalogo"] = (args: FunctionArgs) => helperFunctionsEngine.catalogo(args)
    e.EvaluateFunction["Edad"] = (args: FunctionArgs) => helperFunctionsEngine.Edad(args)
    e.EvaluateFunction["EdadActuarial"] = (args: FunctionArgs) => helperFunctionsEngine.EdadActuarial(args)
    e.EvaluateFunction["Msg"] = (args: FunctionArgs) => helperFunctionsEngine.Msg(args)
    e.EvaluateFunction["isCollection"] = (args: FunctionArgs) => helperFunctionsEngine.isCollection(args)
    e.EvaluateFunction["sizeCollection"] = (args: FunctionArgs) => helperFunctionsEngine.sizeCollection(args)
    e.EvaluateFunction["convertstring"] = (args: FunctionArgs) => helperFunctionsEngine.convertstring(args)
    e.EvaluateFunction["setValues"] = (args: FunctionArgs) => helperFunctionsEngine.setValues(args)
    e.EvaluateFunction["Hoy"]= (args: FunctionArgs) => helperFunctionsEngine.Hoy(args)
    e.EvaluateFunction["getCatalogDescription"] = (args: FunctionArgs) => helperFunctionsEngine.getCatalogDescription(args)
    e.EvaluateFunction["getSingleCuote"] = (args: FunctionArgs) => helperFunctionsEngine.getSingleCuote(args)
    e.EvaluateFunction["getParameterFromStorage"] = (args: FunctionArgs) => helperFunctionsEngine.getParameterFromStorage(args, store)
    e.EvaluateFunction["isNull"] = (args: FunctionArgs) => helperFunctionsEngine.isNull(args)

    e.EvaluateFunction["isValidNif"] = (args: FunctionArgs) => helperFunctionsEngine.isValidNIF(args)
    e.EvaluateFunction["isValidNie"] = (args: FunctionArgs) => helperFunctionsEngine.isValidNIE(args)
    e.EvaluateFunction["isValidCif"] = (args: FunctionArgs) => helperFunctionsEngine.isValidCIF(args)
    e.EvaluateFunction["isValidEmail"] = (args: FunctionArgs) => helperFunctionsEngine.isValidEMAIL(args)
    e.EvaluateFunction["isValidIban"] = (args: FunctionArgs) => helperFunctionsEngine.isValidIBAN(args)


    try {
      const resulExp = e.Evaluate();  
      
      return resulExp;
    } catch (err: any) { 
      
        MessageService.showMessage(MessageType.Error, '', 'Ha ocurrido un error al resolver la expresión: "' + inputExpresion + '".Error:' + e?.errors?.map( (x:any)=> x.toString()).join('##ERRDET##'), true);
    }
    return null;
  }

  public static resolveExpressions(    
    inputExpresions: IapExpressionDetail[],
    data: Dictionary<String, Object>[],
    store: Store<any>,
  ): any {
    
    let resu: any;

    let dic = JSON.parse(JSON.stringify(data));

    inputExpresions
      .sort((a, b) => {
        return a.processOrder - b.processOrder;
      })
      .forEach((exp) => {
        resu = this.resolveExpression(exp.expression, dic, store);
        var aux = {
          key: exp.variable,
          value: resu,
        };
        dic.push(aux as any);
      });

    return resu;
  }
}
