import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: ApplicationConnectionInputType!){
    applicationConnectionMutation{
        updateApplicationConnection(data:$data){
            applicationId
            applicationVersion
            serverName
            dataBaseName
            idDataBaseType
            idDbType
            idEnvironmentType
            userName
            fcr
            ucr
            uum
            fum
        }
    }
}
`);


export const ADD = gql(`
mutation($data: ApplicationConnectionInputType!){
    applicationConnectionMutation{
        addApplicationConnection(data:$data){
            applicationId
            applicationVersion
            serverName
            dataBaseName
            idDataBaseType
            idDbType
            idEnvironmentType
            userName
            fcr
            ucr
            uum
            fum
        }
    }
}
`);


export const DELETE = gql(`
mutation($data: ApplicationConnectionInputType!){
    applicationConnectionMutation{
        deleteApplicationConnection(data:$data)
    }
}
`);


export const CHANGE_PWD = gql(`
mutation($data: ApplicationConnectionInputType!, $pwd: String!){
    applicationConnectionMutation{
        changeApplicationConnection(data:$data, pwd:$pwd)
    }
}
`);




