import BaseControlTypeConst from "./BaseControlTypeConst"

export default class DataTableTypeConst extends BaseControlTypeConst {

    static VALUE = 'value'
    static DATAKEY = 'dataKey'
    static ROWS = 'rows'
    static FIRST = 'first'
    static TOTALRECORDS = 'totalRecords'
    static PAGINATOR = 'paginator'
    static PAGINATORPOSITION = 'paginatorPosition'
    static ALWAYSSHOWPAGINATOR = 'alwaysShowPaginator'
    static PAGINATORTEMPLATE = 'paginatorTemplate'
    static PAGELINKSIZE = 'pageLinkSize'
    static ROWSPERPAGEOPTIONS = 'rowsPerPageOptions'
    static CURRENTPAGEREPORTTEMPLATE = 'currentPageReportTemplate'
    static LAZY = 'lazy'
    static LOADING = 'loading'
    static LOADINGICON = 'loadingIcon'
    static SORTFIELD = 'sortField'
    static SORTORDER = 'sortOrder'
    static DEFAULTSORTORDER = 'defaultSortOrder'
    static MULTISORTMETA = 'multiSortMeta'
    static SORTMODE = 'sortMode'
    static REMOVABLESORT = 'removableSort'
    static FILTERS = 'filters'
    static FILTERDISPLAY = 'filterDisplay'
    static GLOBALFILTERFIELDS = 'globalFilterFields'
    static FILTERLOCALE = 'filterLocale'
    static SELECTION = 'selection'
    static SELECTIONMODE = 'selectionMode'
    static COMPARESELECTIONBY = 'compareSelectionBy'
    static METAKEYSELECTION = 'metaKeySelection'
    static CONTEXTMENU = 'contextMenu'
    static CONTEXTMENUSELECTION = 'contextMenuSelection'
    static SELECTALL = 'selectAll'
    static ROWHOVER = 'rowHover'
    static CSVSEPARATOR = 'csvSeparator'
    static EXPORTFILENAME = 'exportFilename'
    static RESIZABLECOLUMNS = 'resizableColumns'
    static COLUMNRESIZEMODE = 'columnResizeMode'
    static REORDERABLECOLUMNS = 'reorderableColumns'
    static EXPANDEDROWS = 'expandedRows'
    static EXPANDEDROWICON = 'expandedRowIcon'
    static COLLAPSEDROWICON = 'collapsedRowIcon'
    static ROWGROUPMODE = 'rowGroupMode'
    static GROUPROWSBY = 'groupRowsBy'
    static EXPANDABLEROWGROUPS = 'expandableRowGroups'
    static EXPANDEDROWGROUPS = 'expandedRowGroups'
    static STATESTORAGE = 'stateStorage'
    static STATEKEY = 'stateKey'
    static EDITMODE = 'editMode'
    static EDITINGROWS = 'editingRows'
    static ROWCLASS = 'rowClass'
    static ROWSTYLE = 'rowStyle'
    static SCROLLABLE = 'scrollable'
    static SCROLLHEIGHT = 'scrollHeight'
    static VIRTUALSCROLLEROPTIONS = 'virtualScrollerOptions'
    static FROZENVALUE = 'frozenValue'
    static BREAKPOINT = 'breakpoint'
    static SHOWGRIDLINES = 'showGridlines'
    static STRIPEDROWS = 'stripedRows'
    static SIZE = 'size'
    static TABLESTYLE = 'tableStyle'
    static TABLECLASS = 'tableClass'
    static TABLEPROPS = 'tableProps'
    static FILTERINPUTPROPS = 'filterInputProps'
    static PT = 'pt'
    static UNSTYLED = 'unstyled'
    static DATASOURCE = 'dataSource'

}
