import gql from 'graphql-tag';


export const DECRYPT = gql(`
mutation($data: String!){
    encryptMutation{
        decrypt(data:$data)
    }
}
`);


export const ENCRYPT = gql(`
mutation($data: String!){
    encryptMutation{
        encrypt(data:$data)
    }
}
`);


export const GENK = gql(`
mutation($optionType: Int!,$options: GeneratePasswordOptionsInputType,$hash: Boolean!){
    encryptMutation{
        generateKey(optionType:$optionType,options:$options,hash:$hash){
            key
            value
        }
    }
}
`);
