<template>

    <SummaryDebug v-if="getPropertyBooleanValue(PickListTypeConst.DEBUG, Component)" :data="Component"
        :vmodel="vmodel" />
    <PickList
        v-if="loaded && plValues && (getPropertyBooleanValue(PickListTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW))"
        :key="componentKey" :id="getPropertyValue(PickListTypeConst.ID)"
        v-tooltip="getPropertyValue(PickListTypeConst.TOOLTIP)" :style="getPropertyValue(PickListTypeConst.STYLE)"
        :class="{ [getPropertyValue(PickListTypeConst.CLASS) ?? '']: true }"
        :name="getPropertyValue(PickListTypeConst.NAME)" :dataKey="getPropertyValue(PickListTypeConst.DATAKEY)"
        :metaKeySelection="getPropertyBooleanValue(PickListTypeConst.METAKEYSELECTION)"
        :autoOptionFocus="getPropertyBooleanValue(PickListTypeConst.AUTOOPTIONFOCUS)"
        :focusOnHover="getPropertyBooleanValue(PickListTypeConst.FOCUSONHOVER)"
        :listStyle="getPropertyValue(PickListTypeConst.LISTSTYLE)"
        :responsive="getPropertyBooleanValue(PickListTypeConst.RESPONSIVE)"
        :breakpoint="getPropertyValue(PickListTypeConst.BREAKPOINT)"
        :scrollHeight="getPropertyValue(PickListTypeConst.SCROLLHEIGHT)"
        :striped="getPropertyBooleanValue(PickListTypeConst.STRIPED)"
        :showSourceControls="getPropertyBooleanValue(PickListTypeConst.SHOWSOURCECONTROLS)"
        :showTargetControls="getPropertyBooleanValue(PickListTypeConst.SHOWTARGETCONTROLS)" v-model="plValues"
        @update:modelValue="updateSelectionKeys" @update:selection="updateSelectionKeys" @reorder="updateSelectionKeys"
        @selection-change="updateSelectionKeys" @move-to-target="updateSelectionKeys"
        @move-all-to-target="updateSelectionKeys" @move-to-source="updateSelectionKeys"
        @move-all-to-source="updateSelectionKeys">

        <template #item="{ item }">
            {{ printItem(item) }}
        </template>

    </PickList>


</template>

<script lang="ts">

import { defineComponent, onMounted, ref, onBeforeUnmount, shallowRef, watchEffect } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import PickListTypeConst from '../../../../domain/Constants/PickListTypeConst';
import { Container } from 'inversify';
import WidgetComponentDataTree from '../../../../../designer/domain/ComponentDataForm';
import ControlTypeConst from '../../../../domain/Constants/ControlTypeConst';
import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_pick_list',

    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => WidgetComponentDataTree,
            default: () => ({})
        },

        slotProps: {
            type: Object,
            default: () => ({}),
        },
    },
    setup(props, context) {
        const arrayModel = ref([]);
        const store = useStore();
        const plValues = ref<[[], []] | null>(null);

        const setVModelToArray = () => {
            arrayModel.value = (vmodel.value ?? '').split(',').filter(x => x !== '') as []
        }

        const cbVModel = (vmodelInput:any) =>{            
            if (plValues.value && vmodelInput ){
                const key = getPropertyValue(PickListTypeConst.DATAKEY);
                const newData = vmodelInput?.replace(/\"/g,'')?.split(',');
                if (arrayModel.value.toString() !== newData.toString()){                    
                    arrayModel.value = newData
                    const allvalues = plValues.value[0].concat(plValues.value[1])
                    //@ts-ignore:disable-next-line
                    const notselected = allvalues.filter((x: any) => !arrayModel.value.includes(x[key]))
                    //@ts-ignore:disable-next-line
                    const selected = allvalues.filter((x: any) => arrayModel.value.includes(x[key]))
                    //@ts-ignore:disable-next-line
                    plValues.value = [notselected, selected];
                }                
            }
            else{
                if (vmodelInput == null && plValues.value){
                    arrayModel.value=[]
                    const allvalues = plValues.value[0].concat(plValues.value[1]) ?? []
                    plValues.value = [allvalues as any, []];
                }
            }
            
        }

        const { vmodel, canDoOperation, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue
            , getPropertyValueObjectOrFile, getCatalogValue
            , loaded, baseOnMounted, baseOnBeforeUnmount, canEdit
            , isValidData, fieldKey, fieldKeyComponentDataSourceId, processEventComponent
            , componentKey, getLayoutComponent, buildMenu
        } = ComponentCommonRender(props.Component, props.slotProps, props.container, store, setVModelToArray,cbVModel)


     



        const setSelectedKeyToVModel = () => {
            if (arrayModel.value?.length > 0) {
                vmodel.value = JSON.stringify((arrayModel.value ?? []).join(','))
            }
            else {
                vmodel.value = null
            }

            processEventComponent(CatalogEventConst.CHANGE)
        }







        const getValue = () => {

            getPropertyValueObjectOrFile(PickListTypeConst.OPTIONS, PickListTypeConst.DATAFILEVALUE, PickListTypeConst.DATASOURCE, PickListTypeConst.CATALOGTYPE, true).
                then((response: any) => {
                    if (response) {                        
                        const key = getPropertyValue(PickListTypeConst.DATAKEY);
                        if (vmodel.value && key) {
                            arrayModel.value = vmodel.value.split(',')
                            //@ts-ignore:disable-next-line
                            const notselected = response.filter((x: any) => !arrayModel.value.includes(x[key]))
                            //@ts-ignore:disable-next-line
                            const selected = response.filter((x: any) => arrayModel.value.includes(x[key]))
                            //@ts-ignore:disable-next-line
                            plValues.value = [notselected, selected];
                        }
                        else {
                            plValues.value = [response, []];
                        }

                    }
                    else {
                        plValues.value = [[], []]
                    }
                })


        }


        const updateSelectionKeys = () => {
            const key = getPropertyValue(PickListTypeConst.DATAKEY);
            if (key && plValues.value) {
                arrayModel.value = plValues.value[1].map(x => x[key]) ?? [];
                setSelectedKeyToVModel();
            }
        }

        const printItem = (item: any) => {
            const key = getPropertyValue(PickListTypeConst.LK) ?? 'name'
            return item[key ?? 'name']
        }


       

        onMounted(() => {
            baseOnMounted();
            watchEffect(() => {
                if (loaded.value == true) {
                    getValue();
                }
            })
        })

        onBeforeUnmount(() => {
            plValues.value = null;
            baseOnBeforeUnmount();
        })



        return {

            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            PickListTypeConst,
            ControlTypeConst,
            getPropertyNumberValue,
            loaded,
            canEdit,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            processEventComponent,
            CatalogEventConst,
            ObjectGroupConst,
            canDoOperation,
            getValue,

            updateSelectionKeys,
            componentKey,

            plValues,
            printItem
        };
    },
});
</script>
<style scoped></style>