import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import { IServiceObjectGroup } from './IServiceObjectGroup';
import { IapObjectGroup } from '../domain/iapObjectGroup';
import type { IObjectGroupRepository } from '../domain/IObjectGroupRepository';



@injectable()
export class ObjectGroupService implements IServiceObjectGroup {
  private readonly repo: IObjectGroupRepository

  public constructor (@inject(TYPES.OBJECTGROUP_DIRECTIVE_REPOSITORY) _repo: IObjectGroupRepository) {
    this.repo = _repo
  }

  async update (data: IapObjectGroup): (Promise<boolean>) {
    return await this.repo.update(data);
  }

  async add (data: IapObjectGroup): (Promise<boolean>) {
    return await this.repo.add(data);
  }

  async  getByIdObject (applicationId:number, applicationVersion:number, idObject:string) : Promise<IapObjectGroup[]> {
    return await this.repo.getByIdObject(applicationId, applicationVersion, idObject);
  }
  
  async  getByObjectId (applicationId:number, applicationVersion:number, idObject:string,objectId:string) : Promise<IapObjectGroup[]> {
    return await this.repo.getByObjectId(applicationId, applicationVersion, idObject,objectId);
  }

  async grantAccessToObjectGroup(objectId: string, objectGroup: IapObjectGroup, objectType: string, applicationId: number, applicationVersion: number) :Promise<Boolean> {
    return await this.repo.grantAccessToObjectGroup(objectId, objectGroup, objectType, applicationId, applicationVersion)
  }

  async grantAccessToObjectGroups(objectId: string, objectGroup: IapObjectGroup[], objectType: string, applicationId: number, applicationVersion: number) :Promise<Boolean> {
    return await this.repo.grantAccessToObjectGroups(objectId, objectGroup, objectType, applicationId, applicationVersion)
  }
  
}
