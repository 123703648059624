<template>
    <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
         :data="Component"
         :vmodel="vmodel"
         />

        <div style="" :style="getPropertyValue(BaseControlTypeConst.STYLE)">
           <codemirror v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" :key="componentKey"
                v-model="vmodel" :placeholder="getPropertyValue(BaseControlTypeConst.PLACEHOLDER)"  
                :style="{ height: '100%' }"
                :disabled="getPropertyBooleanValue(BaseControlTypeConst.DISABLED) && !canDoOperation(ObjectGroupConst.EDIT)"
                :autofocus="true" :indent-with-tab="true" :tab-size="4" :extensions="extensions" @ready="handleReady"
                @update="handleStateUpdate" />
        </div> 
 
</template>

<script lang="ts">

import { defineComponent, onBeforeUnmount, shallowRef, reactive,computed } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import { EditorView, tooltips, ViewUpdate } from '@codemirror/view'
import { redo, undo } from '@codemirror/commands'
import { Codemirror } from 'vue-codemirror'
import { markdown } from '@codemirror/lang-markdown'

export default defineComponent({
 name: 'dynamic_codemirror',
 components: {
    SummaryDebug,
    Codemirror
 },
 props:
 {
     container: {
         type: Object as () => Container
     },
     Component: {
         type: Object as () => ComponentDataForm,
         default: () => ({})
     },      
     slotProps: {
         type: Object,
         default: () => ({})
     },
 },
 setup(props, context) {
     //
     
     const store = useStore();
     const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded, 
           baseOnBeforeUnmount,componentKey
     } = ComponentCommonRender(props.Component,props.slotProps, props.container,store)

    
    const extensions = [markdown()]
    

    tooltips({
      position: 'fixed', // 'absolute' | 'fixed',
      parent: document.getElementById('dvcontainer') as any // document.body,
    })


   

    const cmView = shallowRef<EditorView>()
    const handleReady = ({ view }: any) => {
      cmView.value = view
    }


    const handleUndo = () => {
      undo({
        state: cmView.value!.state,
        dispatch: cmView.value!.dispatch
      })
    }

    const handleRedo = () => {
      redo({
        state: cmView.value!.state,
        dispatch: cmView.value!.dispatch
      })
    }

    const state = reactive({
      lines: null as null | number,
      cursor: null as null | number,
      selected: null as null | number,
      length: null as null | number
    })

    const handleStateUpdate = (viewUpdate: ViewUpdate) => {
      // selected
      const ranges = viewUpdate.state.selection.ranges
      state.selected = ranges.reduce((plus, range) => plus + range.to - range.from, 0)
      state.cursor = ranges[0].anchor
      // length
      state.length = viewUpdate.state.doc.length
      state.lines = viewUpdate.state.doc.lines

    }
    const styleObject = computed(()=>{
     return    {
      color: 'red',
      
      fontSize: '13px',
      heigth:'200px'
    }
    }) 


     onBeforeUnmount(() => {
         baseOnBeforeUnmount();          
     })

    
     return {
         getPropertyValue,
         getPropertyBooleanValue,
         vmodel,
         BaseControlTypeConst,
         loaded,
         ObjectGroupConst
         ,canDoOperation
         ,componentKey
         ,extensions
         ,handleReady
         ,handleStateUpdate
         ,handleRedo
         ,handleUndo
         ,styleObject
     };
 },
});
</script>
<style scoped></style>
