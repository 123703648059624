import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'
import { IServiceCatalog } from './IServiceCatalog'
import type { ICatalogRepository } from '../domain/ICatalogRepository'
import { IapCatalog } from '../domain/iapCatalog'




@injectable()
export class CatalogService implements IServiceCatalog {
  private readonly cfgRepo: ICatalogRepository

  public constructor (@inject(TYPES.CATALOGO_REPOSITORY) _cfgRepo: ICatalogRepository) {
    this.cfgRepo = _cfgRepo
  }
     
    async getCatalogByType(tipoCatalogoId: string,) :Promise<IapCatalog[]> {
      return await this.cfgRepo.getCatalogByType(tipoCatalogoId)
    }
    async getCatalogByTypes(tipoCatalogosId: string[],) :Promise<IapCatalog[]> {
      return await this.cfgRepo.getCatalogByTypes(tipoCatalogosId)
    }
 
}
