import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'


import { IServiceExpressionDetail } from './IServiceExpressionDetail';
import { IapExpressionDetail } from '../domain/iapExpressionDetail';
import type { IExpressionDetailRepository } from '../domain/IExpressionDetailRepository';




@injectable()
export class ExpressionDetailService implements IServiceExpressionDetail {
  private readonly repo: IExpressionDetailRepository

  public constructor (@inject(TYPES.EXPRESSION_DETAIL_REPOSITORY) _repo: IExpressionDetailRepository) {
    this.repo = _repo
  }


  async update (data: IapExpressionDetail): (Promise<IapExpressionDetail>) {
    return await this.repo.update(data);
  }

  async add (data: IapExpressionDetail): (Promise<IapExpressionDetail>) {
    return await this.repo.add(data);
  }

  async getAll(expressionId: string) :Promise<IapExpressionDetail[]> {
    return await this.repo.getAll(expressionId)
  }

  async getById(id: string) :Promise<IapExpressionDetail> {
    return await this.repo.getById(id)
  }

  async delete(id: string) :Promise<boolean> {
    return await this.repo.delete(id)
  }
 
}
