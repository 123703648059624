import { ClassicPreset } from 'rete';
import { AreaPlugin } from 'rete-area-plugin';
import { AreaExtra, Schemes } from '../rete/helperRete';
import { LocalService } from '../../../../../../../common/infrastructure/servicios';
import { Router} from 'vue-router';
import { Container } from 'inversify';
import { IapComponent } from '../../../../../component/domain/iapComponent';
import CatalogObjectTypeConst from '../../../../../catalog/domain/const/CatalogObjectTypeConst';
import CatalogExpConst from '../../../../../catalog/domain/const/CatalogExpConst';
import { IapWorkFlowActivityControl } from '../../../../domain/service/iapWorkFlowActivityControl';
import { ExpresionEngine } from '../../../../../expression/infrastructure/helper/expressionEngine';
import { IapWorkFlowActivity } from '../../../../domain/service/iapWorkFlowActivity';
import { ChangeComponentControl } from '../controls/changeComponentControl';
import { IapComponentAttribute } from '../../../../../component/domain/iapComponentAttribute';
import EventBusCustom from '../../../../../../../common/infrastructure/event-bus-custom';
import EventConst from '../../../../../../../common/domain/constantes/EventConst';
import ChangeComponentNodeConst from '../constants/ChangeComponentNodeConst';
import { IapComponentEvent } from '../../../../../component/domain/iapComponentEvent';
import InteractionConst from '../../../../../interaction/domain/interactionConst';
import { InteractionEvent } from '../../../../../interaction/domain/interactionEvent';
import BaseControlTypeConst from '../../../../../form/domain/Constants/BaseControlTypeConst';
import { DataflowEngine } from 'rete-engine';
import HelperUtils from '../../../../../../../common/infrastructure/funciones/HelperUtils';
import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';
import OperationDataTypeConst from '../../../../../../../common/domain/constantes/OperationDataTypeConst';
import ProcessQueue from '../../../../../../../common/infrastructure/funciones/processQueue';

export class ChangeComponentNode extends ClassicPreset.Node<
  { ejecutar: ClassicPreset.Socket, dataInput: ClassicPreset.Socket  },
  { ejecutar: ClassicPreset.Socket },
  { value: ChangeComponentControl }
> {
  height = 525;
  width = 420;

  //private value: Number;
  private keyEventComponent: string;

  private variableComponent: number;

  private variableAttributesInput: IapComponentAttribute[];
  private variableEventsInput: IapComponentEvent[];
  private area: AreaPlugin<Schemes, AreaExtra>;
  private updateNode: any;
  private getNodeInternalData: any;
  private showExpression: any;
  private rdControlId: '';
  private router: Router;
  private container: Container | undefined;
  private rootComponentId: number;
  private currentComponentId: number;
  private store: any;
  private activity: IapWorkFlowActivity | undefined;
  private componentData: IapComponent[];
  private dataflow: DataflowEngine<Schemes>
  private formKey:String;
  private currentElementKey:string;
  private crudEvents=[CatalogEventConst.ONCRUDCLEAR,CatalogEventConst.ONCRUDCLOSEACTIVETAB,CatalogEventConst.ONCRUDCLOSEALLTAB,
    CatalogEventConst.ONCRUDREFRESHACTIVETAB,CatalogEventConst.ONCRUDSEACH,CatalogEventConst.ONCRUDLOADFROMDS]

  constructor(area: AreaPlugin<Schemes, AreaExtra>, socket: ClassicPreset.Socket,public formKeyInput: String,public currentElementKeyInput:string, public rootComponentInputId: number, public currentComponentInputId: number, public applicationId: number, public applicationVersion: number, public variableComponentInput: number, public variableComponentParametersInput: [], public variableComponentEventsInput: [], updateNode: any = undefined, getNodeInternalData: any = undefined, showExpressionFunction: any = undefined, router: Router, container: Container | undefined, storeInput: any, itemActivity: IapWorkFlowActivity | undefined, dataflow: DataflowEngine<Schemes>,componentDataInput: IapComponent[]) {
    super("Change Component");
    this.area = area;
    this.dataflow = dataflow;
    this.updateNode = updateNode;
    this.getNodeInternalData = getNodeInternalData;
    this.showExpression = showExpressionFunction;

    this.variableComponent = variableComponentInput;

    this.variableAttributesInput = variableComponentParametersInput;
    this.variableEventsInput = variableComponentEventsInput;
    this.router = router;
    this.container = container;
    this.rootComponentId = rootComponentInputId;
    this.currentComponentId = currentComponentInputId;
    this.store = storeInput;
    this.activity = itemActivity;
    this.keyEventComponent = (rootComponentInputId ?? -1).toString() + '_' + EventConst.SHOWCOMPONENT;
    this.componentData = componentDataInput;
    this.formKey = formKeyInput;
    this.currentElementKey = currentElementKeyInput;
    

    const dsControl = new ChangeComponentControl(formKeyInput,rootComponentInputId, currentComponentInputId, applicationId, applicationVersion, variableComponentInput, variableComponentParametersInput, variableComponentEventsInput, container,itemActivity, this.updateData, this.getNode, this.showExp);

    this.rdControlId = (dsControl as any).id;

    this.addInput("ejecutar", new ClassicPreset.Input(socket, "Ejecutar", true));
    this.addControl(
      "value",
      dsControl

      //new ClassicPreset.InputControl("text", { initial })
    );

    this.addInput("dataInput", new ClassicPreset.Input(socket, "DataInput"));

    this.addOutput("ejecutar", new ClassicPreset.Output(socket, "Ejecutar"));


    //area.update("control",dsControl.id)
    //this.addOutput("value", new ClassicPreset.Output(socket, "Valor"));





  }

  showExp = (evt: any) => {
    if (this.showExpression) {
      return this.showExpression(evt)
    }
    return null;
  }

  getNode = (key: string) => {
    if (this.getNodeInternalData) {
      return this.getNodeInternalData(this.id, key, true, false)
    }
    return null;
  }
  updateData = async (evt: any) => {

    
    //this.value = evt
    //@ts-ignore:disable-next-line
    this.controls.value[evt.key] = evt.data;
    //this.controls[evt.key].valueConnection = evt.data;

    this.area.update("control", this.rdControlId)


    if (this.updateNode) {
      await this.updateNode(this.id, evt.key, JSON.stringify(evt.data), (evt?.operation ?? OperationDataTypeConst.UPDATE))

  }
      
    }


  


  createPromiseInteraction = async (element: any): Promise<InteractionEvent> => {
    
    return await new Promise<InteractionEvent>((resolve, reject) => {
      let typeid = element.typeId == 'attr' ? InteractionConst.SET_PROPERTY_VALUE : InteractionConst.CALL_FUNCTION;
     const objectname = element.typeId == 'attr' ? null : 'processEvent()';

      if (typeid == InteractionConst.SET_PROPERTY_VALUE && element.name == BaseControlTypeConst.MODELVALUE)
      {
        typeid = InteractionConst.SET_VMODEL;
      }


      

      var data: Partial<InteractionEvent> = {
        objectId: element.id,
        typeId: this.crudEvents.includes(element.typeId ?? '') || ((element.typeId ?? '') == CatalogEventConst.ONCLOUSED) || ((element.typeId ?? '') == CatalogEventConst.ONDIALOGCLOSEOK) || ((element.typeId ?? '') == CatalogEventConst.ONDIALOGCLOSECANCEL)  ? element.typeId :  typeid,
        objectValue: element.value,
        objectName: objectname

      }

      
      const keyComponentEventBus = this.formKey +  this.rootComponentId.toString() + this.variableComponent.toString() + EventConst.INTERACTION;


      var doCallbackOk = (response: InteractionEvent) => {
        //console.log('respuesta:' + compId.toString())
        resolve(response);
      }

      
      // acciones del crud propias
      if (this.crudEvents.includes(data.typeId ?? ''))
      {
        var doCallbackOkCrud = (response: InteractionEvent) => {
          // procesamos los posibles eventos que se ejecuten despues de los propios del crud
          data.typeId = typeid
          EventBusCustom.emit(keyComponentEventBus, { data: data, callBackResponse: doCallbackOk })
        }
       
        EventBusCustom.emit('current_' + keyComponentEventBus, { data: data, callBackResponse: doCallbackOkCrud });

      }
      else
      {
        // solo se permite el close sobre un root
        if ((data.typeId ?? '') == CatalogEventConst.ONCLOUSED)
        {
          var doCallbackOkClose = (response: InteractionEvent) => {
            // procesamos los posibles eventos que se ejecuten despues de los propios del crud
            data.typeId = typeid
            EventBusCustom.emit(keyComponentEventBus, { data: data, callBackResponse: doCallbackOk })
          }

          
          const keyComponentRootEventBus = this.formKey +  this.rootComponentId.toString() + EventConst.INTERACTION;
          EventBusCustom.emit(keyComponentRootEventBus, { data: data, callBackResponse: doCallbackOkClose });
        }
        else if((data.typeId ?? '') == CatalogEventConst.ONDIALOGCLOSECANCEL || (data.typeId ?? '') == CatalogEventConst.ONDIALOGCLOSEOK)
        {

          var doCallbackOkDialog = (response: InteractionEvent) => {

            data.typeId = typeid
            EventBusCustom.emit(keyComponentEventBus, { data: data, callBackResponse: doCallbackOk })
          }

          
          const keyComponentRootEventBus = this.formKey +  this.rootComponentId.toString() + EventConst.INTERACTION;
          EventBusCustom.emit(keyComponentRootEventBus, { data: data, callBackResponse: doCallbackOkDialog });

        }
        else // resto de eventos
        {
          EventBusCustom.emit(keyComponentEventBus, { data: data, callBackResponse: doCallbackOk });
        }
        
      }
      


    });


  };


  resolveExpressions = () =>{
  
    const currentComp = this.componentData.find(x => x.id == this.currentComponentId)
    
    const WfData = currentComp?.workFlows?.find(w => w.id == this.activity?.workFlowId)

    this.activity?.iapWorkFlowActivityControls?.forEach((wcf: IapWorkFlowActivityControl) => {

      if (currentComp && wcf) {

        if (wcf.name == ChangeComponentNodeConst.VAR_ATTR_IN) {
          this.variableAttributesInput.forEach(param => {
            const keyParameters = '#' + wcf.id.toString() + '#parameterId=' + param.id;
            const exps = currentComp.expressions?.filter(x =>
              x.idObjeto == CatalogObjectTypeConst.WFAC
              && x.idTypeExpression == CatalogExpConst.EXP_SET
              && x.iapExpressionDetails?.length > 0
              && x.objetoId.endsWith(keyParameters));
            if (exps?.length > 0) {
              exps?.every(exp => {
                if (exp.iapExpressionDetails?.length > 0) {
                  const localData = LocalService.getValue(this.formKey + LocalService.COMPONENTS_EXP + (this.rootComponentId ?? -1).toString());
                  const data = HelperUtils.jsonParse(localData,[])
                  let resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, this.store)
                  //resu = resu?.toString();
                  if (resu) {
                    if (Object.keys(resu).length == 0) {
                      resu = resu?.toString();
                    }
                  }

                  param.value = resu;


                }
              })

            }
          })
        }


      }

    })
    
  }

  async execute(input: "ejecutar", forward: (output: "ejecutar") => void) {    
    const inputs = (await this.dataflow.fetchInputs(this.id)) as {
      dataInput: any;
    };

    if (inputs && inputs?.dataInput && inputs.dataInput?.length==1)
    {
      let auxData: any = null;
      const data = inputs.dataInput[0]

      if (data){
        this.variableAttributesInput.filter((x:any) => x.updatable && x.value?.startsWith('#') && x.value?.endsWith('#')).forEach(param => 
        {
          //@ts-ignore:disable-next-line
          const keyParam = param.value.replaceAll('#','');
          param.value = HelperUtils.propValue(data,keyParam)?.toString() ?? ''
        });
      }
    
    }

   /*
    this.msgOutput = this.variableMsg ?? '';

    const msg = inputs?.message?.find(x => x !== undefined);
    if (msg && Array.isArray(msg) && msg?.length > 0) {
      this.msgOutput = '';
      var keys = Object.keys(msg[0])
      keys.forEach(key => {
        this.msgOutput += msg[0][key]
      });
    }
    else if (msg) {
      this.msgOutput = msg as any
    }
    */


      // vamos a buscar las expresiones si las hubiera
      this.resolveExpressions();



        const dataToDoAttr = this.variableAttributesInput.filter((x:any) => x['updatable']).map((x: any) =>
        ({
          id: x.id,
          name:x.name,
          typeId: 'attr',
          value: x.value,
          order: x['order']

        }
        ))

        const dataToDoEvt = this.variableEventsInput.filter( (x:any) => x['updatable']).map((x: any) =>
        ({
          id: x.id,
          name:x.name,
          typeId: this.crudEvents.includes(x.idEventType ?? '') || ((x.idEventType  ?? '')== CatalogEventConst.ONCLOUSED)   || ((x.idEventType  ?? '')== CatalogEventConst.ONDIALOGCLOSECANCEL) || ((x.idEventType  ?? '')== CatalogEventConst.ONDIALOGCLOSEOK)  ?  x.idEventType:'evt',
          value: x['id'], // se pasa el id del evento a ejecutar
          order: x['order']

        }
        ))


        const dataToDo = dataToDoAttr.concat(dataToDoEvt)

        if (dataToDo.length > 0) {
          var promises = new Array();
          let canDo = false;
          //let validate = Array<boolean>();
          dataToDo.sort((x: any) => x['sort']).forEach(comp => {
            promises.push(this.createPromiseInteraction(comp));
          });



          await Promise.all(promises).then((responses: InteractionEvent[]) => {
            canDo = !(responses.filter(x => !x.interactionResult).length > 0)
          })


          if (canDo) {
            forward("ejecutar");
          }
        }
        else {
          forward("ejecutar");
        }


      





  }


  data(): { value: string } {
    return {
      //@ts-ignore:disable-next-line
      value: this.controls.value['value'] || ""
    };
  }
  /*
  data(): { connection: String, procName: String, parametersInput:[] } {
    return {
      connection: this.controls.value['variableconnection'] ?? '',
      procName: this.controls.value['variableprocName'] ?? '',
      parametersInput: this.controls.value['variableAttributesInput'] ?? '',

    };
    
  }
  */
}