<script lang="ts">

import { defineComponent, h, nextTick, onBeforeUnmount, onMounted } from 'vue';
import type { PropType } from "vue";

export default defineComponent({
  name: 'vidle',
  emits: ["idle", "remind"],
  props: {
    duration: {
      type: Number,
      // default 5 minutes
      default: 60 * 5,
    },
    events: {
      type: Array as PropType<string[]>,
      default: (): string[] => ["mousemove", "keypress"],
    },
    loop: {
      type: Boolean,
      default: false,
    },
    reminders: {
      type: Array as PropType<number[]>,
      // array of seconds
      // emit "remind" event on each second
      default: (): number[] => [],
    },
    wait: {
      type: Number,
      default: 0,
    },
  },
  render() {
    return h(
      "div",
      {
        class: "v-idle",
      },
      this.display
    );
  },
  setup(props, context) {

    let display = "";
    let timer: number | undefined = undefined;
    let start = 0;
    let counter: number | undefined = undefined;
    let diff = 0;
    let minutes = "";
    let seconds = "";


    const setDisplay = () => {
      // seconds since start
      diff = props.duration - (((Date.now() - start) / 1000) | 0);
      if (diff < 0 && !props.loop) {
        return;
      }
      shouldRemind();

      // bitwise OR to handle parseInt
      const minute = (diff / 60) | 0;
      const second = diff % 60 | 0;

      minutes = `${minute < 10 ? "0" + minute : minute}`;
      seconds = `${second < 10 ? "0" + second : second}`;

      display = `${minutes}:${seconds}`;
    }

    const shouldRemind = () => {
      if (props.reminders.length > 0) {
        if (props.reminders.includes(diff)) {
          remind();
        }
      }
    }

    const countdown = () => {
      setDisplay();

      if (diff <= 0) {
        clearTimer(props.loop);
      }
    }

    const idle = () => {
      context.emit("idle");
    }

    const remind = () => {
      context.emit("remind", diff);
    }

    const setTimer = () => {
      timer = window.setInterval(idle, props.duration * 1000);
      counter = window.setInterval(countdown, 1000);
    }

    const clearTimer = (loop = false) => {
      window.clearInterval(timer);
      window.clearInterval(counter);
      if (loop) {
        start = Date.now();
        diff = 0;
        setDisplay();
        setTimer();
      }
    }

    onMounted(() => {
      setTimeout(() => {
        start = Date.now();
        setDisplay();
        nextTick(() => {
          setTimer();
          for (let i = props.events.length - 1; i >= 0; i -= 1) {
            window.addEventListener(props.events[i] as any, clearTimer);
          }
        });
      }, props.wait * 1000);
    })

    onBeforeUnmount(() => {
      clearInterval(timer);
      clearInterval(counter);
      for (let i = props.events.length - 1; i >= 0; i -= 1) {
        window.removeEventListener(props.events[i] as any, clearTimer);
      }
    })


    return {
      display,
      timer,
      start,
      counter,
      diff,
      minutes,
      seconds,
    }
  },
});
</script>

