import gql from 'graphql-tag';

export const GET_ALL_CATALOGTYPE = gql(`
query($maxregs: Int) {
    dataSourceQuery {
      getAll(maxregs:$maxregs) {
        id,
        applicationId,
        applicationVersion,
        idBaseDatos,
        Name,
        Description,
        QuerySelect,
        fcr,
        ucr,
        uum,
        fum
      }
    }
}
`);

export const GET_CATALOGTYPE_BY_ID = gql(`
query($id: Int, $version: Int) {
    dataSourceQuery {
      getById(id:$id, version:$version) {
        id,
        applicationId,
        applicationVersion,
        idBaseDatos,
        Name,
        Description,
        QuerySelect,
        fcr,
        ucr,
        uum,
        fum
      }
    }
}
`);

