<template>
       <SummaryDebug v-if="getPropertyBooleanValue(IframeTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
            
     <div v-if="!encrypted">
         Inicalizando entorno por favor espere.....
    </div>
    <iframe v-else-if="loaded && encrypted && getPropertyBooleanValue(IframeTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :src="url"
        :width="getPropertyNumberValue(IframeTypeConst.WIDTH)" :height="getPropertyNumberValue(IframeTypeConst.HEIGHT)"
        :frameborder="getPropertyNumberValue(IframeTypeConst.FRAMEBORDER)"
        :scrolling="getPropertyValue(IframeTypeConst.SCROLLING)"
        :allowfullscreen="getPropertyBooleanValue(IframeTypeConst.ALLOWFULLSCREEN)"
        onload='javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));' 
        :style="getPropertyValue(IframeTypeConst.STYLE)">
    </iframe>
</template>
<script lang="ts">


import { defineComponent, onMounted, shallowRef, onBeforeUnmount, onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import IframeTypeConst from '../../../../domain/Constants/IframeTypeConst';
import { Container } from 'inversify';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import { IServiceEncrypt } from '../../../../../../builderEncrypt/application/IServiceEncrypt';
import { TYPES } from '../../../../../../../common/domain/types';
import HelperLoading from '../../../../../../../common/infrastructure/funciones/HelperLoading';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_html_iframe',
    components: {
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },    
        slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue, loaded,  baseOnBeforeUnmount
            ,fieldKey,fieldKeyComponentDataSourceId ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);
        
        let url = shallowRef(getPropertyValue(IframeTypeConst.SRC) + (getPropertyValue(IframeTypeConst.ENCRYPT_VAR) ?? '?'));
        const encrypted = shallowRef(false);
        const encryptData = ()=>
        {            
            
            if (props.container)
            {
                HelperLoading.showLoading()
                const request = (getPropertyValue(IframeTypeConst.ENCRYPT_PAR) ?? '').replace('@@SESSIONID',store.getters.getSessionId);
                const _srv = props.container.get<IServiceEncrypt>(TYPES.ENCRYPT_SERVICE)
                    _srv.encrypt(request)
                    .then(response => {                        
                        url.value += encodeURIComponent(response)
                    })
                    .finally(() => {
                        encrypted.value=true;
                        HelperLoading.hideLoading()
                    })
            }
        }


        onMounted(() => {
            if (getPropertyBooleanValue(IframeTypeConst.ENCRYPT) && getPropertyValue(IframeTypeConst.ENCRYPT_PAR)){
                encryptData()
            }
            else{                
                url.value += (getPropertyValue(IframeTypeConst.ENCRYPT_PAR) ?? '').replace('@@SESSIONID',store.getters.getSessionId)
                encrypted.value=true;
            }
        })

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })


        return {
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            IframeTypeConst,
            loaded,
            getPropertyNumberValue,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,url
            ,encrypted
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
