import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';

import { IApplicationConnectionRepository } from '../domain/IApplicationConnectionRepository';

import { UPDATE, ADD, DELETE, CHANGE_PWD } from './mutation/applicationConnectionMutation';
import {  GET_APPLICATIONCONNECTION_BY_ID, GET_CONN } from './query/applicationConnectionQuery';
import { IapApplicationConnection } from '../domain/iapApplicationConnection';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class ApplicationConnectionApolloClientRepository implements IApplicationConnectionRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId=ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }


 


  async getByApplicationId (id: number, version: number): (Promise<IapApplicationConnection[]>) {
    return new Promise<IapApplicationConnection[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_APPLICATIONCONNECTION_BY_ID, { applicationId: id, version: version },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationConnectionQuery.getByApplicationId)
        }
        catch(e) {
          reject()
        }
      });
    });
  }



  async getConnection (data: IapApplicationConnection): (Promise<String>) {
    return new Promise<String>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_CONN, { data: data },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationConnectionQuery.getConnection)
        }
        catch(e) {
          reject()
        }
      });
    });
  }



  async update (data: IapApplicationConnection): (Promise<IapApplicationConnection>) {
    return new Promise<IapApplicationConnection>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE, { data: data },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationConnectionMutation.updateApplicationConnection)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


  async add (data: IapApplicationConnection): (Promise<IapApplicationConnection>) {
    return new Promise<IapApplicationConnection>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD, { data: data },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationConnectionMutation.addApplicationConnection)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async delete (data: IapApplicationConnection): (Promise<Boolean>) {
    return new Promise<Boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(DELETE, { data: data },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationConnectionMutation.deleteApplicationConnection)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async changePwd (data: IapApplicationConnection,pwd:String): (Promise<Boolean>) {
    return new Promise<Boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(CHANGE_PWD, { data: data , pwd:pwd },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationConnectionMutation.changeApplicationConnection)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

}


