import BaseControlTypeConst from "./BaseControlTypeConst"

export default class TimeLineTypeConst extends BaseControlTypeConst {
  static VALUE = 'value'
  static ALIGN = 'align'
  static LAYOUT = 'layout'
  static DATAKEY = 'dataKey'
  static LAYOUT_CONTENT = 'layouttemp-tlin-content'
  static LAYOUT_OPPOSITE = 'layouttemp-tlin-opposite'
  static LAYOUT_MARKER = 'layouttemp-tlin-marker'
  static LAYOUT_CONNECTOR = 'layouttemp-tlin-connector'


}
