export default class NavigationNodeConst {
    
    static VAR_TIPO='variableRedirectTypeInput';
    static VAR_PARURL='variableParamsRedirectUrlInput';
    static VAR_PARURLTARGET='variableParamsRedirectUrlTargetInput';
    static VAR_PARURLPARAMS='variableParamsRedirectUrlParamsInput';

    static VAR_PAR='variableParamsRedirectInput';
    static VAR_MENUID='variableMenuInput';
    static VAR_COMPS='variableCompsInput';
    
  }