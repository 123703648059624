import gql from "graphql-tag";

export const GET_ALL = gql(`
query($expressionId: ID!) {
    expressionDetailQuery {
        getAll(expressionId:$expressionId) {            
            id
            expressionId
            variable
            expression
            expressionTranslated
            processOrder
            localizable
            fcr
            ucr
            uum
            fum
    }
  }
}
`);

export const GET_BY_ID = gql(`
query($id: ID!) {
    expressionDetailQuery {
        getById(id:$id) {
            id
            expressionId
            variable
            expression
            expressionTranslated
            processOrder
            localizable
            fcr
            ucr
            uum
            fum
    }
  }
}
`);
