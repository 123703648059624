import BaseControlTypeConst from "./BaseControlTypeConst"

export default class PasswordTypeConst extends BaseControlTypeConst {
    static PROMPTLABEL = 'promptLabel'
    static MEDIUMREGEX = 'mediumRegex'
    static STRONGREGEX = 'strongRegex'
    static WEAKLABEL = 'weakLabel'
    static MEDIUMLABEL = 'mediumLabel'
    static STRONGLABEL = 'strongLabel'
    static FEEDBACK = 'feedback'
    static TOGGLEMASK = 'toggleMask'
    static READONLY = 'readonly'
}
