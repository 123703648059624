import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IDataSourceFieldRepository } from '../domain/IDataSourceFieldRepository';

import { IServiceDataSourceField } from './IServiceDataSourceField';
import { IapDataSourceField } from '../domain/iapDataSourceField';





@injectable()
export class DataSourceFieldService implements IServiceDataSourceField {
  private readonly repo: IDataSourceFieldRepository

  public constructor (@inject(TYPES.DATASOURCEFIELD_REPOSITORY) _repo: IDataSourceFieldRepository) {
    this.repo = _repo
  }

  async update (data: IapDataSourceField): (Promise<boolean>) {
    return await this.repo.update(data);
  }

  async getAllByDataSourceId(dataSourceId: number ) :Promise<IapDataSourceField[]> {
    return await this.repo.getAllByDataSourceId(dataSourceId)
  }

  async getById(dataSourceFieldId: number) :Promise<IapDataSourceField> {
    return await this.repo.getById(dataSourceFieldId)
  }

  async getAll(maxregs: number | undefined) :Promise<IapDataSourceField[]> {
    return await this.repo.getAll(maxregs)
  }
}
