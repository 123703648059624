import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IApplicationRepository } from '../domain/IApplicationRepository';



import { IServiceApplication } from './IServiceApplication';
import { IapApplication } from '../domain/iapApplication';
import { ApplicationData } from '../domain/applicationData';
import { IapCatalogType } from '../../catalog/domain/iapCatalogType';





@injectable()
export class ApplicationService implements IServiceApplication {
  private readonly repo: IApplicationRepository

  public constructor (@inject(TYPES.APPLICATION_REPOSITORY) _repo: IApplicationRepository) {
    this.repo = _repo
  }
  
  async delete(id: number) :Promise<boolean> {
    return await this.repo.delete(id)
  }

  async add (data: IapApplication): (Promise<IapApplication>) {
    return await this.repo.add(data);
  }

  async getInitialData() :Promise<ApplicationData> {
    return await this.repo.getInitialData();
  }

  async update (data: IapApplication): (Promise<boolean>) {
    return await this.repo.update(data);
  }

  async getAll(maxregs: number | undefined) :Promise<IapApplication[]> {
    return await this.repo.getAll(maxregs)
  }

  async getById(id: number, version: number) :Promise<IapApplication> {
    return await this.repo.getById(id, version)
  }

  async getApplicationCatalogs (id: number, version: number) :Promise<IapCatalogType[]> {
    return await this.repo.getApplicationCatalogs(id, version)
  }

  async getApplicationCatalogTypes (id: number, version: number) :Promise<IapCatalogType[]> {
    return await this.repo.getApplicationCatalogTypes(id, version)
  }

  
  async getApplicationCatalogTypesByTypeIds (id: number, version: number, catalogTypeIdList:string[]) :Promise<IapCatalogType[]> {
    return await this.repo.getApplicationCatalogTypesByTypeIds(id, version,catalogTypeIdList)
  }

}