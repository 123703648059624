<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />

    <CustomTabView v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(TabViewTypeConst.ID)" v-tooltip="getPropertyValue(TabViewTypeConst.TOOLTIP)"
        :style="getPropertyValue(TabViewTypeConst.STYLE)" :class="getPropertyValue(TabViewTypeConst.CLASS)"
        :name="getPropertyValue(TabViewTypeConst.NAME)" :disabled="getPropertyBooleanValue(TabViewTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(TabViewTypeConst.VISIBLE)"
        :placeholder="getPropertyValue(TabViewTypeConst.PLACEHOLDER)" :lazy="getPropertyBooleanValue(TabViewTypeConst.LAZY)"
        :scrollable="getPropertyBooleanValue(TabViewTypeConst.SCROLLABLE)"
        :selectOnFocus="getPropertyBooleanValue(TabViewTypeConst.SELECTONFOCUS)"
        v-model:activeIndex="activeIndex"
        :tabindex="getPropertyNumberValue(TabViewTypeConst.TABINDEX)" :onTabChange="doChangeTab">

        <template #default>
            <slot name="content">

            </slot>
            <TabPanel v-if="getPropertyBooleanValue(TabViewTypeConst.ISDYNAMIC)" v-for="(tab, index) in itemTabs"
                :key="tab.value2 + '_' + index">
                <template #header>
                  <span>Componente:  {{ tab.value2 }}</span>
                    
                </template>
                <!--
            <template #header>
                    <div>
                        <span style="margin-right:10px" v-html="tab.label"> </span> <i v-if="tab.info"
                            class="pi pi-info-circle" :class="tab.color + '_color'"
                            v-tooltip="{ value: tab.info, class: 'p-message p-component p-message-info' , escape: true, }"></i>
                    </div>
                    
                </template>
  
                -->
                <!--
                <FormBuilder  :container="container" :componentId="tab.componentId" :applicationId="tab.applicationId" :applicationVersion="tab.applicationVersion" />
                -->

            </TabPanel>
        </template>

    </CustomTabView>
</template>
<script lang="ts">


import { defineComponent, onMounted, onBeforeUnmount, useSlots, computed, watch, onBeforeUpdate, onUpdated } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import TabViewTypeConst from '../../../../domain/Constants/TabViewTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import CustomTabView from '../../../../../../../common/infrastructure/componentes/base/common/controles/CustomTabView.vue'

import multiTab from '../../../../domain/Functions/MultiTab';
import { useStore } from 'vuex';

import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_tab_view',
    components: {
        CustomTabView,
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        
        slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {
        //  
        const store = useStore();
        const { activeIndex,vmodel,canDoOperation, loaded, resolveExpressions, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue, 
            setPropertyValueByName,baseOnMounted, baseOnBeforeUnmount, getLayoutComponent,fieldKey,fieldKeyComponentDataSourceId 
            ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);
      
        const { itemTabs,
            activeId,
            selectedTab,
            changeTab,
            closeTab, 
            addTab,
            clearAllTabData           
        } = multiTab(store);

        let activeIndexBk = 0;
      

      const doChangeTab = (event: any) =>{
        setPropertyValueByName(TabViewTypeConst.ACTIVEINDEX,activeIndex.value);
        changeTab(event);
      }

    
        onMounted(() => {
            /*
            itemTabs.value = [
                {
key:'1',
applicationId:1,
componentId:16182, //idcomponente
applicationVersion:1,
keyData:null,
closable:true,
header:'',
keyInternal:'',
                },
                {
key:'1',
applicationId:1,
componentId:819, //idcomponente
applicationVersion:1,
keyData:null,
closable:true,
header:'',
keyInternal:'',
                },
                {
key:'1',
applicationId:1,
componentId:44200, //idcomponente
applicationVersion:1,
keyData:null,
closable:true,
header:'',
keyInternal:'',
                },

            ]

            */
            activeIndex.value = getPropertyNumberValue(TabViewTypeConst.ACTIVEINDEX)
            activeIndexBk= activeIndex.value;

        })

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
            setPropertyValueByName(TabViewTypeConst.ACTIVEINDEX,activeIndexBk);
            clearAllTabData();
        })



        onUpdated(() => {
            //addTab('uno',props.Component.applicationId,props.Component.applicationVersion,22019)
        })


        return {


            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            TabViewTypeConst,
            getPropertyNumberValue,
            loaded,
            BaseControlTypeConst,            
            getLayoutComponent,
            itemTabs,
            doChangeTab,
            fieldKey,
            fieldKeyComponentDataSourceId,
            activeIndex,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey

        };
    },
});
</script>
<style lang="scss" scoped>

::v-deep(.p-tabview-nav li.p-highlight .p-tabview-nav-link) {
    outline: 0 none !important;
    outline-offset: 0 !important;
    box-shadow: inset 0 0 0 0.2rem #bfdbfe !important;
}
</style>
