/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Environment from './environment';



export const deleteCustomStyles = () =>{
  
  const elementcss = document.getElementById("appcustomcss");
  if (elementcss){
      elementcss.remove(); 
  }  
  
}


export  const addCustomStyles = () =>{
    
  const element = document.createElement('link')
  element.setAttribute("id", "appcustomcss");
  element.setAttribute("rel", "stylesheet");
  element.setAttribute("href", Environment.APP_CSS + '?_t=' + new Date().getTime().toString());
  //element.innerHTML = cssCode
  document.head.appendChild(element)
}


export default {addCustomStyles,deleteCustomStyles }
