import gql from 'graphql-tag';

export const GET_ALL_APPLICATIONNOTE = gql(`
query($maxregs: Int) {
    applicationNoteQuery {
      getAll(maxregs:$maxregs) {
        id,
        applicationId,
        applicationVersion,
        appVersion,
        title,
        description,
        fcr,
        ucr,
        uum,
        fum
      }
    }
}
`);

export const GET_APPLICATIONNOTE_BY_ID = gql(`
query($id: Int) {
    applicationNoteQuery {
      getById(id:$id) {
        id,
        applicationId,
        applicationVersion,
        appVersion,
        title,
        description,
        fcr,
        ucr,
        uum,
        fum
      }
    }
}
`);



export const GET_APPLICATIONNOTE_BY_APPLICATIONID = gql(`
query($applicationId: Int!,$applicationVersion: Int!) {
    applicationNoteQuery {
      getByApplicationId(applicationId:$applicationId,applicationVersion:$applicationVersion) {
        id,
        applicationId,
        applicationVersion,
        appVersion,
        title,
        description,
        fcr,
        ucr,
        uum,
        fum
      }
    }
}
`);

