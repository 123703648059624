import {
    FunctionArgs,    
} from "ncalcjs";
import HelperUtils from "../../../../../common/infrastructure/funciones/HelperUtils";
import HelperCommon from "../../../../../common/infrastructure/funciones/HelperCommon";
import helperCatalog from "../../../catalog/infrastructure/helper/helperCatalog";
import HelperValidation from "../../../../../common/infrastructure/funciones/HelperValidation";

const getPropertyValue = (args: FunctionArgs) => {
    args.Result = '';
    if (args.Parameters.length == 3) {
        try {
            const obj = args.Parameters[0].Evaluate();
            const field = args.Parameters[1].Evaluate()?.toString();
            const key = args.Parameters[2].Evaluate()?.toString();
            const reg = obj.find((x: any) => x.campo == field)
            if (reg) {
                args.Result = reg[key];
            }

        } catch { }
    }

};
const falso = (args: FunctionArgs) => {
    args.Result = false;
};

const verdadero = (args: FunctionArgs) => {
    args.Result = true;
};

const tamcadena = (args: FunctionArgs) => {
    args.Result = "";
    if (args.Parameters.length == 1) {
        try {
            const cadena = args.Parameters[0].Evaluate().toString();

            args.Result = cadena.Length;
        } catch { }
    }
};

const Subcadena = (args: FunctionArgs) => {
    args.Result = "";
    if (args.Parameters.length == 2 || args.Parameters.length == 3) {
        try {
            const cadena = args.Parameters[0].Evaluate().toString();

            let initpos = parseInt(args.Parameters[1].Evaluate().ToString());
            let finpos = cadena.Length;
            if (args.Parameters.length == 3) {
                finpos = parseInt(args.Parameters[2].Evaluate().ToString());
                if (finpos >= 0) args.Result = cadena.substring(initpos, finpos);
            } else if (args.Parameters.length == 2) {
                args.Result = cadena.substring(initpos);
            }
        } catch { }
    }
};

const Guid = (args: FunctionArgs) => {
    args.Result = HelperUtils.newGuid();
};

const YearDiff = (args: FunctionArgs) => {
    args.Result = false;
    if (args.Parameters.length < 2) {
        args.Result = 0;
    } else {
        try {
            const dt1 = new Date(args.Parameters[0].Evaluate().toString());
            const dt2 = new Date(args.Parameters[1].Evaluate().toString());
            var diff = (dt2.getTime() - dt1.getTime()) / 1000;
            diff /= 60 * 60 * 24;
            const dd = Math.abs(Math.round(diff / 365.25));
            args.Result = dd;
        } catch {
            args.Result = 0;
        }
    }
};

const MonthDiff = (args: FunctionArgs) => {
    args.Result = false;
    if (args.Parameters.length < 2) {
        args.Result = 0;
    } else {
        try {
            const dt1 = new Date(args.Parameters[0].Evaluate().toString());
            const dt2 = new Date(args.Parameters[1].Evaluate().toString());
            const monthDiff = dt1.getMonth() + 1 - (dt2.getMonth() + 1);
            const yearDiff = dt1.getFullYear() - dt2.getFullYear();

            const dd = monthDiff + yearDiff * 12;
            args.Result = dd;
        } catch {
            args.Result = 0;
        }
    }
};

const DayDiff = (args: FunctionArgs) => {
    args.Result = false;
    if (args.Parameters.length < 2) {
        args.Result = 0;
    } else {
        try {
            const dt1 = new Date(args.Parameters[0].Evaluate().toString());
            const dt2 = new Date(args.Parameters[1].Evaluate().toString());
            // To calculate the time difference of two dates
            const Difference_In_Time = dt2.getTime() - dt1.getTime();

            // To calculate the no. of days between two dates
            const dd = Difference_In_Time / (1000 * 3600 * 24);
            args.Result = dd;
        } catch {
            args.Result = 0;
        }
    }
};

const ConvertirMoneda = (args: FunctionArgs) => {
    args.Result = 0.0;
    if (args.Parameters.length < 1) {
        args.Result = 0.0;
    } else {
        try {
            const inputResu = args.Parameters[0].Evaluate();

            try {
                args.Result = parseFloat(inputResu?.toString());
            } catch {
                const defaultResu = args.Parameters[1].Evaluate();
                try {
                    args.Result = parseFloat(defaultResu?.tostring());
                } catch {
                    args.Result = 0.0;
                }
            }
        } catch {
            args.Result = 0.0;
        }
    }
};

const ConvertirNumero = (args: FunctionArgs) => {    
    args.Result = 0;
    if (args.Parameters.length < 1) {
        args.Result = 0;
    } else {
        try {
            const inputResu = args.Parameters[0].Evaluate();

            try {
                args.Result = parseInt(inputResu?.toString()) || 0;
            } catch {
                const defaultResu = args.Parameters[1].Evaluate();
                try {
                    args.Result = parseInt(defaultResu?.tostring());
                } catch {
                    args.Result = 0;
                }
            }
        } catch {
            args.Result = 0;
        }
    }
};

const _in = (args: FunctionArgs) => {
    args.Result = false;
    if (args.Parameters.length < 2) {
        args.Result = true;
    } else {
        try {
            var inputResu = args.Parameters[0].Evaluate();
            let Lista = new Array<Object>();

            for (var i = 1; i <= args.Parameters.length - 1; i++) {
                const aux = args.Parameters[i].Evaluate()?.ToString();
                if (!HelperCommon.isNullOrWhitespace(aux)) {
                    Lista.push(aux);
                }
            }

            args.Result = Lista.includes(inputResu);
        } catch { }
    }
};

const Rango = (args: FunctionArgs) => {
    // TODO por hacer
    args.Result = "";
};


const LoopForTemplateFile = (args: FunctionArgs) => {
    // TODO por hacer
    args.Result = "";
};


const addday = (args: FunctionArgs) => {
    if (args.Parameters.length < 2) {
        args.Result = 0;
    }
    try {
        const total = parseInt(args.Parameters[1].Evaluate()?.toString());
        const dd = new Date(args.Parameters[0].Evaluate()?.toString());
        args.Result = dd.setDate(dd.getDate() + total);
    } catch {
        args.Result = 0;
    }
};

const AddYear = (args: FunctionArgs) => {
    if (args.Parameters.length < 2) {
        args.Result = 0;
    }
    try {
        const total = parseInt(args.Parameters[1].Evaluate()?.toString());
        const dd = new Date(args.Parameters[0].Evaluate()?.toString());
        args.Result = dd.setFullYear(dd.getFullYear() + total);
    } catch {
        args.Result = 0;
    }
};

const addmonth = (args: FunctionArgs) => {
    if (args.Parameters.length < 2) {
        args.Result = 0;
    }
    try {
        const total = parseInt(args.Parameters[1].Evaluate()?.toString());
        const dd = new Date(args.Parameters[0].Evaluate()?.toString());
        args.Result = dd.setMonth(dd.getMonth() + total);
    } catch {
        args.Result = 0;
    }
};

const GetDay = (args: FunctionArgs) => {
    if (args.Parameters.length < 1) {
        args.Result = 0;
    }
    try {
        args.Result = new Date(
            args.Parameters[0].Evaluate()?.toString()
        ).getUTCDate();
    } catch {
        args.Result = 0;
    }
};

const getyear = (args: FunctionArgs) => {
    if (args.Parameters.length < 1) {
        args.Result = 0;
    }
    try {
        args.Result = new Date(
            args.Parameters[0].Evaluate()?.toString()
        ).getFullYear();
    } catch {
        args.Result = 0;
    }
};

const GetMonth = (args: FunctionArgs) => {
    if (args.Parameters.length < 1) {
        args.Result = 0;
    }
    try {
        args.Result =
            new Date(args.Parameters[0].Evaluate()?.toString()).getMonth() + 1;
    } catch {
        args.Result = 0;
    }
};

const catalogo = (args: FunctionArgs) => {
    // TODO por hacer
    args.Result = "";
};

const Edad = (args: FunctionArgs) => {
    const dateString = args.Parameters[0].Evaluate()?.toString();
    const hoy = new Date();
    const fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    const diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
        diferenciaMeses < 0 ||
        (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
        edad--;
    }
    args.Result = edad;
};

const EdadActuarial = (args: FunctionArgs) => {
    const dateString = args.Parameters[0].Evaluate()?.toString();
    const hoy = new Date();
    const fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    const diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
        diferenciaMeses < 0 ||
        (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
        edad--;
    }
    args.Result = edad;
};



const Msg = (args: FunctionArgs) => {
    var inputResu = args.Parameters[0].Evaluate();
    args.Result = inputResu?.toString();
};


const isCollection = (args: FunctionArgs) => {

    if (args.Parameters.length < 1) {
        args.Result = false;
    }
    else {
        var inputResu = args.Parameters[0].Evaluate();
        args.Result = Array.isArray(inputResu)
    }
};

const sizeCollection = (args: FunctionArgs) => {

    if (args.Parameters.length < 1) {
        args.Result = 0;
    }
    else {
        var inputResu = args.Parameters[0].Evaluate();
        if (Array.isArray(inputResu)) {
            args.Result = inputResu.length
        }
        else {
            args.Result = 0
        }

    }


};

const convertstring = (args: FunctionArgs) => {
    var inputResu = args.Parameters[0].Evaluate();
    args.Result = inputResu?.toString() || '';
};

const setValues = (args: FunctionArgs) => {
    // TODO por hacer
    args.Result = "";
};

const Hoy = (args: FunctionArgs) => {
    // TODO
    //args.Result = "";
    args.Result = new Date().toLocaleDateString("es-ES");
    /*
                      if (args.Parameters.length == 1)
                      {
                          let num = parseInt(inputResu.toString().toLowerCase().substring(0, inputResu.toString().Length - 1));
                          var inputResu = args.Parameters[0].Evaluate();
                          if (int.TryParse(inputResu.ToString().ToLower().Substring(0, inputResu.ToString().Length - 1), out num))
                          {
                              const opcion = inputResu.ToString().ToLower().Substring(inputResu.ToString().Length - 1);
                              switch (opcion.ToLower())
                              {
                                  case "d":
                                      args.Result = DateTime.Parse(DateTime.Now.AddDays(num).ToShortDateString());
                                      break;
                                  case "m":
                                      args.Result = DateTime.Parse(DateTime.Now.AddMonths(num).ToShortDateString());
                                      break;
                                  case "y":
                                  case "a":
                                      args.Result = DateTime.Parse(DateTime.Now.AddYears(num).ToShortDateString());
                                      break;
                              }
                          }
                          else
                          {
                              string opcion = inputResu.ToString().ToLower().Substring(inputResu.ToString().Length - 1);
                              switch (opcion.ToLower())
                              {
                                  case "d":
                                      args.Result = DateTime.Now.Day.ToString();
                                      break;
                                  case "m":
                                      args.Result = DateTime.Now.Month.ToString();
                                      break;
                                  case "y":
                                  case "a":
                                      args.Result = DateTime.Now.Year.ToString();
                                      break;
                              }
                          }
                      }
                      */
};

const getCatalogDescription = (args: FunctionArgs) => {
    args.Result = '';
    if (args.Parameters.length < 1) {
        args.Result = '';
    } else {
        try {
            const inputCatalog = args.Parameters[0].Evaluate() ?? '';
            args.Result = helperCatalog.getAllCatalogApp().flatMap(x => x.iapCatalogs)?.find(x => x.id.toLowerCase() == inputCatalog?.toLowerCase())?.description ?? inputCatalog

        } catch {
            args.Result = '';
        }
    }
};

const getSingleCuote = (args: FunctionArgs) => {
    args.Result = '';
    if (args.Parameters.length < 1) {
        args.Result = '';
    } else {
        try {
            const value = args.Parameters[0].Evaluate() ?? '';
            args.Result = `'${value}'`;

        } catch {
            args.Result = '';
        }
    }
};

const isNull = (args: FunctionArgs) => {
    args.Result = false;
    if (args.Parameters.length < 1) {
        args.Result = false;
    } else {
        try {
            const value = args.Parameters[0].Evaluate() ?? '';
            args.Result = (value == undefined || value == null || value == '') ? true : false;

        } catch {
            args.Result = false;
        }
    }
};

const getParameterFromStorage = (args: FunctionArgs, store: any) => {
    args.Result = '';
    if (args.Parameters.length < 1) {
        args.Result = '';
    } else {
        try {
            const value = args.Parameters[0].Evaluate() ?? '';
            const parts = value.split('.');

            let result = store;
            parts.forEach((part: string, index: number) => {
                /*if (store && typeof store[part] === 'function') {
                    // Si el resultado es una función, la invocamos
                    store = store[part]();
                } else*/ 
                if (result && (result.hasOwnProperty(part) || typeof result[part] === 'function')) {
                    // Si es una propiedad, simplemente la obtenemos
                    if(typeof result[part] === 'function' && parts[index+1]){
                        result = result[part](parts[index+1]);
                    }
                    else{
                        result = result[part];
                    }
                    
                }
            });

            args.Result = result;

        } catch {
            args.Result = '';
        }
    }
};


const isValidNIF = (args: FunctionArgs) => {
    args.Result = false;
    if (args.Parameters.length < 1) {
        args.Result = false;
    } else {
        try {
            const value = args.Parameters[0].Evaluate() ?? '';
            args.Result = HelperValidation.isValidNIF(value);

        } catch {
            args.Result = false;
        }
    }
};

const isValidNIE = (args: FunctionArgs) => {
    args.Result = false;
    if (args.Parameters.length < 1) {
        args.Result = false;
    } else {
        try {
            const value = args.Parameters[0].Evaluate() ?? '';
            args.Result = HelperValidation.isValidNIE(value);

        } catch {
            args.Result = false;
        }
    }
};


const isValidCIF = (args: FunctionArgs) => {
    args.Result = false;
    if (args.Parameters.length < 1) {
        args.Result = false;
    } else {
        try {
            const value = args.Parameters[0].Evaluate() ?? '';
            args.Result = HelperValidation.isValidCIF(value);

        } catch {
            args.Result = false;
        }
    }
};


const isValidEMAIL = (args: FunctionArgs) => {
    args.Result = false;
    if (args.Parameters.length < 1) {
        args.Result = false;
    } else {
        try {
            const value = args.Parameters[0].Evaluate() ?? '';
            args.Result = HelperCommon.validarEmail(value);

        } catch {
            args.Result = false;
        }
    }
};


const isValidIBAN = (args: FunctionArgs) => {
    args.Result = false;
    if (args.Parameters.length < 1) {
        args.Result = false;
    } else {
        try {
            const value = args.Parameters[0].Evaluate() ?? '';
            args.Result = HelperCommon.validarIban(value);

        } catch {
            args.Result = false;
        }
    }
};



export default {


    getPropertyValue, falso, verdadero, tamcadena, Subcadena, Guid, YearDiff, MonthDiff, DayDiff, ConvertirMoneda, ConvertirNumero,
    _in, Rango, LoopForTemplateFile, addday, AddYear, addmonth, GetDay, getyear, GetMonth, catalogo, Edad,
    EdadActuarial, Msg, isCollection, sizeCollection, convertstring, setValues, Hoy, getCatalogDescription, getSingleCuote,
    getParameterFromStorage, isNull,
    isValidNIF,isValidNIE,isValidCIF,
    isValidEMAIL,isValidIBAN





}
