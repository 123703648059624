import gql from 'graphql-tag';

export const GET_ALL_APPLICATIONCONFIGURATION = gql(`
query($maxregs: Int) {
    applicationConfigurationQuery {
      getAll(maxregs:$maxregs) {
            id,
            applicationId,
            applicationVersion,
            idKeyType,
            idEnvironmentType,
            keyValue,
            keyBinaryValue,
            order,
            fcr,
            ucr,
            uum,
            fum
      }
    }
}
`);


export const GET_APPLICATION_BY_ID = gql(`
query( $applicationId: Int!, $version: Int!) {
  applicationConfigurationQuery {
    getByApplicationId(applicationId:$applicationId, version:$version) {
            id,  
            applicationId,
            applicationVersion,
            idKeyType,
            idEnvironmentType,
            keyValue,
            keyBinaryValue,
            order,
            fcr,
            ucr,
            uum,
            fum
      }
    }
}
`);


export const GET_APPLICATION_BY_ID_TYPES = gql(`
  query( $applicationId: Int!, $version: Int!,$types:[String!]) {
    applicationConfigurationQuery {
      getByAppAndTypes(applicationId:$applicationId, version:$version,types:$types) {
               id,  
               applicationId,
              applicationVersion,
              idKeyType,
              idEnvironmentType,
              keyValue,
              keyBinaryValue,
              order,
              fcr,
              ucr,
              uum,
              fum
        }
      }
  }
  `);




