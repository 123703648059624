import BaseControlTypeConst from "./BaseControlTypeConst"

export default class RatingTypeConst extends BaseControlTypeConst {
  
    static STARS = 'stars'
    static CANCEL = 'cancel'
    static ONICON = 'onIcon'
    static OFFICON = 'offIcon'
    static CANCELICON = 'cancelIcon'
    static READONLY = 'readonly'

    
  }