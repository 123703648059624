export default class CatalogTypeConst {
  static ALIGN = "align";
  static ALIGNTIMELINE = "aligntimeline";
  static BUTTONLAYOUT = "buttonlayout";
  static COMPGROUP = "compgroup";
  static COMPONENTSAPP = "componentsapp";
  static DISPLAY = "display";
  static EVENT = "event";
  static FILTERBUSQUEDA = "filterbusqueda";
  static ICONOSAPP = "iconosapp";
  static ICONPOS = "iconpos";
  static LAYOUT = "layout";
  static LAYOUTTEMP = "layouttemp";
  static MODE = "mode";
  static SELECTIONMODE = "selectionmode";
  static SEVERITY = "severity";
  static SHAPE = "shape";
  static SIZE = "size";
  static SIZECONTROL = "sizecontrol";
  static TIPOATTR = "tipoattr";
  static TIPOBD = "tipobd";
  static TIPOCONTROL = "tipocontrol";
  static TIPODATO = "tipodato";
  static TIPOOBJETO = "tipoobjeto";
  static TYPEDIVIDER = "typedivider";
  static VIEW = "view";

  static COMMAND="cmd";

  static MODELVALUE="modelvalue";
  static EXPRESSION="exp";

  static CLASS="class";

  static TEMPLAREND='templarend';
  static TEMPLATETHEME='templatetheme';
  static TEMPLATETHEMESTYLE='templatethemestyle';
  static EXPTOOL='exptool';


  static MIME = "mime";

  static APPCONFIG = "appconfig";
  static LANGUAGE = "language";

  static DATABASE_NAME_TYPE="tipobd";
  static DATASOURCETYPE = "datasourcetype"
  static DATABASETYPE = "dbtype"
  static SERVICETYPE = "servicetype"
  static HTMLMETHOD = "httpmethod";
  static SERVICE_CONFIGURATION = "srvcfg";
  static SORT_TYPE = "sorttype";

  static TIPODATOSQL="typefielddb";

  static COMP_SUBTYPE ="compsubtype";
  static VALIDATION ="validation";


  static FNAGGREGATE="fnaggregate"; // funciones de agregado
  static FILTERCRUD="filtercrud"; // filtro de los cruds
  static FILTERCONTROLTYPE="filtercontroltype"; // tipo filtro de los cruds

  static RESPONSESRVTYPE="responsesrvtype"; // funciones de agregado

  static MATCHMODE="matchmode";

  static APP_RENDER='apprender';
  static ENV='env';

}
