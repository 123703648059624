<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <Knob v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(KnobTypeConst.ID)" v-tooltip="getPropertyValue(KnobTypeConst.TOOLTIP)"
        :style="getPropertyValue(KnobTypeConst.STYLE)"
        :class="{ [getPropertyValue(KnobTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted, 'p-readonly': getPropertyBooleanValue(KnobTypeConst.READONLY) }"
        :name="getPropertyValue(KnobTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(KnobTypeConst.DISABLED) || (!canEdit)"
        :visible="getPropertyBooleanValue(KnobTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :placeholder="getPropertyValue(KnobTypeConst.PLACEHOLDER)"
        :size="getPropertyNumberValue(KnobTypeConst.SIZE)"
        :readonly="getPropertyBooleanValue(KnobTypeConst.READONLY) || !canDoOperation(ObjectGroupConst.EDIT)"
        :step="getPropertyNumberValue(KnobTypeConst.STEP)"
        :min="getPropertyNumberValue(KnobTypeConst.MIN)"
        :max="getPropertyNumberValue(KnobTypeConst.MAX)"
        :valueColor="getPropertyValue(KnobTypeConst.VALUECOLOR)"
        :rangeColor="getPropertyValue(KnobTypeConst.RANGECOLOR)"
        :textColor="getPropertyValue(KnobTypeConst.TEXTCOLOR)"
        :strokeWidth="getPropertyNumberValue(KnobTypeConst.STROKEWIDTH)"
        :showValue="getPropertyBooleanValue(KnobTypeConst.SHOWVALUE)"
        :valueTemplate="getPropertyValue(KnobTypeConst.VALUETEMPLATE)"
        :tabindex="getPropertyNumberValue(KnobTypeConst.TABINDEX)"
        :aria-labelledby="getPropertyValue(KnobTypeConst.ARIA_LABELLEDBY)"
        :aria-label="getPropertyValue(KnobTypeConst.ARIA_LABEL)"
        :unstyled="getPropertyBooleanValue(KnobTypeConst.UNSTYLED)"
        v-model="vmodel" >
    </Knob>
    <CustomValidate v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE)" v-model:submitted="submitted"
        v-model:vObject="v$" />
</template>
<script lang="ts">


import { defineComponent, onMounted, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import KnobTypeConst from '../../../../domain/Constants/KnobTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import CustomValidate from '../../shared/CustomValidate.vue';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_knob',
    components: {
        CustomValidate,
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue ,loaded,  getCatalogValue,
            baseOnMounted, baseOnBeforeUnmount, v$, submitted, canEdit, isValidData
            , fieldKey, fieldKeyComponentDataSourceId ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);


        onMounted(() => {

            baseOnMounted();

        })

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })



        return {
            getPropertyValue,
            getPropertyBooleanValue,
            getPropertyNumberValue,
            vmodel,
            KnobTypeConst,
            loaded,
            BaseControlTypeConst,
            getCatalogValue,
            v$,
            submitted,
            canEdit,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst   
            ,canDoOperation
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
