<template>
    <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG, Component)" :data="Component"
        :vmodel="vmodel" />
    <svg ref="svgcontrol"
        v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :key="componentKey" :id="getPropertyValue(SvgTypeConst.ID)" v-tooltip="getPropertyValue(SvgTypeConst.TOOLTIP)"
        :style="getPropertyValue(SvgTypeConst.STYLE)"
        :class="{ [getPropertyValue(SvgTypeConst.CLASS) ?? '']: true, 'customrequired': getPropertyBooleanValue(SvgTypeConst.REQUIRED) }"
        :name="getPropertyValue(SvgTypeConst.NAME)" :disabled="getPropertyBooleanValue(SvgTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(SvgTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :placeholder="getPropertyValue(SvgTypeConst.PLACEHOLDER)" :height="getPropertyNumberValue(SvgTypeConst.HEIGHT)"
        :width="getPropertyNumberValue(SvgTypeConst.WIDTH)" :viewBox="getPropertyValue(SvgTypeConst.VB)">

    </svg>
</template>
<script lang="ts">


import { defineComponent, onBeforeUnmount, ref, onMounted, watchEffect } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import SvgTypeConst from '../../../../domain/Constants/SvgTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_html_svg',
    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {
        //

        const store = useStore();
        const { vmodel, canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded, baseOnBeforeUnmount
            , fieldKey, fieldKeyComponentDataSourceId, componentKey, getPropertyNumberValue, getPropertyValueObjectOrFile
        } = ComponentCommonRender(props.Component, props.slotProps, props.container, store);

        const svgcontrol = ref();

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })

        onMounted(() => {
            watchEffect(() => {
                if (loaded.value == true) {
                    if (svgcontrol.value) {
                        getPropertyValueObjectOrFile(SvgTypeConst.CONTENT, SvgTypeConst.DATAFILEVALUE, SvgTypeConst.DATASOURCEVALUE, null,false).then((values: any) => {
                            svgcontrol.value.innerHTML = values
                        })

                    }
                }
            })




        })



        return {
            getPropertyValue,
            getPropertyBooleanValue,
            getPropertyNumberValue,
            vmodel,
            SvgTypeConst,
            loaded,
            BaseControlTypeConst,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            , canDoOperation
            , componentKey
            , svgcontrol
        };
    },
});
</script>
<style scoped></style>
