import BaseControlTypeConst from "./BaseControlTypeConst"

export default class DialogTypeConst extends BaseControlTypeConst {
    
    static HEADER= 'header'
    static FOOTER= 'footer'
    static MODAL= 'modal'
    static CONTENTSTYLE= 'contentStyle'
    static CONTENTCLASS= 'contentClass'
    static CONTENTPROPS= 'contentProps'
    static RTL= 'rtl'
    static CLOSABLE= 'closable'
    static DISMISSABLEMASK= 'dismissableMask'
    static CLOSEONESCAPE= 'closeOnEscape'
    static SHOWHEADER= 'showHeader'
    static BLOCKSCROLL= 'blockScroll'
    static BASEZINDEX= 'baseZIndex'
    static AUTOZINDEX= 'autoZIndex'
    static POSITION= 'position'
    static MAXIMIZABLE= 'maximizable'
    static BREAKPOINTS= 'breakpoints'
    static DRAGGABLE= 'draggable'
    static KEEPINVIEWPORT= 'keepInViewPort'
    static MINX= 'minX'
    static MINY= 'minY'
    static CLOSEICON= 'closeIcon'
    static MAXIMIZEICON= 'maximizeIcon'
    static MINIMIZEICON= 'minimizeIcon'
    static UNSTYLED= 'unstyled'

    static LAYOUT_CLOSE_ICON='layouttemp-dialog-closeicon'
    static LAYOUT_FOOTER='layouttemp-dialog-footer'
    static LAYOUT_HEADER='layouttemp-dialog-header'
    static LAYOUT_MAXIMIZE_ICON='layouttemp-dialog-maximizeicon'
    

}
