import gql from 'graphql-tag';


export const GET_DATASOURCE_LOOKUP_BY_ID = gql(`
query($id: Int!) {
    dataSourceLookUpQuery {
      getById(id:$id) {
        id,
        dataSourceId,
        idFieldDescription,
        tableAlias,
        maxRowsReturned
        queryDisplay,
        queryWhere,
        queryOrder,        
        addNewvaluesOnTheFly,
        filterDistinct,
        additionaFieldsOnSearch
        fieldsOnReturn
        idControlType,
        componentId,
        parameters,
        iapDataSourceLookUpFieldMaps{
            id,
            dataSourceFieldIdSource,
            dataSourceFieldIdTarget,
            dataSourceFieldIdTargetLookUp,
            dataSourceLookUpId,
            isFieldLinked,
            isFieldFiltered
        }
        dataSource{
            id,
            applicationId,
            applicationVersion,
            idDataSourceType,
            name,
            description,
            iapDataSourceFields{
                          id
                          dataSourceId
                          dataSourceTableAliasId
                          tableName
                          field
                          tableNameAlias
                          fieldAlias
                          position
                          defaultValue
                          sqlType
                          idDataType
                          catalogTypeId
                          dataSourceLookUpId
                          dataSourceLookUp{
                            idControlType
                          }
                          shortDescription
                          description
                          computed
                          fcr
                          ucr
                          uum
                          fum
                          iapObjectDataSourceFieldConfigurations{
                            idObjeto,
                            objetoId,
                            dataSourceFieldId
                            defaultFilterSearch      
                            defaultFilterSearchOrder
                            autoUpdate
                            defaultValue
                            defaultValidation
                            edit
                            add
                            editMassive
                            import
                            fcr
                            filterResult
                            filterResultOrder
                            filterSearch
                            filterSearchOrder
                            fum
                            list
                            view
                            mandatory
                            sortOrder
                            idSortType
                            aggregateOperation
                            idFilterType
                            idFilterControlType
                            idDefaultMatchMode
                            defaultFilterValue
                            cellStyle  
                            displayContentMaxlength          
                            dataSourceField{
                                id
                                tableName
                                field
                                tableNameAlias,
                                fieldAlias,
                                dataSourceLookUpId
                            }
                          }
            }
        }

        
      }
    }
}
`);






