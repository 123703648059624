import HtmlTypeConst from "./HtmlTypeConst"

export default class AutocompleteTypeConst extends HtmlTypeConst {
    
    static SUGGESTIONS= 'suggestions'
    static FIELD= 'field'
    static OPTIONLABEL= 'optionLabel'
    static OPTIONDISABLED= 'optionDisabled'
    static OPTIONGROUPLABEL= 'optionGroupLabel'
    static OPTIONGROUPCHILDREN= 'optionGroupChildren'
    static SCROLLHEIGHT= 'scrollHeight'
    static DROPDOWN= 'dropdown'
    static DROPDOWNMODE= 'dropdownMode'
    static AUTOHIGHLIGHT= 'autoHighlight'
    static MULTIPLE= 'multiple'
    static LOADING= 'loading'
    static DATAKEY= 'dataKey'
    static MINLENGTH= 'minLength'
    static DELAY= 'delay'
    static APPENDTO= 'appendTo'
    static FORCESELECTION= 'forceSelection'
    static COMPLETEONFOCUS= 'completeOnFocus'
    static INPUTID= 'inputId'
    static INPUTSTYLE= 'inputStyle'
    static INPUTCLASS= 'inputClass'
    static INPUTPROPS= 'inputProps'
    static PANELSTYLE= 'panelStyle'
    static PANELCLASS= 'panelClass'
    static PANELPROPS= 'panelProps'
    static DROPDOWNICON= 'dropdownIcon'
    static DROPDOWNCLASS= 'dropdownClass'
    static LOADINGICON= 'loadingIcon'
    static REMOVETOKENICON= 'removeTokenIcon'
    static VIRTUALSCROLLEROPTIONS= 'virtualScrollerOptions'
    static AUTOOPTIONFOCUS= 'autoOptionFocus'
    static SELECTONFOCUS= 'selectOnFocus'
    static SEARCHLOCALE= 'searchLocale'
    static SEARCHMESSAGE= 'searchMessage'
    static SELECTIONMESSAGE= 'selectionMessage'
    static EMPTYSELECTIONMESSAGE= 'emptySelectionMessage'
    static EMPTYSEARCHMESSAGE= 'emptySearchMessage'
    static TABINDEX= 'tabindex'
    static ARIALABEL= 'ariaLabel'
    static ARIALABELLEDBY= 'ariaLabelledby'
    static READONLY = 'readonly'
    static LAYOUT_CONTENT='layouttemp-autocomp-content'
    static LAYOUT_EMPTY='layouttemp-autocomp-empty'
    static LAYOUT_FOOTER='layouttemp-autocomp-footer'
    static LAYOUT_HEADER='layouttemp-autocomp-header'
    static LAYOUT_OPTION='layouttemp-autocomp-option'
    static LAYOUT_OPTIONGROUP='layouttemp-autocomp-optiongroup'
    
  }
  