

export const messages = {
    locale: 'en',
    messages: {
        es: {
            msg: 'hola',
            named: '{msg} world!',
            list: '{0} world!',
            literal: "{'hello'} world!",
            the_world: 'the world',
            dio: 'DIO:',
            linked: '@:dio @:the_world !!!!'
          },
      en: {
        msg: 'hello',
        named: '{msg} world!',
        list: '{0} world!',
        literal: "{'hello'} world!",
        the_world: 'the world',
        dio: 'DIO:',
        linked: '@:dio @:the_world !!!!'
      },     
    }
  }

  