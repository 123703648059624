<template>
    <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG, Component)" :data="Component"
        :vmodel="vmodel" />

    <span class="p-float-label wrap-input100 validate-input" :key="componentKey">
        <!--<Dropdown v-if="paths.length > 1" v-model="selectedPathId"  style="width: 250px;" @change="onChangePath()"
                        :options="paths" :showClear="true" optionValue="id" optionLabel="path"
                        placeholder="" @before-show="loadHistory" filter showClear/>-->

        <Button v-if="paths.length > 1" id="showBrowsing" size="small" v-tooltip="'Historial de navegación'"
            class="p-button p-component p-button-icon-only p-button-secondary p-button-rounded" icon="pi pi-history"
            :model="paths" @click="openBrowsing" />


        <OverlayPanel ref="opBrowsing" :dismissable="true" :showCloseIcon="true">
            <card>
                <template #content>
                    <div class="flex justify-content-center">
                        <DataTable :value="paths" :paginator="true" :rows="5" resizableColumns columnResizeMode="fit"
                            style="width:100%">

                            <Column field="path" header="Sitio" sortable>
                                <template #body="slotProps">
                                    <a href="#" @click="redirectToPath(slotProps.data)"><span
                                            v-html="slotProps.data.caption ?? slotProps.data.path" /> </a>

                                </template>
                            </Column>
                            <Column field="date" header="Fecha" sortable>
                                <template #body="slotProps">
                                    {{ formatDate(slotProps.data.date) }}
                                </template>
                            </Column>

                        </DataTable>

                    </div>
                </template>
            </card>

        </OverlayPanel>
    </span>
</template>
<script lang="ts">


import { defineComponent, onMounted, ref, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import Environment from "@ilinium/shared/src/common/infrastructure/funciones/environment";
import SummaryDebug from '../../shared/SummaryDebug.vue';
import { useRouter } from 'vue-router';
import { db, Browsing } from "../../../../../../../common/domain/db";
import HelperNavigation from '../../../../../../../common/infrastructure/funciones/HelperNavigation';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';
import EventConst from '../../../../../../../common/domain/constantes/EventConst';
import { InteractionEvent } from '../../../../../interaction/domain/interactionEvent';
import InteractionConst from '../../../../../interaction/domain/interactionConst';
import { IapComponent } from '../../../../../component/domain/iapComponent';
import EventBusCustom from '../../../../../../../common/infrastructure/event-bus-custom';
import { CustomMenu } from '../../../../../../../common/domain/modelos/menu/CustomMenu';
import { decompress } from 'compress-json';
import { searchMenuTree } from '../../../../../menu/infrastructure/HelperMenu';


export default defineComponent({
    name: 'dynamicbrowsing',
    components: {
        SummaryDebug,
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {

        const router = useRouter();
        const store = useStore();
        const paths = ref<Browsing[]>([]);
        const selectedPathId = ref('')
        const opBrowsing = ref();

        const { vmodel, canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded, resolveExpressions, baseOnBeforeUnmount
            , fieldKey, fieldKeyComponentDataSourceId, componentKey
        } = ComponentCommonRender(props.Component, props.slotProps, props.container, store);

        let isLoading = ref<boolean>(false);

        const openBrowsing = (event: any) => {
            loadHistory();
            opBrowsing.value?.toggle(event);
        }

        const closeBrowsing = () => {
            opBrowsing.value?.hide();
        }

        function onChangePath() {
            const selectedPath = paths.value.find((path: any) => path.id === selectedPathId.value);
            if (selectedPath) {
                router.push({ path: selectedPath.path });
            }
        };

        onMounted(async () => {
            await loadHistory();
        })

        onBeforeUnmount(() => {
            opBrowsing.value?.hide();
            paths.value.length = 0
            selectedPathId.value = '';
            baseOnBeforeUnmount();

        })



        async function loadHistory() {

            const myHistory = await db.browsing.where("userId").equals(store.getters.getCurrentUser.id)
                .and((record: any) => record.applicationId === Environment.APPID && Environment.APPVERSION === 1)
                .sortBy("date").then(records => records.reverse());
            paths.value = myHistory;
        }



        async function navigateAction(_dataNavigation: any) {

            if (_dataNavigation) {
                const dataNavigation = decompress(_dataNavigation)
                const menus = store.getters.getApplicationMenus;
                const menu = searchMenuTree(menus, dataNavigation?.menuId) as CustomMenu;
                if (menu?.componentId !== null) {
                    let _formLoadedKey: string | undefined = HelperNavigation.getComponentKeyLoaded(menu.componentId ?? -1);
                    const isLoaded = (_formLoadedKey !== undefined)
                    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));


                    if (menu.componentId !== null && !isLoaded) {
                        let retries = 0;
                        do {
                            _formLoadedKey = HelperNavigation.getComponentKeyLoaded(menu.componentId)

                            if (_formLoadedKey == undefined) {
                                retries++;
                                await delay(1000);
                            }

                        } while (_formLoadedKey == undefined && retries < 61)


                    }
                    if (!menu.keepAlive && !isLoaded) {
                        await delay(100);
                    }


                    if (menu.componentId !== null && !HelperCommon.isNullOrWhitespace(_formLoadedKey ?? '')) {

                        const keyEventComponentRoot = _formLoadedKey + (menu.componentId ?? -1).toString() + EventConst.INTERACTION;
                        var data: Partial<InteractionEvent> = {
                            typeId: InteractionConst.GET_ROOT_COMPONENTS,
                            objectValue: (menu.componentId ?? -1).toString()
                        }

                        var doCallbackOk = async (response: InteractionEvent) => {
                            if (response.interactionResult) {
                                const componentData = (response.interactionResult ?? []) as IapComponent[]

                                if (componentData.length > 0) {
                                    let compsIds = dataNavigation.comps ?? [];
                                    if (compsIds.length == 0 && menu.componentId !== null) {
                                        compsIds = [menu.componentId];
                                    }

                                    HelperNavigation.doInteraction((_formLoadedKey ?? ''), componentData, compsIds, JSON.stringify(dataNavigation.parameters));
                                }

                            }

                        }

                        EventBusCustom.emit(keyEventComponentRoot, { data: data, callBackResponse: doCallbackOk });

                    }
                }
            }
        }


        const redirectToPath = async (data: any) => {            
            closeBrowsing();

            // si estoy ya posicionado ahi vamos a navegar
            if (router.currentRoute.value.fullPath == data.path) {
                navigateAction(data.data);
            }
            else {
                const navigationResult = await router.push({ path: data.path as any });
                if (navigationResult) {
                    // navigation prevented

                } else {

                    // navigation succeeded (this includes the case of a redirection)
                    navigateAction(data.data);
                }

            }

        };
        function padTwoDigits(num: number) {
            return num.toString().padStart(2, "0");
        }

        function formatDate(rowData: any) {
            // Convierte la fecha al formato dd/MM/yyyy
            const date = new Date(rowData);
            return (
                [
                    padTwoDigits(date.getDate()),
                    padTwoDigits(date.getMonth() + 1),
                    date.getFullYear(),


                ].join('/') +
                " " +
                [
                    padTwoDigits(date.getHours()),
                    padTwoDigits(date.getMinutes()),
                    //padTwoDigits(date.getSeconds()),
                ].join(":")
            );

        }

        return {
            BaseControlTypeConst,
            ObjectGroupConst,
            paths,
            selectedPathId,
            onChangePath,
            getPropertyBooleanValue,
            vmodel,
            loadHistory,
            openBrowsing,
            opBrowsing,
            redirectToPath,
            formatDate,
            componentKey,
            loaded

        };
    },
});
</script>
<style scoped></style>
