/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { computed } from "vue";
import SqlTypesConst from "../../../../../common/domain/constantes/SqlTypesConst";
import HelperCommon from "../../../../../common/infrastructure/funciones/HelperCommon";
import { IapComponentDataSource } from "../../../component/domain/iapComponentDataSource";
import { IapDataSourceField } from "../../../datasource/domain/iapDataSourceField";
import DataSourceConst from "./dataSourceConst";
import { IapDataSource } from "../../../datasource/domain/iapDataSource";
import { clave } from "../../../dataupdate/domain/clave";
import OperationDataTypeConst from "../../../../../common/domain/constantes/OperationDataTypeConst";
import HelperUtils from "../../../../../common/infrastructure/funciones/HelperUtils";
import FiltroBusquedaConst from "@ilinium/shared/src/common/domain/constantes/FiltroBusquedaConst";

export default function DataSourceUtilComp(
  compDataSource: IapComponentDataSource,
  store:any,
  dsInput?: IapDataSource
) {

  
   const isType = (item: IapDataSourceField, tipo: string) => {
    return item.sqlType == tipo;
  };


  const isIdCampoType = (item: IapDataSourceField, tipo: string) => {
    return item.idDataType == tipo;
  };


  const isCatalogType = (item: IapDataSourceField) => {
    return !HelperCommon.isNullOrWhitespace(item.catalogTypeId ?? "")
  };

  const hasLookUpType = (item: IapDataSourceField) => {
    return item.dataSourceLookUpId !== null
  };

  const hasLookUpByFieldId = (key: string) => {
    let outPut = false
    const item = getFieldFromKeyCol(key);
    if (item) {
      outPut = hasLookUpType(item);
    }

    return outPut
  }

  const hasLookUpByFieldType = (key: string, typeId:string) => {

    let outPut = false
    const item = getFieldFromKeyCol(key)

    if (item) {
      outPut = hasLookUpType(item) && isIdCampoType(item,typeId);
    }

    return outPut
  }

  const getLookUpByFieldId = (key: string) => {

    
    const item = getFieldFromKeyCol(key)

    if (item && item.dataSourceLookUp) {
      return item.dataSourceLookUp;
    }

  }

  const isTableType = (item: IapDataSourceField) => {
    return (
      item?.foreignTableName?.toLocaleLowerCase() !== DataSourceConst.CAT.toLowerCase() &&
      (!HelperCommon.isNullOrWhitespace(
        item.catalogTypeId ?? ""
      ))
    );
  };

  const isCatalogTypeByFieldId = (key: string) => {
    let outPut = false
    const item = getFieldFromKeyCol(key);
    if (item) {
      outPut = isCatalogType(item)
    }

    return outPut
  };

  const isFilterVisible = (item: IapDataSourceField ) => {
    return (!isCatalogType(item) && item.dataSourceLookUpId==null && !isType(item, SqlTypesConst.BIT));
  }

  
  const initFilterField = (f: IapDataSourceField) => {
    if(!f) return  FiltroBusquedaConst.FILTROBUSQUEDA_CONTIENE
    return isType(f, SqlTypesConst.DATETIME) || isType(f, SqlTypesConst.DATE) || isType(f, SqlTypesConst.INT) || isType(f, SqlTypesConst.MONEY)   ? FiltroBusquedaConst.FILTROBUSQUEDA_IGUAL : FiltroBusquedaConst.FILTROBUSQUEDA_CONTIENE
  }


  const isColumnType = (columna: string, tipo: string) => {

    var item = getFieldFromKeyCol(columna);
    if (item) {
      return isType(item, tipo);
    }

    return false;
  }

  const isColumnIdtipoCampoType = (columna: string, tipo: string) => {

    let item=getFieldFromKeyCol(columna);

    if(!item){
      item=getFieldFromKeyCol(columna,true);
    }

    if (item) {
      return isIdCampoType(item, tipo);
    }

    return false;
  }

 

  function aplanarObjectEnLista(json: object): object {
    
    const tablasRellenas: object = {};

    // Función recursiva para buscar los campos en el JSON
    function findFields(objeto: object, tabla: string): void {
        for (const key in objeto) {
          //@ts-ignore:disable-next-line  
            if (objeto.hasOwnProperty(key) && typeof objeto[key] === 'object' && !Array.isArray(objeto[key])) {
              //@ts-ignore:disable-next-line    
              findFields(objeto[key], `${tabla}.${key}`);
            } else {
                const campoBusqueda = `${tabla}.${key}`;
                if (tablasData.value.some(campo => campo.field.toLowerCase() == campoBusqueda.toLowerCase())) {
                    /*if (!tablasRellenas[tabla]) {
                        tablasRellenas[tabla] = [];
                    }
                    tablasRellenas[tabla].push({ [key]: objeto[key] });*/
                    //@ts-ignore:disable-next-line  
                    if (!tablasRellenas[tabla]) {
                      //@ts-ignore:disable-next-line  
                      tablasRellenas[tabla] = [];
                    }
                  //@ts-ignore:disable-next-line  
                    tablasRellenas[tabla].push({ [key]: objeto[key] });
                }
            }
        }
    }

    for (const tabla in json) {
      //@ts-ignore:disable-next-line  
      if (json.hasOwnProperty(tabla) && typeof json[tabla] === 'object' && Array.isArray(json[tabla])) {
        //@ts-ignore:disable-next-line      
        json[tabla].forEach((objeto: object) => {
                findFields(objeto, tabla);
            });
        }
        //@ts-ignore:disable-next-line  
        else if (json.hasOwnProperty(tabla) && typeof json[tabla] === 'object') {
          //@ts-ignore:disable-next-line    
          findFields(json[tabla], tabla);
        }
    }

    return tablasRellenas;
  }

  const getPrimaryKeys = (dsData: IapDataSource, data: any, tableName: string): clave[] => {
    const _outputData: clave[] = [];
    const keys = dsData.iapDataSourceFields.filter(x => x.tableName == tableName && x.primaryKey).map(x =>
        getFieldKeyColumn(x)
    );

    keys.forEach(k => {
        const field= getFieldFromKeyCol(k,false,dsData.iapDataSourceFields);
        const dataKey=getFieldKeyColumn(field);

      _outputData.push({
        fieldId: getFieldId(k,dsData.iapDataSourceFields) as any,
        value: data[dataKey]
      })
    })

    return _outputData;
  }

  const getDefaultvalueDataBase = (data: IapDataSourceField) => {
    if (data.defaultValue) {
      const i = data.defaultValue.startsWith('((') ? 2 : 1;
      let _valor = data.defaultValue.substring(i, data.defaultValue.length - i) 
      //@ts-ignore:disable-next-line
      _valor = _valor.replaceAll('(', '').replaceAll(')', '')
      if (_valor.toLocaleLowerCase() == 'getdate') {
          return new Date()
      }
      if (_valor.toLocaleLowerCase() == 'getcurrentuser') {
          return store.getters.currentUser.id
      }
      if (_valor.toLocaleLowerCase() == 'newid') {
        return store.getters.currentUser.id
    }

      if (data.defaultValue.toLocaleLowerCase() == 'getdate()') {
          return new Date()
      }
      if (data.defaultValue.toLocaleLowerCase() =='newid()') {
        return HelperUtils.newGuid()          
      }
      if (data.defaultValue.toLocaleLowerCase() == 'getcurrentuser()') {
          return store.getters.getCurrentUser.id
      }

      if (isType(data, SqlTypesConst.BIT)) {
          return Boolean(JSON.parse(_valor))
      }
      else {
          return _valor
      }

  }

  return null;
  }
  const getValuesWithoutPrimaryKeys = (dsData: IapDataSource, data: any, tableName: string, operationType: number): clave[] => {
    
    const _outputData: clave[] = [];
    const notKeys = dsData.iapDataSourceFields.filter(x => x.tableName == tableName && (!x.primaryKey || (x.primaryKey && x.identityColumn == false && operationType == OperationDataTypeConst.INSERT))).map(x => ({
      fullId: getFieldKeyColumn(x),
      id: x.id,
      field:x.field
    }));
    notKeys.forEach(k => {
      const dsf = dsData.iapDataSourceFields.find(x => x.id == k.id);
      if (dsf && (data[k.fullId] || /*(!data[k.fullId] && dsf?.defaultValue && */operationType == OperationDataTypeConst.INSERT/*)*/)) {
        const _value = dsf.defaultValue && !data[k.fullId] ? getDefaultvalueDataBase(dsf) : data[k.fullId]
        let _valueEnd = ((HelperCommon.isNullOrWhitespace(_value ?? '') || (_value ?? '')?.toString().trim().toLowerCase() == 'null') && dsf.nullable) ? null: _value
        const mustSerialize = (dsf?.sqlType == SqlTypesConst.BIT) ?? false;
        const mustToString = (dsf?.sqlType == SqlTypesConst.MONEY) ?? false;
        _outputData.push({
          fieldId: k.id,
          value: (mustSerialize && _valueEnd != null ? JSON.stringify(_valueEnd)  : (mustToString && _valueEnd != null ? _valueEnd.toString() :_valueEnd)) 
        })
      }
    })

    return _outputData;
  }


  const tablas = computed(() => {
    if (dsInput) {
      return dsInput.iapDataSourceTableAliases;
    }
    return compDataSource.dataSource.iapDataSourceTableAliases;
  })

  const tablasData = computed(() => {
    if (dsInput) {
      return dsInput.iapDataSourceFields;
    }
    return compDataSource.dataSource.iapDataSourceFields
   
  })

  const dataBaseId = computed(() => {
    if (dsInput) {
      return dsInput?.iapDataSourceDataBase?.idBaseDatos
    }
    return compDataSource.dataSource?.iapDataSourceDataBase?.idBaseDatos
  })

  const dataSourceFieldConfig = computed(() => {
    if (dsInput) {
      return dsInput?.iapDataSourceFields?.flatMap(x => x.iapObjectDataSourceFieldConfigurations) as any
    }
    return compDataSource.iapComponentDataSourceFieldConfigurations as any
  })



  const getFieldValidationConfig = (key:string,objetoId:number=0):any[]=> {
    
    let configArr:any[]=[];
    let config;
    if(key){
      const item = getFieldFromKeyCol(key);

      if(objetoId!=0){
        config=dataSourceFieldConfig.value.find((x:any)=>x.dataSourceFieldId==item?.id && x?.objetoId==objetoId)
      }
      else{
        config=dataSourceFieldConfig.value.find((x:any)=>x.dataSourceFieldId==item?.id)
      }
      
      if (config) {
        configArr=!HelperCommon.isNullOrWhitespace(config.defaultValidation??'')?JSON.parse(config.defaultValidation??'') as any[]:[];
      }
    }
    return configArr;
  }

  const getFieldFromKeyCol=(key:string,custom:boolean=false,fields:IapDataSourceField[]=[])=>{

      const dsFields=fields && fields.length>0?fields:tablasData.value;

       if(!custom){
        //@ts-ignore:disable-next-line
        return (dsFields as IapDataSourceField[]).find(x => ([x.id.replaceAll('-', ''), !HelperCommon.isNullOrWhitespace(x.fieldAlias??'')?x.fieldAlias:x.field].join(DataSourceConst.DELIMITERFIELDS))  == key);
       }   
       else{
        //@ts-ignore:disable-next-line
        return (dsFields as IapDataSourceField[]).find(x =>
          //@ts-ignore:disable-next-line  
          (([DataSourceConst.AGGREGATE_TABLE,x.id.replaceAll('-', ''), !HelperCommon.isNullOrWhitespace(x.fieldAlias??'')?x.fieldAlias:x.field].join(DataSourceConst.DELIMITERFIELDS))== key)
        )

       }
      }

   const getFieldKeyColumn=(field:IapDataSourceField | undefined,custom:boolean=false):string=>{
        if(field){
          if(!custom){
            //@ts-ignore:disable-next-line
            return ([field.id.replaceAll('-', ''), !HelperCommon.isNullOrWhitespace(field.fieldAlias??'')?field.fieldAlias:field.field].join(DataSourceConst.DELIMITERFIELDS)) 
          }
          else{
            //@ts-ignore:disable-next-line
            return ([DataSourceConst.AGGREGATE_TABLE,field.id.replaceAll('-', ''), !HelperCommon.isNullOrWhitespace(field.fieldAlias??'')?field.fieldAlias:field.field].join(DataSourceConst.DELIMITERFIELDS))
          }
          
        }  
        return "";
   }

   const getFieldId=(key:string,fields:IapDataSourceField[])=>{
            const field=fields.find(x=>getFieldKeyColumn(x)==key);
            return field?.id;
   }



  return {

    tablas,
    tablasData,
    dataBaseId,
    //getTables,
    isType,
    isCatalogType,
    isCatalogTypeByFieldId,
    isFilterVisible,
    isIdCampoType,
    isColumnType,
    isColumnIdtipoCampoType,
    aplanarObjectEnLista,
    isTableType,
    getPrimaryKeys,
    getValuesWithoutPrimaryKeys,
    hasLookUpType,
    hasLookUpByFieldId,
    getLookUpByFieldId,
    hasLookUpByFieldType,
    getFieldValidationConfig,
    getFieldFromKeyCol,
    getFieldKeyColumn,
    getFieldId,
    initFilterField    
  };
}


