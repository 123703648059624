import { ClassicPreset } from "rete";
import { Container } from 'inversify';
import { IapWorkFlowActivity } from "../../../../domain/service/iapWorkFlowActivity";

export class StoreProcedureControl extends ClassicPreset.Control {
  constructor(public formKeyInput: String,
    public rootComponentId:number,
    public currentComponentId:number,
    public applicationId:number, 
    public applicationVersion:number,
    public variableConnectionInput: string,
    public variableProcNameInput: string,
    public variableParametersInput:[],    
    public container: Container | undefined, 
    public itemActivity: IapWorkFlowActivity | undefined,
    public onChange: (evt: any) => void,
    public getNode: (evt: any) => void, 
    public showExpression:(evt:any) => void) {
    super();
  }
}