import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: ModeloComponentHelpInputType!){
    componentHelpMutation{
        updateComponentHelp(data:$data)
    }
}
`);

export const ADD = gql(`
mutation($data: ModeloComponentHelpInputType!){
    componentHelpMutation{
        addComponentHelp(data:$data)
    }
}
`);



