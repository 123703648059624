<template>

<span>Tipo </span>
    <Dropdown @pointerdown.stop="" @dblclick.stop=""  v-model="selectedType" :options="[{id:0, name:'Guid'},{id:1, name:'Generador claves'}]" optionValue="id" optionLabel="name" placeholder="Selecciona una opción" class="w-full md:w-56 mb-1" 
    @change="changeType()"/>        
    <br />

    <div  @pointerdown.stop="" @dblclick.stop=""  style="overflow-y: scroll; height:240px;">
        <span>Opciones</span>      
        <div class="card flex flex-wrap justify-center gap-4">
            <div class="flex items-center gap-2" >
                <Checkbox @pointerdown.stop="" @dblclick.stop=""  v-model="selectedHash" inputId="hash" binary @change="updateHash()" />
                <label for="hash" > Crear Hash 
                    <i class="pi pi-info-circle ml-1" v-tooltip="'Crea el tipo de opción desde el servidor y lo devuelve en un hash.'"></i>
                </label>
            </div>        
            <div v-if="selectedType == 1 && selectedOptions" class="flex flex-wrap justify-center gap-4" >
                <div class="flex items-center gap-2" >
                <InputNumber @pointerdown.stop="" @dblclick.stop="" v-model="selectedOptions.length" inputId="length" :min="0" :max="100" :showButtons="true" />
                <label for="length"> Longitud de la Clave</label>
            </div>
            
            <div class="flex items-center gap-2">
                <Checkbox @pointerdown.stop="" @dblclick.stop=""  v-model="selectedOptions.lowercase" inputId="lowercase" binary @change="updateOptions()" />
                <label for="lowercase"> Minúsculas </label>
            </div>
            <div class="flex items-center gap-2">
                <Checkbox @pointerdown.stop="" @dblclick.stop=""  v-model="selectedOptions.uppercase" inputId="uppercase" binary @change="updateOptions()" />
                <label for="uppercase"> Mayúsculas </label>
            </div>
            <div class="flex items-center gap-2">
                <Checkbox @pointerdown.stop="" @dblclick.stop=""  v-model="selectedOptions.numbers" inputId="numbers" binary @change="updateOptions()" />
                <label for="numbers"> Números </label>
            </div>
            <div class="flex items-center gap-2">
                <Checkbox @pointerdown.stop="" @dblclick.stop=""  v-model="selectedOptions.symbols" inputId="symbols" binary @change="updateOptions()" />
                <label for="symbols"> Símbolos </label>
            </div>
            <div class="flex items-center gap-2" >
                <InputText @pointerdown.stop="" @dblclick.stop="" v-model="selectedOptions.exclude" inputId="exclude" />
                <label for="exclude"> Caracteres a Excluir </label>
            </div>

            <div class="flex items-center gap-2" >
                <InputNumber @pointerdown.stop="" @dblclick.stop="" v-model="selectedOptions.minLengthLowercase" inputId="minLengthLowercase" :min="0" :max="100" :showButtons="true" />
                <label for="minLengthLowercase"> Mínimo de Minúsculas </label>
            </div>

            <div class="flex items-center gap-2" >
                <InputNumber @pointerdown.stop="" @dblclick.stop="" v-model="selectedOptions.minLengthUppercase" inputId="minLengthUppercase" :min="0" :max="100" :showButtons="true" />
                <label for="minLengthUppercase"> Mínimo de Mayúsculas </label>
            </div>

            <div class="flex items-center gap-2" >
                <InputNumber @pointerdown.stop="" @dblclick.stop="" v-model="selectedOptions.minLengthNumbers" inputId="minLengthNumbers" :min="0" :max="100" :showButtons="true" />
                <label for="minLengthNumbers"> Mínimo de Números </label>
            </div>

            <div class="flex items-center gap-2" >
                <InputNumber @pointerdown.stop="" @dblclick.stop="" v-model="selectedOptions.minLengthSymbols" inputId="minLengthSymbols" :min="0" :max="100" :showButtons="true" />
                <label for="minLengthSymbols"> Mínimo de Símbolos </label>
            </div>
            
            </div>
            

        </div>
      
    </div>
    <br />   
</template>
<script lang="ts">

import { defineComponent,  onMounted, ref, getCurrentInstance } from 'vue';
import HelperCustomComponentControls from '../../../functions/helperCustomComponentControls';
import GeneratorKeysComponentNodeConst from '../constants/GeneratorKeysComponentNodeConst';
import { GenerateOptions } from '../../../../../../../common/infrastructure/security';
import PrimeVue from 'primevue/config'
import HelperPrime from '../../../../../../../common/infrastructure/funciones/HelperPrime';
import Dropdown from 'primevue/dropdown'
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Tooltip from 'primevue/tooltip';


export default defineComponent({
    name: 'customkeygencontrol',
    components: {
    },
    props: {
        data: Object
    },
    setup(props) {

        const app = getCurrentInstance()        
        app?.appContext.app.component('Dropdown',Dropdown)
        app?.appContext.app.component('Checkbox',Checkbox)
        app?.appContext.app.component('InputNumber',InputNumber)
        app?.appContext.app.component('InputText',InputText)
        app?.appContext.app.directive('tooltip',Tooltip)
        
        
        app?.appContext.app.use(PrimeVue, HelperPrime.getPrimeOptions())

        const { showExpression } = HelperCustomComponentControls(props.data);
        const selectedType = ref(1);
        const selectedOptions = ref<GenerateOptions>();
        const selectedHash = ref(1);

    

    
      const changeType = () =>{
        if (props.data){
            
            props.data.onChange({ key: GeneratorKeysComponentNodeConst.VAR_TYPE, data: selectedType.value })

            if (selectedType.value == 0 ){
                props.data.onChange({ key: GeneratorKeysComponentNodeConst.VAR_OPTIONS, data: null })    
            }
            else{
                selectedOptions.value =initOptions();
                updateOptions();
            }
        }
        
      }

      const initOptions = ()=>{
        return {
                length: 12,
                lowercase: true,
                uppercase: true,
                numbers: true,
                symbols: true,
                exclude: '',
                minLengthLowercase: 3,
                minLengthUppercase: 3,
                minLengthNumbers: 3,
                minLengthSymbols: 3,
                } as GenerateOptions
      }


      const updateOptions = ()=>{
        if (props.data){
            props.data.onChange({ key: GeneratorKeysComponentNodeConst.VAR_OPTIONS, data: JSON.stringify(selectedOptions.value) })
        }
        
      }

      const updateHash = ()=>{
        if (props.data){
            props.data.onChange({ key: GeneratorKeysComponentNodeConst.VAR_HASH, data: JSON.stringify(selectedHash.value) })
        }
        
      }

      const getObject = (obj:any) => {
            if (typeof obj == 'string'){
                try{
                    return JSON.parse(obj);
                }
                catch{
                    return null;
                }
            }
            return obj;
        }

        onMounted(() => {            
            if (props.data) {
                selectedType.value = props.data[GeneratorKeysComponentNodeConst.VAR_TYPE];                
                selectedOptions.value = getObject(props.data[GeneratorKeysComponentNodeConst.VAR_OPTIONS]) ?? initOptions();
                selectedHash.value = props.data[GeneratorKeysComponentNodeConst.VAR_HASH]
                if (selectedType.value  == 1 &&  props.data[GeneratorKeysComponentNodeConst.VAR_OPTIONS] == null){
                    updateOptions();
                }
            }            
        })


        
        return {
            selectedType,            
            selectedOptions,
            selectedHash,
            showExpression,
            changeType,
            updateOptions,
            updateHash
        };
    },
});
</script>
<style scoped></style>
