export default class CatalogObjectTypeConst {
 
    static GENERAL='tipoobjeto-app'
    static ATTRCOMP='tipoobjeto-compattr'
    static EVTCOMP='tipoobjeto-compevt'
    static MEN='tipoobjeto-men'    
    static DSF='tipoobjeto-dsf'
    static DSSC='tipoobjeto-dssc'
    static EXPV='tipoobjeto-expv'
    static WFA='tipoobjeto-wfa'       
    static COMP ='tipoobjeto-comp'
    static WF='tipoobjeto-wf'
    static WFAC='tipoobjeto-wfac'
    static WFAV='tipoobjeto-wfav'
    static WFACN='tipoobjeto-wfacn'
    static APPNOT='tipoobjeto-appnot'
    static HELP='tipoobjeto-help'
    static CODE='tipoobjeto-code'
    static EXP='tipoobjeto-exp'
    static DS='tipoobjeto-ds'
    static DSSP='tipoobjeto-dssp'
    static DSFN='tipoobjeto-dsfn'
    static LOOKUP='tipoobjeto-lk'
    static CDS='tipoobjeto-cds'


    }
    