import { TYPES } from '@ilinium/shared/src/common/domain/types'
import MyApolloClient from '@ilinium/shared/src/common/infrastructure/funciones/HelperApolloClient'
import ApolloConst from '@ilinium/shared/src/common/domain/constantes/Apollo/ApolloConst'



import { IAuthRepository } from '@ilinium/shared/src/entidades/builderMaster/auth/domain/IAuthRepository'
import { AuthService } from '@ilinium/shared/src/entidades/builderMaster/auth/application/AuthService'
import { Container } from 'inversify'
import { IServiceAuth } from '@ilinium/shared/src/entidades/builderMaster/auth/application/IServiceAuth'
import { AuthAxiosRepository } from '@ilinium/shared/src/entidades/builderMaster/auth/infrastructure/AuthAxiosRepository'


import { IDataUpdateRepository } from '@ilinium/shared/src/entidades/builder/dataupdate/domain/IDataUpdateRepository';
import { DataUpdateService } from '@ilinium/shared/src/entidades/builder/dataupdate/application/DataUpdateService';
import { IServiceDataUpdate } from '@ilinium/shared/src/entidades/builder/dataupdate/application/IServiceDataUpdate';
import { DataUpdateApolloClientRepository } from '@ilinium/shared/src/entidades/builder/dataupdate/infrastructure/DataUpdateApolloClientRepository';


//// BUILDER
import { IComponentRepository } from '@ilinium/shared/src/entidades/builder/component/domain/IComponentRepository';
import { ComponentService } from '@ilinium/shared/src/entidades/builder/component/application/ComponentService';
import { IServiceComponent } from '@ilinium/shared/src/entidades/builder/component/application/IServiceComponent';
import { ComponentApolloClientRepository } from '@ilinium/shared/src/entidades/builder/component/infrastructure/ComponentApolloClientRepository';

import { IComponentEventRepository } from '@ilinium/shared/src/entidades/builder/component/domain/IComponentEventRepository';
import { ComponentEventService } from '@ilinium/shared/src/entidades/builder/component/application/ComponentEventService';
import { IServiceComponentEvent } from '@ilinium/shared/src/entidades/builder/component/application/IServiceComponentEvent';
import { ComponentEventApolloClientRepository } from '@ilinium/shared/src/entidades/builder/component/infrastructure/ComponentEventApolloClientRepository';

import { ICodeRepository } from '@ilinium/shared/src/entidades/builder/code/domain/ICodeRepository';
import { CodeService } from '@ilinium/shared/src/entidades/builder/code/application/CodeService';
import { IServiceCode } from '@ilinium/shared/src/entidades/builder/code/application/IServiceCode';
import { CodeApolloClientRepository } from '@ilinium/shared/src/entidades/builder/code/infrastructure/CodeApolloClientRepository';


import { IHelpRepository } from '@ilinium/shared/src/entidades/builder/help/domain/IHelpRepository';
import { HelpService } from '@ilinium/shared/src/entidades/builder/help/application/HelpService';
import { IServiceHelp } from '@ilinium/shared/src/entidades/builder/help/application/IServiceHelp';
import { HelpApolloClientRepository } from '@ilinium/shared/src/entidades/builder/help/infrastructure/HelpApolloClientRepository';


import { IComponentAttributeEventRepository } from '@ilinium/shared/src/entidades/builder/component/domain/IComponentAttributeEventRepository';
import { ComponentAttributeEventService } from '@ilinium/shared/src/entidades/builder/component/application/ComponentAttributeEventService';
import { IServiceComponentAttributeEvent } from '@ilinium/shared/src/entidades/builder/component/application/IServiceComponentAttributeEvent';
import { ComponentAttributeEventApolloClientRepository } from '@ilinium/shared/src/entidades/builder/component/infrastructure/ComponentAttributeEventApolloClientRepository';

import { IExpressionRepository } from '@ilinium/shared/src/entidades/builder/expression/domain/IExpressionRepository';
import { ExpressionService } from '@ilinium/shared/src/entidades/builder/expression/application/ExpressionService';
import { IServiceExpression } from '@ilinium/shared/src/entidades/builder/expression/application/IServiceExpression';
import { ExpressionApolloClientRepository } from '@ilinium/shared/src/entidades/builder/expression/infrastructure/ExpressionApolloClientRepository';

import { IExpressionDetailRepository } from '@ilinium/shared/src/entidades/builder/expression/domain/IExpressionDetailRepository';
import { ExpressionDetailService } from '@ilinium/shared/src/entidades/builder/expression/application/ExpressionDetailService';
import { IServiceExpressionDetail } from '@ilinium/shared/src/entidades/builder/expression/application/IServiceExpressionDetail';
import { ExpressionDetailApolloClientRepository } from '@ilinium/shared/src/entidades/builder/expression/infrastructure/ExpressionDetailApolloClientRepository';



import { IComponentAttributeRepository } from '@ilinium/shared/src/entidades/builder/component/domain/IComponentAttributeRepository';
import { ComponentAttributeService } from '@ilinium/shared/src/entidades/builder/component/application/ComponentAttributeService';
import { IServiceComponentAttribute } from '@ilinium/shared/src/entidades/builder/component/application/IServiceComponentAttribute';
import { ComponentAttributeApolloClientRepository } from '@ilinium/shared/src/entidades/builder/component/infrastructure/ComponentAttributeApolloClientRepository';

import { IComponentAttributeTemplateRepository } from '@ilinium/shared/src/entidades/builder/component/domain/IComponentAttributeTemplateRepository';
import { ComponentAttributeTemplateService } from '@ilinium/shared/src/entidades/builder/component/application/ComponentAttributeTemplateService';
import { IServiceComponentAttributeTemplate } from '@ilinium/shared/src/entidades/builder/component/application/IServiceComponentAttributeTemplate';
import { ComponentAttributeTemplateApolloClientRepository } from '@ilinium/shared/src/entidades/builder/component/infrastructure/ComponentAttributeTemplateApolloClientRepository';


import { IMenuRepository } from '@ilinium/shared/src/entidades/builder/menu/domain/IMenuRepository';
import { MenuService } from '@ilinium/shared/src/entidades/builder/menu/application/MenuService';
import { IServiceMenu } from '@ilinium/shared/src/entidades/builder/menu/application/IServiceMenu';
import { MenuApolloClientRepository } from '@ilinium/shared/src/entidades/builder/menu/infrastructure/MenuApolloClientRepository';


import { ICatalogRepository } from '@ilinium/shared/src/entidades/builder/catalog/domain/ICatalogRepository';
import { CatalogApolloClientRepository } from '@ilinium/shared/src/entidades/builder/catalog/infrastructure/CatalogApolloClientRepository';
import { IServiceCatalog } from '@ilinium/shared/src/entidades/builder/catalog/application/IServiceCatalog';
import { CatalogService } from '@ilinium/shared/src/entidades/builder/catalog/application/CatalogService';

import { ICatalogTypeRepository } from '@ilinium/shared/src/entidades/builder/catalog/domain/ICatalogTypeRepository';
import { CatalogTypeApolloClientRepository } from '@ilinium/shared/src/entidades/builder/catalog/infrastructure/CatalogTypeApolloClientRepository';
import { IServiceCatalogType } from '@ilinium/shared/src/entidades/builder/catalog/application/IServiceCatalogType';
import { CatalogTypeService } from '@ilinium/shared/src/entidades/builder/catalog/application/CatalogTypeService';

import { ISearchRepository } from '@ilinium/shared/src/entidades/builder/search/domain/ISearchRepository';
import { SearchApolloClientRepository } from '@ilinium/shared/src/entidades/builder/search/infrastructure/SearchApolloClientRepository';
import { IServiceSearch } from '@ilinium/shared/src/entidades/builder/search/application/IServiceSearch';
import { SearchService } from '@ilinium/shared/src/entidades/builder/search/application/SearchService';

import { IApplicationRepository } from '@ilinium/shared/src/entidades/builder/application/domain/IApplicationRepository';
import { ApplicationApolloClientRepository } from '@ilinium/shared/src/entidades/builder/application/infrastructure/ApplicationApolloClientRepository';
import { IServiceApplication } from '@ilinium/shared/src/entidades/builder/application/application/IServiceApplication';
import { ApplicationService } from '@ilinium/shared/src/entidades/builder/application/application/ApplicationService';

import { IApplicationConnectionRepository } from '@ilinium/shared/src/entidades/builder/application/domain/IApplicationConnectionRepository';
import { ApplicationConnectionApolloClientRepository } from '@ilinium/shared/src/entidades/builder/application/infrastructure/ApplicationConnectionApolloClientRepository';
import { IServiceApplicationConnection } from '@ilinium/shared/src/entidades/builder/application/application/IServiceApplicationConnection';
import { ApplicationConnectionService } from '@ilinium/shared/src/entidades/builder/application/application/ApplicationConnectionService';

import { IApplicationNoteRepository } from '@ilinium/shared/src/entidades/builder/application/domain/IApplicationNoteRepository';
import { ApplicationNoteApolloClientRepository } from '@ilinium/shared/src/entidades/builder/application/infrastructure/ApplicationNoteApolloClientRepository';
import { IServiceApplicationNote } from '@ilinium/shared/src/entidades/builder/application/application/IServiceApplicationNote';
import { ApplicationNoteService } from '@ilinium/shared/src/entidades/builder/application/application/ApplicationNoteService';



import { IApplicationConfigurationRepository } from '@ilinium/shared/src/entidades/builder/application/domain/IApplicationConfigurationRepository';
import { ApplicationConfigurationApolloClientRepository } from '@ilinium/shared/src/entidades/builder/application/infrastructure/ApplicationConfigurationApolloClientRepository';
import { IServiceApplicationConfiguration } from '@ilinium/shared/src/entidades/builder/application/application/IServiceApplicationConfiguration';
import { ApplicationConfigurationService } from '@ilinium/shared/src/entidades/builder/application/application/ApplicationConfigurationService';


import { IDataSourceRepository } from '@ilinium/shared/src/entidades/builder/datasource/domain/IDataSourceRepository';
import { DataSourceApolloClientRepository } from '@ilinium/shared/src/entidades/builder/datasource/infrastructure/DataSourceApolloClientRepository';
import { IServiceDataSource } from '@ilinium/shared/src/entidades/builder/datasource/application/IServiceDataSource';
import { DataSourceService } from '@ilinium/shared/src/entidades/builder/datasource/application/DataSourceService';

import { IDataSourceServiceRepository } from '@ilinium/shared/src/entidades/builder/datasource/domain/IDataSourceServiceRepository';
import { DataSourceServiceApolloClientRepository } from '@ilinium/shared/src/entidades/builder/datasource/infrastructure/DataSourceServiceApolloClientRepository';
import { IServiceDataSourceService } from '@ilinium/shared/src/entidades/builder/datasource/application/IServiceDataSourceService';
import { DataSourceServiceService } from '@ilinium/shared/src/entidades/builder/datasource/application/DataSourceServiceService';

import { IDataSourceServiceConfigurationRepository } from '@ilinium/shared/src/entidades/builder/datasource/domain/IDataSourceServiceConfigurationRepository';
import { DataSourceServiceConfigurationApolloClientRepository } from '@ilinium/shared/src/entidades/builder/datasource/infrastructure/DataSourceServiceConfigurationApolloClientRepository';
import { IServiceDataSourceServiceConfiguration } from '@ilinium/shared/src/entidades/builder/datasource/application/IServiceDataSourceServiceConfiguration';
import { DataSourceServiceConfigurationService } from '@ilinium/shared/src/entidades/builder/datasource/application/DataSourceServiceConfigurationService';

import { IDataSourceFieldRepository } from '@ilinium/shared/src/entidades/builder/datasource/domain/IDataSourceFieldRepository';
import { DataSourceFieldApolloClientRepository } from '@ilinium/shared/src/entidades/builder/datasource/infrastructure/DataSourceFieldApolloClientRepository';
import { IServiceDataSourceField } from '@ilinium/shared/src/entidades/builder/datasource/application/IServiceDataSourceField';
import { DataSourceFieldService } from '@ilinium/shared/src/entidades/builder/datasource/application/DataSourceFieldService';

import { IDataSourceLookUpRepository } from '@ilinium/shared/src/entidades/builder/datasource/domain/IDataSourceLookUpRepository';
import { DataSourceLookUpApolloClientRepository } from '@ilinium/shared/src/entidades/builder/datasource/infrastructure/DataSourceLookUpApolloClientRepository';
import { IServiceDataSourceLookUp } from '@ilinium/shared/src/entidades/builder/datasource/application/IServiceDataSourceLookUp';
import { DataSourceLookUpService } from '@ilinium/shared/src/entidades/builder/datasource/application/DataSourceLookUpService';

import { IObjectDataSourceFieldConfigurationRepository } from '@ilinium/shared/src/entidades/builder/datasource/domain/IObjectDataSourceFieldConfigurationRepository';
import { ObjectDataSourceFieldConfigurationApolloClientRepository } from '@ilinium/shared/src/entidades/builder/datasource/infrastructure/ObjectDataSourceFieldConfigurationApolloClientRepository';
import { IServiceObjectDataSourceFieldConfiguration } from '@ilinium/shared/src/entidades/builder/datasource/application/IServiceObjectDataSourceFieldConfiguration';
import { ObjectDataSourceFieldConfigurationService } from '@ilinium/shared/src/entidades/builder/datasource/application/ObjectDataSourceFieldConfigurationService';

import { IObjectDataSourceFieldRelationRepository } from '@ilinium/shared/src/entidades/builder/datasource/domain/IObjectDataSourceFieldRelationRepository';
import { ObjectDataSourceFieldRelationApolloClientRepository } from '@ilinium/shared/src/entidades/builder/datasource/infrastructure/ObjectDataSourceFieldRelationApolloClientRepository';
import { IServiceObjectDataSourceFieldRelation } from '@ilinium/shared/src/entidades/builder/datasource/application/IServiceObjectDataSourceFieldRelation';
import { ObjectDataSourceFieldRelationService } from '@ilinium/shared/src/entidades/builder/datasource/application/ObjectDataSourceFieldRelationService';



import { IComponentDataSourceRepository } from '@ilinium/shared/src/entidades/builder/component/domain/IComponentDataSourceRepository';
import { ComponentDataSourceApolloClientRepository } from '@ilinium/shared/src/entidades/builder/component/infrastructure/ComponentDataSourceApolloClientRepository';
import { IServiceComponentDataSource } from '@ilinium/shared/src/entidades/builder/component/application/IServiceComponentDataSource';
import { ComponentDataSourceService } from '@ilinium/shared/src/entidades/builder/component/application/ComponentDataSourceService';

import { IDataSourceMigrationRepository } from '@ilinium/shared/src/entidades/builder/datasource/domain/IDataSourceMigrationRepository';
import { DataSourceMigrationApolloClientRepository } from '@ilinium/shared/src/entidades/builder/datasource/infrastructure/DataSourceMigrationApolloClientRepository';
import { IServiceDataSourceMigration } from '@ilinium/shared/src/entidades/builder/datasource/application/IServiceDataSourceMigration';
import { DataSourceMigrationService } from '@ilinium/shared/src/entidades/builder/datasource/application/DataSourceMigrationService';

import { IDataSourceMigrationLogRepository } from '@ilinium/shared/src/entidades/builder/datasource/domain/IDataSourceMigrationLogRepository';
import { DataSourceMigrationLogApolloClientRepository } from '@ilinium/shared/src/entidades/builder/datasource/infrastructure/DataSourceMigrationLogApolloClientRepository';
import { IServiceDataSourceMigrationLog } from '@ilinium/shared/src/entidades/builder/datasource/application/IServiceDataSourceMigrationLog';
import { DataSourceMigrationLogService } from '@ilinium/shared/src/entidades/builder/datasource/application/DataSourceMigrationLogService';

import { IComponentDataSourceFieldConfigurationRepository } from '@ilinium/shared/src/entidades/builder/component/domain/IComponentDataSourceFieldConfigurationRepository';
import { ComponentDataSourceFieldConfigurationApolloClientRepository } from '@ilinium/shared/src/entidades/builder/component/infrastructure/ComponentDataSourceFieldConfigurationApolloClientRepository';
import { IServiceComponentDataSourceFieldConfiguration } from '@ilinium/shared/src/entidades/builder/component/application/IServiceComponentDataSourceFieldConfiguration';
import { ComponentDataSourceFieldConfigurationService } from '@ilinium/shared/src/entidades/builder/component/application/ComponentDataSourceFieldConfigurationService';

import { IComponentDataSourceServiceConfigurationRepository } from '@ilinium/shared/src/entidades/builder/component/domain/IComponentDataSourceServiceConfigurationRepository';
import { ComponentDataSourceServiceConfigurationApolloClientRepository } from '@ilinium/shared/src/entidades/builder/component/infrastructure/ComponentDataSourceServiceConfigurationApolloClientRepository';
import { IServiceComponentDataSourceServiceConfiguration } from '@ilinium/shared/src/entidades/builder/component/application/IServiceComponentDataSourceServiceConfiguration';
import { ComponentDataSourceServiceConfigurationService } from '@ilinium/shared/src/entidades/builder/component/application/ComponentDataSourceServiceConfigurationService';


import { IFieldConfigUserRepository } from '@ilinium/shared/src/entidades/builderMaster/user/domain/IFieldConfigUserRepository';
import { FieldConfigUserApolloClientRepository } from '@ilinium/shared/src/entidades/builderMaster/user/infrastructure/FieldConfigUserApolloClientRepository';
import { IServiceFieldConfigUser } from '@ilinium/shared/src/entidades/builderMaster/user/application/IServiceFieldConfigUser';
import { FieldConfigUserService } from '@ilinium/shared/src/entidades/builderMaster/user/application/FieldConfigUserService';


import { IDataSourceTableAliasRepository } from '@ilinium/shared/src/entidades/builder/datasource/domain/IDataSourceTableAliasRepository';
import { DataSourceTableAliasApolloClientRepository } from '@ilinium/shared/src/entidades/builder/datasource/infrastructure/DataSourceTableAliasApolloClientRepository';
import { IServiceDataSourceTableAlias } from '@ilinium/shared/src/entidades/builder/datasource/application/IServiceDataSourceTableAlias';
import { DataSourceTableAliasService } from '@ilinium/shared/src/entidades/builder/datasource/application/DataSourceTableAliasService';

import { IWorkFlowRepository } from '@ilinium/shared/src/entidades/builder/workflow/domain/repositorry/IWorkFlowRepository';
import { IServiceWorkFlow } from '@ilinium/shared/src/entidades/builder/workflow/application/interface/IServiceWorkFlow';
import { WorkFlowService } from '@ilinium/shared/src/entidades/builder/workflow/application/WorkFlowService';
import { WorkFlowApolloClientRepository } from '@ilinium//shared/src/entidades/builder/workflow/infrastructure/WorkFlowApolloClientRepository';

import { IWorkFlowActivityRepository } from '@ilinium/shared/src/entidades/builder/workflow/domain/repositorry/IWorkFlowActivityRepository';
import { IServiceWorkFlowActivity } from '@ilinium/shared/src/entidades/builder/workflow/application/interface/IServiceWorkFlowActivity';
import { WorkFlowActivityService } from '@ilinium/shared/src/entidades/builder/workflow/application/WorkFlowActivityService';
import { WorkFlowActivityApolloClientRepository } from '@ilinium/shared/src/entidades/builder/workflow/infrastructure/WorkFlowActivityApolloClientRepository';

import { IWorkFlowActivityVariableRepository } from '@ilinium/shared/src/entidades/builder/workflow/domain/repositorry/IWorkFlowActivityVariableRepository';
import { WorkFlowActivityVariableApolloClientRepository } from '@ilinium//shared/src/entidades/builder/workflow/infrastructure/WorkFlowActivityVariableApolloClientRepository';
import { IServiceWorkFlowActivityVariable } from '@ilinium/shared/src/entidades/builder/workflow/application/interface/IServiceWorkFlowActivityVariable';
import { WorkFlowActivityVariableService } from '@ilinium//shared/src/entidades/builder/workflow/application/WorkFlowActivityVariableService';

import { IWorkFlowActivityControlRepository } from '@ilinium/shared/src/entidades/builder/workflow/domain/repositorry/IWorkFlowActivityControlRepository';
import { WorkFlowActivityControlApolloClientRepository } from '@ilinium//shared/src/entidades/builder/workflow/infrastructure/WorkFlowActivityControlApolloClientRepository';
import { IServiceWorkFlowActivityControl } from '@ilinium/shared/src/entidades/builder/workflow/application/interface/IServiceWorkFlowActivityControl';
import { WorkFlowActivityControlService } from '@ilinium//shared/src/entidades/builder/workflow/application/WorkFlowActivityControlService';


import { IWorkFlowActivityConnectionRepository } from '@ilinium/shared/src/entidades/builder/workflow/domain/repositorry/IWorkFlowActivityConnectionRepository';
import { WorkFlowActivityConnectionApolloClientRepository } from '@ilinium//shared/src/entidades/builder/workflow/infrastructure/WorkFlowActivityConnectionApolloClientRepository';
import { IServiceWorkFlowActivityConnection } from '@ilinium/shared/src/entidades/builder/workflow/application/interface/IServiceWorkFlowActivityConnection';
import { WorkFlowActivityConnectionService } from '@ilinium//shared/src/entidades/builder/workflow/application/WorkFlowActivityConnectionService';


import { ISearchConfigRepository } from '@ilinium/shared/src/entidades/builderMaster/user/domain/ISearchConfigRepository';
import { SearchConfigApolloClientRepository } from '@ilinium/shared/src/entidades/builderMaster/user/infrastructure/SearchConfigApolloClientRepository';
import { IServiceSearchConfig } from '@ilinium/shared/src/entidades/builderMaster/user/application/IServiceSearchConfig';
import { ServiceSearchConfig } from '@ilinium/shared/src/entidades/builderMaster/user/application/ServiceSearchConfig';

import { IUserRepository } from '@ilinium/shared/src/entidades/builderMaster/user/domain/IUserRepository';
import { UserApolloClientRepository } from '@ilinium/shared/src/entidades/builderMaster/user/infrastructure/UserApolloClientRepository';
import { IServiceUser } from '@ilinium/shared/src/entidades/builderMaster/user/application/IServiceUser';
import { ServiceUser } from '@ilinium/shared/src/entidades/builderMaster/user/application/ServiceUser';

import { IObjectGroupRepository } from '@ilinium/shared/src/entidades/builder/objectgroup/domain/IObjectGroupRepository';
import { IServiceObjectGroup } from '@ilinium/shared/src/entidades/builder/objectgroup/application/IServiceObjectGroup';
import { ObjectGroupService } from '@ilinium/shared/src/entidades/builder/objectgroup/application/ObjectGroupService';
import { ObjectGroupApolloClientRepository } from '@ilinium/shared/src/entidades/builder/objectgroup/infrastructure/ObjectGroupApolloClientRepository';

import { ICacheRepository } from '@ilinium/shared/src/entidades/builder/cache/domain/ICacheRepository';
import { CacheService } from '@ilinium/shared/src/entidades/builder/cache/application/CacheService';
import { IServiceCache } from '@ilinium/shared/src/entidades/builder/cache/application/IServiceCache';
import { CacheApolloClientRepository } from '@ilinium/shared/src/entidades/builder/cache/infrastructure/CacheApolloClientRepository';

import { IUserOptionRepository } from '@ilinium/shared/src/entidades/builderMaster/user/domain/IUserOptionRepository';
import { UserOptionApolloClientRepository } from '@ilinium/shared/src/entidades/builderMaster/user/infrastructure/UserOptionApolloClientRepository';
import { IServiceUserOption } from '@ilinium/shared/src/entidades/builderMaster/user/application/IServiceUserOption';
import { ServiceUserOption } from '@ilinium/shared/src/entidades/builderMaster/user/application/ServiceUserOption';

import { IDocumentRepository } from '@ilinium/shared/src/entidades/builderDocument/document/domain/IDocumentRepository';
import { DocumentApolloClientRepository } from '@ilinium/shared/src/entidades/builderDocument/document/infrastructure/DocumentApolloClientRepository';
import { IServiceDocument } from '@ilinium/shared/src/entidades/builderDocument/document/application/IServiceDocument';
import { ServiceDocument } from '@ilinium/shared/src/entidades/builderDocument/document/application/ServiceDocument';

import { IEncryptRepository } from '@ilinium/shared/src/entidades/builderEncrypt/domain/IEncryptRepository';
import { EncryptApolloClientRepository } from '@ilinium/shared/src/entidades/builderEncrypt/infrastructure/EncryptApolloClientRepository';
import { IServiceEncrypt } from '@ilinium/shared/src/entidades/builderEncrypt/application/IServiceEncrypt';
import { ServiceEncrypt } from '@ilinium/shared/src/entidades/builderEncrypt/application/ServiceEncrypt';


import { IConfigurationRepository } from '@ilinium/shared/src/entidades/builderModel/configuration/domain/IConfigurationRepository';
import { IServiceConfiguration } from '@ilinium/shared/src/entidades/builderModel/configuration/application/IServiceConfiguration';
import { ConfigurationService } from '@ilinium/shared/src/entidades/builderModel/configuration/application/ConfigurationService';
import { ConfigurationApolloClientRepository } from '@ilinium/shared/src/entidades/builderModel/configuration/infrastructure/ConfigurationApolloClientRepository';


import { IDobleAuthenticationUserRepository } from '@ilinium/shared/src/entidades/builderMaster/user/domain/IDobleAuthenticationUserRepository'
import { DobleAuthenticationUserService } from '@ilinium/shared/src/entidades/builderMaster/user/application/DobleAuthenticationUserService'
import { IServiceDobleAuthenticationUser } from '@ilinium//shared/src/entidades/builderMaster/user/application/IServiceDobleAuthenticationUser'
import { DobleAuthenticationUserApolloClientRepository } from '@ilinium/shared/src/entidades/builderMaster/user/infrastructure/DobleAuthenticationUserApolloClientRepository'

import { IImportConfigRepository } from '@ilinium/shared/src/entidades/builderMaster/user/domain/IImportConfigRepository';
import { ImportConfigApolloClientRepository } from '@ilinium/shared/src/entidades/builderMaster/user/infrastructure/ImportConfigApolloClientRepository';
import { IServiceImportConfig } from '@ilinium/shared/src/entidades/builderMaster/user/application/IServiceImportConfig';
import { ServiceImportConfig } from '@ilinium/shared/src/entidades/builderMaster/user/application/ServiceImportConfig';
import Environment from '@ilinium/shared/src/common/infrastructure/funciones/environment'
import CatalogEnvironmentConst from '@ilinium/shared/src/entidades/builder/catalog/domain/const/CatalogEnvironmentConst'


const container = new Container()
const apolloInstance = new MyApolloClient([Environment.VUE_APP_URLBUILD ?? ''], [ApolloConst.DEFAULT],container);



/* BUILDER */

container.bind<IAuthRepository>(TYPES.AUTH_REPOSITORY).toConstantValue(new AuthAxiosRepository(Environment.VUE_APP_URLAUTH ?? ''))
container.bind<IServiceAuth>(TYPES.AUTH_SERVICE).to(AuthService)


// actualizaciones
container.bind<IDataUpdateRepository>(TYPES.DATAUPDATE_REPOSITORY).toConstantValue(new DataUpdateApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
container.bind<IServiceDataUpdate>(TYPES.DATAUPDATE_SERVICE).to(DataUpdateService)

// quitamos del contenedor los métodos que no se llaman en la parte estática
if (!Environment.IS_APP_STATIC)
{

        // componente
    container.bind<IComponentRepository>(TYPES.COMPONENT_REPOSITORY).toConstantValue(new ComponentApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceComponent>(TYPES.COMPONENT_SERVICE).to(ComponentService)



    // componenteEvent
    container.bind<IComponentEventRepository>(TYPES.COMPONENT_EVENT_REPOSITORY).toConstantValue(new ComponentEventApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceComponentEvent>(TYPES.COMPONENT_EVENT_SERVICE).to(ComponentEventService)



    // componentAttribute
    container.bind<IComponentAttributeRepository>(TYPES.COMPONENT_ATTRIBUTE_REPOSITORY).toConstantValue(new ComponentAttributeApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceComponentAttribute>(TYPES.COMPONENT_ATTRIBUTE_SERVICE).to(ComponentAttributeService)

    // componentAttributeTemplate
    container.bind<IComponentAttributeTemplateRepository>(TYPES.COMPONENT_ATTRIBUTE_TEMPLATE_REPOSITORY).toConstantValue(new ComponentAttributeTemplateApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceComponentAttributeTemplate>(TYPES.COMPONENT_ATTRIBUTE_TEMPLATE_SERVICE).to(ComponentAttributeTemplateService)


    // componentAttributeEvent
    container.bind<IComponentAttributeEventRepository>(TYPES.COMPONENT_ATTRIBUTE_EVENT_SERVICE).toConstantValue(new ComponentAttributeEventApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceComponentAttributeEvent>(TYPES.COMPONENT_ATTRIBUTE_EVENT_SERVICE).to(ComponentAttributeEventService)


    // componentDataSource
    container.bind<IComponentDataSourceRepository>(TYPES.COMPONENT_DATASOURCE_REPOSITORY).toConstantValue(new ComponentDataSourceApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceComponentDataSource>(TYPES.COMPONENT_DATASOURCE_SERVICE).to(ComponentDataSourceService)


    // componentDataSourceFieldConfiguration
    container.bind<IComponentDataSourceFieldConfigurationRepository>(TYPES.COMPONENT_DATASOURCE_FIELD_CONF_REPOSITORY).toConstantValue(new ComponentDataSourceFieldConfigurationApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceComponentDataSourceFieldConfiguration>(TYPES.COMPONENT_DATASOURCE_FIELD_CONF_SERVICE).to(ComponentDataSourceFieldConfigurationService)

    // componentDataSourceServiceConfiguration
    container.bind<IComponentDataSourceServiceConfigurationRepository>(TYPES.COMPONENT_DATASOURCE_SERVICE_CONF_REPOSITORY).toConstantValue(new ComponentDataSourceServiceConfigurationApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceComponentDataSourceServiceConfiguration>(TYPES.COMPONENT_DATASOURCE_SERVICE_CONF_SERVICE).to(ComponentDataSourceServiceConfigurationService)

    // objectDataSourceFieldConfiguration
    container.bind<IObjectDataSourceFieldConfigurationRepository>(TYPES.OBJECTDATASOURCEFIELDCONFIGURATION_REPOSITORY).toConstantValue(new ObjectDataSourceFieldConfigurationApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceObjectDataSourceFieldConfiguration>(TYPES.OBJECTDATASOURCEFIELDCONFIGURATION_SERVICE).to(ObjectDataSourceFieldConfigurationService)

    // objectDataSourceFieldRelation
    container.bind<IObjectDataSourceFieldRelationRepository>(TYPES.OBJECTDATASOURCEFIELDRELATION_REPOSITORY).toConstantValue(new ObjectDataSourceFieldRelationApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceObjectDataSourceFieldRelation>(TYPES.OBJECTDATASOURCEFIELDRELATION_SERVICE).to(ObjectDataSourceFieldRelationService)

    // objectGroup
    container.bind<IObjectGroupRepository>(TYPES.OBJECTGROUP_DIRECTIVE_REPOSITORY).toConstantValue(new ObjectGroupApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceObjectGroup>(TYPES.OBJECTGROUP_DIRECTIVE_SERVICE).to(ObjectGroupService)

    // catalog
    container.bind<ICatalogRepository>(TYPES.CATALOG_REPOSITORY).toConstantValue(new CatalogApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceCatalog>(TYPES.CATALOG_SERVICE).to(CatalogService)

    // catalogType
    container.bind<ICatalogTypeRepository>(TYPES.CATALOGTYPE_REPOSITORY).toConstantValue(new CatalogTypeApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceCatalogType>(TYPES.CATALOGTYPE_SERVICE).to(CatalogTypeService)

    // applicationConnection

    container.bind<IApplicationConnectionRepository>(TYPES.APPLICATIONCONNECTION_REPOSITORY).toConstantValue(new ApplicationConnectionApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceApplicationConnection>(TYPES.APPLICATIONCONNECTION_SERVICE).to(ApplicationConnectionService)

    // applicationNote
    container.bind<IApplicationNoteRepository>(TYPES.APPLICATIONNOTE_REPOSITORY).toConstantValue(new ApplicationNoteApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceApplicationNote>(TYPES.APPLICATIONNOTE_SERVICE).to(ApplicationNoteService)


    // applicationConfiguration

    container.bind<IApplicationConfigurationRepository>(TYPES.APPLICATIONCONFIGURATION_REPOSITORY).toConstantValue(new ApplicationConfigurationApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceApplicationConfiguration>(TYPES.APPLICATIONCONFIGURATION_SERVICE).to(ApplicationConfigurationService)

    // datasourceServiceConfiguration

    container.bind<IDataSourceServiceConfigurationRepository>(TYPES.DATASOURCESERVICECONFIGURATION_REPOSITORY).toConstantValue(new DataSourceServiceConfigurationApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceDataSourceServiceConfiguration>(TYPES.DATASOURCESERVICECONFIGURATION_SERVICE).to(DataSourceServiceConfigurationService)

    // datasourceField

    container.bind<IDataSourceFieldRepository>(TYPES.DATASOURCEFIELD_REPOSITORY).toConstantValue(new DataSourceFieldApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceDataSourceField>(TYPES.DATASOURCEFIELD_SERVICE).to(DataSourceFieldService)



    // datasourceTableAlias

    container.bind<IDataSourceTableAliasRepository>(TYPES.DATASOURCE_ALIAS_REPOSITORY).toConstantValue(new DataSourceTableAliasApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceDataSourceTableAlias>(TYPES.DATASOURCE_ALIAS_SERVICE).to(DataSourceTableAliasService)

    // DataSourceMigration
    container.bind<IDataSourceMigrationRepository>(TYPES.DATASOURCE_MIGRATION_REPOSITORY).toConstantValue(new DataSourceMigrationApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceDataSourceMigration>(TYPES.DATASOURCE_MIGRATION_SERVICE).to(DataSourceMigrationService)

    // DataSourceMigrationLog
    container.bind<IDataSourceMigrationLogRepository>(TYPES.DATASOURCE_MIGRATION_LOG_REPOSITORY).toConstantValue(new DataSourceMigrationLogApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceDataSourceMigrationLog>(TYPES.DATASOURCE_MIGRATION_LOG_SERVICE).to(DataSourceMigrationLogService)


    // workflow

    container.bind<IWorkFlowRepository>(TYPES.WORKFLOW_REPOSITORY).toConstantValue(new WorkFlowApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceWorkFlow>(TYPES.WORKFLOW_SERVICE).to(WorkFlowService)

    // workflowactivity

    container.bind<IWorkFlowActivityRepository>(TYPES.WORKFLOWACTIVITY_REPOSITORY).toConstantValue(new WorkFlowActivityApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceWorkFlowActivity>(TYPES.WORKFLOWACTIVITY_SERVICE).to(WorkFlowActivityService)


    // workflowactivityvarriables

    container.bind<IWorkFlowActivityVariableRepository>(TYPES.WORKFLOWACTIVITYVAR_REPOSITORY).toConstantValue(new WorkFlowActivityVariableApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceWorkFlowActivityVariable>(TYPES.WORKFLOWACTIVITYVAR_SERVICE).to(WorkFlowActivityVariableService)


    // workflowactivityvarriableControl

    container.bind<IWorkFlowActivityControlRepository>(TYPES.WORKFLOWACTIVITYCONTROL_REPOSITORY).toConstantValue(new WorkFlowActivityControlApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceWorkFlowActivityControl>(TYPES.WORKFLOWACTIVITYCONTROL_SERVICE).to(WorkFlowActivityControlService)


    // workflowactivityconexiones

    container.bind<IWorkFlowActivityConnectionRepository>(TYPES.WORKFLOWACTIVITYCON_REPOSITORY).toConstantValue(new WorkFlowActivityConnectionApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceWorkFlowActivityConnection>(TYPES.WORKFLOWACTIVITYCON_SERVICE).to(WorkFlowActivityConnectionService)


    // Expression
    container.bind<IExpressionRepository>(TYPES.EXPRESSION_REPOSITORY).toConstantValue(new ExpressionApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceExpression>(TYPES.EXPRESSION_SERVICE).to(ExpressionService)


    // ExpressionDetail
    container.bind<IExpressionDetailRepository>(TYPES.EXPRESSION_DETAIL_REPOSITORY).toConstantValue(new ExpressionDetailApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceExpressionDetail>(TYPES.EXPRESSION_DETAIL_SERVICE).to(ExpressionDetailService)



    // Code
    container.bind<ICodeRepository>(TYPES.CODE_REPOSITORY).toConstantValue(new CodeApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceCode>(TYPES.CODE_SERVICE).to(CodeService)



    /**********************APP MODEL */


    // objectGroup
    container.bind<IConfigurationRepository>(TYPES.CONFIGURATION_REPOSITORY).toConstantValue(new ConfigurationApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
    container.bind<IServiceConfiguration>(TYPES.CONFIGURATION_SERVICE).to(ConfigurationService)

}

 // Cache
 container.bind<ICacheRepository>(TYPES.CACHE_REPOSITORY).toConstantValue(new CacheApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
 container.bind<IServiceCache>(TYPES.CACHE_SERVICE).to(CacheService)


// menu
container.bind<IMenuRepository>(TYPES.MENU_REPOSITORY).toConstantValue(new MenuApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
container.bind<IServiceMenu>(TYPES.MENU_SERVICE).to(MenuService)



// search
container.bind<ISearchRepository>(TYPES.SEARCH_REPOSITORY).toConstantValue(new SearchApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
container.bind<IServiceSearch>(TYPES.SEARCH_SERVICE).to(SearchService)

// application
container.bind<IApplicationRepository>(TYPES.APPLICATION_REPOSITORY).toConstantValue(new ApplicationApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
container.bind<IServiceApplication>(TYPES.APPLICATION_SERVICE).to(ApplicationService)



// Solo para el sendmail
container.bind<IDobleAuthenticationUserRepository>(TYPES.DOBLE_FACTOR_USER_REPOSITORY).toConstantValue(new DobleAuthenticationUserApolloClientRepository(apolloInstance))
container.bind<IServiceDobleAuthenticationUser>(TYPES.DOBLE_FACTOR_USER_SERVICE).to(DobleAuthenticationUserService)

// datasource

container.bind<IDataSourceRepository>(TYPES.DATASOURCE_REPOSITORY).toConstantValue(new DataSourceApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
container.bind<IServiceDataSource>(TYPES.DATASOURCE_SERVICE).to(DataSourceService)

// datasourceService

container.bind<IDataSourceServiceRepository>(TYPES.DATASOURCESERVICE_REPOSITORY).toConstantValue(new DataSourceServiceApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
container.bind<IServiceDataSourceService>(TYPES.DATASOURCESERVICE_SERVICE).to(DataSourceServiceService)



// datasourceLookUp

container.bind<IDataSourceLookUpRepository>(TYPES.DATASOURCELOOKUP_REPOSITORY).toConstantValue(new DataSourceLookUpApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
container.bind<IServiceDataSourceLookUp>(TYPES.DATASOURCELOOKUP_SERVICE).to(DataSourceLookUpService)


// Help
container.bind<IHelpRepository>(TYPES.HELP_REPOSITORY).toConstantValue(new HelpApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
container.bind<IServiceHelp>(TYPES.HELP_SERVICE).to(HelpService)



/************ APP MASTER */

// FieldConfigUser


container.bind<IFieldConfigUserRepository>(TYPES.FIELD_CONF_USR_REPOSITORY).toConstantValue(new FieldConfigUserApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
container.bind<IServiceFieldConfigUser>(TYPES.FIELD_CONF_USR_SERVICE).to(FieldConfigUserService)

// searchconfig
container.bind<ISearchConfigRepository>(TYPES.SEARCH_USR_REPOSITORY).toConstantValue(new SearchConfigApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
container.bind<IServiceSearchConfig>(TYPES.SEARCH_USR_SERVICE).to(ServiceSearchConfig)

// user
container.bind<IUserRepository>(TYPES.USR_REPOSITORY).toConstantValue(new UserApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
container.bind<IServiceUser>(TYPES.USR_SERVICE).to(ServiceUser)


// UserOption
container.bind<IUserOptionRepository>(TYPES.USER_OPTION_REPOSITORY).toConstantValue(new UserOptionApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
container.bind<IServiceUserOption>(TYPES.USER_OPTION_SERVICE).to(ServiceUserOption)

// importconfig
container.bind<IImportConfigRepository>(TYPES.IMPORT_REPOSITORY).toConstantValue(new ImportConfigApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
container.bind<IServiceImportConfig>(TYPES.IMPORT_SERVICE).to(ServiceImportConfig)


/****** DOCUMENTS */

container.bind<IDocumentRepository>(TYPES.STORAGE_REPOSITORY).toConstantValue(new DocumentApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
container.bind<IServiceDocument>(TYPES.STORAGE_SERVICE).to(ServiceDocument)

/****** ENCRYPT */

container.bind<IEncryptRepository>(TYPES.ENCRYPT_REPOSITORY).toConstantValue(new EncryptApolloClientRepository(apolloInstance, ApolloConst.DEFAULT))
container.bind<IServiceEncrypt>(TYPES.ENCRYPT_SERVICE).to(ServiceEncrypt)





export { container }
