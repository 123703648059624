<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"

            />
    <div v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :key="componentKey"    
        :id="getPropertyValue(ExpressionControlTypeConst.ID)"
        v-tooltip="getPropertyValue(ExpressionControlTypeConst.TOOLTIP)"
        :style="getPropertyValue(ExpressionControlTypeConst.STYLE)"
        :class="{ [getPropertyValue(ExpressionControlTypeConst.CLASS) ?? '']: true, 'customrequired': getPropertyBooleanValue(ExpressionControlTypeConst.REQUIRED) }"
        :name="getPropertyValue(ExpressionControlTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(ExpressionControlTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(ExpressionControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :placeholder="getPropertyValue(ExpressionControlTypeConst.PLACEHOLDER)">
        
        <Panel toggleable>
            <template #header>
                <div class="flex align-items-center gap-2">
                    <i class="fa fa-subscript" aria-hidden="true"></i>
                    <span class="font-bold">{{ getPropertyValue(ExpressionControlTypeConst.TITLE) }}</span>
                </div>
            </template>
            <p class="m-0" >
                <Expression v-if="hasLoadData" :key="keyExp" :container="container" :catalogTypes="catalogTypes"                 
                :applicationId="Component.applicationId" :applicationVersion="Component.applicationVersion" 
                @expressionsChanged="loadExpressions()"
                :isExternal="true"
                :externalTranslateFunction="translateFormula"
                :externalTree="externalTree"
                :expressions="expressions"  
                @load:Expressions="loadExpressions()"
                @external:update="doOperationExternal($event,OperationDataTypeConst.UPDATE)" 
                @external:delete="doOperationExternal($event,OperationDataTypeConst.DELETE)" 
                @external:insert="doOperationExternal($event,OperationDataTypeConst.INSERT)" >
            </Expression>
            </p>
        </Panel>
        <slot name="content"></slot>

    </div>
</template>
<script lang="ts">


import { defineComponent, onMounted, ref,shallowRef, onBeforeUnmount } from 'vue';
import { MessageService } from '../../../../../../../common/infrastructure/servicios';
import { MessageType } from '../../../../../../../common/infrastructure/servicios/MessageService';
import { IapComponentDataSource } from '../../../../../component/domain/iapComponentDataSource';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import ExpressionControlTypeConst from '../../../../domain/Constants/ExpressionControlTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { IapComponentAttribute } from '../../../../../component/domain/iapComponentAttribute';
import { IapCatalogType } from '../../../../../catalog/domain/iapCatalogType';
import { Dictionary } from '../../../../../expression/domain/dictionary';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import Expression from '../../../../../expression/infrastructure/component/Expression.vue';
import helperCatalog from '../../../../../catalog/infrastructure/helper/helperCatalog';
import { IapExpression } from '../../../../../expression/domain/iapExpression';
import {IapExpressionDetail } from '../../../../../expression/domain/iapExpressionDetail'; 
import { fieldDefinition } from '../../../../../dataupdate/domain/fieldDefinition';
import DataSourceUtilComp from '../../../../../crud/infrastructure/functions/dataSourceUtilComp';
import SqlTypesConst from '../../../../../../../common/domain/constantes/SqlTypesConst';
import OperationDataTypeConst from '../../../../../../../common/domain/constantes/OperationDataTypeConst';
import { DataUpdateOperation } from '../../../../../dataupdate/domain/dataUpdateOperation';
import { dataUpdate } from  '../../../../../dataupdate/domain/dataUpdate';
import HelperOperationData from "../../../../../../../common/infrastructure/funciones/HelperOperationData";
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';
import HelperUtils from '../../../../../../../common/infrastructure/funciones/HelperUtils';
import HelperLoading from '../../../../../../../common/infrastructure/funciones/HelperLoading';
import DataSourceComp from '../../../../../crud/infrastructure/functions/dataSourceComp';
import Environment from '../../../../../../../common/infrastructure/funciones/environment';
import { fetchWrapper } from '../../../../../../../common/infrastructure/funciones/helperFetch';
import HelperCompress from '../../../../../../../common/infrastructure/funciones/HelperCompress';
import ComponentUtil from '../../../../../designer/infrastructure/component/util/componentUtil';
import SummaryDebug from '../../shared/SummaryDebug.vue';	
//import jsonDataTranslates from '../../../../../form/infrastructure/data/expressionTranslates.json'

export default defineComponent({
    name: 'dynamic_expression_crud',
    components:{
        Expression,
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },      
        slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {
        //
        //{ key:'XXXXX',data:{label:'XXXXXX',value:'XXXXXXXX'}, children: []},
		
        const store = useStore();
        const { vmodel,resolveDatasource,getPropertyValueObject, canDoOperation, getPropertyValue, getPropertyBooleanValue, 
            loaded, getPropertyNumberValue, baseOnBeforeUnmount, fieldKey, fieldKeyComponentDataSourceId,
             fillDataSource ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);
        const hasLoadData = shallowRef(false);
        const expressions = shallowRef<IapExpression[]>([])        
        const { getFieldKeyColumn } = DataSourceComp(props.container as any, props, context.emit , {}, {} as any, [], store, {} as any)
        let dicTranslates : Dictionary<string,string>[] = []// jsonDataTranslates;//getPropertyValueObject(ExpressionControlTypeConst.DIC_TRANS) ?? []
        const externalTree = ref()
        // se filtran loscatálogos
        const catalogTypes = helperCatalog.getCatalogTypes(getPropertyValueObject(ExpressionControlTypeConst.CAT_FILTER) ?? []);
        // se añaden los catálogos para la traducción
       const keyExp = ref(HelperUtils.newGuid())

        const translateFormula = (expression:string| null) => {
            dicTranslates.forEach( (dic: Dictionary<string,string>)  =>{
                expression = HelperUtils.replaceAll(expression,dic.key,dic.value,true) ?? ''
            })

            return expression
        }

        const translateAll = () =>{
            expressions.value?.forEach( (exp:IapExpression)=>
            {
                exp?.iapExpressionDetails.forEach((d:IapExpressionDetail)=>
                {
                    d.expressionTranslated = translateFormula(d.expression) ?? ''
                })
                
            })
            
        }

        const convertToExpression = (data: [], datasource: IapComponentDataSource) => {
            
            const idExpression = HelperUtils.newGuid()
            let _auxExps = new Array()
            const _fields = getPropertyValue(ExpressionControlTypeConst.FIELDS_DEF);
            const _dsmap = getPropertyValue(ExpressionControlTypeConst.DSEXP_MAP);
            const dsmap = (JSON.parse(_dsmap ?? "[]") ?? []) as Dictionary<string, string>[]
            const fields = (JSON.parse(_fields ?? "[]") ?? []) as fieldDefinition[];

            if (dsmap.length > 0 && fields.length > 0 && datasource) {
                const _columnsIds =
                
                datasource?.dataSource.iapDataSourceFields.filter(x => x.dataSourceTableAliasId == null).map(x => ({
                id: x.id + '#'+ datasource?.id,
                field: getFieldKeyColumn(x,true)
            }))
                .concat(
                    datasource?.dataSource.iapDataSourceFields.filter(x => x.dataSourceTableAliasId != null).map(x => ({
                        id: x.id+ '#'+ datasource?.id,
                        field: getFieldKeyColumn(x)
                    }))

                )

               
               
                data.forEach(d => {
                    const expdata = {} as any
                    fields.forEach(f => {
                        
                        const dsfKey = dsmap.find(x => x.value == f.id)?.key
                        if (dsfKey) {

                            const fieldName = _columnsIds.find(x=> x.id == dsfKey)?.field
                            if (fieldName){
                                
                                    expdata[f.name] = d[fieldName] ;                                                              

                                    if (f.name =='expression'){
                                        expdata['expressionTranslated'] = d[fieldName] //translateFormula(d[fieldName])
                                    }
                            }
                            
                        }
                    })

                    expdata['expressionId'] = idExpression
                    
                    expdata['localizable'] = false

                    expdata['orignalRow'] = d
                    

                 
                     //@ts-ignore:disable-next-line
                     _auxExps.push(expdata)
                })

                expressions.value = []
               
                HelperUtils.addItemToShallowRefArray(expressions,  [{
                        id: idExpression,
                        applicationId: props.Component.applicationId,
                        applicationVersion: props.Component.applicationVersion,
                        idObjeto: '',
                        objetoId: '',
                        idObjetoRoot: null,
                        objetoIdRoot: null,
                        idTypeExpression: '',
                        group: '',
                        fcr: new Date(),
                        ucr: 0,
                        uum: 0,
                        fum: new Date(),
                        iapExpressionDetails: _auxExps as any
                }])

                _auxExps = []

            }

        }

        const convertExpressionToObject = (data: IapExpressionDetail, datasource: IapComponentDataSource, takeFromOriginalrow:boolean) => {
           
            const _fields = getPropertyValue(ExpressionControlTypeConst.FIELDS_DEF);
            const _dsmap = getPropertyValue(ExpressionControlTypeConst.DSEXP_MAP);
            const dsmap = (JSON.parse(_dsmap ?? "[]") ?? []) as Dictionary<string, string>[]
            const fields = (JSON.parse(_fields ?? "[]") ?? []) as fieldDefinition[];
            const _columnsIds = new Array();

            if (dsmap.length > 0 && fields.length > 0 && datasource) {
                const { getDefaultvalue } = DataSourceComp(props.container as any, props, {}, {}, datasource as any, [], store)
                datasource?.dataSource.iapDataSourceFields.filter(x => x.dataSourceTableAliasId == null).forEach(x => 
                _columnsIds.push(
                    {
                        id: x.id + '#'+ datasource?.id,
                        field: getFieldKeyColumn(x,true),
                        fieldExp:  fields.find(f => f.id == dsmap.find(d=> d.key == x.id + '#'+ datasource?.id)?.value)?.name,
                        fieldExpValue:data[fields.find(f => f.id == dsmap.find(d=> d.key == x.id + '#'+ datasource?.id)?.value)?.name ?? ''],
                        defaultValue:getDefaultvalue(x,datasource.iapComponentDataSourceFieldConfigurations?.find(cfg=>cfg.dataSourceFieldId==x.id)?.defaultValue,null,false)
                    } as any)
                )


                
                    datasource?.dataSource.iapDataSourceFields.filter(x => x.dataSourceTableAliasId != null).forEach(x => 
                    {                    
                        _columnsIds.push({
                        id: x.id+ '#'+ datasource?.id,
                        field: getFieldKeyColumn(x),
                        fieldExp:  fields.find(f => f.id == dsmap.find(d=> d.key == x.id + '#'+ datasource?.id)?.value)?.name,
                        fieldExpValue:data[fields.find(f => f.id == dsmap.find(d=> d.key == x.id + '#'+ datasource?.id)?.value)?.name ?? ''],
                        defaultValue:getDefaultvalue(x,datasource.iapComponentDataSourceFieldConfigurations?.find(cfg=>cfg.dataSourceFieldId==x.id)?.defaultValue,null,false)
                    } as any)
                })

                
                
              
                const _itemId = _columnsIds.find(x=> x.fieldExp.toLowerCase() == 'id')
                //const _itemVar = _columnsIds.find(x=> x.fieldExp.toLowerCase() == 'variable')
                if (_itemId){

                    if (takeFromOriginalrow){
                        const _dataRow =data['orignalRow'];// datasource.dataSource?.items.find(x=> x[_itemId.field] == _itemId.fieldExpValue && x[_itemVar.field] == _itemVar.fieldExpValue )
                        if (_dataRow){
                            _columnsIds.filter(x=> x.fieldExp && x.id !==  _itemId.id ).forEach(col =>{
                                if ( _dataRow[col.field]){
                                    _dataRow[col.field] = col.fieldExpValue
                                }                            
                            })

                            return _dataRow;
                        }
                    }
                    else
                    {                        
                        const aux :any = {}
                        _columnsIds.forEach(x=>
                        {
                            aux[x.field] = HelperCommon.isNullOrWhitespace(x.fieldExpValue) ? x.defaultValue :x.fieldExpValue
                        })

                        return aux;
                    }
                    

                }
        

            }

            return null;
            

        }

     
        const doOperationExternal = (_dataExp:IapExpressionDetail,operation:number) => {
            const datasource = props.Component.get(-1,'iapComponentDataSources').find(x=> x.id == getPropertyNumberValue(ExpressionControlTypeConst.DSEXP))
            if (datasource){

                const _data = convertExpressionToObject(_dataExp,datasource,(operation !== OperationDataTypeConst.INSERT))

                if (_data)
                {
                        const { getPrimaryKeys } = DataSourceUtilComp(datasource, store)
                
                        const _columnsIds =
                        datasource?.dataSource.iapDataSourceFields.filter(x => x.dataSourceTableAliasId != null && ((!x.primaryKey && operation !== OperationDataTypeConst.INSERT) || (operation == OperationDataTypeConst.INSERT)) ).map(x => ({
                                id: x.id,
                                field: getFieldKeyColumn(x),
                                tableName:(HelperCommon.isNullOrWhitespace(x.tableNameAlias ?? '') ? x.tableName: x.tableNameAlias ),
                                mustSerialize: (x.sqlType == SqlTypesConst.BIT) ?? false,
                                mustToString:(x.sqlType == SqlTypesConst.MONEY) ?? false
                            }))

                        

                        const tableName = _columnsIds.find(x=> x.tableName !== '')?.tableName ?? ''; //dsf?.tableName ?? '';
                        const pks = getPrimaryKeys(datasource.dataSource, _data as any, tableName as any)
                    
                        const ValuesToUpdate = _columnsIds.map(c =>({
                            fieldId: c.id,
                            value: c.mustSerialize ? JSON.stringify(_data[c.field]) as any :c.mustToString? _data[c.field].toString():_data[c.field] as any,//JSON.stringify(newValue) as any
                        })) 

                        const dataUpdate: dataUpdate = {
                            valores: (operation == OperationDataTypeConst.UPDATE || operation == OperationDataTypeConst.INSERT ? ValuesToUpdate as any : []),
                            claves: (operation == OperationDataTypeConst.UPDATE || operation == OperationDataTypeConst.DELETE ? pks as any : []),
                            tipoOperacion: operation,
                            id: HelperUtils.newGuid()
                        };

                        
                        const doOperationTransaction = dataUpdate.valores.length > 1;
                        const dataInputRequest:DataUpdateOperation={
                            componentId:datasource.componentId,
                            componentDataSourceId:datasource.id,
                            dataSourceId:datasource.dataSourceId,
                            parameters:[],
                            data:[dataUpdate]
                        }
                        HelperOperationData.doOperationData(props.container,datasource.applicationId,datasource.applicationVersion, [dataInputRequest], doOperationTransaction).then(result =>{
                            if(result.length > 0)
                            {
                                expressions.value = []
                                loadExpressions()
                            }
                        });
                }
            
        }
    }



const addDataToDictionary = (keyInput:string, valueInput:string) =>{
    if (dicTranslates.find(x=> x.key == keyInput) == undefined){
        dicTranslates.push({
                    key:keyInput,
                    value:valueInput
                } as Dictionary<string,string>)
    }    
}

    const loadCatalogTranslates  = () =>{
        catalogTypes.flatMap(x=> x.iapCatalogs).forEach(c=> {
            dicTranslates.push({
                key:c.id,
                value:c.description
            } as Dictionary<string,string>)
        } )
    }



    const fillToolsTree = (key:string, data:any | undefined = undefined) =>{
        if (key.toLowerCase() == 'variables')
        {
            const nodeVariables = externalTree.value.find(x=> x.key.toLowerCase() == 'variables')
            if (nodeVariables){
                nodeVariables.children = []
                expressions.value[0].iapExpressionDetails?.forEach( (exp:IapExpressionDetail) =>{
                nodeVariables.children.push({ key:exp.variable,data:{label:exp.variable,value:'[' + exp.variable + ']'}, children: []})
                //addDataToDictionary()
            })

            }
        }

    }

        const loadDictionaryFile = async (): Promise<boolean> => {


        return await new Promise<boolean>((resolve) => {

            const attrDictionary = props.Component.iapComponentAttributes.find(x=> x.name == ExpressionControlTypeConst.DIC_TRANS);

                if (attrDictionary && attrDictionary?.valueBinaryFileName)
                {
                    const fileName = Environment.URL_COMPONENT_ATTR + attrDictionary.id + "_" + attrDictionary.valueBinaryFileName;
                        fetchWrapper.getBinaryFile(fileName + '.zip' + '?_t=' + new Date().getTime().toString(),store)
                                .then((response: any) => 
                                {                            
                                    if (response)
                                    {
                                        HelperCompress.decompressFile(attrDictionary.id + "_" + attrDictionary.valueBinaryFileName, response)
                                        .then((responseData: string) => 
                                        {                                
                                            if (responseData){                                    
                                                dicTranslates = []
                                                dicTranslates = JSON.parse(responseData) as Dictionary<string,string>[]
                                                loadCatalogTranslates();
                                                
                                                responseData = ''                                
                                                resolve(true)
                                            }
                                            else{
                                                resolve(false)
                                            }
                                        })

                                    }
                                }).catch(() => {
                                    resolve(false)
                                })
                }
                else{
                    resolve(false)
                }
        });

        };


        const loadTreeObjectFile = async (): Promise<boolean> => {

            return await new Promise<boolean>((resolve) => {

                const attrDictionary = props.Component.iapComponentAttributes.find(x=> x.name == ExpressionControlTypeConst.DIC_TREE_OBJECT);

                    if (attrDictionary && attrDictionary?.valueBinaryFileName)
                    {
                        const fileName = Environment.URL_COMPONENT_ATTR + attrDictionary.id + "_" + attrDictionary.valueBinaryFileName;
                            fetchWrapper.getBinaryFile(fileName + '.zip' + '?_t=' + new Date().getTime().toString(),store)
                                    .then((response: any) => 
                                    {                            
                                        if (response)
                                        {
                                            HelperCompress.decompressFile(attrDictionary.id + "_" + attrDictionary.valueBinaryFileName, response)
                                            .then((responseData: string) => 
                                            {                                
                                                if (responseData){ 
                                                    
                                                    externalTree.value = JSON.parse(responseData)  as any                    
                                                    resolve(true)
                                                }
                                                else{
                                                    resolve(false)
                                                }
                                            })

                                        }
                                    }).catch(() => {
                                        resolve(false)
                                    })
                    }
                    else{
                        resolve(false)
                    }
            });

};



    const getColumnNamesFromXML = (data:string) =>{
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(data,"text/xml");
        const nodes =  xmlDoc?.childNodes[0]?.childNodes[0]?.childNodes
        const names = new Array()
        if (nodes){
            nodes.forEach(x =>{
                names.push(x.nodeName)
            })
        }
        else{
            return []
        }

        return names
    }





    const processDynamicTreeTools = () =>{
        
        const cdsTree = props.Component.get(-1,'iapComponentDataSources').find(x=> x.id == getPropertyNumberValue(ExpressionControlTypeConst.DSEXPTREE))
        if (cdsTree)
        {
            const { convertTreeToFlat } = ComponentUtil();
            const keys = externalTree.value.map(x=> x.key)
            const keysItems = Object.keys(cdsTree.dataSource?.items?.find(x=> x !== undefined) ?? {})
            keys.forEach(k =>{
                keysItems.filter(z => z.toLowerCase().endsWith('_' + k.toLowerCase())).forEach(kd =>{
                    const _items = cdsTree.dataSource?.items.map(i => i[kd])
                    _items.forEach( (itemData:any) =>{                        
                        const _data = JSON.parse(itemData)
                        HelperUtils.parseAllObjects(Array.isArray(_data) ? _data : [_data]);
                        let _node = externalTree.value.find(x=> x.key == k)
                        if (_node){
                            if (_data[k]){
                                _node.children = _node.children.concat(_data[k])
                            }
                            else{
                                _node.children = _node.children.concat(_data.find(u => u.key == k)?.children)
                            }
                            
                        }
                        (_data[k] ?? _data.find(u => u.key == k)?.children)?.forEach(q =>{
                            convertTreeToFlat(q,[]).forEach(p =>{                            
                            addDataToDictionary(p.key,p.data.label)
                        })
                        })
                        
                    })

                })
            })
            translateAll()
        }
    }

        
        const loadExpressions = () => {
            hasLoadData.value = false;
            if (props.container) {
                
                const cds = props.Component.get(-1,'iapComponentDataSources').find(x=> x.id == getPropertyNumberValue(ExpressionControlTypeConst.DSEXP))
                if (cds){

                    fillDataSource(cds).then((result)=>{
                        HelperLoading.showLoading()
                            //cds.dataSource.items=null;
                        //resolveDatasource(cds.id).then(result => {
                            
                            if (result) {
                                
                                convertToExpression(cds?.dataSource.items ?? [], cds)
                                
                                fillToolsTree('variables')
                                processDynamicTreeTools()
                            
                            
                                        
                            }
                        }) .finally(() => {
                            
                                HelperLoading.hideLoading()                        
                                hasLoadData.value = true
                                keyExp.value = HelperUtils.newGuid()
                            })
                    //})

                  

                }
                else{
                    MessageService.showMessage(MessageType.Aviso, '', 'No hay origen de datos de expresiones configurado.', true, false, false, '');
                }
              
            }

        };

        

        const  loadData = async () =>{
            var promises = new Array();
            let canDo = false;
            promises.push(loadDictionaryFile());
            promises.push(loadTreeObjectFile());
            
            const response = await Promise.all(promises).then(() => {
                loadExpressions();
            })
        }
        
        onMounted(() => {

            // carga inicial del diccionario de de las expresiones
            loadData();

        });

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })



        return {
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            ExpressionControlTypeConst,
            loaded,
            BaseControlTypeConst,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            , canDoOperation,
            catalogTypes,
            hasLoadData,
            loadExpressions,
            expressions,
            doOperationExternal,
            OperationDataTypeConst,
            translateFormula,
            externalTree,       
            keyExp     
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
