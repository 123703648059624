import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_CrudDocument = _resolveComponent("CrudDocument")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? (_openBlock(), _createElementBlock("div", { key: _ctx.componentKey }, [
          _withDirectives(_createVNode(_component_CrudDocument, {
            ref: "crudDocumentControl",
            rootParentId: _ctx.Component.rootParentId,
            container: _ctx.container,
            Component: _ctx.Component,
            slotProps: _ctx.slotProps,
            id: _ctx.getPropertyValue(_ctx.CrudDocumentTypeConst.ID),
            style: _normalizeStyle(_ctx.getPropertyValue(_ctx.CrudDocumentTypeConst.STYLE)),
            class: _normalizeClass(_ctx.getPropertyValue(_ctx.CrudDocumentTypeConst.CLASS)),
            name: _ctx.getPropertyValue(_ctx.CrudDocumentTypeConst.NAME),
            disabled: _ctx.getPropertyBooleanValue(_ctx.CrudDocumentTypeConst.DISABLED),
            visible: _ctx.getPropertyBooleanValue(_ctx.CrudDocumentTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW),
            placeholder: _ctx.getPropertyValue(_ctx.CrudDocumentTypeConst.PLACEHOLDER),
            caption: _ctx.getPropertyValue(_ctx.CrudDocumentTypeConst.CAPTION),
            fileExtensions: _ctx.getPropertyValue(_ctx.CrudDocumentTypeConst.FILEEXTENSIONS),
            maxFileSize: _ctx.getPropertyNumberValue(_ctx.CrudDocumentTypeConst.MAXFILESIZE),
            multiple: _ctx.getPropertyBooleanValue(_ctx.CrudDocumentTypeConst.MULTIPLE),
            idObject: _ctx.getPropertyValue(_ctx.CrudDocumentTypeConst.IDOBJECT),
            objectId: _ctx.getPropertyNumberValue(_ctx.CrudDocumentTypeConst.OBJECTID),
            captionDropArea: _ctx.getPropertyValue(_ctx.CrudDocumentTypeConst.CAPTIONDROPAREA),
            canAdd: _ctx.getPropertyBooleanValue(_ctx.CrudDocumentTypeConst.CANADD) && _ctx.canDoOperation(_ctx.ObjectGroupConst.ADD),
            canEdit: _ctx.getPropertyBooleanValue(_ctx.CrudDocumentTypeConst.CANADD) && _ctx.canDoOperation(_ctx.ObjectGroupConst.EDIT),
            canDelete: _ctx.getPropertyBooleanValue(_ctx.CrudDocumentTypeConst.CANADD) && _ctx.canDoOperation(_ctx.ObjectGroupConst.DELETE),
            onlyListDocument: _ctx.getPropertyBooleanValue(_ctx.CrudDocumentTypeConst.ONLYLISTDOCUMENT),
            showAddinPopUp: _ctx.getPropertyBooleanValue(_ctx.CrudDocumentTypeConst.SHOW_POPUP_ADD),
            entityMainIntegracionId: _ctx.getPropertyNumberValue(_ctx.CrudDocumentTypeConst.ENT_MAIN_INT_ID),
            source: _ctx.getPropertyValue(_ctx.CrudDocumentTypeConst.SOURCE)
          }, null, 8 /* PROPS */, ["rootParentId", "container", "Component", "slotProps", "id", "style", "class", "name", "disabled", "visible", "placeholder", "caption", "fileExtensions", "maxFileSize", "multiple", "idObject", "objectId", "captionDropArea", "canAdd", "canEdit", "canDelete", "onlyListDocument", "showAddinPopUp", "entityMainIntegracionId", "source"]), [
            [_vShow, _ctx.loaded && !_ctx.viewDetail && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE)],
            [_directive_tooltip, _ctx.getPropertyValue(_ctx.CrudDocumentTypeConst.TOOLTIP)]
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}