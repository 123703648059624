<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <Dropdown v-if="loaded && dataValue && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :key="componentKey"
        :id="getPropertyValue(DropDownTypeConst.ID)" v-tooltip="getPropertyValue(DropDownTypeConst.TOOLTIP)"
        :style="getPropertyValue(DropDownTypeConst.STYLE)"
        :class="{ [getPropertyValue(DropDownTypeConst.CLASS) ?? '']: true,  'p-invalid': v$.vmodel.$error && submitted,'p-readonly': getPropertyBooleanValue(DropDownTypeConst.READONLY) }"
        :name="getPropertyValue(DropDownTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(DropDownTypeConst.DISABLED) || (!canEdit)"
        :visible="getPropertyBooleanValue(DropDownTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :placeholder="getPropertyValue(DropDownTypeConst.PLACEHOLDER)"
        :options="dataValue"
        :filter="getPropertyBooleanValue(DropDownTypeConst.FILTER)"
        :filterPlaceholder="getPropertyValue(DropDownTypeConst.FILTERPLACEHOLDER)"
        :dataKey="getPropertyValue(DropDownTypeConst.DATAKEY)"
        :optionLabel="getPropertyValue(DropDownTypeConst.OPTIONLABEL)"
        :optionValue="getPropertyValue(DropDownTypeConst.OPTIONVALUE)"
        :optionDisabled="getPropertyValue(DropDownTypeConst.OPTIONDISABLED)"
        :optionGroupLabel="getPropertyValue(DropDownTypeConst.OPTIONGROUPLABEL)"
        :optionGroupChildren="getPropertyValue(DropDownTypeConst.OPTIONGROUPCHILDREN)"
        :showClear="getPropertyBooleanValue(DropDownTypeConst.CLEAR)" 
        :inputProps="getInputStyle"
        @change="processEventComponent(CatalogEventConst.CHANGE)"
        v-model="vmodel">
    </Dropdown>
    <CustomValidate v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE)" v-model:submitted="submitted" v-model:vObject="v$" />
</template>
<script lang="ts">


import { defineComponent, onMounted,  onBeforeUnmount, computed,shallowRef } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import DropDownTypeConst from '../../../../domain/Constants/DropDownTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import CustomValidate from '../../shared/CustomValidate.vue';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';	
import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';

export default defineComponent({
    name: 'dynamic_drop_down',
    components: {
        CustomValidate,
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },       
        slotProps: {
            type: Object,
            default: () => ({})
        },


    },
    setup(props, context) {
        //

        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue,getPropertyValueOption, getPropertyValueOptions, getPropertyBooleanValue, 
            loaded, baseOnMounted, baseOnBeforeUnmount, v$, submitted, 
            canEdit,isValidData,fieldKey,fieldKeyComponentDataSourceId,processEventComponent ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);

        const dataValue = shallowRef<any[] | undefined>(undefined)
      
        const getInputStyle = computed(() => {

        let inputStyleObj:any={};

        if(getPropertyBooleanValue(DropDownTypeConst.READONLY) ){
            inputStyleObj.readonly=true;
        }

        return inputStyleObj;
        })

        const loadData = () =>{
            getPropertyValueOptions(DropDownTypeConst.OPTIONS).then((response:any)=>{
                if (response){
                    dataValue.value = response;
                }
            })
        }


        onMounted(() => {
            baseOnMounted();
            loadData();
        })

        onBeforeUnmount(() => {
            dataValue.value = undefined;
            baseOnBeforeUnmount();
        })


        return {          
            getPropertyValue,
            getPropertyValueOptions,
            getPropertyBooleanValue,
            vmodel,
            DropDownTypeConst,
            loaded,
            BaseControlTypeConst,
            v$,
            submitted,
            canEdit,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            getPropertyValueOption,
            processEventComponent,
            CatalogEventConst,
            getInputStyle,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
            ,dataValue

        };
    },
});
</script>
<style scoped></style>
