import { ClassicPreset } from 'rete';
import { AreaPlugin } from 'rete-area-plugin';
import { AreaExtra, Schemes } from '../rete/helperRete';
import { LocalService, MessageService } from '../../../../../../../common/infrastructure/servicios';
import { Router } from 'vue-router';
import { Container } from 'inversify';
import { IapComponent } from '../../../../../component/domain/iapComponent';
import CatalogObjectTypeConst from '../../../../../catalog/domain/const/CatalogObjectTypeConst';
import CatalogExpConst from '../../../../../catalog/domain/const/CatalogExpConst';
import { IapWorkFlowActivityControl } from '../../../../domain/service/iapWorkFlowActivityControl';
import { ExpresionEngine } from '../../../../../expression/infrastructure/helper/expressionEngine';
import { IapWorkFlowActivity } from '../../../../domain/service/iapWorkFlowActivity';
import { GeneratorKeyControl } from '../controls/generatorKeyControl';
import { IapComponentAttribute } from '../../../../../component/domain/iapComponentAttribute';
import EventBusCustom from '../../../../../../../common/infrastructure/event-bus-custom';
import EventConst from '../../../../../../../common/domain/constantes/EventConst';
import CatalogAttrConst from '../../../../../catalog/domain/const/CatalogAttrConst';
import { DataflowEngine } from 'rete-engine';
import HelperUtils from '../../../../../../../common/infrastructure/funciones/HelperUtils';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';
import OperationDataTypeConst from '../../../../../../../common/domain/constantes/OperationDataTypeConst';
import { IServiceEncrypt } from '../../../../../../builderEncrypt/application/IServiceEncrypt';
import { TYPES } from '../../../../../../../common/domain/types';
import HelperLoading from '../../../../../../../common/infrastructure/funciones/HelperLoading';
import { GenerateOptions, GeneratePassword } from '../../../../../../../common/infrastructure/security';
import { MessageType } from '../../../../../../../common/infrastructure/servicios/MessageService';
import { Dictionary } from '../../../../../expression/domain/dictionary';
export class GeneratorKeyComponentNode extends ClassicPreset.Node<
  { ejecutar: ClassicPreset.Socket },
  { dataOutput: ClassicPreset.Socket },
  { value: GeneratorKeyControl }
> {
  height = 450;
  width = 380;


  private variableType: number;
  private variableKeyOptions: string | null;
  private variableKeyHash: boolean;
  private variableOutputData: string | null;
  private variableOutputDataHash: string | null;
  private area: AreaPlugin<Schemes, AreaExtra>;
  private updateNode: any;
  private getNodeInternalData: any;
  private showExpression: any;
  private rdControlId: '';
  private router: Router;
  private container: Container | undefined;
  private rootComponentId: number;
  private currentComponentId: number;
  private store: any;
  private activity: IapWorkFlowActivity | undefined;
  private componentData: IapComponent[];
  private dataflow: DataflowEngine<Schemes>
  private formKey: String;
  private currentElementKey: string;


  constructor(area: AreaPlugin<Schemes, AreaExtra>, socket: ClassicPreset.Socket, public formKeyInput: String,
    public currentElementKeyInput: string,
    public rootComponentInputId: number,
    public currentComponentInputId: number,
    public applicationId: number,
    public applicationVersion: number,
    public variableTypeInput: number,
    public variableKeyOptionsInput: string | null,
    public variableKeyHashInput: boolean,

    updateNode: any = undefined,
    getNodeInternalData: any = undefined,
    showExpressionFunction: any = undefined, router: Router, container: Container | undefined, storeInput: any, itemActivity: IapWorkFlowActivity | undefined, dataflow: DataflowEngine<Schemes>, componentDataInput: IapComponent[]) {
    super("Key Generator Component");
    this.area = area;
    this.dataflow = dataflow;
    this.updateNode = updateNode;
    this.getNodeInternalData = getNodeInternalData;
    this.showExpression = showExpressionFunction;
    this.variableType = variableTypeInput;
    this.variableKeyOptions = variableKeyOptionsInput;
    this.router = router;
    this.container = container;
    this.rootComponentId = rootComponentInputId;
    this.currentComponentId = currentComponentInputId;
    this.store = storeInput;
    this.activity = itemActivity;
    this.variableOutputData = null;
    this.variableOutputDataHash = null;
    this.variableKeyHash = variableKeyHashInput;
    this.componentData = componentDataInput;
    this.formKey = formKeyInput;
    this.currentElementKey = currentElementKeyInput;
    const dsControl = new GeneratorKeyControl(formKeyInput, rootComponentInputId, currentComponentInputId, applicationId, applicationVersion, variableTypeInput, variableKeyOptionsInput, variableKeyHashInput, container, storeInput, itemActivity, this.updateData, this.getNode, this.showExp);

    this.rdControlId = (dsControl as any).id;

    this.addInput("ejecutar", new ClassicPreset.Input(socket, "Ejecutar", true));
    this.addControl(
      "value",
      dsControl
    );


    this.addOutput("dataOutput", new ClassicPreset.Output(socket, "DataOutput", true));


  }

  showExp = (evt: any) => {
    if (this.showExpression) {
      return this.showExpression(evt)
    }
    return null;
  }

  getNode = (key: string) => {
    if (this.getNodeInternalData) {
      return this.getNodeInternalData(this.id, key, true, false)
    }
    return null;
  }
  updateData = (evt: any) => {

    //this.value = evt
    //@ts-ignore:disable-next-line
    this.controls.value[evt.key] = evt.data;
    //this.controls[evt.key].valueConnection = evt.data;


    this.area.update("control", this.rdControlId)


    if (this.updateNode) {
      this.updateNode(this.id, evt.key, evt.data, (evt?.operation ?? OperationDataTypeConst.UPDATE))
    }


  }

  /*
  resolveExpressions = () =>{
    const currentComp = this.componentData.find(x => x.id == this.currentComponentId)
        
        const WfData = currentComp?.workFlows.find(w => w.id == this.activity?.workFlowId )

        this.activity?.iapWorkFlowActivityControls.forEach((wcf: IapWorkFlowActivityControl) => {

          if (currentComp && wcf) 
          {
                       
              const keyControl = CatalogObjectTypeConst.EVTCOMP + '#' + WfData?.objetoId + '#' + wcf.id.toString();
              
              const exps = currentComp.expressions?.filter(x =>
                x.idObjeto == CatalogObjectTypeConst.WFAC
                && x.idTypeExpression == CatalogExpConst.EXP_SET
                && x.iapExpressionDetails?.length > 0
                && x.objetoId == keyControl);
              if (exps?.length > 0) 
              {
                exps?.every(exp => {
                  if (exp.iapExpressionDetails?.length > 0) {
                    const localData = LocalService.getValue(this.formKey + LocalService.COMPONENTS_EXP + (this.rootComponentId ?? -1).toString());
                    const data = HelperUtils.jsonParse(localData,[])
                    let resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, this.store)
                    //resu = resu?.toString();
                    if (resu) {
                      if (Object.keys(resu).length == 0) {
                        resu = resu?.toString();
                      }
                    }

                    switch(wcf.name) {
                      case EncryptComponentNodeConst.VAR_DATA:
                        // code block
                        this.variableDataToEncrypt = resu
                        break;
                      case EncryptComponentNodeConst.VAR_ENCODEURL:
                        this.variableDataEncodeUrl = resu;
                        // code block
                        break;
                     
                      default:
                        // code block
                    }

                  }
                })

              }
  
          }

        })
    
  }
  */

  async execute(input: "ejecutar", forward: (output: "dataOutput") => void) {

    /*
    const inputs = (await this.dataflow.fetchInputs(this.id)) as {
      dataInput: any;
    };

    if (inputs && inputs?.dataInput && inputs.dataInput?.length==1)
    {
      let auxData: any = null;
      const data = inputs.dataInput[0]
      if (data && this.variableDataToEncrypt?.includes('#') ){
    
          //@ts-ignore:disable-next-line
          const keyParam = param.value.replaceAll('#','');
          this.variableDataToEncrypt = HelperUtils.propValue(data,keyParam)?.toString() ?? ''

      }
     
    }
    */

    // vamos a buscar las expresiones si las hubiera

    //this.resolveExpressions();

    // fin de resolver las expresiones
    
    let  _opts : GenerateOptions | null = null;
    // se genera local
    if (!this.variableKeyHash) {
      // generamos un guid
      switch (this.variableType) {
        case 0:
          // generamos un guid
          this.variableOutputData = HelperUtils.newGuid();
          forward('dataOutput');
          break;
        case 1:
          // generamos un pwd
          if (this.variableKeyOptions != null) {
             _opts = JSON.parse(this.variableKeyOptions) as GenerateOptions;
            try {
              this.variableOutputData = GeneratePassword(_opts)
            }
            catch (err: any) {
              MessageService.showMessage(MessageType.Error, 'Error de validación', err.message);
            }

          }

          forward('dataOutput');
          break;

        default:
          forward('dataOutput');
          break;
      }
    }
    else {
      if (this.container) {
        HelperLoading.showLoading()

        
        if (this.variableKeyOptions != null && this.variableType == 1) {
          _opts = JSON.parse(this.variableKeyOptions) as GenerateOptions;

        }
        const _srv = this.container.get<IServiceEncrypt>(TYPES.ENCRYPT_SERVICE)
        _srv.generateKey(this.variableType,_opts,this.variableKeyHash)
          .then((response:Dictionary<string,string>) => {
            this.variableOutputData = response.key;
            this.variableOutputDataHash = response.value;
          })
          .finally(() => {
            HelperLoading.hideLoading();
            forward('dataOutput');
          })
      }
      else {
        forward('dataOutput');
      }
    }


  }




  data() {
    return {
      dataOutput: {
        key: this.variableOutputData || "",
        keyhash: this.variableOutputDataHash || ""

      }

    };
  }

}