<template>
    <i class="fa fa-microphone" :style="{ color: gerIconColor }" @click="toggleMic"></i>
</template>
<script lang="ts">

import { computed, defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
    name: 'miccomponent',
    props: {
        modelValue: {
            type: String
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const isRecording = ref(false)
        const Recognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition
        const sr = new Recognition()

        const transcript = computed({
            get: () => props.modelValue,
            set: (val) => emit('update:modelValue', val),
        });


        const gerIconColor = computed(() => {
            return isRecording.value ? 'red' : 'green'
        })



        const toggleMic = () => {
            if (isRecording.value) {
                sr.stop()
            } else {
                sr.start()
            }
        }


        onMounted(() => {
            sr.continuous = true
            sr.interimResults = true

            sr.onstart = () => {
                
                isRecording.value = true
            }

            sr.onend = () => {
                
                isRecording.value = false
            }

            sr.onresult = (evt: any) => {
                /*
                for (let i = 0; i < evt.results.length; i++) {
                    const result = evt.results[i]
        
                    if (result.isFinal) CheckForCommand(result)
                }
                */

                const t = Array.from(evt.results as [])
                    .map(result => result[0])
                    .map(result => (result as any).transcript)
                    .join('')

                transcript.value = t
            }
        })

        return {
            toggleMic,
            gerIconColor
        };
    },
});
</script>
<style scoped></style>
