<template>
    <div v-show="isLoadedKeepAlive">
        <div v-if="showComponentForm && componentInputId != -1">
            <Dialog v-if="showComponentFormDialog" v-model:visible="componentInputModal" maximizable modal
                styleClass="mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6"
                contentStyleClass="border-round-bottom border-top-1 surface-border p-0" @hide="backToOtherComponent($event)">
                <template #header>
                    <span v-html="componentInputHeader"></span>
                </template>

                <form_builder :container="container" :componentId="componentInputId" :applicationId="applicationId"
                    :applicationVersion="applicationVersion" :preview="preview" :attrsInput="componentAttributesList"
                    :canDestroy="true" @close="backToOtherComponent($event)" />


            </Dialog>
            <div v-else-if="componentInputId != -1" class="grid">
                <div class="col-12">
                    <div class="box-panel shadow-5 flex">
                        <span v-html="componentInputHeader"></span>
                        <Button style="cursor:pointer" icon="pi pi-arrow-left" v-tooltip="'Volver'"
                            class="mt-3 ml-auto p-button-rounded p-button-secondary p-button-outlined"
                            @click="backToOtherComponent($event)" />
                    </div>
                </div>

                <div class="col-12 lg:col-12">

                    <form_builder :container="container" :componentId="componentInputId" :applicationId="applicationId"
                        :applicationVersion="applicationVersion" :preview="preview"
                        :attrsInput="componentAttributesList" :canDestroy="true" @close="backToOtherComponent($event)" />


                </div>
            </div>
        </div>


        <div v-show="(!showComponentForm || showComponentFormDialog) && isLoaded">


            <keep-alive v-if="isKeepAlive">
                <component :is="DynamicComponent" v-model:comps="comps" :container="container"
                    :rootParentId="componentId" :formKey="formKey" :lookUpComps="lookUpComps" />

            </keep-alive>
            <component v-else :is="DynamicComponent" v-model:comps="comps" :container="container"
                :rootParentId="componentId" :formKey="formKey" :lookUpComps="lookUpComps" />



        </div>
        <Dock v-if="pendingOperations.length > 0" :model="itemsDock" :position="'right'">
            <template #item="{ item }">
                <div class="p-overlaybadge" data-pc-name="overlaybadge" data-pc-section="root" pc16="">
                    <Button v-tooltip.left="item.label" :icon="item.icon" :severity="item.severity"
                        @click="onDockItemClick($event, item)" style="opacity: 0.6;" />
                    <span v-if="item.badge" class="p-badge p-component p-badge-circle" data-pc-name="pcbadge"
                        data-pc-extend="badge" data-pc-section="root">{{ item.badge }}</span>
                </div>
            </template>
        </Dock>
    </div>
</template>
<script lang="ts">

import { Container } from 'inversify';
import { computed, defineAsyncComponent, defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, onUnmounted, ref, shallowRef, triggerRef } from 'vue';
import { useStore } from 'vuex';
import Environment from '../../../../common/infrastructure/funciones/environment';
import { TYPES } from '../../../../common/domain/types';
import HelperLoading from '../../../../common/infrastructure/funciones/HelperLoading';
import { LocalService } from '../../../../common/infrastructure/servicios';
import { IServiceComponent } from '../../component/application/IServiceComponent';
import { IapComponent } from '../../component/domain/iapComponent';
import ComponentDataForm from '../../designer/domain/ComponentDataForm';
import DataPendingUpdate from '../../designer/domain/dataPendingUpdate';
import ExpressionNomenclatorConst from '../../expression/domain/const/ExpressionNomenclatorConst';
import ExpressionToolConst from '../../expression/domain/const/ExpressionToolConst';
import { Dictionary } from '../../expression/domain/dictionary';
import { fetchWrapper } from '../../../../common/infrastructure/funciones/helperFetch';
import CatalogObjectTypeConst from '../../catalog/domain/const/CatalogObjectTypeConst';
import helperCatalog from '../../catalog/infrastructure/helper/helperCatalog';
import BaseControlTypeConst from '../domain/Constants/BaseControlTypeConst';
import ControlTypeConst from '../domain/Constants/ControlTypeConst';
import EventBusCustom from '../../../../common/infrastructure/event-bus-custom';
import EventConst from '../../../../common/domain/constantes/EventConst';
import { IapComponentAttribute } from '../../component/domain/iapComponentAttribute';
import { InteractionEvent } from '../../interaction/domain/interactionEvent';
import InteractionConst from '../../interaction/domain/interactionConst';
import HelperCompress from '../../../../common/infrastructure/funciones/HelperCompress';
import AppCache from '../../../../common/infrastructure/funciones/appCache';
import ComponentTypeConst from '../domain/Constants/ComponentTypeConst';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';
import CatalogEventConst from '../../catalog/domain/const/CatalogEventConst';
import { IapComponentDataSource } from '../../component/domain/iapComponentDataSource';
import ComponentRenderHelper from '../domain/Functions/ComponentRenderHelper';
import HelperCommon from '../../../../common/infrastructure/funciones/HelperCommon';
import CatalogExpConst from '../../catalog/domain/const/CatalogExpConst';
import CatalogAttrConst from '../../catalog/domain/const/CatalogAttrConst';
import { useRouter } from 'vue-router';
import CompsQueue from '../../../../common/infrastructure/funciones/compsQueue';
import OperationDataTypeConst from '../../../../common/domain/constantes/OperationDataTypeConst';
import dataBinary from './data/dataBinary';
import HelperNavigation from '../../../../common/infrastructure/funciones/HelperNavigation';
import ComponentHelperRender from '../domain/Functions/ComponentHelperRender';
import SearchFormEvtConst from '../domain/Constants/SearchFormEvtConst';

export default defineComponent({
    name: 'form_builder',
    components: {


    },
    emits: ['close','searchFormChanges'],
    props:
    {
        container: {
            type: Object as () => Container
        },
        componentId: {
            type: Number,
            defaultValue: (-1)
        },
        applicationId: Number,
        applicationVersion: Number,
        preview: {
            type: Boolean,
            defaultValue: (false)
        },
        attrsInput: {
            type: Object as () => IapComponentAttribute[],
            defaultValue: ([])
        },
        canDestroy: {
            type: Boolean,
            defaultValue: (false)
        },
        menuId:{
            type: Number,
            defaultValue: (-1)
        }

    },
    setup(props, context) {
        
        const getFormKey = () =>{
            if ((props?.menuId ?? -1) > 0 && (props?.componentId ?? -1) > 0 ){
                const _formLoadedKey = HelperNavigation.getComponentKeyLoaded(props?.componentId ?? -1)
                if (!HelperCommon.isNullOrWhitespace(_formLoadedKey ?? '')){
                    return _formLoadedKey;
                }
            }
            return HelperUtils.newGuid();
        }

        const formKey = getFormKey()
        const store = useStore();
        const router = useRouter();
        const showComponentForm = shallowRef(false);
        const componentInputModal = shallowRef(false);
        const componentInputHeader = shallowRef('');
        const componentInputId = shallowRef(-1);
        const componentAttributesList = ref<IapComponentAttribute[]>([]);
        const showComponentFormDialog = shallowRef(false);
        const lookUpComps: { compId: number, iapComponentAttribute: IapComponentAttribute | null }[] = ([]);
        const keyEventComponent = formKey + (props?.componentId ?? -1).toString() + '_' + EventConst.SHOWCOMPONENT;
        const keyEventComponentRoot = formKey + (props?.componentId ?? -1).toString() + EventConst.INTERACTION;
        const evtDisplayForm = ref()
        const comps = shallowRef<ComponentDataForm[]>([]);
        const pendingOperations = shallowRef<DataPendingUpdate[]>([]);

        let hasLocalFiles = false;

        const isKeepAlive = shallowRef(false);
        const isLoaded = shallowRef(false);

        const isLoadedKeepAlive = shallowRef(true);

        const pendingLength = computed(() => {            
            return pendingOperations.value.length;
        })

     
        const itemsDock = computed(() => {
            return [               
                {
                    label: 'Cancelar',
                    icon: 'pi pi-times',
                    severity: 'danger',
                    badge: '',
                    command: () => {
                        doOperations( ComponentTypeConst.CMD_BEFORE_CANCEL,cancelPendingOperations);
                    }
                },
                {
                    label: 'Guardar',
                    icon: 'pi pi-save',
                    badge: pendingLength.value.toString(),
                    severity: "",
                    command: () => {
                        doOperations( ComponentTypeConst.CMD_BEFORE_SAVE,savePendingOperations);                        
                    }

                }
            ]
        })


        const doOperations = (attrName: string, _doCallBackOperation: any = undefined) => {    
            const rootComp = comps.value.find(x => x.id == (props.componentId ?? -1));
            if (rootComp) {

                const attr = rootComp.iapComponentAttributes.find(x => x.name == attrName);
                const hasWf = rootComp.workFlows?.find(x => x.idObjeto == CatalogObjectTypeConst.ATTRCOMP && x.objetoId == attr?.id.toString() && x.active == true)
                if (attr && hasWf) {                    
                    var data: Partial<InteractionEvent> = {
                        objectId: attr.id,
                        typeId: InteractionConst.CALL_FUNCTION,
                        objectValue: attr.id,
                        objectName: 'processeventattr()'

                    }

                    const keyComponentEventBus = formKey + (props.componentId ?? -1).toString() + (props.componentId ?? -1).toString() + EventConst.INTERACTION;



                    var doCallbackOk = (response: InteractionEvent) => {                        
                        if (_doCallBackOperation) {
                            _doCallBackOperation();
                        }
                    }

                    EventBusCustom.emit(keyComponentEventBus, { data: data, callBackResponse: doCallbackOk })

                }
                else{
                    if (_doCallBackOperation) {
                    _doCallBackOperation();
                }
                }
                
            }
            else {
                if (_doCallBackOperation) {
                    _doCallBackOperation();
                }
            }



        }

        const cancelPendingOperations = () => {

            const totalOperations = pendingOperations.value.length;
            let _totalOperations=0;  

            pendingOperations.value.forEach(el => {


                var data: Partial<InteractionEvent> = {
                    objectId: el.componentId.toString(),
                    typeId: InteractionConst.SET_VMODEL,
                    objectValue: el.oldValue,
                    objectName: null

                }


                const keyComponentEventBus = el.formKey + props.componentId?.toString() + el.componentId.toString() + EventConst.INTERACTION;


                var doCallbackOk = (response: InteractionEvent) => {
                    if (response.interactionResult == el.oldValue) {
                        el.deleted = true;
                        
                    }
                    _totalOperations++;
                    if (_totalOperations >= totalOperations){
                        doOperations( ComponentTypeConst.CMD_AFTER_CANCEL);
                    }
                }

                EventBusCustom.emit(keyComponentEventBus, { data: data, callBackResponse: doCallbackOk })

            })




        }


        const savePendingOperations = () => {


            const cds  = comps.value.flatMap(x => x.iapComponentDataSources).filter( x=> pendingOperations.value.map(item => item.componentDataSourceId).includes(x.id));
            const totalOperations =  cds.map(item => item.componentId)
                .filter((value, index, self) => self.indexOf(value) === index).length;

            let _totalOperations=0;   

            cds.map(item => item.componentId)
                .filter((value, index, self) => self.indexOf(value) === index).forEach(currentComponentId =>{

                    var data: Partial<InteractionEvent> = {
                    objectId: currentComponentId,
                    typeId: InteractionConst.CALL_FUNCTION,
                    objectValue: {
                    cds:cds.filter(x=> x.componentId == currentComponentId).sort((a, b) => { return (a.order ?? 0) - (b.order ?? 0); }),
                    operation:OperationDataTypeConst.UPDATE,
                    expressions:comps.value.find(c => c.id == currentComponentId)?.expressions,
                    trans:cds.filter(x=> x.componentId == currentComponentId).length>1,
                    cdsFilter:buildPendingOperationsDictionary(cds.filter(x=> x.componentId == currentComponentId).sort((a, b) => { return (a.order ?? 0) - (b.order ?? 0); }))
                    },
                    objectName: 'doOperationDataMassive()'

                }


                const keyComponentEventBus = formKey + (props.componentId ?? -1).toString() + currentComponentId.toString() + EventConst.INTERACTION;


                var doCallbackOk = (response: InteractionEvent) => {
                    if (response.interactionResult == true) {
                        const idsCds =  response.objectValue.cds.map(x=> x.id);
                        pendingOperations.value = pendingOperations.value.filter(x=> !idsCds.includes(x.componentDataSourceId))
                        
                    }
                    _totalOperations++;
                    if (_totalOperations >= totalOperations){
                        doOperations( ComponentTypeConst.CMD_AFTER_SAVE);
                    }
                }

                EventBusCustom.emit(keyComponentEventBus, { data: data, callBackResponse: doCallbackOk })

                })

        }

        const buildPendingOperationsDictionary = (cds:IapComponentDataSource[]) =>{

            return pendingOperations.value.filter(x=> cds.map(c=> c.id).includes(x.componentDataSourceId)).map(x=>
            ({
                key:x.componentDataSourceId,
                value:x.fieldId

            }) as Dictionary<number,String>)
        }


        const DynamicComponent = computed(() => {
            if (comps.value.length > 0 && isLoaded.value) {
                if (hasLocalFiles) {
                    HelperLoading.hideLoading()
                    //return ComponentRenderHelper.BuildComponent(props.componentId ?? -1, comps.value)
                    return defineAsyncComponent({
                        loader: () => import(`./data/${props.componentId}.vue`),
                        //errorComponent: ComponentRenderHelper.BuildComponent(props.componentId ?? -1, comps.value),
                    });

                }
                else {
                    return ComponentRenderHelper.BuildComponent(props.componentId ?? -1, comps.value/*,false (probar no dinamicos. Estatica)*/)
                }
            }
            else {
                return null
            }

        })

        const setKeepAlive = () => {

            if (comps.value?.length > 0) {
                const attrKeepAlive = comps.value[0]?.iapComponentAttributes?.find(x => x.name == ComponentTypeConst.KEEP_ALIVE);
                if (attrKeepAlive) {
                    isKeepAlive.value = (attrKeepAlive.value ?? 'false').toLowerCase() == 'true'
                }

            }

            isKeepAlive.value = false;
        }


        const buildDictionary = (comps: IapComponent[]) => {

            let dic: Dictionary<String, Object>[] = ([])
            //const expcab = comps.flatMap(x => x.expressions).filter(x => x).map(x => x.objetoId)
            const exptext = comps.flatMap(x => x.expressions).filter(x => x !== null).flatMap(x => x.iapExpressionDetails).filter(x => x !== null).map(x => x.expression).join("")
            const match = exptext.match(/\[.*?\]/g)

            if (match && match?.length > 0) {

                const userData = [
                    {
                        key: [ExpressionNomenclatorConst.EXPNOM_USER, ExpressionToolConst.EXPTOOL_USRID].join('.'),
                        value: store.getters.getCurrentUser.id
                    },
                    {
                        key: [ExpressionNomenclatorConst.EXPNOM_USER, ExpressionToolConst.EXPTOOL_NOMB].join('.'),
                        value: store.getters.getCurrentUser.fullName
                    },
                    {
                        key: [ExpressionNomenclatorConst.EXPNOM_USER, ExpressionToolConst.EXPTOOL_EM].join('.'),
                        value: store.getters.getCurrentUser.userName
                    },
                    {
                        key: [ExpressionNomenclatorConst.EXPNOM_USER, ExpressionToolConst.EXPTOOL_PROFILE].join('.'),
                        value: store.getters.getCurrentProfile
                    },
                    {
                        key: [ExpressionNomenclatorConst.EXPNOM_USER, ExpressionToolConst.EXPTOOL_LASTSESSION].join('.'),
                        value: HelperCommon.formateaFechaHoraLarga(store.getters.getCurrentUser.lastConnection)
                    },
                    {
                        key: [ExpressionNomenclatorConst.EXPNOM_USER, ExpressionToolConst.EXPTOOL_TRYFAIL].join('.'),
                        value: store.getters.getCurrentUser.loginTries
                    }
                    ,
                    {
                        key: [ExpressionNomenclatorConst.EXPNOM_USER, ExpressionToolConst.EXPTOOL_MAINENTITYID].join('.'),
                        value: store.getters.getCurrentUser.entityMainCurrentId
                    },
                    {
                        key: [ExpressionNomenclatorConst.EXPNOM_USER, ExpressionToolConst.EXPTOOL_PROFILETYPE].join('.'),
                        value: store.getters.getCurrentProfileType
                    }
                ]



                const clearKeys = match.map(x => x?.replaceAll('[', '').replaceAll(']', ''))

                dic = dic.concat(userData.filter(x => clearKeys.includes(x.key)));



                const atts = comps.flatMap(x => x.iapComponentAttributes).filter(x => clearKeys.includes([ExpressionNomenclatorConst.EXPNOM_ATTRIBUTES, x.id].join('.'))).map(x => ({
                    key: [ExpressionNomenclatorConst.EXPNOM_ATTRIBUTES, x.id].join('.'),
                    value: HelperUtils.resolveAttrValue(x.idDataType, x.value)
                }) as Dictionary<String, Object>);

                dic = dic.concat(atts.flatMap(x => x))

                const ds = comps.flatMap(x => x.iapComponentDataSources).filter(x => clearKeys.includes([ExpressionNomenclatorConst.EXPNOM_DATASOURCE, x.id, x.dataSourceId, x.componentId].join('.'))).map(x => ({
                    key: [ExpressionNomenclatorConst.EXPNOM_DATASOURCE, x.id, x.dataSourceId, x.componentId].join('.'),
                    value: x.dataSource.items
                }) as Dictionary<String, Object>);

                dic = dic.concat(ds.flatMap(x => x))

                comps.flatMap(x => x.iapComponentDataSources).forEach(dsf => {
                    const newdsf = dsf.dataSource.iapDataSourceFields.filter(x => clearKeys.includes([ExpressionNomenclatorConst.EXPNOM_DSF, dsf.id, x.id].join('.'))).map(x => ({
                        key: [ExpressionNomenclatorConst.EXPNOM_DSF, dsf.id, x.id].join('.'),
                        value: null as any
                    }) as Dictionary<String, Object>)

                    dic = dic.concat(newdsf.flatMap(x => x))

                    dsf.dataSource.iapDataSourceFields.filter(x => x.dataSourceLookUpId != null)
                        .map(x => ({ dataSourceFieldId: x.id, dataSourceLookUpId: x.dataSourceLookUpId, iapDataSourceFields: x.dataSourceLookUp?.dataSource.iapDataSourceFields }))
                        .forEach(x => {
                            const newdsflk = x.iapDataSourceFields?.filter(dsf => clearKeys.includes([ExpressionNomenclatorConst.EXPNOM_DSLK, x.dataSourceLookUpId, dsf.id].join('.'))).map(dsf => ({
                                key: [ExpressionNomenclatorConst.EXPNOM_DSLK, x.dataSourceLookUpId, dsf.id].join('.'),
                                value: null as any
                            }) as Dictionary<String, Object>)

                            if (newdsflk) {
                                dic = dic.concat(newdsflk.flatMap(x => x))
                            }
                        })

                    const newdssc = dsf.dataSource.iapDataSourceServiceConfigurations.filter(x => clearKeys.includes([ExpressionNomenclatorConst.EXPNOM_DSSC, dsf.id, x.id].join('.'))).map(x => ({
                        key: [ExpressionNomenclatorConst.EXPNOM_DSSC, dsf.id, x.id].join('.'),
                        value: null as any
                    }) as Dictionary<String, Object>)

                    dic = dic.concat(newdssc.flatMap(x => x))
                });


                const catalogData = builtCatalogDictionary();

                //metemos catalogos
                dic = dic.concat(catalogData.filter(x => clearKeys.includes(x.key)));


            }

            dic = dic.filter((a, i) => dic.findIndex((s) => a.key === s.key) === i);
            LocalService.setValue(formKey + LocalService.COMPONENTS_EXP + (props?.componentId ?? -1).toString(), JSON.stringify(dic))
            dic = []
        }


        const setAttributesInput = (comps: IapComponent[]) => {
            if (props.attrsInput) {
                const clearKeys = props.attrsInput.map(x => x.id);
                const atts = comps.flatMap(x => x.iapComponentAttributes).filter(x => clearKeys.includes(x.id)).map((attr: IapComponentAttribute) => {
                    const itemAttr = props.attrsInput?.find(x => x.id == attr.id);
                    if (itemAttr) {
                        attr.value = itemAttr.value
                    }

                });

            }

        }




        const builtCatalogDictionary = () => {

            return helperCatalog.getAllCatalogApp().filter(x => x != null).flatMap(x => x.iapCatalogs).map(x => ({ key: [ExpressionNomenclatorConst.EXPNOM_CATALOG, x.id].join('.'), value: x.id }));

        };


        const getAllChildren = (componentId: number, out: ComponentDataForm[] | null = null) => {
            let outPut: ComponentDataForm[]
            if (out == null) {
                outPut = [];
            }
            else {
                outPut = out;
            }

            comps.value.filter(x => x.parentId == componentId).sort((a, b) => { return (a.order ?? 0) - (b.order ?? 0); }).forEach(c => {
                outPut.push(c)
                getAllChildren(c.id, outPut)
            })
            return outPut;
        }
        const setComponentData = (componentId: number, objectTypeId: string, value: object) => {

            switch (objectTypeId) {

                case 'addpendingoperations':
                    if (value) {                        
                        const _item = pendingOperations.value.find(x => x.componentId == (value as DataPendingUpdate).componentId && x.fieldId == (value as DataPendingUpdate).fieldId);
                        if (_item) {
                            if (_item.deleted || _item.oldValue == (value as DataPendingUpdate).newValue) {
                                pendingOperations.value = pendingOperations.value.filter(x => x.componentId !== _item.componentId && x.fieldId !== _item.fieldId);
                            }
                            else {
                                _item.newValue = (value as DataPendingUpdate).newValue;
                            }

                        }
                        else {
                            pendingOperations.value.push(value as any);
                        }
                        triggerRef(pendingOperations)

                    }

                    break;
                case 'iapComponentDataSources':
                    // code block
                    let ds: IapComponentDataSource[] = ([])
                    if (componentId == -1) {
                        ds = comps.value.flatMap(x => x.iapComponentDataSources)


                    }
                    else {
                        const comp = comps.value.find(x => x.id == componentId)

                        if (comp && comp['iapComponentDataSources']) {
                            ds = comp.iapComponentDataSources
                        }

                    }

                    if (value) {
                        ds.forEach(x => {
                            const newds = (value as IapComponentDataSource[]).find(v => v.id == x.id)
                            if (newds) {
                                x = newds;
                            }
                        })
                    }
                    else {
                        ds.forEach(x => {
                            x = null as any;
                        });
                    }


                    return true;
                case 'iapComponentAttributes':

                    // code block
                    const comp = comps.value.find(x => x.id == componentId)

                    if (comp) {
                        comp.iapComponentAttributes = value as any
                    }

                    return true;
                case 'loaded':
                    // code block

                    /*
                                            const index = comps.value.findIndex(x => x.id == componentId)
                                            comps.value = [
                                                    {
                                                        ...comps.value[index],
                                                        loaded: value as any
                    
                                                    },
                                                    ...comps.value.slice(index + 1)
                                                ]
                    */



                    const compl = comps.value.find(x => x.id == componentId)
                    if (compl) {
                        compl.loaded = value as any
                    }


                    return true;
                /*case 'iapComponentAttributes':
                    // code block
                    if (componentId == -1) {
                        return comps.value.flatMap(x => x.iapComponentAttributes)
                    }
                    else {
                        return comps.value.find(x => x.id == componentId)?.iapComponentAttributes
                    }

                  case 'workFlows':
                          // code block
                          if (componentId== -1){
                              return comps.value.flatMap(x=> x.workFlows)                    
                          }
                          else{
                              return comps.value.find(x=> x.id == componentId)?.workFlows
                          }       
                  
                      case 'expressions':
                          // code block
                          if (componentId== -1){
                              return comps.value.flatMap(x=> x.expressions)                    
                          }
                          else{
                              return comps.value.find(x=> x.id == componentId)?.expressions
                          }   
                      case 'objectGroups':
                       // code block
                          if (componentId== -1){
                              return comps.value.flatMap(x=> x.objectGroups)                    
                          }
                          else{
                              return comps.value.find(x=> x.id == componentId)?.objectGroups
                          }   
                          case 'IapComponent':
                       // code block
                          if (componentId== -1){
                              return comps.value[0]                    
                          }
                          else{
                              return comps.value.find(x=> x.id == componentId)
                          }   
                      */

                default:
                    return false;
            }


        }

        const getComponentData = (componentId: number, objectTypeId: string) => {

            switch (objectTypeId) {
                case 'iapComponentDataSources':
                    if (componentId == -1) {
                        return comps.value.flatMap(x => x.iapComponentDataSources)
                    }
                    else {
                        return comps.value.find(x => x.id == componentId)?.iapComponentDataSources
                    }
                case 'children':
                    // code block
                    return comps.value.filter(x => x.parentId == componentId);
                case 'allchildren':
                    // code block
                    return getAllChildren(componentId);// comps.value.filter(x=> x.parentId == componentId)    
                case 'component':
                    return comps.value.find(x => x.id == componentId)
                case 'iapComponentAttributes':
                    return comps.value.find(x => x.id == componentId)?.iapComponentAttributes
                /*case 'workFlows':
                        // code block
                        if (componentId== -1){
                            return comps.value.flatMap(x=> x.workFlows)                    
                        }
                        else{
                            return comps.value.find(x=> x.id == componentId)?.workFlows
                        }       
                
                    case 'expressions':
                        // code block
                        if (componentId== -1){
                            return comps.value.flatMap(x=> x.expressions)                    
                        }
                        else{
                            return comps.value.find(x=> x.id == componentId)?.expressions
                        }   
                    case 'objectGroups':
                     // code block
                        if (componentId== -1){
                            return comps.value.flatMap(x=> x.objectGroups)                    
                        }
                        else{
                            return comps.value.find(x=> x.id == componentId)?.objectGroups
                        }   
                        case 'IapComponent':
                     // code block
                        if (componentId== -1){
                            return comps.value[0]                    
                        }
                        else{
                            return comps.value.find(x=> x.id == componentId)
                        }   
                    */

                default:
                    return null;
            }

        }

        const buildDependencies = () => {

            const attrModels = comps.value.flatMap(a => a.iapComponentAttributes).filter(a => a.name == BaseControlTypeConst.MODELVALUE && a.idAttributeType == CatalogAttrConst.TIPOATTR_COMMON && a.value);

            const exptext = comps.value.flatMap(x => x.expressions).filter(x => x !== null && x.idTypeExpression == CatalogExpConst.EXP_SET
                && x.idObjeto == CatalogObjectTypeConst.ATTRCOMP
                && x.iapExpressionDetails != null);


            if (exptext?.length > 0) {

                // se miran las dependencias de los atributos a traves de las propiedades
                const attsKeys = comps.value.flatMap(x => x.iapComponentAttributes)
                    .map(x => ({ att: x, key: [ExpressionNomenclatorConst.EXPNOM_ATTRIBUTES, x.id].join('.') }))
                    .map(x => ({
                        componentId: x.att.componentId,
                        attributeId: x.att.id,
                        exp: exptext.filter(z => z.iapExpressionDetails.filter(y => y.expression.includes('[' + x.key + ']')).length > 0)
                    })).filter(x => x.exp.length > 0)






                attsKeys.forEach(x => {


                    const attr = attrModels.find(a => a.value == x.attributeId);
                    const comp = comps.value.find(c => c.id == attr?.componentId);
                    if (comp) {
                        comp.dependencies = [];
                        comp.dependencies = x.exp.map(e => ({
                            componentId: Number(e.objetoIdRoot),
                            attributeId: e.objetoId,
                            typeId: null,
                            objectName: null
                        })) as any;
                    }
                });



                // se miran las dependencias de los atributos a traves de los componentsdatasource

                let cdsKeys = new Array();
                comps.value.flatMap(x => x.iapComponentDataSources).forEach(dsf => {
                    const newdsf = dsf.dataSource.iapDataSourceFields
                        .map(x => ({ componentId: dsf.componentId, cdsId: dsf.id, dsf: x, key: [ExpressionNomenclatorConst.EXPNOM_DSF, dsf.id, x.id].join('.') }))
                        .map(x => ({
                            componentId: x.componentId,
                            dsfId: x.dsf.id + '#' + x.cdsId.toString(),
                            exp: exptext.filter(z => z.iapExpressionDetails.filter(y => y.expression.includes('[' + x.key + ']')).length > 0)
                        })).filter(x => x.exp.length > 0);

                    cdsKeys = cdsKeys.concat(newdsf);

                });


                cdsKeys.forEach(x => {

                    const attr = attrModels.find(a => a.value == x.dsfId);
                    const comp = comps.value.find(c => c.id == attr?.componentId);
                    if (comp) {

                        comp.dependencies = comp.dependencies.concat(x.exp.map(e => ({
                            componentId: Number(e.objetoIdRoot),
                            attributeId: e.objetoId,
                            typeId: null,
                            objectName: null
                        })) as any);

                    }
                });



                exptext.length = 0;
                attsKeys.length = 0;
                cdsKeys.length = 0;

            }


            //@ts-ignore:disable-next-line
            const resultSharedModel = Object.groupBy(attrModels, ({ value }) => value);
            Object.keys(resultSharedModel)?.forEach((key: any) => {
                resultSharedModel[key]?.forEach((attr: IapComponentAttribute) => {
                    const comp = comps.value.find(c => c.id == attr?.componentId);
                    if (comp) {

                        comp.dependencies = comp.dependencies.concat(
                            resultSharedModel[key].filter((x: IapComponentAttribute) => x.id != attr.id).map((x: IapComponentAttribute) => ({
                                componentId: x.componentId,
                                attributeId: x.id,
                                typeId: InteractionConst.SET_VMODEL,
                                objectName: null
                            })));
                    }

                })
            })



        }

        const isSearchForm=(comp:ComponentDataForm | undefined)=>{
            if(!comp) return false;
            const { getPropertyBooleanValue } = ComponentHelperRender({} as any,undefined,store as any); 
            return getPropertyBooleanValue(ComponentTypeConst.SEARCH_FORM,comp)
        }


        const buildComponetTree = (response: IapComponent[]) => {

            setAttributesInput(response);
            buildLookUpComps(response);

            comps.value = response.map(x => ({
                ...x,
                formKey: formKey,

                rootParentId: props.componentId,
                lookUpDesc:'',
                loaded: false,
                vmodel:null,
                dependencies: [],
                children: response.filter(y => y.parentId != null && y.parentId == x.id).sort((a, b) => { return (a.order ?? 0) - (b.order ?? 0); }).map(y => ({
                    id: y.id,
                    idType: y.idType
                })),
                get: (componentId: number, objectTypeId: string) => { return getComponentData(componentId, objectTypeId) },
                set: (componentId: number, objectTypeId: string, value: object) => { return setComponentData(componentId, objectTypeId, value) }

            })) as ComponentDataForm[];

        
            if(isSearchForm(comps.value.find(x=>x.id==props.componentId))) context.emit('searchFormChanges', {typeId:SearchFormEvtConst.COMPS_LOADED,data:comps.value});

            buildDictionary(response)
            setKeepAlive();
            buildDependencies();

            CompsQueue.enqueue(keyEventComponentRoot);

            isLoaded.value = true;

            addNavigation();


        }

      
        const loadLocalData = async (): Promise<boolean> => {

            return await new Promise<boolean>(async (resolve) => {

                try {
                    /*
                    await fetchWrapper.get(`${window.location.origin}/data/${props.componentId}.json`).then(
                        (response:any) =>{
                            buildComponetTree(response as any);
                        hasLocalFiles=true;
                        resolve(true);
                        }
                    )
                    .catch(()=>{
                        hasLocalFiles=false;
                        resolve(false);
                    })
                    */
                    const db = dataBinary.getComponentData(props.componentId ?? -1);
                    if (db?.length > 0){
                        buildComponetTree(db);
                        hasLocalFiles = true;
                        resolve(true);
                    }
                    else{
                        resolve(false);   
                    }
                    
                    /*const dataImport = await import(`./data/${props.componentId}.json`);
                    buildComponetTree(dataImport.default);
                    
                    hasLocalFiles = true;
                    resolve(true);
                    */


                } catch (error) {
                    resolve(false);

                }

            });

        };


        const loadFromDataBase = () => {
            if (props.container && store.getters.isUserAuthenticated) {
                HelperLoading.showLoading()
                const _srv = props.container.get<IServiceComponent>(TYPES.COMPONENT_REPOSITORY)
                _srv.getTreeAllObjectsById(props.applicationId ?? -1, props.applicationVersion ?? -1, props.componentId ?? -1, false).then(response => {

                    buildComponetTree(response);
                    response = []

                })
                    .finally(() => {
                        HelperLoading.hideLoading()
                        
                    })
            }
        }

     

        const loadFromFileServer = (fileName: String) => {
            fetchWrapper.getBinaryFile(fileName + '.zip' + '?_t=' + new Date().getTime().toString(), store)
                .then((response: any) => {
                    //const zip = new JSZip();
                    /*if (Program){
                        //@ts-ignore:disable-next-line
                        const resu = Program.decompressZip(response,props.componentId + '.json');                        
                    }
                    */
                    HelperCompress.decompressBinaryFile(props.componentId + '.json', response)
                        .then((responseData: any) => {
                            // let response = JSON.parse(responseData) as IapComponent[]
                            let response=dataBinary.decompressComp(HelperUtils.binArrayToJson(responseData));
                            const attrCache = response[0].iapComponentAttributes.find(x => x.name == ComponentTypeConst.APP_CACHE);
                            if (attrCache && (attrCache.value ?? 'false') == 'true') {
                                AppCache.setCache(CatalogObjectTypeConst.COMP, props.componentId ?? -1, response);
                            }


                            buildComponetTree(response);
                            response = []
                            responseData = '';

                        }).finally(() => {
                            HelperLoading.hideLoading()
                        })
                        .catch((response) => {
                            loadFromDataBase();

                        })

                })
                .catch((response) => {
                    loadFromDataBase();

                })
        }

        const loadFromFile = () => {

            if (store.getters.isUserAuthenticated) {

                HelperLoading.showLoading()
                //const token = store.getters.getAcessToken;
                const fileName = Environment.URL_COMPONENT + props.componentId + '.json';
                const cacheComponent = AppCache.getCache(CatalogObjectTypeConst.COMP, props.componentId ?? -1)
                if (cacheComponent) {
                    buildComponetTree(cacheComponent.data as IapComponent[]);
                    HelperLoading.hideLoading()
                }
                else {
                    loadLocalData().then(response => {
                        if (response === false) {
                            loadFromFileServer(fileName);

                        }
                    }).finally(()=>{
                        
                    })

                }
            }

        }


        const doCallBackResponse = (evt: any) => {
            if (evt?.callBackResponse) {
                evt?.callBackResponse(true)
            }
        }

        const backToOtherComponent = (evt:any) => {
            showComponentForm.value = false;
            componentInputModal.value = false;
            showComponentFormDialog.value = false;
            componentInputId.value = -1;
            componentAttributesList.value = [];
            componentInputHeader.value = '';
            if(!evt){
                doCallBackResponse(evtDisplayForm.value);
            }
        }


        const doOperationEvent = (evt: { componentId: number, header: string, modal: boolean, attrs: IapComponentAttribute[], callBackResponse: any }) => {
            componentInputModal.value = evt.modal;
            componentInputId.value = evt.componentId;
            componentAttributesList.value = evt.attrs;
            showComponentFormDialog.value = evt.modal;
            componentInputHeader.value = evt.header;
            showComponentForm.value = true

            // para los modales esperamos a que acabe o se cierre
            if (showComponentFormDialog.value) {
                evtDisplayForm.value = evt
            }
            else {
                doCallBackResponse(evt)
            }

        }
        const doOperationEventRoot = (evt: { data: InteractionEvent, callBackResponse: any }) => {
            if (comps.value.length > 0) {
                const Component = comps.value[0]
                switch (evt.data.typeId) {
                    case InteractionConst.GET_PROPERTY_VALUE:

                        const attrGet = Component.iapComponentAttributes?.find(x => x.id == evt.data.objectId || x.name == evt.data.objectName)
                        if (attrGet) {
                            evt.data.interactionResult = attrGet.value;
                        }

                        break;
                    case InteractionConst.CALL_FUNCTION:

                        if (evt.data.objectName?.toLowerCase() == 'getcurrentexpr()') {
                            evt.data.interactionResult = Component.expressions ?? []
                        }

                        break;
                    case InteractionConst.GET_ROOT_COMPONENTS:
                        if (props.componentId == evt.data.objectValue) {
                            evt.data.interactionResult = comps.value;
                        }
                        else {
                            evt.data.interactionResult = null;
                        }
                        break;

                    case CatalogEventConst.ONCLOUSED:
                    case CatalogEventConst.ONDIALOGCLOSEOK:
                        context.emit('close');
                        evt.data.interactionResult = true;
                        break;
                    case CatalogEventConst.ONDIALOGCLOSECANCEL:
                        evt.data.interactionResult =null;
                        context.emit('close',true);
                        break;    
                    case InteractionConst.DESTROY_ROOT_COMPONENTS:
                        doUnmountActions();
                        evt.data.interactionResult = true;
                        break;
                    case InteractionConst.GET_STATIC_COMP:                       
                        evt.data.interactionResult = dataBinary.getComponentData(evt.data.objectValue ?? -1);
                        break;
                    case InteractionConst.SEARCH_FORM_VMODEL_CHANGE:                       
                        context.emit('searchFormChanges', {typeId:SearchFormEvtConst.VMODEL_CHANGE,data:evt.data.objectValue}); 
                        break;
                    case InteractionConst.SEARCH_FORM_ATTR_CHANGE:                       
                        context.emit('searchFormChanges', {typeId:SearchFormEvtConst.ATTR_CHANGE,data:evt.data.objectValue}); 
                        break;



                }

                if (evt.callBackResponse) {
                    evt.callBackResponse(evt.data);
                }
            }
        }


        const buildLookUpComps = (data: IapComponent[]) => {

            data.filter(comp => comp.idType == ControlTypeConst.CTLOOKUPEDITOR).forEach(comp => {
                lookUpComps.push({ compId: comp.id, iapComponentAttribute: comp.iapComponentAttributes.find(ca => ca.name == BaseControlTypeConst.MODELVALUE) ?? null })
            });

        }


        const onDockItemClick = (event: Event, item: any) => {
            if (item.command) {
                item.command();
            }

            event.preventDefault();
        };

        const doUnmountActions = () => {

            isLoadedKeepAlive.value = false;
            isLoaded.value = false;
            comps.value = [];
            pendingOperations.value = [];
            lookUpComps.length = 0;
            LocalService.destroy(formKey + LocalService.COMPONENTS_EXP + (props?.componentId ?? -1).toString());
            LocalService.destroy(formKey + LocalService.COMPONENTS_CTRL + (props?.componentId ?? -1).toString());
            EventBusCustom.off(keyEventComponent, doOperationEvent);
            EventBusCustom.off(keyEventComponentRoot, doOperationEventRoot);
            CompsQueue.dequeue(keyEventComponentRoot);
            
            if ((props?.menuId ?? -1) >0 && (props?.componentId ?? -1) > 0){
                HelperNavigation.deleteComponentKeyLoaded(props?.componentId ?? -1);
            }
        }

        const addNavigation = () =>{
            if ((props?.menuId ?? -1) >0 && (props?.componentId ?? -1) > 0){
                    HelperNavigation.setComponentLoaded({key: (props?.componentId ?? -1), value:formKey} as Dictionary<Number,string>);
                }
        }

        onMounted(() => {


            if (store.getters.isUserAuthenticated) {
                // para los modales o formularios de detalle
                EventBusCustom.on(keyEventComponent, doOperationEvent);

                // para leer cosas del padre
                EventBusCustom.on(keyEventComponentRoot, doOperationEventRoot);


                if (props.preview) {
                    loadFromDataBase();
                }
                else {

                    loadFromFile();
                }
                
              
            }

        })


        onBeforeUnmount(() => {
            doUnmountActions();
            doCallBackResponse(evtDisplayForm.value);
        })

        onActivated(() => {
            if (router.currentRoute.value.meta?.keppAlive === true) {
                isLoadedKeepAlive.value = true;
            }

        })

        onDeactivated(() => {

            if (router.currentRoute.value.meta?.keppAlive === true) {
                isLoadedKeepAlive.value = false;
            }

            if (props.canDestroy || (!store.getters.isUserAuthenticated)) {
                doUnmountActions();
            }
        })

        return {
            builtCatalogDictionary,
            Environment,
            showComponentForm,
            backToOtherComponent,
            componentInputModal,
            componentInputId,
            componentAttributesList,
            showComponentFormDialog,
            componentInputHeader,
            formKey,
            isKeepAlive,
            isLoaded,
            lookUpComps,
            comps,
            DynamicComponent,
            isLoadedKeepAlive,
            itemsDock,
            pendingOperations,
            onDockItemClick

        };
    },
});
</script>
<style scoped>
.p-overlaybadge {
    position: relative;
}

.p-overlaybadge .p-badge {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
    margin: 0;
    outline-width: var(--p-overlaybadge-outline-width);
    outline-style: solid;
    outline-color: var(--p-overlaybadge-outline-color);
}

.p-badge-circle {
    padding: 0;
    border-radius: 50%;
}
</style>
