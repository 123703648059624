import { GenerateOptions } from '../types'

export const checkError = (options: GenerateOptions) => {
  const {
    length,
    lowercase,
    uppercase,
    numbers,
    symbols,
    minLengthLowercase,
    minLengthUppercase,
    minLengthNumbers,
    minLengthSymbols,
  } = options

  //Checking if the sum of minimum characters of Lowercase, uppercase, numbers and special case is greater than the total password length.
  if (
    minLengthLowercase! +
      minLengthUppercase! +
      minLengthNumbers! +
      minLengthSymbols! >
    length!
  ) {
    //MessageService.showMessage(MessageType.Error, 'Error de validación', 'La suma de caracteres mínimos en las contraseñas mayores a la longitud de la contraseña a generar.');
    throw new Error(
      'La suma de caracteres mínimos en las contraseñas mayores a la longitud de la contraseña a generar.'
    )
  }

  //Checking if lowercase, uppercase , numbers and special characters have all been set to false.
  if (
    lowercase == false &&
    uppercase == false &&
    numbers == false &&
    symbols == false
  ) {
    //MessageService.showMessage(MessageType.Error, 'Error de validación', 'No hay caracteres válidos para generar la contraseña.');
    throw new Error(
      'No hay caracteres válidos para generar la contraseña.'
    )
  }
}

// module.exports = checkError;
