import gql from 'graphql-tag';


export const DELETE = gql(`
mutation($id: Int!){
    applicationNoteMutation{
        deleteApplicationNote(id:$id)
    }
}
`);

export const UPDATE = gql(`
mutation($data: ApplicationNoteInputType!){
    applicationNoteMutation{
        updateApplicationNote(data:$data){
            id,
            applicationId,
            applicationVersion,
            appVersion,
            title,
            description,
            fcr,
            ucr,
            uum,
            fum
        }
    }
}
`);

export const ADD = gql(`
mutation($data: ApplicationNoteInputType!){
    applicationNoteMutation{
        addApplicationNote(data:$data){
            id,
            applicationId,
            applicationVersion,
            appVersion,
            title,
            description,
            fcr,
            ucr
        }
    }
}
`);



