import { computed, shallowRef } from 'vue';
import { LocalService, MessageService } from "../../../../../common/infrastructure/servicios";
import CatalogExpConst from "../../../catalog/domain/const/CatalogExpConst";
import CatalogObjectTypeConst from "../../../catalog/domain/const/CatalogObjectTypeConst";
import { Container } from 'inversify';
import { IServiceSearch } from "../../../search/application/IServiceSearch";
import { TYPES } from "../../../../../common/domain/types";
import HelperLoading from "../../../../../common/infrastructure/funciones/HelperLoading";
import { IapComponentDataSource } from "../../../component/domain/iapComponentDataSource";
import FiltroBusquedaConst from "../../../../../common/domain/constantes/FiltroBusquedaConst";
import { GroupSearch } from "../../../search/domain/search";
import ExpressionNomenclatorConst from "../../../expression/domain/const/ExpressionNomenclatorConst";
import BaseControlTypeConst from "../Constants/BaseControlTypeConst";
import CatalogModelValueConst from "../../../catalog/domain/const/CatalogModelValueConst";
import ComponentDataForm from "../../../designer/domain/ComponentDataForm";
import HelperCommon from "../../../../../common/infrastructure/funciones/HelperCommon";
import CatalogAttrConst from "../../../catalog/domain/const/CatalogAttrConst";
//@ts-ignore:disable-next-line
import debounce from 'lodash.debounce';
import DataSourceConst from "../../../crud/infrastructure/functions/dataSourceConst";
import HelperUtils from "../../../../../common/infrastructure/funciones/HelperUtils";
import ControlTypeConst from "../Constants/ControlTypeConst";
import EventBusCustom from '../../../../../common/infrastructure/event-bus-custom';
import EventConst from "../../../../../common/domain/constantes/EventConst";
import { MessageType } from "../../../../../common/infrastructure/servicios/MessageService";
import DataSourceComp from "../../../crud/infrastructure/functions/dataSourceComp";
import helperCatalog from "../../../catalog/infrastructure/helper/helperCatalog";
import { IapComponentAttribute } from '../../../component/domain/iapComponentAttribute';
import { Store } from 'vuex';
import HelperSecurity from '../../../../../common/infrastructure/funciones/HelperSecurity';
import ObjectGroupConst from '../../../../../common/domain/constantes/ObjectGroupConst';
import CatalogDataSourceType from '../../../catalog/domain/const/CatalogDataSourceType';
import CrudTableTypeConst from '../Constants/CrudTableTypeConst';
import OperatorLogicConst from '../../../search/domain/Const/OperatorLogicConst';
import CdsPropsConst from '../../../catalog/domain/const/CdsPropsConst';
import { ResultadSearch } from '../../../search/domain/resultSearch';
import { SearchMultiple } from '../../../search/domain/searchMultiple';
import ComponentHelperRender from './ComponentHelperRender';
import { InteractionEvent } from '../../../interaction/domain/interactionEvent';

export default function StaticFunctionsRender(
  Component: ComponentDataForm,
  slotProps: any,
  container: Container | undefined,
  store: Store<any>,
  index: number,
  loaded: boolean[]
) {

  const user = store?.getters.getCurrentUser;
  const fieldKeyComponentDataSourceId = shallowRef(-1)
  const keyComponentEventBus = Component.formKey + Component.rootParentId.toString() + Component.id.toString() + EventConst.INTERACTION;

  const { resolveExpressions, includeDSExp, getProperty, getPropertyBooleanValue, hasExpressions, canDoOperation } = ComponentHelperRender(Component, slotProps, store);

  const { getFieldKeyColumn } = DataSourceComp(container as any, { Component: Component }, undefined, undefined, {} as any, [], store)

  const rootParentAttr = computed({
    get: () => Component.get(Component.rootParentId, 'iapComponentAttributes') as IapComponentAttribute[],
    set: (val) => Component.set(Component.rootParentId, 'iapComponentAttributes', val as any),
  });

  const componentDataSources = computed({
    get: () => Component.get(-1, 'iapComponentDataSources') as IapComponentDataSource[],
    set: (val) => Component.set(-1, 'iapComponentDataSources', val as any),
  });

  const fieldKey = shallowRef('');

  const getDictionaryKeys = () => {
    const localData = LocalService.getValue(Component.formKey + LocalService.COMPONENTS_EXP + (Component.rootParentId ?? -1).toString());
    const data = HelperUtils.jsonParse(localData, [])

    if (data && data?.length > 0) {
      return data.map((x: any) => x.key);
    }
    return [];
  }

  const dictionaryKeys = getDictionaryKeys();
  const catalogTypes = () => {
    return helperCatalog.getAllCatalogApp();
  }


  const updateDictionary = (key: string, value: any, dictionary: any = null) => {
    if (dictionary != null) {
      const reg = dictionary.find((x: any) => x.key == key);

      if (reg) {

        reg.value = value;
      }
    }
    else {
      const localData = LocalService.getValue(Component.formKey + LocalService.COMPONENTS_EXP + (Component.rootParentId ?? -1).toString());
      const data = HelperUtils.jsonParse(localData, [])

      const reg = data.find((x: any) => x.key == key);

      if (reg) {

        reg.value = value;
        LocalService.setValue(Component.formKey + LocalService.COMPONENTS_EXP + (Component.rootParentId ?? -1).toString(), JSON.stringify(data))
      }
    }
  }

  const resolveAllExpressions = (): Promise<boolean> => {

    return new Promise<boolean>((resolve) => {


      const attributes = Component?.iapComponentAttributes || [];

      const hasPropCusAttr = attributes.some(attr => attr.idAttributeType === CatalogAttrConst.TIPOATTR_PROPCUS);

      const noMatchingExpr = attributes.filter(attr => hasExpressions(CatalogObjectTypeConst.ATTRCOMP, attr.id))
        .every(attr => !includeDSExp(attr.id, Component?.expressions));


      if (hasPropCusAttr && noMatchingExpr) {
        resolveAttributes().then(() => {
          resolveDatasource().then(() => {
            resolve(true);
          });
        });
      }
      else {
        resolveDatasource().then(() => {
          resolveAttributes().then(() => {
            resolve(true);
          });
        });
      }
    })
  }





  const resolveAttributes = (attributeIdInput: string | null = null) => {
    return new Promise<boolean>((resolve) => {

      Component.iapComponentAttributes.filter(f => f.id == (attributeIdInput ?? f.id) && hasExpressions(CatalogObjectTypeConst.ATTRCOMP, f.id, CatalogExpConst.EXP_SET)).forEach(att => {

        att.value = resolveExpressions(CatalogObjectTypeConst.ATTRCOMP, att.id, CatalogExpConst.EXP_SET);

        updateDictionary([ExpressionNomenclatorConst.EXPNOM_ATTRIBUTES, att.id].join(ExpressionNomenclatorConst.SEPARATOR), HelperUtils.resolveAttrValue(att.idDataType, att.value));
      })
      resolve(true);
    });
  }

  const resolveDatasource = async (compDataSourceIdInput: number | null = null) => {
    return new Promise<boolean>(async (resolve) => {

      var promises = new Array();
      const { getFilterValueData } = DataSourceComp(container as any, { Component: Component }, undefined, {}, {} as any, [], store)

      if (canDoOperation(ObjectGroupConst.VIEW) &&
        (Component.iapComponentDataSources?.length ?? 0) > 0) {
        Component.iapComponentDataSources.filter(x => x.id == (compDataSourceIdInput ?? x.id)).sort(x => x.order).forEach((ds) => {
          ds.dataSource.iapDataSourceFields.filter(f => hasExpressions(CatalogObjectTypeConst.DSF, [f.id, ds.id].join('#'), CatalogExpConst.EXP_FILTER)).forEach(f => {


            f.filterValue = resolveExpressions(CatalogObjectTypeConst.DSF, [f.id, ds.id].join('#'), CatalogExpConst.EXP_FILTER);

            if (f.filterValue && Array.isArray(getFilterValueData(f.filterValue, 1))) {
              const _value = getFilterValueData(f.filterValue, 1);
              f.filterValue = _value[0].value.toString();
            }
          })


          ds.autoLoad = resolveExpressions(CatalogObjectTypeConst.CDS, [ds.id, CdsPropsConst.AUTOLOAD.key].join('#'), CatalogExpConst.EXP_SET, null, false) ?? ds.autoLoad;
          ds.autoSave = resolveExpressions(CatalogObjectTypeConst.CDS, [ds.id, CdsPropsConst.AUTOSAVE.key].join('#'), CatalogExpConst.EXP_SET, null, false) ?? ds.autoSave;
          ds.maxRowsReturned = resolveExpressions(CatalogObjectTypeConst.CDS, [ds.id, CdsPropsConst.MAXROWS.key].join('#'), CatalogExpConst.EXP_SET, null, false) ?? ds.maxRowsReturned;
          ds.order = resolveExpressions(CatalogObjectTypeConst.CDS, [ds.id, CdsPropsConst.ORDER.key].join('#'), CatalogExpConst.EXP_SET, null, false) ?? ds.order;


          // GILBERTO: Aqui se resuelven los valores de los parametros
          ds.dataSource.iapDataSourceServiceConfigurations?.filter(f => hasExpressions(CatalogObjectTypeConst.DSSC, [f.id, ds.id].join('#'), CatalogExpConst.EXP_DEFAULTVALUES)).forEach(f => {
            f.value = resolveExpressions(CatalogObjectTypeConst.DSSC, [f.id, ds.id].join('#'), CatalogExpConst.EXP_DEFAULTVALUES);
          })


          // si no tiene autocarga pero la cfg tiene algun add creamos la fila vacía
          if (!ds.autoLoad && ds.iapComponentDataSourceFieldConfigurations.find(x => x.add === true) && Component?.idType !== ControlTypeConst.CTCRUD) {
            const { insertEmptyRowToDataSource } = DataSourceComp(container as any, { Component: Component } as any, {}, {}, ds, catalogTypes().flatMap(x => x.iapCatalogs), store)
            insertEmptyRowToDataSource([], [ds]);
          }

          const isLazy = Component?.idType == ControlTypeConst.CTCRUD ? getPropertyBooleanValue(CrudTableTypeConst.LAZY, Component, true) : false;
          // SE LLAMA CON LOS FILTROS DE LOS DATASOURCES 
          if (((ds.autoLoad && !isLazy) || compDataSourceIdInput != null) && HelperSecurity.canDoOperation(user, (ds.dataSource?.objectGroups ?? []), ObjectGroupConst.VIEW)) {
            promises.push(ds)

            //promises.push(fillDataSource(ds))
          }
        })

        if (promises.length > 0) {
          const resuFillDs = await fillDataSourceMultiple(promises)
          resolve(resuFillDs)
        }
        else {
          resolve(true);
        }
      }
      else {

        resolve(true);
      }
    });
  }

  const updateCdsDependencies = (ds: IapComponentDataSource, obj: any, index = 0) => {

    // se actualiza el ds si se usa en el diccionario    
    const keyDs = [ExpressionNomenclatorConst.EXPNOM_DATASOURCE, ds.id, ds.dataSourceId, ds.componentId].join(ExpressionNomenclatorConst.SEPARATOR);
    if (dictionaryKeys.includes(keyDs)) {
      updateDictionary(keyDs, obj)
    }


    // falta actualizar los fields que se usen
    if (ds.dataSource?.items?.length > 0) {
      const item = ds.dataSource?.items[index];


      ds.dataSource.iapDataSourceFields
        .filter(f => dictionaryKeys.includes([ExpressionNomenclatorConst.EXPNOM_DSF, ds.id, f.id].join(ExpressionNomenclatorConst.SEPARATOR)))
        .forEach(f => {

          let key = '';

          if (ds.dataSource.idDataSourceType == CatalogDataSourceType.SERVICE) // para los servicios
          {
            if (f.dataSourceTableAliasId == null) {
              key = f.field
            }
            else {
              key = [ds.dataSource?.iapDataSourceDataBase?.idBaseDatos, (!HelperCommon.isNullOrWhitespace(f.tableNameAlias ?? '') ? f.tableNameAlias : f.tableName), (!HelperCommon.isNullOrWhitespace(f.fieldAlias ?? '') ? f.fieldAlias : f.field)].join(DataSourceConst.DELIMITERFIELDS);
            }

          }
          else // para las BBDD
          {
            if (f.dataSourceTableAliasId == null) {
              key = getFieldKeyColumn(f, true)
            }
            else {
              key = getFieldKeyColumn(f);
            }
          }


          if (key) {
            updateDictionary([ExpressionNomenclatorConst.EXPNOM_DSF, ds.id, f.id].join(ExpressionNomenclatorConst.SEPARATOR), item[key]);
          }

        })

      // se añaden los lookups ids
      ds.dataSource?.iapDataSourceFields?.filter(dsField => dsField.dataSourceLookUpId != null).map(x => ({ dataSourceFieldId: x.id, dataSourceLookUpId: x.dataSourceLookUpId, iapDataSourceFields: x.dataSourceLookUp?.dataSource.iapDataSourceFields }))
        //.filter(x =>  dictionaryKeys.includes([ExpressionNomenclatorConst.EXPNOM_DSLK, x.dataSourceLookUpId, ds.id, dsField.id].join('.')))
        .forEach(x => {
          x.iapDataSourceFields?.filter(dsField => dictionaryKeys.includes([ExpressionNomenclatorConst.EXPNOM_DSLK, x.dataSourceLookUpId, dsField.id].join('.')))
            .forEach(dsField => {

              //@ts-ignore:disable-next-line
              let _field = x.dataSourceFieldId.replaceAll('-', '') + DataSourceConst.DELIMITERFIELDS + dsField.id.replaceAll('-', '');

              const _value = item != null ? item[_field] : null;
              updateDictionary([ExpressionNomenclatorConst.EXPNOM_DSLK, x.dataSourceLookUpId, dsField.id].join('.'), _value);

            })
        })
    }
  }


  const buildFillDataSource = (ds: IapComponentDataSource): SearchMultiple | null => {

    const { getDefaultValueServiceCfg, getDefaultvalue, getFilterValueData, adjustCdsAndClauses, isStrictFilter } = DataSourceComp(container as any, { Component: Component }, undefined, {}, {} as any, [], store)
    const claves = ds.dataSource.iapDataSourceFields.filter(x => (x.filterValue ? x.filterValue : getDefaultvalue(x, ds?.iapComponentDataSourceFieldConfigurations?.find(cfg => cfg.dataSourceFieldId == x.id)?.defaultValue, null, true)) || isStrictFilter(x, ds)).map(x =>
    ({

      //@ts-ignore:disable-next-line
      id: HelperUtils.newGuid().toString().replaceAll('-', ''),
      fieldId: x.id,
      filter: getFilterValueData(x.filterValue, 0) ?? FiltroBusquedaConst.FILTROBUSQUEDA_IGUAL,
      value: x.filterValue ? getFilterValueData(x.filterValue, 1) : null, //getDefaultvalue(x,ds?.iapComponentDataSourceFieldConfigurations?.find(cfg=>cfg.dataSourceFieldId==x.id)?.defaultValue,null,true),
      valueList: (x.catalogTypeId ? [x.filterValue] : null),
      valueBool: null,
      valueNumber: null,
      valueDateTime: null,
      rangeValue: null,
      rangeNumber: null,
      rangeDateTime: null,
      required: isStrictFilter(x, ds)

    }));



    const dsFiltersExp = ds.dataSource.iapDataSourceFields.filter(f => hasExpressions(CatalogObjectTypeConst.DSF, [f.id, ds.id].join('#'), CatalogExpConst.EXP_FILTER));

    if (dsFiltersExp.length > 0 && claves.length == 0) {
      ds.dataSource.items = []

      return null;
    }

    const parameters = ds.iapComponentDataSourceServiceConfigurations?.map(x =>
    ({
      key: x.key,
      value: getDefaultValueServiceCfg(x, Component.expressions)
    }));


    const _claves = JSON.parse(JSON.stringify(claves))



    let _parameters = []
    if (parameters) {
      _parameters = JSON.parse(JSON.stringify(parameters))
    }


    const cdsCopy = adjustCdsAndClauses(ds);


    const searchData: GroupSearch = {
      operatorLogic: OperatorLogicConst.AND,
      fields: _claves,
      children: []
    }

    return {
      applicationId: Component.applicationId,
      applicationversion: Component.applicationVersion,
      componentId: Component.id,
      requestData: searchData as any,
      parameters: _parameters,
      maxregs: ds.maxRowsReturned,
      componentDataSource: cdsCopy,
      dataSourceId: ds.dataSourceId,
      count: undefined,
      lazyParams: undefined
    } as SearchMultiple


  }

  const setDataSourceValue = (ds: IapComponentDataSource, obj: any) => {
    if (!Array.isArray(obj)) {
      ds.dataSource.items = [obj];
    }
    else {
      ds.dataSource.items = obj;
    }
  }

  const fillDataSourceMultiple = async (dsList: IapComponentDataSource[]): Promise<boolean> => {
    return await new Promise<boolean>((resolve) => {
      const inputsDs = new Array<SearchMultiple>()
      //const { getDefaultValueServiceCfg, getDefaultvalue,getFilterValueData,adjustCdsAndClauses,isStrictFilter } = DataSourceComp(container as any, {Component:Component}, undefined, {}, {} as any, [],store)
      let mustExit = false
      dsList?.some((ds: IapComponentDataSource) => {
        const _auxData = buildFillDataSource(ds)
        if (_auxData) {
          inputsDs.push(_auxData)
        }
        else {
          mustExit = true;
          return true

        }


      })

      if (mustExit) {
        resolve(false)
        return
      }


      if (container && inputsDs.length > 0) {

        const _srv = container.get<IServiceSearch>(
          TYPES.SEARCH_REPOSITORY
        );
        HelperLoading.showLoading();
        _srv.searchMultiple(inputsDs)
          .then((responses: ResultadSearch[]) => {
            responses.forEach((response: ResultadSearch, index: number) => {
              const ds = dsList[index]
              let obj = JSON.parse(response?.items ?? '[]');

              if (ds.dataSource.idDataSourceType == CatalogDataSourceType.DATABASE) {

                HelperUtils.handleDates(obj as any)
                //ds.dataSource.items = obj
                ds.dataSource.items = []
                Object.assign(ds.dataSource.items, obj);
                updateCdsDependencies(ds, obj)
              }
              else {
                // al servicio siempre hay que hacerle un parse otra vez
                obj = JSON.parse(obj);

                // sacamos las clases               
                const tables = ds.dataSource?.iapDataSourceTableAliases?.filter(x => !HelperCommon.isNullOrWhitespace(x.tableName) && !x.isInput).map(x => x.tableName).filter((value, index, array) => array.indexOf(value) === index) ?? []

                if (tables.length > 0) {
                  tables.forEach((tbl: string) => {
                    try {
                      const _obj = HelperUtils.propValue(obj, tbl)
                      setDataSourceValue(ds, _obj)
                    } catch {
                      // no se encontro prop value                    
                      MessageService.showMessage(MessageType.Error, 'Error al resolver los datos del servicio', 'No se ha encontrado el objeto' + tbl);
                      setDataSourceValue(ds, obj)
                    }

                  })

                }
                else {
                  setDataSourceValue(ds, obj)
                }

                updateCdsDependencies(ds, obj)

              }

            })

            responses.length = 0;

          })
          .catch((err) => {
            MessageService.showMessage(MessageType.Error, 'Error al resolver el método', err);
          })
          .finally(() => {
            HelperLoading.hideLoading();
            resolve(true);
          });
      }
      else {
        resolve(false);
      }
    });
  }




  const getDsFieldKey = (componentDataSourceId: number, dataSourceFieldId: string) => {
    const dsc = componentDataSources.value.find(x => x.id == componentDataSourceId);
    if (dsc) {

      const field = dsc.dataSource.iapDataSourceFields.find(x => x.id == dataSourceFieldId)
      if (field) {
        if (dsc.dataSource.iapDataSourceDataBase) {
          const fieldKey = getFieldKeyColumn(field, (field.dataSourceTableAliasId == null));
          return fieldKey;
        }
        else if (dsc.dataSource.iapDataSourceService) {
          const fieldKey = [(!HelperCommon.isNullOrWhitespace(field.field ?? '') ? field.field : field.fieldAlias)].join(DataSourceConst.DELIMITERFIELDS)
          return fieldKey;
        }
      }
    }

    return null;
  }

  const getDsFieldModel = (componentDataSourceId: number, dataSourceFieldId: string) => {
    const dsc = componentDataSources.value.find(x => x.id == componentDataSourceId);
    if (dsc) {
      const field = dsc.dataSource.iapDataSourceFields.find(x => x.id == dataSourceFieldId)
      if (field) {
        fieldKey.value = getDsFieldKey(componentDataSourceId, dataSourceFieldId) as any
        if (fieldKey.value) {
          if (Array.isArray(dsc.dataSource.items)) {
            const first = dsc.dataSource.items?.find((x: any) => x !== undefined)

            const model = first?.[fieldKey.value];// dsc.dataSource.items.find(x=>x!==undefined)?.map(x=> x[fieldKey])
            return model;
          }
          else {
            const separatedKeys = fieldKey.value.split('.');
            let result = dsc.dataSource.items;
            if (result) {
              separatedKeys.forEach(k => {
                result = result[k];
              });
            }
            const model = result;
            return model;
          }
        }

      }
    }
    return null;
  }




  const doOperationEvent = async (evt: { data: InteractionEvent, callBackResponse: any }) => {
    if (evt.data.objectName?.toLowerCase() == 'resolveattributes()') {
      loaded[index] = false;
      await resolveAttributes(evt.data.objectId).then(() => {
        loaded[index] = true;
        evt.data.interactionResult = true
      });
    }
  }



  const getModelValue = () => {

    let model: any;
    const mv = getProperty(BaseControlTypeConst.MODELVALUE)

    if (mv && mv.value) {

      if (mv.idModelValueType == CatalogModelValueConst.MODELVALUE_PROPERTY) {
        // hay que sacar las propiedades del padre
        // AQUI HAY UN ERROR CUANDO ES UN DATAVIEW O DATA TIME LINE, LAS PROPIEDADES A MIRAR NO SON SOLO LAS DE SU ROOT, SINO LAS DE SU PADRE
        let _model = rootParentAttr.value?.find((x: any) => x.id == mv.value)
        if (_model == null) {
          _model = Component.iapComponentAttributes?.find(x => x.id == mv.value)
        }
        if (_model) {
          model = HelperUtils.resolveAttrValue(_model.idDataType, _model['value'])
        }
      }
      else {

        // hay que sacar el field del ds
        const ids = mv.value?.split('#');
        const dataSourceFieldId = ids[0];
        fieldKeyComponentDataSourceId.value = parseInt(ids[1]);
        // para las tablas, dataviews,...

        const data = slotProps?.data ?? slotProps?.slotProps?.data

        if (data) {
          HelperUtils.handleDates(data as any);
          fieldKey.value = getDsFieldKey(fieldKeyComponentDataSourceId.value, dataSourceFieldId) as any

          if (fieldKey.value) {
            model = data[fieldKey.value]
          }
        }
        else {
          model = getDsFieldModel(fieldKeyComponentDataSourceId.value, dataSourceFieldId);
        }
      }
    }

    return model;
  };

  if ((loaded.length==0 && index==0) || !loaded[index]) {
    resolveAllExpressions().then(() => {
      Component.loaded = true;
      Component.set(Component.id, 'loaded', true as any)
      Component.vmodel = getModelValue();

      if (fieldKeyComponentDataSourceId.value > 0) {
        EventBusCustom.on(Component.formKey + Component.rootParentId.toString() + fieldKey.value + fieldKeyComponentDataSourceId.value.toString(), doOperationEvent);
      }

      EventBusCustom.on(keyComponentEventBus, doOperationEvent);

      loaded[index] = true;

    });
  }



  const baseOnBeforeUnmount = () => {
    Component.loaded = false
    loaded[index] = false;
    Component.vmodel = null;
    fieldKey.value = '';

    Component.iapComponentDataSources?.forEach(cds => {
      cds.dataSource.items = null;
      updateCdsDependencies(cds, null)
    })

    if (fieldKeyComponentDataSourceId.value > 0) {
      EventBusCustom.off(Component.formKey + Component.rootParentId.toString() + fieldKey.value + fieldKeyComponentDataSourceId.value.toString(), doOperationEvent);
    }

    EventBusCustom.off(keyComponentEventBus, doOperationEvent);

    Component.set(Component.id, 'loaded', false as any)

  }




  return {
    baseOnBeforeUnmount
  };

}

