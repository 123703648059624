import BaseControlTypeConst from "./BaseControlTypeConst"

export default class InputNumberTypeConst extends BaseControlTypeConst {
    static FORMAT='format'
    static SHOWBUTTONS='showButtons'
    static BUTTONLAYOUT='buttonLayout'
    static INCREMENTBUTTONCLASS='incrementButtonClass'
    static DECREMENTBUTTONCLASS='decrementButtonClass'
    static MODE='mode'
    static PREFIX='prefix'
    static SUFFIX='suffix'
    static READONLY = 'readonly'
    static MINFRACTIONDIGITS = 'minFractionDigits'
    static MAXFRACTIONDIGITS = 'minFractionDigits'
    static LOCALE= 'locale'
    static LOCALEMATCHER= 'localeMatcher'
    static CURRENCY= 'currency'
    static CURRENCYDISPLAY= 'currencyDisplay'
    static USEGROUPING= 'useGrouping'
    static ROUNDINGMODE= 'roundingMode'
    static MIN= 'min'
    static MAX= 'max'
    static STEP= 'step'
    static ALLOWEMPTY= 'allowEmpty'
    static HIGHLIGHTONFOCUS= 'highlightOnFocus'
    static INPUTID= 'inputId'
    static INPUTCLASS= 'inputClass'
    static INPUTSTYLE= 'inputStyle'
    static INPUTPROPS= 'inputProps'
    static INCREMENTBUTTONPROPS= 'incrementButtonProps'
    static DECREMENTBUTTONPROPS= 'decrementButtonProps'
    static ARIALABELLEDBY= 'ariaLabelledby'
    static ARIALABEL= 'ariaLabel'
    static PT= 'pt'
    static PTOPTIONS= 'ptOptions'
    static UNSTYLED= 'unstyled'
}
