import { ClassicPreset } from "rete";
import { Container } from 'inversify';
import { IapWorkFlowActivity } from "../../../../domain/service/iapWorkFlowActivity";

export class ConditionControl extends ClassicPreset.Control {
  constructor(public formKeyInput: String,
    public rootComponentInputId: number, 
    public currentComponentInputId: number,
    public applicationId: number, 
    public applicationVersion: number,
    public variableConditionInput: string, 
    public container: Container | undefined, 
    public storeInput: any, 
    public itemActivity: IapWorkFlowActivity | undefined, 
    public onChange: (evt: any) => void,public getNode: (evt: any) => void, public showExpression:(evt:any) => void) {
    super();
  }
}