<template>
    <ul v-if="!isSummary">
        <li>
            <details open >
                <summary>
                    <Toolbar class="mb-3 mt-3 mr-1"  @click="stopPropagateEvent($event,!advancedSearch?true:false)">
                        <template #start >
                            <SelectButton v-if="advancedSearch" v-model="groupSearch.operatorLogic" :options="logicOperatorsOptions" optionLabel="name" optionValue="value"
                                aria-labelledby="basic"  @click="stopPropagateEvent($event)" />
                            <Dropdown v-model="selectedSearch" :options="getData" filter optionLabel="label"
                                optionGroupLabel="label" optionValue="value" @change="selectQuestion($event)" @click="stopPropagateEvent($event)"
                                optionGroupChildren="items" placeholder="Selecciona un filtro"
                                class="w-full md:w-14rem mr-2 ml-2">
                                <template #optiongroup="slotProps">
                                    <div class="flex align-items-center">
                                        <i :class="slotProps.option.icon"></i>
                                        <div class="ml-2">{{ slotProps.option.label }}</div>
                                    </div>
                                </template>
                            </Dropdown>
                            <Button v-if="advancedSearch" icon="pi pi-plus" rounded outlined aria-label="Filter" class="ml-1" @click.stop="addChildNode($event)"/>
                            <Button v-if="!isRoot && advancedSearch" icon="pi pi-minus" rounded outlined aria-label="Filter" severity="danger" @click="deleteChild(groupSearch)"
                                class="ml-1" />

                        </template>
            
                    </Toolbar>
                </summary>
                <SearchFields :container="container" v-model="groupSearch.fields" :catalogosData="catalogosData" :dataContext="getData"
                        :compDataSource="compDataSource" :fixCalendar="fixCalendar" :cleanFilter="cleanFilter" @search="$emit('search')"></SearchFields>
            </details>
        </li>
        <li v-for="(value, key, index)  in groupSearch.children" :key="index">
            <search_tree :container="container" :compDataSource="compDataSource" :catalogosData="catalogosData" v-model:allTree="allTree"
                v-model="groupSearch.children[key]" :isSummary="true" :advancedSearch="advancedSearch" ::cleanFilter="cleanFilter"> </search_tree>
        </li>
    </ul>
    <details v-if="isSummary" open>
        <summary>
            <Toolbar class="mb-3 mr-1">
                <template #start>
                    <SelectButton v-model="groupSearch.operatorLogic" :options="logicOperatorsOptions" optionLabel="name" optionValue="value"
                        aria-labelledby="basic"  @click="stopPropagateEvent($event)" />
                    <Dropdown v-model="selectedSearch" :options="getData" filter optionLabel="label"
                        optionGroupLabel="label" optionValue="value" @change="selectQuestion($event)" @click="stopPropagateEvent($event)"
                        optionGroupChildren="items" placeholder="Selecciona un filtro" class="w-full md:w-14rem mr-2 ml-2">
                        <template #optiongroup="slotProps">
                            <div class="flex align-items-center">
                                <i :class="slotProps.option.icon"></i>
                                <div class="ml-2">{{ slotProps.option.label }}</div>
                            </div>
                        </template>
                    </Dropdown>
                    <Button icon="pi pi-plus" rounded outlined aria-label="Filter" class="ml-1" @click.stop="addChildNode($event)" />
                    <Button icon="pi pi-minus" rounded outlined aria-label="Filter" severity="danger" class="ml-1"  @click="deleteChild(groupSearch)" />

                </template>
            </Toolbar>
        </summary>
        <SearchFields :container="container" v-model="groupSearch.fields" :catalogosData="catalogosData"
                :compDataSource="compDataSource"  :fixCalendar="fixCalendar" :dataContext="getData" :cleanFilter="cleanFilter" @search="$emit('search')"></SearchFields>
        <search_tree v-for="(value, key, index)  in groupSearch.children" :key="index" :container="container"
            :compDataSource="compDataSource" :catalogosData="catalogosData" v-model="groupSearch.children[key]" v-model:allTree="allTree"
            :isSummary="false" :advancedSearch="advancedSearch" :cleanFilter="cleanFilter"> </search_tree>
    </details>


</template>
<script lang="ts">

import { defineComponent, computed, ref } from 'vue';
import { Container } from 'inversify';
import { IapCatalog } from '../../../catalog/domain/iapCatalog';
import { IapComponentDataSource } from '../../../component/domain/iapComponentDataSource';
import { GroupSearch } from '../../../search/domain/search';
import SearchFields from './SearchFields.vue'
import OperatorLogicConst from '../../../search/domain/Const/OperatorLogicConst';
import DataSourceComp from '../functions/dataSourceComp';
import { useI18n } from 'vue-i18n';
import { messages } from './localization/MessagesBusqueda'
import { useStore } from 'vuex';
import HelperCommon from '@ilinium/shared/src/common/infrastructure/funciones/HelperCommon';
import DataSourceConst from '../functions/dataSourceConst';
import SqlTypesConst from '@ilinium/shared/src/common/domain/constantes/SqlTypesConst';
import HelperUtils from '@ilinium/shared/src/common/infrastructure/funciones/HelperUtils';
import FiltroBusquedaConst from '@ilinium/shared/src/common/domain/constantes/FiltroBusquedaConst';

export default defineComponent({
    name: 'search_tree',
    props: {
        container: {
            type: Object as () => Container
        },
        compDataSource: {
            type: Object as () => IapComponentDataSource,
            default: () => ({})
        },
        catalogosData: {
            type: Object as () => IapCatalog[],
            default: () => ([])
        },
        modelValue: {
            type: Object as () => GroupSearch,
            default: () => ({})
        },
        isSummary: {
            type: Boolean,
            default: false
        },
        allTree: {
            type: Object as () => GroupSearch,
            default: () => ({})
        },
        isRoot: {
            type: Boolean,
            default: false
        },
        advancedSearch: {
            type: Boolean,
            default: false
        },
        cleanFilter: {
            type: Boolean,
            default: false
        },
    },

    emits: ['update:modelValue','deleteChild','search'],
    components: {
        SearchFields
    },
    setup(props, { emit }) {

        const logicOperatorsOptions = ref([
                { name: 'Y', value: OperatorLogicConst.AND },
                { name: 'O', value: OperatorLogicConst.OR },
            ]);
        // const logicOperatorsOptions=ref([OperatorLogicConst.AND, OperatorLogicConst.OR]);
        const selectedSearch = ref('');
        const { t, locale } = useI18n(messages)
        const store = useStore();
        const fixCalendar=ref(false);
        const {
            tablas,
            tablasData, isType,existeCampoFiltrado,initFilterField
        } = DataSourceComp(props.container as any, props, emit, t, props.compDataSource, props.catalogosData, store)


        const groupSearch = computed({
            get: () => props.modelValue,
            set: (val) => emit("update:modelValue", val),
        });

        const allTreeVal = computed({
            get: () => props.allTree,
            set: (val) => emit("update:modelValue", val),
        });



        const getData = computed(() => {

            var data = [] as any;
            

            if (tablasData.value.length > 0) {
                tablas.value.forEach(x => {
                    const aux = {
                        label: !HelperCommon.isNullOrWhitespace(x.shortDescription ?? '') ? x.shortDescription : x.tableName,
                        icon: x.idIcon ?? 'pi pi-list',
                        code: x.tableName,
                        items: tablasData.value.filter(t => t.dataSourceTableAliasId != null && t.dataSourceTableAliasId == x.id && props.compDataSource.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == t.id)?.filterSearch && (!props.advancedSearch?!existeCampoFiltrado(t,true):true) ).sort((a, b) => { return (props.compDataSource.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == a.id)?.filterSearchOrder ?? 0) - (props.compDataSource.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == b.id)?.filterSearchOrder ?? 0); }).map(t => ({
                            label: (t.shortDescription ?? t.field), tabla: t.tableName, value: t.tableName + DataSourceConst.DELIMITER + t.field, disabled: false,
                            command: (event: any) => {
                                selectedSearch.value = event.item.value
                                selectQuestion(event)
                            }
                        })),
                        visible: tablasData.value.filter(t => t.tableName == x.tableName && props.compDataSource.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == t.id)?.filterSearch && (!props.advancedSearch?!existeCampoFiltrado(t,true):true) ).length > 0

                    }

                    data.push(aux);
                })
            }


            const auxCustom = {
                label: 'Calculados',
                icon: 'pi pi-list',
                code: DataSourceConst.AGGREGATE_TABLE,
                items: tablasData.value.filter(t => t.dataSourceTableAliasId == null && props.compDataSource.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == t.id)?.filterSearch && (!props.advancedSearch?!existeCampoFiltrado(t,true):true) ).sort((a, b) => { return (props.compDataSource.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == a.id)?.filterSearchOrder ?? 0) - (props.compDataSource.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == b.id)?.filterSearchOrder ?? 0); }).map(t => ({
                    label: (t.shortDescription ?? t.fieldAlias), tabla: t.tableName, value: t.tableName + DataSourceConst.DELIMITER + t.fieldAlias, disabled: false,
                    command: (event: any) => {
                        
                        selectedSearch.value = event.item.value
                        selectQuestion(event)
                    }
                })),
                visible: tablasData.value.filter(t => t.dataSourceTableAliasId == null && props.compDataSource.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == t.id)?.filterSearch && (!props.advancedSearch?!existeCampoFiltrado(t,true):true) ).length > 0

            }

            if (auxCustom.items.length > 0) {
                data.push(auxCustom);
            }

            return data;
        })



        const selectQuestion = (event: any) => {
            
            const aux = selectedSearch.value.split(DataSourceConst.DELIMITER)

            tablasData.value.filter(x => x.tableName == aux[0] && (x.field == aux[1] || x.fieldAlias == aux[1])).forEach(c => {
                groupSearch.value.fields.push({
                    id:HelperUtils.newGuid().toString().replaceAll('-',''),
                    fieldId: c.id as any,
                    rangeDateTime: {
                        from: null,
                        to: null
                    },
                    rangeValue: {
                        from: null,
                        to: null
                    },
                    filter: initFilterField(c)
                } as any)
            })

            groupSearch.value.fields.sort((a, b) => { return (props.compDataSource.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == a.fieldId)?.defaultFilterSearchOrder ?? 0) - (props.compDataSource.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == b.fieldId)?.defaultFilterSearchOrder ?? 0); });

            selectedSearch.value = ''

            // //arreglo input calendar por bug primevue
            fixCalendar.value=true;
            //reestablecemos valor inicial
            setTimeout(() => {

                fixCalendar.value=false;

            }, 1000)

        }



        const stopPropagateEvent = (event:any,stop:boolean=true) => {
            if(stop){
                event.stopPropagation();
                event.preventDefault();
            }
        }



        const addChildNode=(event:any)=>{
            groupSearch.value.children.push({
                operatorLogic:OperatorLogicConst.AND,
                fields:[],
                children:[]

            } as any);

        }

        const deleteChild=(data:GroupSearch)=>{
            removeChildFromTree(allTreeVal.value,data);
        }



        const removeChildFromTree = (data: GroupSearch, dataToCompare: GroupSearch) => {
            data.children.forEach((child, index, array) => {
                if (JSON.stringify(child) === JSON.stringify(dataToCompare)) {
                    array.splice(index, 1);
                }
                if (child.children.length > 0) {
                    removeChildFromTree(child, dataToCompare);
                }
            });
        };

      


        return {
            groupSearch,
            logicOperatorsOptions,
            selectedSearch,
            getData,
            selectQuestion,
            addChildNode,
            stopPropagateEvent,
            deleteChild,
            fixCalendar,
      
        };
    },
});
</script>
<style scoped>
.tree{
  --spacing : 1.5rem;
  --radius  : 10px;
}

.tree li{
  display      : block;
  position     : relative;
  padding-left : calc(2 * var(--spacing) - var(--radius) - 2px);
}

.tree ul{
  margin-left  : calc(var(--radius) - var(--spacing));
  padding-left : 0;
}

/* .tree ul li{
  border-left : 2px solid #ddd;
}

.tree ul li:last-child{
  border-color : transparent;
}

.tree ul li::before{
  content      : '';
  display      : block;
  position     : absolute;
  top          : calc(var(--spacing) / -2);
  left         : -2px;
  width        : calc(var(--spacing) + 2px);
  height       : calc(var(--spacing) + 1px);
  border       : solid #ddd;
  border-width : 0 0 2px 2px;
} */

.tree summary{
  display : block;
  cursor  : pointer;
}

.tree summary::marker,
.tree summary::-webkit-details-marker{
  display : none;
}

.tree summary:focus{
  outline : none;
}

.tree summary:focus-visible{
  outline : 1px dotted #000;
}
</style>
<style lang="scss" scoped>
::v-deep(.p-toolbar) {
    padding: 0.5rem 0.50rem !important;
}
</style>
