export default class HelperPrime{

    public static getPrimeOptions(): { locale: { IsNull: string; IsNotNull: string; CurrentMonth: string; PreviousMonth: string; CurrentYear: string; PreviousYear: string; CurrentWeek: string; PreviousWeek: string; startsWith: string; contains: string; notContains: string; endsWith: string; equals: string; notEquals: string; noFilter: string; lt: string; lte: string; gt: string; gte: string; dateIs: string; dateIsNot: string; dateBefore: string; dateAfter: string; clear: string; apply: string; matchAll: string; matchAny: string; addRule: string; removeRule: string; accept: string; reject: string; choose: string; upload: string; cancel: string; dayNames: string[]; dayNamesShort: string[]; dayNamesMin: string[]; monthNames: string[]; monthNamesShort: string[]; today: string; weekHeader: string; firstDayOfWeek: number; dateFormat: string; weak: string; medium: string; strong: string; passwordPrompt: string; emptyFilterMessage: string; emptyMessage: string; aria: { star: string; stars: string; selectAll: string; unselectAll: string; close: string; previous: string; next: string; navigation: string; scrollTop: string; moveTop: string; moveUp: string; moveDown: string; moveBottom: string; moveToTarget: string; moveToSource: string; moveAllToTarget: string; moveAllToSource: string; pageLabel: string; firstPageLabel: string; lastPageLabel: string; nextPageLabel: string; prevPageLabel: string; rowsPerPageLabel: string; jumpToPageDropdownLabel: string; jumpToPageInputLabel: string; selectRow: string; unselectRow: string; expandRow: string; collapseRow: string; showFilterMenu: string; hideFilterMenu: string; filterOperator: string; filterConstraint: string; editRow: string; saveEdit: string; cancelEdit: string; listView: string; gridView: string; slide: string; slideNumber: string; zoomImage: string; zoomIn: string; zoomOut: string; rotateRight: string; rotateLeft: string } }; ripple: boolean; filterMatchModeOptions: { text: string[]; numeric: string[]; date: string[] } } {
        return {
          locale: {
            IsNull: 'Es Nulo',
            IsNotNull: 'No es Nulo',
            CurrentMonth: 'Mes actual',
            PreviousMonth: 'Mes anterior',
            CurrentYear: 'Año actual',
            PreviousYear: 'Año anterior',
            CurrentWeek: 'Semana actual',
            PreviousWeek: 'Semana anterior',
            startsWith: 'Comienza con',
            contains: 'Contiene',
            notContains: 'No contiene',
            endsWith: 'Termina con',
            equals: 'Igual',
            notEquals: 'No es igual',
            noFilter: 'Sin filtro',
            lt: 'Menos que',
            lte: 'Menos que o igual a',
            gt: 'Mayor que',
            gte: 'Mayor qué o igual a',
            dateIs: 'La fecha es',
            dateIsNot: 'La fecha no es',
            dateBefore: 'La fecha es anterior',
            dateAfter: 'La fecha es posterior',
            clear: 'Borrar',
            apply: 'Aplicar',
            matchAll: 'Coincidir con todo',
            matchAny: 'Coincidir con cualquiera',
            addRule: 'Agregar regla',
            removeRule: 'Eliminar regla',
            accept: 'Sí',
            reject: 'No',
            choose: 'Seleccionar',
            upload: 'Subir ficheros',
            cancel: 'Cancelar',
            dayNames: [
              'Domingo',
              'Lunes',
              'Martes',
              'Miércoles',
              'Jueves',
              'Viernes',
              'Sábado'
            ],
            dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sáb'],
            dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
            monthNames: [
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre'
            ],
            monthNamesShort: [
              'Ene',
              'Feb',
              'Mar',
              'Abr',
              'May',
              'Jun',
              'Jul',
              'Agto',
              'Sep',
              'Oct',
              'Nov',
              'Dic'
            ],
            today: 'Hoy',
            weekHeader: 'Sem',
            firstDayOfWeek: 1,
            dateFormat: 'dd/mm/yy',
            weak: 'Débil',
            medium: 'Media',
            strong: 'Fuerte',
            passwordPrompt: 'Introduzca una contraseña',
            emptyFilterMessage: 'No se han encontrado resultados',
            emptyMessage: 'No hay opciones disponibles',
            aria: {
              star: '1 star',
              stars: '{star} stars',
              selectAll: 'All items selected',
              unselectAll: 'All items unselected',
              close: 'Close',
              previous: 'Previous',
              next: 'Next',
              navigation: 'Navigation',
              scrollTop: 'Scroll Top',
              moveTop: 'Move Top',
              moveUp: 'Move Up',
              moveDown: 'Move Down',
              moveBottom: 'Move Bottom',
              moveToTarget: 'Move to Target',
              moveToSource: 'Move to Source',
              moveAllToTarget: 'Move All to Target',
              moveAllToSource: 'Move All to Source',
              pageLabel: 'Page {page}',
              firstPageLabel: 'First Page',
              lastPageLabel: 'Last Page',
              nextPageLabel: 'Next Page',
              prevPageLabel: 'Previous Page',
              rowsPerPageLabel: 'Rows per page',
              jumpToPageDropdownLabel: 'Jump to Page Dropdown',
              jumpToPageInputLabel: 'Jump to Page Input',
              selectRow: 'Row Selected',
              unselectRow: 'Row Unselected',
              expandRow: 'Row Expanded',
              collapseRow: 'Row Collapsed',
              showFilterMenu: 'Show Filter Menu',
              hideFilterMenu: 'Hide Filter Menu',
              filterOperator: 'Filter Operator',
              filterConstraint: 'Filter Constraint',
              editRow: 'Row Edit',
              saveEdit: 'Save Edit',
              cancelEdit: 'Cancel Edit',
              listView: 'List View',
              gridView: 'Grid View',
              slide: 'Slide',
              slideNumber: '{slideNumber}',
              zoomImage: 'Zoom Image',
              zoomIn: 'Zoom In',
              zoomOut: 'Zoom Out',
              rotateRight: 'Rotate Right',
              rotateLeft: 'Rotate Left',
            }
          },
          ripple: true,
    
          filterMatchModeOptions: {
            text: ["startsWith", "contains", "notContains", "endsWith", "equals", "notEquals", 'IsNull', 'IsNotNull'],
            numeric: ["equals", "notEquals", "lt", "lte", "gt", "gte", 'IsNull', 'IsNotNull'],
            date: ["dateIs", "dateIsNot", "dateBefore", "dateAfter", 'IsNull', 'IsNotNull', 'CurrentMonth', 'PreviousMonth', 'CurrentYear', 'PreviousYear', 'CurrentWeek', 'PreviousWeek']
          }
        }
      }

}