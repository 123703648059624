import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: ExpressionDetailInputType!){
    expressionDetailMutation{
        updateVariable(data:$data){
            id
            expressionId
            variable
            expression
            expressionTranslated
            processOrder
            localizable
            fcr
            ucr
            uum
            fum
        }
    }
}
`);

export const ADD = gql(`
mutation($data: ExpressionDetailInputType!){
    expressionDetailMutation{
        addVariable(data:$data){
            id
            expressionId
            variable
            expression
            expressionTranslated
            processOrder
            localizable
            fcr
            ucr
            uum
            fum
        }
    }
}
`);


export const DELETE = gql(`
mutation($id: ID!){
    expressionDetailMutation{
        deleteVariable(id:$id)
    }
}
`);


