import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'
import { IapWorkFlow } from '../domain/service/iapWorkFlow'
import { IServiceWorkFlow } from './interface/IServiceWorkFlow'
import type { IWorkFlowRepository } from '../domain/repositorry/IWorkFlowRepository'





@injectable()
export class WorkFlowService implements IServiceWorkFlow {
  private readonly repo: IWorkFlowRepository

  public constructor (@inject(TYPES.WORKFLOW_REPOSITORY) _repo: IWorkFlowRepository) {
    this.repo = _repo
  }
  async deleteWorkFlowByObjectId (applicationId: number, applicationVersion: number, idObject: string, objectId: string) : Promise<boolean>{
    return await this.repo.deleteWorkFlowByObjectId(applicationId, applicationVersion, idObject, objectId)
  }
  
  async delete (id: number): (Promise<boolean>) {
    return await this.repo.delete(id);
  }

  async update (data: IapWorkFlow): (Promise<IapWorkFlow>) {
    return await this.repo.update(data);
  }

  async add (data: IapWorkFlow): (Promise<IapWorkFlow>) {
    return await this.repo.add(data);
  }

  async getByObjectId(idObject: string, objectId: string):Promise<IapWorkFlow> {
    return await this.repo.getByObjectId(idObject,objectId)
  }

  async getById(id: number) :Promise<IapWorkFlow> {
    return await this.repo.getById(id)
  }
  
  async getByObjectRootId(idObject: string, objectId: string):Promise<IapWorkFlow[]> {
    return await this.repo.getByObjectRootId(idObject,objectId)
  }
 
}
