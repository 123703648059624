import { ClassicPreset } from "rete";
import { Container } from 'inversify';
import { IapWorkFlowActivity } from "../../../../domain/service/iapWorkFlowActivity";

export class DisplayComponentControl extends ClassicPreset.Control {
  constructor(public formKeyInput: String
    ,public rootComponentId:number
    ,public currentComponentId:number
    ,public applicationId:number
    , public applicationVersion:number
    ,public variableComponentInput: number
    ,public variableComponentHeaderInput:string
    , public variableComponentModalInput: boolean
    ,public variableComponentParametersInput:[]
    , public variableComponentCurrInput: boolean
    ,public container: Container | undefined
    ,public itemActivity: IapWorkFlowActivity | undefined
    , public onChange: (evt: any) => void
    ,public getNode: (evt: any) => void
    , public showExpression:(evt:any) => void) {
    super();
  }
}