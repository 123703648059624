<template>
   <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />    
    <CascadeSelect v-if="loaded && dataValue && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :filter=true
        :id="getPropertyValue(DropDownCascadeTypeConst.ID)" v-tooltip="getPropertyValue(DropDownCascadeTypeConst.TOOLTIP)"
        :style="getPropertyValue(DropDownCascadeTypeConst.STYLE)"
        :class="{ [getPropertyValue(DropDownCascadeTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted,'p-readonly': getPropertyBooleanValue(DropDownCascadeTypeConst.READONLY) }"
        :name="getPropertyValue(DropDownCascadeTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(DropDownCascadeTypeConst.DISABLED) || (!canEdit)"
        :visible="getPropertyBooleanValue(DropDownCascadeTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :placeholder="getPropertyValue(DropDownCascadeTypeConst.PLACEHOLDER)"
        :options="dataValue"
        :readonly="getPropertyBooleanValue(DropDownCascadeTypeConst.READONLY) || !canDoOperation(ObjectGroupConst.EDIT)"
        :dataKey="getPropertyValue(DropDownCascadeTypeConst.DATAKEY)"
        :optionLabel="getPropertyValue(DropDownCascadeTypeConst.OPTIONLABEL)"
        :optionValue="getPropertyValue(DropDownCascadeTypeConst.OPTIONVALUE)"
        :optionDisabled="getPropertyValue(DropDownCascadeTypeConst.OPTIONDISABLED)"
        :optionGroupLabel="getPropertyValue(DropDownCascadeTypeConst.OPTIONGROUPLABEL)"
        :optionGroupChildren="getPropertyValueObject(DropDownCascadeTypeConst.OPTIONGROUPCHILDREN,true)" 
        @change="processEventComponent(CatalogEventConst.CHANGE)"
        v-model="vmodel">
    </CascadeSelect>
    
    <CustomValidate v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE)" v-model:submitted="submitted" v-model:vObject="v$" />
</template>
<script lang="ts">


import { defineComponent, onMounted,  onBeforeUnmount, shallowRef, watchEffect } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import DropDownCascadeTypeConst from '../../../../domain/Constants/DropDownCascadeTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import CustomValidate from '../../shared/CustomValidate.vue';
import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';	

export default defineComponent({
    name: 'dynamic_drop_down_cascade',
    components: {
        CustomValidate,
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },      
        slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation,getPropertyValueOption,getPropertyValueObject,getPropertyValueObjectOrFile, 
            getPropertyValue, getPropertyBooleanValue, loaded,  
            resolveExpressions, baseOnMounted, baseOnBeforeUnmount, v$, submitted, 
            canEdit,isValidData,fieldKey,fieldKeyComponentDataSourceId, processEventComponent ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);

        const dataValue = shallowRef<any[] | undefined>(undefined)
      

        const loadData = () =>{
            getPropertyValueObjectOrFile(DropDownCascadeTypeConst.OPTIONS,DropDownCascadeTypeConst.OPTION_FILE,DropDownCascadeTypeConst.DATASOURCEVALUE,null,true)
            .then((response:any) =>{
                if (response){
                    dataValue.value = response;
                }
            });
        }

        onMounted(() => {
            baseOnMounted();
            watchEffect(() => {
                if (loaded.value == true) {
                    loadData();
                }
            })
            
        })

        onBeforeUnmount(() => {
            dataValue.value = undefined;
            baseOnBeforeUnmount();
        })


        return {
            
            getPropertyValue,
            getPropertyValueOption,
            getPropertyBooleanValue,
            vmodel,
            DropDownCascadeTypeConst,
            loaded,
            BaseControlTypeConst,
            v$,
            submitted,
            canEdit,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,            
            processEventComponent,
            CatalogEventConst,
            ObjectGroupConst
            ,canDoOperation
            ,getPropertyValueObject
            ,getPropertyValueObjectOrFile
            ,componentKey
            ,dataValue
    

        };
    },
});
</script>
<style scoped></style>
