import gql from 'graphql-tag';

export const GET_BY_OBJECTID = gql(`
query($applicationId: Int!, $applicationVersion: Int!,$idObject:String!) {
    objectGroupQuery {
        getByIdObject(applicationId: $applicationId, applicationVersion: $applicationVersion, idObject:$idObject) {
            idObjeto
            objetoId
            objetoIdRoot
            idObjetoRoot
            groupId
            applicationId
            applicationVersion
            add
            edit
            list
            delete
            view
            import
            export
            execute
    }
  }
}
`);


export const GET_BY_IDOBJECT = gql(`
query($applicationId: Int!, $applicationVersion: Int!,$idObject:String!,$objectId: String!) {
    objectGroupQuery {
        getByObjectId(applicationId: $applicationId, applicationVersion: $applicationVersion, idObject:$idObject,objectId:$objectId) {
            idObjeto
            objetoId
            objetoIdRoot
            idObjetoRoot
            groupId
            applicationId
            applicationVersion
            add
            edit
            list
            delete
            view
            import
            export
            execute
    }
  }
}
`);