<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
            
    <div :class="{ 'p-inputgroup flex-1': hasGroupControls }">
        <Textarea v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
            :key="componentKey"
            :id="getPropertyValue(TextAreaTypeConst.ID)" v-tooltip="getPropertyValue(TextAreaTypeConst.TOOLTIP)"
            :style="getPropertyValue(TextAreaTypeConst.STYLE)"
            :class="{ [getPropertyValue(TextAreaTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted ,'p-readonly': getPropertyBooleanValue(TextAreaTypeConst.READONLY)}"
            :name="getPropertyValue(TextAreaTypeConst.NAME)"
            :disabled="getPropertyBooleanValue(TextAreaTypeConst.DISABLED) || (!canEdit)"
            :visible="getPropertyBooleanValue(TextAreaTypeConst.VISIBLE)"
            :placeholder="getPropertyValue(TextAreaTypeConst.PLACEHOLDER)"
            :rows="getPropertyNumberValue(TextAreaTypeConst.ROWS)" 
            :cols="getPropertyNumberValue(TextAreaTypeConst.COLS)" 
            :type="getPropertyValue(TextAreaTypeConst.TYPE)"
            :readonly="getPropertyBooleanValue(TextAreaTypeConst.READONLY) || !canDoOperation(ObjectGroupConst.EDIT)"
            :autoResize="getPropertyBooleanValue(TextAreaTypeConst.AUTORESIZE)" v-model="vmodel" >
            </Textarea>

        <span v-if="hasGroupControls" :class="{ 'p-inputgroup-addon': hasGroupControls }">
            <Mic v-if="getPropertyBooleanValue(TextAreaTypeConst.MIC)" v-model="vmodel"></Mic>
            <i v-if="getPropertyBooleanValue(TextAreaTypeConst.COPYTOCLIPBOARDB)" class="ml-1 pi pi-copy"
                style="color: rgb(0, 128, 128)" @click="HelperCommon.copyToClipBoard(vmodel)"></i>
        </span>

    </div>
    <CustomValidate v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" v-model:submitted="submitted" v-model:vObject="v$" />
</template>
<script lang="ts">


import { defineComponent, onMounted,onBeforeUnmount, computed } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import TextAreaTypeConst from '../../../../domain/Constants/TextAreaTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import CustomValidate from '../../shared/CustomValidate.vue';
import Mic from '../../../../../../../common/infrastructure/componentes/base/common/mic/Mic.vue';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_text_area',
    components: {
        CustomValidate,
        Mic,
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },

        slotProps: {
            type: Object,
            default: () => ({})
        },



    },
    setup(props, context) {
        //
        const store = useStore();
        const { vmodel,canDoOperation, loaded, resolveExpressions, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue, 
            baseOnMounted, baseOnBeforeUnmount, v$, submitted, canEdit,isValidData
            ,fieldKey,fieldKeyComponentDataSourceId ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);



        const hasGroupControls = computed(() => {
            return getPropertyBooleanValue(TextAreaTypeConst.MIC) || getPropertyBooleanValue(TextAreaTypeConst.COPYTOCLIPBOARDB)
        })

        onMounted(() => {

            baseOnMounted();

        })

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })




        return {
              getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            TextAreaTypeConst,
            getPropertyNumberValue,
            loaded,
            BaseControlTypeConst,
            v$,
            submitted,
            canEdit,
            hasGroupControls,
            HelperCommon,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
        };
    },




});
</script>
<style scoped></style>
