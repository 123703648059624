import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IApplicationNoteRepository } from '../domain/IApplicationNoteRepository';



import { IServiceApplicationNote } from './IServiceApplicationNote';
import { IapApplicationNote } from '../domain/iapApplicationNote';





@injectable()
export class ApplicationNoteService implements IServiceApplicationNote {
  private readonly repo: IApplicationNoteRepository

  public constructor (@inject(TYPES.APPLICATIONNOTE_REPOSITORY) _repo: IApplicationNoteRepository) {
    this.repo = _repo
  }
  
  async update (data: IapApplicationNote): (Promise<IapApplicationNote>) {
    return await this.repo.update(data);
  }

  async getAll(maxregs: number | undefined) :Promise<IapApplicationNote[]> {
    return await this.repo.getAll(maxregs)
  }

  async getById(id: number) :Promise<IapApplicationNote> {
    return await this.repo.getById(id)
  }

  async getByApplicationId(applicationId: number, applicationVersion: number) :Promise<IapApplicationNote[]> {
    return await this.repo.getByApplicationId(applicationId, applicationVersion)
  }

  async delete(id: number) :Promise<boolean> {
    return await this.repo.delete(id)
  }

  async add (data: IapApplicationNote): (Promise<IapApplicationNote>) {
    return await this.repo.add(data);
  }


}
