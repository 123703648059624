import BaseControlTypeConst from "./BaseControlTypeConst"

export default class TreeTypeConst extends BaseControlTypeConst {

    //static BINARY = 'binary'
    static READONLY = 'readonly'
    //static REQUIRED = 'required'
    static TABINDEX = 'tabIndex'

    static DATAVALUE = 'datavalue'
    static DATAFILEVALUE = 'datafilevalue'
    static DATASOURCEVALUE = 'datasourcevalue'
    static SELECTIONMODE = 'selectionMode'
    static METAKEYSELECTION = 'metaKeySelection'
    static FILTER = 'filter'
    static FILTERBY = 'filterBy'
    static FILTERMODE = 'filterMode'
    static FILTERPLACEHOLDER = 'filterPlaceholder'
    static FILTERLOCALE = 'filterLocale'
    static HIGHLIGHTONSELECT = 'highlightOnSelect'
    static SCROLLHEIGHT = 'scrollHeight'
    static LOADING = 'loading'
    static LOADINGICON = 'loadingIcon'
    static LOADINGMODE = 'loadingMode'

    static EXPANDONLOAD = 'expandOnLoad'
    static EXPANDBTNS = 'showExpandButtons'

    static LAYOUT_MENU = 'layouttemp-tree-menu'
}