/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { Dictionary } from "../../../expression/domain/dictionary";
import CatalogObjectTypeConst from "../../../catalog/domain/const/CatalogObjectTypeConst";
import { ProcedureData } from "../../../search/domain/procedureData";
import { IapComponentAttribute } from "../../../component/domain/iapComponentAttribute";
import { ref } from "vue";
import { IapExpression } from "../../../expression/domain/iapExpression";
import { Container } from "inversify";
import HelperLoading from "../../../../../common/infrastructure/funciones/HelperLoading";
import { IServiceExpression } from "../../../expression/application/IServiceExpression";
import { TYPES } from "../../../../../common/domain/types";
import { IapWorkFlowActivity } from "../../domain/service/iapWorkFlowActivity";


export default function HelperCustomComponentControls
  (
    dataInput: any
  ) {

  const expressions = ref<IapExpression[]>([]);

  const hasExpression = (objectId: string, parameterId:string | null = null): boolean => {
    
    
    if (dataInput.container) {
      const activity = dataInput.itemActivity as IapWorkFlowActivity | undefined;
      if (activity) {
        const ctrl = activity.iapWorkFlowActivityControls.find(x => x.name == objectId);
        if (ctrl) {
          return (expressions.value?.filter(x => x.objetoId.endsWith('#' + ctrl.id.toString())
          || x.objetoId.endsWith('#parameterName='+objectId)
          || x.objetoId.endsWith('#parameterId='+parameterId)
          || x.objetoId.endsWith('#parameterName='+parameterId)
          )?.length ?? 0) > 0
        }

      }

    }

    return false;
  }

  const loadExpressions = () => {
    
    if (dataInput.container) {
      const _srv = (dataInput.container as Container).get<IServiceExpression>(TYPES.EXPRESSION_REPOSITORY)
      HelperLoading.showLoading()
      _srv.getByObjectType([CatalogObjectTypeConst.WFAC], CatalogObjectTypeConst.COMP, (dataInput?.currentComponentInputId ?? dataInput?.currentComponentId ?? -1).toString()
      ).then(response => {
        if (response) {

          expressions.value = response;
        }
      })
        .finally(() => {
          HelperLoading.hideLoading()
        })
    }

  };


  const showExpression = (variableName: string, header: string, item: null | ProcedureData | IapComponentAttribute | Dictionary<string, string> = null) => {
    if (dataInput) {
      const data = dataInput.getNode(variableName)
      if (data) {

        var doCallbackCloseEditorExpresion = () => {          

          loadExpressions();

        }

        let objectDescription = header;
        let objetoId = data['id']?.toString()
        if (objetoId) {
          const idObjeto = CatalogObjectTypeConst.WFAC;
          //objetoId += '#'+ variableName + '=' + item.id;
          if (item && (item as any).parameterName) {
            objetoId += '#parameterName=' + (item as ProcedureData).parameterName
          }
          if (item && (item as any).idAttributeType) {
            objetoId += '#parameterId=' + (item as IapComponentAttribute).id;
          }
          if (item && (item as any).key) {
            objetoId += '#parameterId=' + (item as Dictionary<string, string>).key;
          }


          dataInput.showExpression({ objetoId: objetoId, idObjeto: idObjeto, description: objectDescription, callBackEndExpressions: doCallbackCloseEditorExpresion })
        }
      }
    }
  }

  loadExpressions();

  return {
    showExpression,
    loadExpressions,
    hasExpression,    
  };
}
