import BaseControlTypeConst from "./BaseControlTypeConst"

export default class MenuTypeConst extends BaseControlTypeConst {
    static MODEL = 'model'
    static POPUP = 'popup'
    static APPENDTO = 'appendTo'
    static AUTOZINDEX = 'autoZIndex'
    static BASEZINDEX = 'baseZIndex'
    static EXACT = 'exact'
    static TABINDEX = 'tabindex'
    static ARIA_LABEL = 'aria-label'
    static ARIA_LABELLEDBY = 'aria-labelledby'
    static PT = 'pt'
    static PTOPTIONS = 'ptOptions'
    static UNSTYLED = 'unstyled'
    static LAYOUT_END= 'layouttemp-menu-end'
    static LAYOUT_ITEM= 'layouttemp-menu-item'
    static LAYOUT_ITEMICON= 'layouttemp-menu-itemicon'
    static LAYOUT_START= 'layouttemp-menu-start'
    static LAYOUT_SUBHEADER= 'layouttemp-menu-submheader'

}
