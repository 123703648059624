import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_SplitButton = _resolveComponent("SplitButton")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? _withDirectives((_openBlock(), _createBlock(_component_SplitButton, {
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.SplitButtonTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.SplitButtonTypeConst.STYLE)),
          class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.SplitButtonTypeConst.CLASS) ?? '']: true }),
          name: _ctx.getPropertyValue(_ctx.SplitButtonTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.SplitButtonTypeConst.DISABLED),
          placeholder: _ctx.getPropertyValue(_ctx.SplitButtonTypeConst.PLACEHOLDER),
          label: _ctx.getPropertyValue(_ctx.SplitButtonTypeConst.LABEL),
          icon: _ctx.getPropertyValue(_ctx.SplitButtonTypeConst.ICON),
          raised: _ctx.getPropertyBooleanValue(_ctx.SplitButtonTypeConst.RAISED),
          rounded: _ctx.getPropertyBooleanValue(_ctx.SplitButtonTypeConst.ROUNDED),
          outlined: _ctx.getPropertyBooleanValue(_ctx.SplitButtonTypeConst.OUTLINED),
          text: _ctx.getPropertyBooleanValue(_ctx.SplitButtonTypeConst.TEXT),
          plain: _ctx.getPropertyValue(_ctx.SplitButtonTypeConst.PLAIN),
          size: _ctx.getCatalogValue(_ctx.SplitButtonTypeConst.SIZE),
          model: _ctx.menuItems,
          menuButtonProps: _ctx.menuButtonProps,
          onClick: _cache[0] || (_cache[0] = ($event: any) => ( _ctx.processEventComponent(_ctx.CatalogEventConst.CLICK)))
        }, null, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "placeholder", "label", "icon", "raised", "rounded", "outlined", "text", "plain", "size", "model", "menuButtonProps"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.SplitButtonTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}