import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: ModeloComponentAttributeTemplateInputType!){
    componentAttributeTemplateMutation{
        updateComponentAttributeTemplate(data:$data)
    }
}
`);

export const ADD = gql(`
mutation($data: ModeloComponentAttributeTemplateInputType!){
    componentAttributeTemplateMutation{
        addComponentAttributeTemplate(data:$data)
    }
}
`);



