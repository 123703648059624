import BaseControlTypeConst from "./BaseControlTypeConst"

export default class KnobTypeConst extends BaseControlTypeConst {
    static SIZE = 'size'
    static READONLY = 'readonly'
    static STEP = 'step'
    static MIN = 'min'
    static MAX = 'max'
    static VALUECOLOR = 'valueColor'
    static RANGECOLOR = 'rangeColor'
    static TEXTCOLOR = 'textColor'
    static STROKEWIDTH = 'strokeWidth'
    static SHOWVALUE = 'showValue'
    static VALUETEMPLATE = 'valueTemplate'
    static TABINDEX = 'tabindex'
    static ARIA_LABELLEDBY = 'aria-labelledby'
    static ARIA_LABEL = 'aria-label'
    static UNSTYLED = 'unstyled'
}
