import BaseControlTypeConst from "./BaseControlTypeConst"

export default class MultiSelectTypeConst extends BaseControlTypeConst {

    static DATAKEY = 'datakey'
    static OPTIONS = 'options'
    static OPTIONLABEL = 'optionLabel'
    static OPTIONVALUE = 'optionValue'
    static OPTIONDISABLED = 'optionDisabled'
    static OPTIONGROUPLABEL = 'optionGroupLabel'
    static OPTIONGROUPCHILDREN = 'optionGroupChildren'
    static SCROLLHEIGHT = 'scrollHeight'
    static FILTER = 'filter'
    static FILTERPLACEHOLDER = 'filterPlaceholder'
    static DISPLAY = 'display'
    static SELECTEDITEMSLABEL = 'selectedItemsLabel'
    static MAXSELECTEDLABELS = 'maxSelectedLabels'
    static SELECTIONLIMIT = 'selectionLimit'
    static SHOWTOGGLEALL = 'showToggleAll'
    static SELECTALL = 'selectAll'
    static RESETFILTERONHIDE = 'resetFilterOnHide'
    static AUTOOPTIONFOCUS = 'autoOptionFocus'
    static AUTOFILTERFOCUS = 'autoFilterFocus'
    static FILTERMESSAGE = 'filterMessage'
    static SELECTIONMESSAGE = 'selectionMessage'
    static EMPTYMESSAGE = 'emptyMessage'
    static READONLY='readonly'

    static DATAFILEVALUE = 'datafilevalue'
    static DATASOURCEVALUE = 'dataSource'

    static CATALOGTYPE = 'catalogType'


}
