import gql from 'graphql-tag';

export const GET_ALL = gql(`
 query($applicationId:Int,$applicationVersion:Int, $idObjeto: String!,$objetoId: String!) {
  codeQuery {
        getAll(applicationId:$applicationId,applicationVersion:$applicationVersion, idObjeto:$idObjeto,objetoId:$objetoId) {
          id
          applicationId
          applicationVersion
          objetoId
          idObjeto
          idObjetoRoot
          objetoIdRoot
          fileName
          description
          idFileType
          group
          code
          active
          fcr
          ucr
          uum
          fum
      }
    }
  }
`);


export const GET_BY_ID = gql(`
query($id: ID!) {
  codeQuery {
        getById(id:$id) {
          id
          applicationId
          applicationVersion
          objetoId
          idObjeto
          idObjetoRoot
          objetoIdRoot
          fileName
          description
          idFileType
          group
          code
          active
          fcr
          ucr
          uum
          fum

      }
    }
  }
`);
