import { ClassicPreset } from 'rete';
import { AreaPlugin } from 'rete-area-plugin';
import { AreaExtra, Schemes } from '../rete/helperRete';
import { LocalService } from '../../../../../../../common/infrastructure/servicios';
import { Router } from 'vue-router';
import { Container } from 'inversify';
import { IapComponent } from '../../../../../component/domain/iapComponent';
import CatalogObjectTypeConst from '../../../../../catalog/domain/const/CatalogObjectTypeConst';
import CatalogExpConst from '../../../../../catalog/domain/const/CatalogExpConst';
import { IapWorkFlowActivityControl } from '../../../../domain/service/iapWorkFlowActivityControl';
import { ExpresionEngine } from '../../../../../expression/infrastructure/helper/expressionEngine';
import { IapWorkFlowActivity } from '../../../../domain/service/iapWorkFlowActivity';
import { DisplayComponentControl } from '../controls/displayComponentControl';
import { IapComponentAttribute } from '../../../../../component/domain/iapComponentAttribute';
import EventBusCustom from '../../../../../../../common/infrastructure/event-bus-custom';
import EventConst from '../../../../../../../common/domain/constantes/EventConst';
import DisplayComponentNodeConst from '../constants/DisplayComponentNodeConst';
import CatalogAttrConst from '../../../../../catalog/domain/const/CatalogAttrConst';
import { DataflowEngine } from 'rete-engine';
import HelperUtils from '../../../../../../../common/infrastructure/funciones/HelperUtils';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';
import OperationDataTypeConst from '../../../../../../../common/domain/constantes/OperationDataTypeConst';
export class DisplayComponentNode extends ClassicPreset.Node<
  { ejecutar: ClassicPreset.Socket, dataInput: ClassicPreset.Socket },
  { ejecutar: ClassicPreset.Socket },
  { value: DisplayComponentControl }
> {
  height = 520;
  width = 380;

  //private value: Number;
  private keyEventComponent: string;
  private variableComponentHeader: string;
  private variableComponent: number;
  private variableComponentModal: boolean;
  private variableComponentCurr: boolean;
  private variableParamsInput: IapComponentAttribute[];
  private area: AreaPlugin<Schemes, AreaExtra>;
  private updateNode: any;
  private getNodeInternalData: any;
  private showExpression: any;
  private rdControlId: '';
  private router: Router;
  private container: Container | undefined;
  private rootComponentId: number;
  private currentComponentId: number;
  private store: any;
  private activity: IapWorkFlowActivity | undefined;
  private componentData: IapComponent[];
  private dataflow: DataflowEngine<Schemes>
  private formKey:String;
  private currentElementKey:string;
  

  constructor(area: AreaPlugin<Schemes, AreaExtra>, socket: ClassicPreset.Socket,public formKeyInput: String,public currentElementKeyInput:string, public rootComponentInputId: number, public currentComponentInputId: number, public applicationId: number, public applicationVersion: number, public variableComponentInput: number, public variableComponentHeaderInput: string, public variableComponentModalInput: boolean, public variableComponentParametersInput: [], public variableComponentCurrInput: boolean, updateNode: any = undefined, getNodeInternalData: any = undefined, showExpressionFunction: any = undefined, router: Router, container: Container | undefined, storeInput: any, itemActivity: IapWorkFlowActivity | undefined, dataflow: DataflowEngine<Schemes>,componentDataInput: IapComponent[]) {
    super("Display Component");
    this.area = area;
    this.dataflow = dataflow;
    this.updateNode = updateNode;
    this.getNodeInternalData = getNodeInternalData;
    this.showExpression = showExpressionFunction;
    this.variableComponentHeader = variableComponentHeaderInput;
    this.variableComponent = variableComponentInput;
    this.variableComponentModal = variableComponentModalInput;
    this.variableParamsInput = variableComponentParametersInput;
    this.variableComponentCurr = variableComponentCurrInput;
    this.router = router;
    this.container = container;
    this.rootComponentId = rootComponentInputId;
    this.currentComponentId = currentComponentInputId;
    this.store = storeInput;
    this.activity = itemActivity;
    this.keyEventComponent = formKeyInput + (rootComponentInputId ?? -1).toString() + '_' + EventConst.SHOWCOMPONENT;
    
    this.componentData = componentDataInput;
    this.formKey = formKeyInput;
    this.currentElementKey = currentElementKeyInput;
    const dsControl = new DisplayComponentControl(formKeyInput,rootComponentInputId, currentComponentInputId, applicationId, applicationVersion, variableComponentInput, variableComponentHeaderInput, variableComponentModalInput, variableComponentParametersInput,variableComponentCurrInput, container,itemActivity, this.updateData, this.getNode, this.showExp);

    this.rdControlId = (dsControl as any).id;

    this.addInput("ejecutar", new ClassicPreset.Input(socket, "Ejecutar", true));
    this.addControl(
      "value",
      dsControl

      //new ClassicPreset.InputControl("text", { initial })
    );

    this.addInput("dataInput", new ClassicPreset.Input(socket, "DataInput"));
    this.addOutput("ejecutar", new ClassicPreset.Output(socket, "Ejecutar"));


    //area.update("control",dsControl.id)
    //this.addOutput("value", new ClassicPreset.Output(socket, "Valor"));





  }

  showExp = (evt: any) => {
    if (this.showExpression) {
      return this.showExpression(evt)
    }
    return null;
  }

  getNode = (key: string) => {
    if (this.getNodeInternalData) {
      return this.getNodeInternalData(this.id, key, true, false)
    }
    return null;
  }
  updateData = async (evt: any) => {

    //this.value = evt
    //@ts-ignore:disable-next-line
    this.controls.value[evt.key] = evt.data;
    //this.controls[evt.key].valueConnection = evt.data;


    this.area.update("control", this.rdControlId)


    if (this.updateNode) {
      await this.updateNode(this.id, evt.key, JSON.stringify(evt.data), (evt?.operation ?? OperationDataTypeConst.UPDATE))
    }


  }

  resolveExpressions = () =>{
    const currentComp = this.componentData.find(x => x.id == this.currentComponentId)
        
        const WfData = currentComp?.workFlows.find(w => w.id == this.activity?.workFlowId )

        this.activity?.iapWorkFlowActivityControls.forEach((wcf: IapWorkFlowActivityControl) => {

          if (currentComp && wcf) 
          {
            
            if (wcf.name == DisplayComponentNodeConst.VAR_PARAM_IN) {
              this.variableParamsInput.forEach(param => {
                const keyParameters = '#' + wcf.id.toString() + '#parameterId=' + param.id;
                const exps = currentComp.expressions?.filter(x =>
                  x.idObjeto == CatalogObjectTypeConst.WFAC
                  && x.idTypeExpression == CatalogExpConst.EXP_SET
                  && x.iapExpressionDetails?.length > 0
                  && x.objetoId.endsWith(keyParameters));
                if (exps?.length > 0) {
                  exps?.every(exp => {
                    if (exp.iapExpressionDetails?.length > 0) {
                      const localData = LocalService.getValue(this.formKey + LocalService.COMPONENTS_EXP + (this.rootComponentId ?? -1).toString());
                      const data = HelperUtils.jsonParse(localData,[])
                      let resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, this.store)
                      //resu = resu?.toString();
                      if (resu) {
                        if (Object.keys(resu).length == 0) {
                          resu = resu?.toString();
                        }
                      }
  
                      param.value = resu;
  
  
                    }
                  })
  
                }
              })
            }
            else {
              const keyControl = CatalogObjectTypeConst.ATTRCOMP + '#' + WfData?.objetoId + '#' + wcf.id.toString();
              
              const exps = currentComp.expressions?.filter(x =>
                x.idObjeto == CatalogObjectTypeConst.WFAC
                && x.idTypeExpression == CatalogExpConst.EXP_SET
                && x.iapExpressionDetails?.length > 0
                && x.objetoId == keyControl);
              if (exps?.length > 0) 
              {
                exps?.every(exp => {
                  if (exp.iapExpressionDetails?.length > 0) {
                    const localData = LocalService.getValue(this.formKey + LocalService.COMPONENTS_EXP + (this.rootComponentId ?? -1).toString());
                    const data = HelperUtils.jsonParse(localData,[])
                    let resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, this.store)
                    //resu = resu?.toString();
                    if (resu) {
                      if (Object.keys(resu).length == 0) {
                        resu = resu?.toString();
                      }
                    }

                    switch(wcf.name) {
                      case DisplayComponentNodeConst.VAR_HEADER_IN:
                        // code block
                        this.variableComponentHeader = resu
                        break;
                      case DisplayComponentNodeConst.VAR_COMPMODAL_IN:
                        this.variableComponentModal = resu;
                        // code block
                        break;
                      case DisplayComponentNodeConst.VAR_COMPCURR_IN:
                          this.variableComponentCurr = resu;
                          // code block
                          break;
                      default:
                        // code block
                    }

                  }
                })

              }
  
            }
  
  
          }

        })
    
  }

  async execute(input: "ejecutar", forward: (output: "ejecutar") => void) {

    
    const inputs = (await this.dataflow.fetchInputs(this.id)) as {
      dataInput: any;
    };

    if (inputs && inputs?.dataInput && inputs.dataInput?.length==1)
    {
      let auxData: any = null;
      const data = inputs.dataInput[0]
      if (data){
    
        this.variableParamsInput.filter((x:any) => x.idAttributeType == CatalogAttrConst.TIPOATTR_PROPCUS  && x.value?.startsWith('#') && x.value?.endsWith('#')).forEach(param => 
        {
          //@ts-ignore:disable-next-line
          const keyParam = param.value.replaceAll('#','');
          param.value = HelperUtils.propValue(data,keyParam)?.toString() ?? ''
        });
      }
     
    }

    // vamos a buscar las expresiones si las hubiera
    
       this.resolveExpressions();

    // fin de resolver las expresiones



      var doCallbackOk = (response: boolean) => {
        //console.log('respuesta:' + compId.toString())
        if (response) {
          forward("ejecutar");
        }
      }

      if (this.variableComponentCurr){
        this.keyEventComponent = 'current_' + this.formKey +   (this.rootComponentId ?? -1).toString()  + '_' +  (this.currentComponentId ?? -1).toString() + '_' + EventConst.SHOWCOMPONENT;
      }

      if (!HelperCommon.isNullOrWhitespace(this.currentElementKey ?? '')){
        this.keyEventComponent = this.currentElementKey
      }
      EventBusCustom.emit(this.keyEventComponent, { componentId: this.variableComponent, header: this.variableComponentHeader, modal: this.variableComponentModal, current: this.variableComponentCurr, attrs: this.variableParamsInput, callBackResponse: doCallbackOk });



  }


  data(): { value: string } {
    return {
      //@ts-ignore:disable-next-line
      value: this.controls.value['value'] || ""
    };
  }
  /*
  data(): { connection: String, procName: String, parametersInput:[] } {
    return {
      connection: this.controls.value['variableconnection'] ?? '',
      procName: this.controls.value['variableprocName'] ?? '',
      parametersInput: this.controls.value['variableparamsInput'] ?? '',

    };
    
  }
  */
}