<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <Column v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" :key="componentKey"
        :id="getPropertyValue(ColumnTypeConst.ID)" v-tooltip="getPropertyValue(ColumnTypeConst.TOOLTIP)"
        :style="getPropertyValue(ColumnTypeConst.STYLE)"
        :class="{ [getPropertyValue(ColumnTypeConst.CLASS) ?? '']: true, 'customrequired': getPropertyBooleanValue(ColumnTypeConst.REQUIRED) }"
        :name="getPropertyValue(ColumnTypeConst.NAME)" :disabled="getPropertyBooleanValue(ColumnTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(ColumnTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :placeholder="getPropertyValue(ColumnTypeConst.PLACEHOLDER)" :field="getPropertyValue(ColumnTypeConst.FIELD)"
        :header="getPropertyValue(ColumnTypeConst.HEADER)" :sortable="getPropertyBooleanValue(ColumnTypeConst.SORTABLE)"
        v-model="vmodel">
    </Column>
</template>
<script lang="ts">


import { defineComponent, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import ColumnTypeConst from '../../../../domain/Constants/ColumnTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_column',
    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },     
        slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {
        //

        
        const store = useStore();
        const { vmodel,canDoOperation, loaded, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue,
             resolveExpressions, baseOnBeforeUnmount,fieldKey,fieldKeyComponentDataSourceId
             ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);

     


        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })

        return {
         
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            ColumnTypeConst,
            getPropertyNumberValue,
            loaded,
            BaseControlTypeConst,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
