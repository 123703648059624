import BaseControlTypeConst from "./BaseControlTypeConst"

export default class SvgTypeConst extends BaseControlTypeConst {

    static HEIGHT = 'height'
    static WIDTH = 'width'

    static VB = 'viewBox'
    static CONTENT = 'content'
    static DATAFILEVALUE = 'datafilevalue'
    static DATASOURCEVALUE = 'dataSource'

}