import { ClassicPreset } from "rete";
import { Container } from 'inversify';

export class ValidateComponentControl extends ClassicPreset.Control {
  constructor(public formKeyInput: String,
    public rootComponentId:number,
    public currentComponentId:number,
    public applicationId:number, 
    public applicationVersion:number,
    public variableComponentInput: number,
    public container: Container | undefined, 
    public onChange: (evt: any) => void,public getNode: (evt: any) => void, public showExpression:(evt:any) => void) {
    super();
  }
}