import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { IApplicationConnectionRepository } from '../domain/IApplicationConnectionRepository';



import { IServiceApplicationConnection } from './IServiceApplicationConnection';
import { IapApplicationConnection } from '../domain/iapApplicationConnection';





@injectable()
export class ApplicationConnectionService implements IServiceApplicationConnection {
  private readonly repo: IApplicationConnectionRepository

  public constructor (@inject(TYPES.APPLICATIONCONNECTION_REPOSITORY) _repo: IApplicationConnectionRepository) {
    this.repo = _repo
  }

  async getByApplicationId(id: number, version: number) :Promise<IapApplicationConnection[]> {
    return await this.repo.getByApplicationId(id, version)
  }

  async add(data: IapApplicationConnection) :Promise<IapApplicationConnection> {
    return await this.repo.add(data)
  }

  async update(data: IapApplicationConnection) :Promise<IapApplicationConnection> {
    return await this.repo.update(data)
  }

  async delete(data: IapApplicationConnection) :Promise<Boolean> {
    return await this.repo.delete(data)
  }

  async changePwd (data: IapApplicationConnection,pwd:String) : Promise<Boolean>{
    return await this.repo.changePwd(data,pwd);
  }

  async getConnection (data: IapApplicationConnection) : Promise<String>{
    return await this.repo.getConnection(data);
  }

}
