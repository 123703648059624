<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <InputNumber v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(InputNumberTypeConst.ID)" v-tooltip="getPropertyValue(InputNumberTypeConst.TOOLTIP)"
        :style="getPropertyValue(InputNumberTypeConst.STYLE)"
        :class="{ [getPropertyValue(InputNumberTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted,'p-readonly': getPropertyBooleanValue(InputNumberTypeConst.READONLY) }"
        :name="getPropertyValue(InputNumberTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(InputNumberTypeConst.DISABLED) || (!canEdit)"
        :visible="getPropertyBooleanValue(InputNumberTypeConst.VISIBLE)"
        :placeholder="getPropertyValue(InputNumberTypeConst.PLACEHOLDER)"
        :format="getPropertyBooleanValue(InputNumberTypeConst.FORMAT)"
        :showButtons="getPropertyBooleanValue(InputNumberTypeConst.SHOWBUTTONS)"
        :buttonLayout="getCatalogValue(InputNumberTypeConst.BUTTONLAYOUT)"
        :incrementButtonClass="getPropertyValue(InputNumberTypeConst.INCREMENTBUTTONCLASS)"
        :decrementButtonClass="getPropertyValue(InputNumberTypeConst.DECREMENTBUTTONCLASS)"
        :readonly="getPropertyBooleanValue(InputNumberTypeConst.READONLY) || !canDoOperation(ObjectGroupConst.EDIT)"
        :mode="getCatalogValue(InputNumberTypeConst.MODE)" :prefix="getPropertyValue(InputNumberTypeConst.PREFIX)"
        :suffix="getPropertyValue(InputNumberTypeConst.SUFFIX)" 
        :minFractionDigits="getPropertyNumberValue(InputNumberTypeConst.MINFRACTIONDIGITS)" 
        :maxFractionDigits="getPropertyNumberValue(InputNumberTypeConst.MAXFRACTIONDIGITS)"  
        :locale="getPropertyValue(InputNumberTypeConst.LOCALE)"
        :localeMatcher="getCatalogValue(InputNumberTypeConst.LOCALEMATCHER)"
        :currency="getPropertyValue(InputNumberTypeConst.CURRENCY)"
        :currencyDisplay="getCatalogValue(InputNumberTypeConst.CURRENCYDISPLAY)"
        :useGrouping="getPropertyBooleanValue(InputNumberTypeConst.USEGROUPING)"
        :roundingMode="getPropertyValue(InputNumberTypeConst.ROUNDINGMODE)"
        :min="!HelperCommon.isNullOrWhitespace(getPropertyValue(InputNumberTypeConst.MIN))?getPropertyNumberValue(InputNumberTypeConst.MIN):null"
        :max="!HelperCommon.isNullOrWhitespace(getPropertyValue(InputNumberTypeConst.MAX))?getPropertyNumberValue(InputNumberTypeConst.MAX):null"
        :step="getPropertyNumberValue(InputNumberTypeConst.STEP)"
        :allowEmpty="getPropertyBooleanValue(InputNumberTypeConst.ALLOWEMPTY)"
        :highlightOnFocus="getPropertyBooleanValue(InputNumberTypeConst.HIGHLIGHTONFOCUS)"
        :inputId="getPropertyValue(InputNumberTypeConst.INPUTID)"
        :inputClass="getPropertyValue(InputNumberTypeConst.INPUTCLASS)"
        :inputStyle="getPropertyValue(InputNumberTypeConst.INPUTSTYLE)"
        :inputProps="getPropertyValue(InputNumberTypeConst.INPUTPROPS)"
        :incrementButtonProps="getPropertyValue(InputNumberTypeConst.INCREMENTBUTTONPROPS)"
        :decrementButtonProps="getPropertyValue(InputNumberTypeConst.DECREMENTBUTTONPROPS)"
        :ariaLabel="getPropertyValue(InputNumberTypeConst.ARIALABEL)"
        :pt="getPropertyValue(InputNumberTypeConst.PT)"
        :ptOptions="getPropertyValue(InputNumberTypeConst.PTOPTIONS)"
        :unstyled="getPropertyBooleanValue(InputNumberTypeConst.UNSTYLED)"
        :ariaLabelledby="getPropertyValue(InputNumberTypeConst.ARIALABELLEDBY)"
         v-model="vmodel" >
    </InputNumber>
    <CustomValidate v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" v-model:submitted="submitted" v-model:vObject="v$" />
</template>
<script lang="ts">


import { defineComponent, onMounted, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import InputNumberTypeConst from '../../../../domain/Constants/InputNumberTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import CustomValidate from '../../shared/CustomValidate.vue';
import { useStore } from 'vuex';	
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_input_number',
    components: {
        CustomValidate,
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },     
        slotProps: {
            type: Object,
            default: () => ({})
        },        
    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue,getPropertyNumberValue, loaded, getCatalogValue,
             baseOnMounted, baseOnBeforeUnmount, v$, submitted, canEdit,isValidData 
             ,fieldKey,fieldKeyComponentDataSourceId,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);

        



        onMounted(() => {

            baseOnMounted();

        })

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })


        return {
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            InputNumberTypeConst,
            loaded,
            BaseControlTypeConst,
            getCatalogValue,
            v$,
            submitted,
            canEdit,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            getPropertyNumberValue,
            HelperCommon,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
