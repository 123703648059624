<template>
    <span>Componente</span>
    <Dropdown @pointerdown.stop="" @dblclick.stop="" filter v-model="selectedComponent" :options="dtComponentsInfo" optionValue="id"
     optionLabel="customDesc" placeholder="Selecciona una opción" class="w-full md:w-56 mb-1" 
    @change="changeComponent()"/>   
    
    <div v-if="selectedComponent > 0" @pointerdown.stop="" @dblclick.stop=""
        style="overflow-y:auto; overflow-x:auto; height:300px;">
       
        <br />
        <span>Atributos</span>
        <table class="tableParameters">
            <thead>
                <tr>
                    <th v-for="(column, index) in ['Actualizable','Orden','Nombre', 'Valor', '']" :key="index"> {{ column }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in dtComponentsVarInfo" :key="index">
                    <td>
                        <input @pointerdown.stop="" @dblclick.stop="" type="checkbox"
                            v-model="item['updatable']"
                            @change="changeAttributeValue()"
                            class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full " />                       
                    </td>
                    <td style="width: 10px;">
                        <input @pointerdown.stop="" @dblclick.stop="" type="number"
                            v-model="item['order']"
                            @change="changeAttributeValue()"
                            class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full " />                       
                    </td>
                    <td v-for="(column, indexColumn) in ['name']" :key="indexColumn">
                        <span :title="item[column]"> {{ item[column].length > 10 ? item[column].substring(0, 10) + '...':  item[column] }} </span>
                        
                    </td>
                    <td>                        
                        <input @pointerdown.stop="" @dblclick.stop="" type="text" @change="changeAttributeValue()"
                            v-model="item['value']"
                            class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full " />

                    </td>
                    <td><i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" :class="{ 'fa fa-subscript': true, 'text-green-500': hasExpression(ChangeComponentNodeConst.VAR_ATTR_IN,item.id) }"
                            @click="showExpression(ChangeComponentNodeConst.VAR_ATTR_IN,item.name,item)"></i></td>
                </tr>
            </tbody>
        </table>
        <br />
        <div v-if="dtComponentsEventsInfo?.length > 0">
            <span>Eventos</span>
        <table class="tableEvents">
            <thead>
                <tr>
                    <th v-for="(column, index) in ['Ejecutar','Orden','Nombre','']" :key="index"> {{ column }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in dtComponentsEventsInfo" :key="index">
                    <td>
                        <input @pointerdown.stop="" @dblclick.stop="" type="checkbox"
                            v-model="item['updatable']"
                            @change="changeEventValue()"
                            class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full " />                       
                    </td>
                    <td style="width: 10px;">
                        <input @pointerdown.stop="" @dblclick.stop="" type="number"
                            v-model="item['order']"
                            @change="changeEventValue()"
                            class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full " />                       
                    </td>
                    <td v-for="(column, indexColumn) in ['name']" :key="indexColumn">
                        {{ item[column] }}
                    </td>
                   

                    <td>
                        <!--
                        <i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" class="fa fa-subscript"
                            @click="showExpression(ChangeComponentNodeConst.VAR_EVENT_IN,item.name,item)"></i>
                        -->
                        </td>
                </tr>
            </tbody>
        </table>
        </div>
        
    
    </div>
</template>
<script lang="ts">

import { computed, defineComponent, onMounted, ref,getCurrentInstance } from 'vue';
import { Container } from 'inversify';
import { TYPES } from '../../../../../../../common/domain/types';
import HelperLoading from '../../../../../../../common/infrastructure/funciones/HelperLoading';
import CatalogObjectTypeConst from '../../../../../catalog/domain/const/CatalogObjectTypeConst';
import { IServiceComponent } from '../../../../../component/application/IServiceComponent';
import { IapComponent } from '../../../../../component/domain/iapComponent';
import { IapComponentAttribute } from '../../../../../component/domain/iapComponentAttribute';
import CatalogAttrConst from '../../../../../catalog/domain/const/CatalogAttrConst';
import HelperCustomComponentControls from '../../../functions/helperCustomComponentControls';
import ChangeComponentNodeConst from '../constants/ChangeComponentNodeConst';
import { IapComponentEvent } from '@ilinium/shared/src/entidades/builder/component/domain/iapComponentEvent';
import PrimeVue from 'primevue/config'
import HelperPrime from '../../../../../../../common/infrastructure/funciones/HelperPrime';
import Dropdown from 'primevue/dropdown'

export default defineComponent({
    name: 'customchangecomponentcontrol',

    props: {
        data: Object
    },
    setup(props) {

        const app = getCurrentInstance()        
        app?.appContext.app.component('Dropdown',Dropdown)
        app?.appContext.app.use(PrimeVue, HelperPrime.getPrimeOptions())

        const notAttrsCommonPermittedChanged =['id','name']
        const selectedComponent = ref(-1);
        const dtComponentsInfo = ref<IapComponent[]>([]);
        const dtComponentsVarInfo = ref<IapComponentAttribute[]>([]);
        const dtComponentsEventsInfo = ref<IapComponentEvent[]>([]);

        const { showExpression, hasExpression } = HelperCustomComponentControls(props.data);

        /*
        const connectionData = computed(() => {
            return datacatalog.filter(x => x.id == CatalogTypeConst.TIPOBD)?.find(x => x !== undefined)?.iapCatalogs.sort((a, b) => { return (a.order ?? 0) - (b.order ?? 0); });
        })
        */

        const getComponentNames = computed(() => {
            const aux = dtComponentsInfo.value.map((x: IapComponent) => ({ id: x?.id, description: x?.name }))
            return aux.filter((a, i) => aux.findIndex((s) => a.id === s.id) === i)
        })

        const getComponentVariables = computed(() => {
            return dtComponentsVarInfo.value.map( (x:any) => ({ updatable:x['updatable'],order:x['order'], id: x.id, name: x.name, value: x.value, idAttributeType:x.idAttributeType }));
        })

        const getComponentEvents = computed(() => {
            return dtComponentsEventsInfo.value.map((x:any) => ({ updatable:x['updatable'],order:x['order'], id: x.id, name: x.name, idEventType: x.idEventType }));
        })

        const changeComponent = () => {
            if (props.data) {
                getComponentAttributes(selectedComponent.value).then(response => {
                    if (response && props.data) {
                        props.data.onChange({key: ChangeComponentNodeConst.VAR_COMP_IN, data: selectedComponent.value })
                        props.data.onChange({ key: ChangeComponentNodeConst.VAR_ATTR_IN, data: getComponentVariables.value  })
                        props.data.onChange({ key: ChangeComponentNodeConst.VAR_EVENT_IN, data: getComponentEvents.value })
                    }

                });
              
           
            }
        }


        const changeEventValue = () => {
            if (props.data) {
                dtComponentsEventsInfo.value = getComponentEvents.value as any;
                props.data.onChange({key: ChangeComponentNodeConst.VAR_EVENT_IN, data: getComponentEvents.value })
            }

        }

        const changeAttributeValue = () => {
            if (props.data) {                
                props.data.onChange({key: ChangeComponentNodeConst.VAR_ATTR_IN, data: getComponentVariables.value })
            }

        }

        const loadComponentsTree = async () => {
            if (props.data && props.data.container) {
                HelperLoading.showLoading()
                const _srv = (props.data.container as Container).get<IServiceComponent>(TYPES.COMPONENT_REPOSITORY)

                await _srv.getSimpleTreeById(props.data.applicationId ?? -1, props.data.applicationVersion ?? -1, props.data.rootComponentId ?? -1,false ).then((response: IapComponent[]) => {
                    dtComponentsInfo.value =  response.map((x: IapComponent) => ({ ...x, customDesc: '(' + x?.id + ') - ' + x?.name })) as any;
                })
                    .finally(() => {
                        HelperLoading.hideLoading()
                    })
            }
        }

        const initAttributes = () =>{
            if (props.data){
                (props.data.variableComponentParametersInput as IapComponentAttribute[]).forEach(x => {
                            const item = dtComponentsVarInfo.value.find(y => y.id == x.id)
                            if (item) {
                                item.value = x.value;                            
                                item['order']=x['order'] ?? 0;
                                item['updatable']=x['updatable'] ?? false;
                            }
                        })
            }
        }

        const initEvents = () =>{
            if (props.data){
                (props.data.variableComponentEventsInput as IapComponentEvent[]).forEach(x => {
                            const item = dtComponentsEventsInfo.value.find(y => y.id == x.id)
                            if (item) {
                                item['order']=x['order'] ?? 0;
                                item['updatable']=x['updatable'] ?? false;

                                //item.value = x.value
                            }
                        })
            }
        }



        const getComponentAttributes = async (componentId: number, setInitData: boolean = false): Promise<boolean> => {


return await new Promise<boolean>((resolve, reject) => {

    if (componentId > 0 && props.data && props.data.container) {
                HelperLoading.showLoading()
                const _srv = (props.data.container as Container).get<IServiceComponent>(TYPES.COMPONENT_REPOSITORY)

                _srv.getById(componentId).then((response: IapComponent) => {
                    dtComponentsVarInfo.value = response.iapComponentAttributes.filter(x => !(x.idAttributeType == CatalogAttrConst.TIPOATTR_COMMON && notAttrsCommonPermittedChanged.includes(x.name)));
                    dtComponentsEventsInfo.value = response.iapComponentEvents;
                    // se le añaden las colunmas para hacerlo actualizable o no, y el orden a seguir
                    dtComponentsVarInfo.value.forEach((x:any)=>{
                        x['order']=0;
                        x['updatable']=false;
                    })
                    dtComponentsEventsInfo.value.forEach((x:any)=>{
                        x['order']=0;
                        x['updatable']=false;
                    })


                    if (setInitData && props.data) 
                    {                        
                      initAttributes();
                      initEvents();                   
                    }
                    else
                    {
                        changeAttributeValue();
                        changeEventValue();
                    }
                })
                    .finally(() => {
                        HelperLoading.hideLoading()
                        resolve(true);
                    })
            }



});


};



       

        onMounted(() => {            
            loadComponentsTree().then( () =>{
                if (props.data) {
                selectedComponent.value = props.data.variableComponentInput                
                dtComponentsVarInfo.value = props.data.variableComponentParametersInput as IapComponentAttribute[]
                dtComponentsEventsInfo.value = props.data.variableComponentEventsInput as IapComponentEvent[]
                
                if (selectedComponent.value) {
                    getComponentAttributes(selectedComponent.value, true);
                }
            }

            })

        })

        return {
            selectedComponent,
            changeComponent,
            changeEventValue,
            dtComponentsInfo,
            getComponentNames,
            getComponentVariables,
            changeAttributeValue,
            CatalogObjectTypeConst,
            showExpression,
            dtComponentsVarInfo,
            dtComponentsEventsInfo,
            ChangeComponentNodeConst,
            hasExpression
        };
    },
});
</script>
<style scoped></style>
