import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: CodeInputType!){
    codeMutation{
        updateCode(data:$data){
            id
          applicationId
          applicationVersion
          objetoId
          idObjeto
          idObjetoRoot
          objetoIdRoot
          fileName
          description
          idFileType
          group
          code
          active
          fcr
          ucr
          uum
          fum
        }
    }
}
`);

export const ADD = gql(`
mutation($data: CodeInputType!){
    codeMutation{
        addCode(data:$data){
            id
          applicationId
          applicationVersion
          objetoId
          idObjeto
          idObjetoRoot
          objetoIdRoot
          fileName
          description
          idFileType
          group
          code
          active
          fcr
          ucr
          uum
          fum
        }
    }
}
`);



