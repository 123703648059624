import BaseControlTypeConst from "./BaseControlTypeConst"

export default class PickListTypeConst extends BaseControlTypeConst {
  
    static LK='labelKey';
    static DATAKEY='dataKey';
    static METAKEYSELECTION='metaKeySelection';
    static AUTOOPTIONFOCUS='autoOptionFocus';
    static FOCUSONHOVER='focusOnHover';
    static LISTSTYLE='listStyle';
    static RESPONSIVE='responsive';
    static BREAKPOINT='breakpoint';
    static SCROLLHEIGHT='scrollHeight';
    static STRIPED='striped';
    static SHOWSOURCECONTROLS='showSourceControls';
    static SHOWTARGETCONTROLS='showTargetControls';
    static OPTIONS='options';
    static DATASOURCE='dataSource';
    static DATAFILEVALUE='datafilevalue';
    static CATALOGTYPE='catalogType';
  
    
  }
  