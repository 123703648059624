<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
            
    <ScrollPanel v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(ScrollPanelTypeConst.ID)" v-tooltip="getPropertyValue(ScrollPanelTypeConst.TOOLTIP)"
        :style="getPropertyValue(ScrollPanelTypeConst.STYLE)" :class="getPropertyValue(ScrollPanelTypeConst.CLASS)"
        :name="getPropertyValue(ScrollPanelTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(ScrollPanelTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(ScrollPanelTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :placeholder="getPropertyValue(ScrollPanelTypeConst.PLACEHOLDER)"
        :step="getPropertyNumberValue(ScrollPanelTypeConst.STEP)" v-model="vmodel" >
    </ScrollPanel>
</template>
<script lang="ts">


import { defineComponent, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import ScrollPanelTypeConst from '../../../../domain/Constants/ScrollPanelTypeConst';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_scroll_panel',
    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },
   

    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, loaded, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue, baseOnBeforeUnmount 
            ,fieldKey,fieldKeyComponentDataSourceId,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);



        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })


        return {
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            ScrollPanelTypeConst,
            getPropertyNumberValue,
            loaded,
            BaseControlTypeConst,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
