<template>
    <span>Componente</span>

    <Dropdown @pointerdown.stop="" @dblclick.stop=""  v-model="selectedComponent" :options="dtComponentsInfo" optionValue="id" optionLabel="customDesc" filter placeholder="Selecciona una opción" class="w-full md:w-56 mb-1" 
    @change="changeComponent()"/>      
    <div v-if="selectedComponent" @pointerdown.stop="" @dblclick.stop=""
        style="overflow-y:auto; overflow-x:hidden; height:300px;">

        <br />
        <span>Cabecera </span>
        <div style="display: flex;">
            <textarea style="resize: none;" @pointerdown.stop="" @dblclick.stop="" id="header" name="header" rows="4"
                cols="50" v-model="selectedComponentHeader"
                class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full "
                @change="changeComponentHeader"></textarea>
            <i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" :class="{ 'fa fa-subscript': true, 'text-green-500': hasExpression(DisplayComponentNodeConst.VAR_HEADER_IN) }"
                @click="showExpression(DisplayComponentNodeConst.VAR_HEADER_IN, 'Cabecera a mostrar')"></i>
        </div>
        <br />
        <span>Mostrar Modal </span>
        <input type="checkbox" id="modal" name="modal" @pointerdown.stop="" @dblclick.stop=""
            v-model="selectedComponentModalInput" @change="changeComponentModal">
        <i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" :class="{ 'fa fa-subscript': true, 'text-green-500': hasExpression(DisplayComponentNodeConst.VAR_COMPMODAL_IN) }"
            @click="showExpression(DisplayComponentNodeConst.VAR_COMPMODAL_IN, 'Mostrar modal del componente')"></i>
            <br />            
        <span>Mostrar En componente actual </span>
        <input type="checkbox" id="modal" name="modal" @pointerdown.stop="" @dblclick.stop=""
            v-model="selectedComponentCurrModalInput" @change="changeComponentCurrentModal">
        <i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" :class="{ 'fa fa-subscript': true, 'text-green-500': hasExpression(DisplayComponentNodeConst.VAR_COMPCURR_IN) }"
            @click="showExpression(DisplayComponentNodeConst.VAR_COMPCURR_IN, 'Mostrar modal del componente')"></i>
        
        <br />
        <br />
        <span>Parámetros</span>
        <table class="tableParameters">
            <thead>
                <tr>
                    <th v-for="(column, index) in ['Nombre', 'Valor', '']" :key="index"> {{ column }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in dtComponentsVarInfo" :key="index">
                    <td v-for="(column, indexColumn) in ['name']" :key="indexColumn">
                        {{ item[column] }}
                    </td>
                    <td><input @pointerdown.stop="" @dblclick.stop="" type="text" @change="changeVariableValue()"
                            v-model="item['value']"
                            class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full " />

                    </td>
                    <td><i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" :class="{ 'fa fa-subscript': true, 'text-green-500': hasExpression(DisplayComponentNodeConst.VAR_PARAM_IN,item.id) }"
                            @click="showExpression(DisplayComponentNodeConst.VAR_PARAM_IN, item.name, item)"></i></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script lang="ts">

import { computed, defineComponent, onMounted, ref,getCurrentInstance } from 'vue';
import { Container } from 'inversify';
import { TYPES } from '../../../../../../../common/domain/types';
import HelperLoading from '../../../../../../../common/infrastructure/funciones/HelperLoading';
import CatalogObjectTypeConst from '../../../../../catalog/domain/const/CatalogObjectTypeConst';
import { IServiceComponent } from '../../../../../component/application/IServiceComponent';
import { IapComponent } from '../../../../../component/domain/iapComponent';
import { IapComponentAttribute } from '../../../../../component/domain/iapComponentAttribute';
import CatalogAttrConst from '../../../../../catalog/domain/const/CatalogAttrConst';
import HelperCustomComponentControls from '../../../functions/helperCustomComponentControls';
import DisplayComponentNodeConst from '../constants/DisplayComponentNodeConst';
import PrimeVue from 'primevue/config'
import HelperPrime from '../../../../../../../common/infrastructure/funciones/HelperPrime';
import Dropdown from 'primevue/dropdown'


export default defineComponent({
    name: 'custommodalcomponentcontrol',

    props: {
        data: Object
    },
    setup(props) {


        const app = getCurrentInstance()        
        app?.appContext.app.component('Dropdown',Dropdown)
        app?.appContext.app.use(PrimeVue, HelperPrime.getPrimeOptions())
        const selectedComponentHeader = ref('');
        const selectedComponent = ref(-1);
        const selectedComponentModalInput = ref(false);
        const selectedComponentCurrModalInput = ref(false);

        //const datacatalog = helperCatalog.getAllCatalogApp();
        const dtComponentsInfo = ref<IapComponent[]>([]);
        const dtComponentsVarInfo = ref<IapComponentAttribute[]>([]);
        const { showExpression, hasExpression } = HelperCustomComponentControls(props.data);

        /*
        const connectionData = computed(() => {
            return datacatalog.filter(x => x.id == CatalogTypeConst.TIPOBD)?.find(x => x !== undefined)?.iapCatalogs.sort((a, b) => { return (a.order ?? 0) - (b.order ?? 0); });
        })
        */

        const getComponentNames = computed(() => {
            const aux = dtComponentsInfo.value.map((x: IapComponent) => ({ id: x?.id, description: x?.name }))
            return aux.filter((a, i) => aux.findIndex((s) => a.id === s.id) === i)
        })

        const getComponentVariables = computed(() => {
            return dtComponentsVarInfo.value.map(x => ({ id: x.id, name: x.name, value: x.value, idAttributeType: x.idAttributeType }));
        })


        const changeComponent = () => {
            if (props.data) {
                getComponentAttributes(selectedComponent.value).then(response => {
                    if (response && props.data) {
                        selectedComponentHeader.value = "";
                        props.data.onChange({ key: DisplayComponentNodeConst.VAR_HEADER_IN, data: selectedComponentHeader.value })
                        props.data.onChange({ key: DisplayComponentNodeConst.VAR_COMP_IN, data: selectedComponent.value })
                        props.data.onChange({ key: DisplayComponentNodeConst.VAR_COMPMODAL_IN, data: selectedComponentModalInput.value })
                        props.data.onChange({ key: DisplayComponentNodeConst.VAR_COMPCURR_IN, data: selectedComponentCurrModalInput.value })
                        props.data.onChange({ key: DisplayComponentNodeConst.VAR_PARAM_IN, data: getComponentVariables.value })
                    }

                });

            }
        }

        const changeComponentModal = () => {
            if (props.data) {
                props.data.onChange({ key: DisplayComponentNodeConst.VAR_COMPMODAL_IN, data: selectedComponentModalInput.value })
            }
        }

        const changeComponentCurrentModal = () => {
            if (props.data) {
                props.data.onChange({ key: DisplayComponentNodeConst.VAR_COMPCURR_IN, data: selectedComponentCurrModalInput.value })
            }
        }


        const changeComponentHeader = () => {
            if (props.data) {
                props.data.onChange({ key: DisplayComponentNodeConst.VAR_HEADER_IN, data: selectedComponentHeader.value })
            }
        }


        const changeProcedureName = () => {
            if (props.data) {
                dtComponentsVarInfo.value = getComponentVariables.value as any;
                props.data.onChange({ key: DisplayComponentNodeConst.VAR_PARAM_IN, data: getComponentVariables.value })
            }

        }

        const changeVariableValue = () => {
            
            if (props.data) {
                props.data.onChange({ key: DisplayComponentNodeConst.VAR_PARAM_IN, data: getComponentVariables.value })
            }

        }

        const loadComponentsParent = () => {
            if (props.data && props.data.container) {
                HelperLoading.showLoading()
                const _srv = (props.data.container as Container).get<IServiceComponent>(TYPES.COMPONENT_REPOSITORY)

                _srv.getParents(props.data.applicationId ?? -1, props.data.applicationVersion ?? -1).then((response: IapComponent[]) => {
                    dtComponentsInfo.value =  response.map((x: IapComponent) => ({ ...x, customDesc: '(' + x?.id + ') - ' + x?.name })) as any;

                })
                    .finally(() => {
                        HelperLoading.hideLoading()
                    })
            }
        }



        const getComponentAttributes = async (componentId: number, setInitData: boolean = false): Promise<boolean> => {


            return await new Promise<boolean>((resolve, reject) => {

                if (componentId > 0 && props.data && props.data.container) {
                    HelperLoading.showLoading()
                    const _srv = (props.data.container as Container).get<IServiceComponent>(TYPES.COMPONENT_REPOSITORY)

                    _srv.getById(componentId).then((response: IapComponent) => {
                        dtComponentsVarInfo.value = response.iapComponentAttributes.filter(x => x.idAttributeType == CatalogAttrConst.TIPOATTR_PROPCUS);

                        if (setInitData && props.data) {
                            (props.data.variableComponentParametersInput as IapComponentAttribute[]).forEach(x => {
                                const item = dtComponentsVarInfo.value.find(y => y.id == x.id)
                                if (item) {
                                    item.value = x.value
                                }
                            })

                        }
                    })
                        .finally(() => {
                            HelperLoading.hideLoading()
                            resolve(true)
                        })
                }


            });


        };






        onMounted(() => {
            loadComponentsParent();

            if (props.data) {

                selectedComponentHeader.value = props.data.variableComponentHeaderInput
                selectedComponent.value = props.data.variableComponentInput
                selectedComponentModalInput.value = props.data.variableComponentModalInput
                selectedComponentCurrModalInput.value = props.data.variableComponentCurrInput
                dtComponentsVarInfo.value = props.data.variableComponentParametersInput as IapComponentAttribute[]

                if (selectedComponent.value) {
                    getComponentAttributes(selectedComponent.value, true);
                }
            }

        })

        return {
            selectedComponent,
            selectedComponentModalInput,
            selectedComponentCurrModalInput,
            selectedComponentHeader,
            changeComponent,
            changeProcedureName,
            dtComponentsInfo,
            getComponentNames,
            getComponentVariables,
            changeVariableValue,
            CatalogObjectTypeConst,
            showExpression,
            dtComponentsVarInfo,
            changeComponentModal,
            changeComponentHeader,
            DisplayComponentNodeConst,
            changeComponentCurrentModal,
            hasExpression
        };
    },
});
</script>
<style scoped></style>
