import { Store } from "vuex";
import { IapComponent } from "../../../entidades/builder/component/domain/iapComponent";
import { Dictionary } from "../../../entidades/builder/expression/domain/dictionary";
import BaseControlTypeConst from "../../../entidades/builder/form/domain/Constants/BaseControlTypeConst";
import ControlTypeConst from "../../../entidades/builder/form/domain/Constants/ControlTypeConst";
import TabViewTypeConst from "../../../entidades/builder/form/domain/Constants/TabViewTypeConst";
import InteractionConst from "../../../entidades/builder/interaction/domain/interactionConst";
import { InteractionEvent } from "../../../entidades/builder/interaction/domain/interactionEvent";
import EventConst from "../../domain/constantes/EventConst";
import { db } from "../../domain/db";
import EventBusCustom from '../event-bus-custom'
import LocalService, { getLocalValue } from "../servicios/LocalService";
import HelperUtils from "./HelperUtils";
import Environment from "./environment";
import { Router } from "vue-router";
import DexieConst from "../../domain/constantes/Dexie/DexieConst";
import { decompress,compress } from 'compress-json'

  
  
export const getComponentKeyLoaded = (compId:Number): string | undefined => {
  
  const data = getComponentLoaded();
  return data.find(x=> x.key == compId)?.value
}

export const getComponentLoaded = (): Dictionary<Number,string>[] => {
  
  const localData = getLocalValue(LocalService.COMP_LOADED) ?? '[]';
  if (localData == undefined || localData == "") {
    return [] as Dictionary<Number,string>[]
  }
  const data = JSON.parse(localData) as Dictionary<Number,string>[]
  return data;
}

export const setComponentLoaded = (dataInput:Dictionary<Number,string>): void => {
  
  const data = getComponentLoaded();
  if (data.find(x=> x.key == dataInput.key)=== undefined){
    data.push(dataInput);
    LocalService.setLocalValue(LocalService.COMP_LOADED, JSON.stringify(data))
  }
}

export const deleteComponentKeyLoaded = (compId:Number): void => {
  
  let  data = getComponentLoaded();
  data = data.filter( x=> x.key !== compId);
  LocalService.setLocalValue(LocalService.COMP_LOADED, JSON.stringify(data))
}



const buildInteractionEvent = (comp:IapComponent,_typeId:string,_objectId:any, _objName:string | null, _value:any) =>{

      var data: Partial<InteractionEvent> = {
        objectId:_objectId,
        typeId: _typeId,
        objectValue: _value,
        objectName: _objName

      }

      return data;
}

  
  const callInteraction = async (key: String, data: Partial<InteractionEvent>): Promise<InteractionEvent> => {
  
    return await new Promise<InteractionEvent>((resolve) => {
  
      var doCallbackOk = (response: InteractionEvent) => {
        resolve(response);
      }
      
      EventBusCustom.emit(key, { data: data, callBackResponse: doCallbackOk });
  
    });
  
  
  };
  

  async function resolveInteraction(comp: IapComponent | undefined, keyform: string, componentData: IapComponent[], compId: number, parameters: any) {
    
    if (comp) {
      switch (comp.idType) {
        case ControlTypeConst.CTCRUD:          
          const _key = 'current_' + keyform + (componentData[0].id ?? -1).toString() + compId.toString() + '_' + EventConst.NAVIGATION;
          const dataEventCrud = buildInteractionEvent(comp, InteractionConst.CALL_FUNCTION, comp.id, null, parameters);
          await callInteraction(_key, dataEventCrud);
          break;
        /*case ControlTypeConst.CTTABVIEW:
          break;
          */
        case ControlTypeConst.CTTABPANEL:
          // code block
          const attrDataTabView = componentData.find(x=> x.id == comp.parentId)?.iapComponentAttributes?.find(x => x.name ==  TabViewTypeConst.ACTIVEINDEX);
          const _keytab = keyform  + (componentData[0].id ?? -1).toString() + (comp.parentId ?? -1).toString() + EventConst.INTERACTION;
          const position = componentData.filter(x=> x.parentId == comp.parentId && HelperUtils.booleanify(x?.iapComponentAttributes?.find(x => x.name ==  BaseControlTypeConst.VISIBLE)?.value ?? 'false')).map((x,index) => ({pos:index,id:x.id })).find(z=> z.id == comp.id);
          if (attrDataTabView && position){
            const dataEventTabPanel = buildInteractionEvent(comp,InteractionConst.SET_PROPERTY_VALUE,attrDataTabView?.id,TabViewTypeConst.ACTIVEINDEX,position?.pos ?? 0)
            await callInteraction(_keytab,dataEventTabPanel);
  
          }
          break;
        default:
        // code block
      }
    }
  }
  


  export const doInteraction = async (keyform:string,componentData: IapComponent[],componetsIds:number[],parameters:any): Promise<boolean> => {

    return await new Promise<boolean>((resolve) => {
        componetsIds.forEach(compId =>{
          const comp = componentData.find(x=> x.id == compId);
          resolveInteraction(comp, keyform, componentData, compId, parameters);
        })
        resolve(true);
  
    });
}


export async function navigateLastPath(router:Router,store:Store<any>) {
  const myHistory = await db.browsing.where("userId").equals(store.getters.getCurrentUser.id)
                  .and((record: any) => record.applicationId === Environment.APPID && Environment.APPVERSION === 1)
                  .sortBy("date").then(records => records.reverse());
  if (myHistory.length > 0){
      router.push(myHistory[0].path)
  }
  
}
export async function addPathToBrowsing(store:Store<any>,path: string, title: string| null=null,data:any| null = null) {
  try {
      if (store.getters.isUserAuthenticated) {
          const count = await db.browsing.where("userId").equals(store.getters.getCurrentUser.id)
                  .and((record: any) => record.applicationId === Environment.APPID && Environment.APPVERSION === 1)
                  .count();
          if (count >= DexieConst.MAX_BROWSING) {
              const recordsToDelete = await db.browsing.where("userId").equals(store.getters.getCurrentUser.id)
                  .and((record: any) => record.applicationId === Environment.APPID && Environment.APPVERSION === 1)
                  .sortBy("date");//.reverse()

              const limitedRecords = recordsToDelete.slice(0, count - DexieConst.MAX_BROWSING - 1);
              await db.browsing.bulkDelete(limitedRecords.map(record => record.id));
          }
        
          let _data = (data != null ? compress(data):null);
          //const count2 = await db.browsing.count();
          // Add the new friend!
          const id = await db.browsing.add({
              path: path,
              date: new Date(),
              userId: store.getters.getCurrentUser.id,
              applicationId: Environment.APPID,
              applicationVersion: Environment.APPVERSION,
              caption:title,
              parentId:null,
              data:_data
          });
      }
  } catch (error) {
      
  }
}
  

  export default {doInteraction, getComponentKeyLoaded,getComponentLoaded,setComponentLoaded,deleteComponentKeyLoaded,navigateLastPath,addPathToBrowsing}
