import BaseControlTypeConst from "./BaseControlTypeConst"

export default class ColorPickerTypeConst extends BaseControlTypeConst {
    static DEFAULTCOLOR = 'defaultColor';
    static INLINE = 'boolean';
    static FORMAT = 'format';
    static TABINDEX = 'tabindex';
    static AUTOZINDEX = 'autoZIndex';
    static BASEZINDEX = 'baseZIndex';
    static PANELCLASS = 'panelClass'
    //static APPENDTO = 'appendTo'
    static UNSTYLED = 'unstyled';
    static READONLY = 'readonly'
}
