<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <b v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(HtmlTypeConst.ID)" v-tooltip="getPropertyValue(HtmlTypeConst.TOOLTIP)"
        :style="getPropertyValue(HtmlTypeConst.STYLE)"
        :class="{ [getPropertyValue(HtmlTypeConst.CLASS) ?? '']: true, 'customrequired': getPropertyBooleanValue(HtmlTypeConst.REQUIRED) }"
        :name="getPropertyValue(HtmlTypeConst.NAME)" :disabled="getPropertyBooleanValue(HtmlTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(HtmlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :placeholder="getPropertyValue(HtmlTypeConst.PLACEHOLDER)">
        {{ getPropertyValue(HtmlTypeConst.TEXT) }}
        {{ vmodel }}
    </b>
</template>
<script lang="ts">


import { defineComponent, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import HtmlTypeConst from '../../../../domain/Constants/HtmlTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_html_b',
    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },      
        slotProps: {
            type: Object,
            default: () => ({})
        },
    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded,  
            baseOnBeforeUnmount,fieldKey,fieldKeyComponentDataSourceId 
            ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);


        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })


        return {
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            HtmlTypeConst,
            loaded,
            BaseControlTypeConst,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
