import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: ModeloMenuDirectiveInputType!){
    menuDirectiveMutation{
        updateMenuDirective(data:$data)
    }
}
`);

export const ADD = gql(`
mutation($data: ModeloMenuDirectiveInputType!){
    menuDirectiveMutation{
        addMenuDirective(data:$data)
    }
}
`);

export const GRANTACCESS = gql(`
mutation($objectId: String!, $objectGroup: ObjectGroupInputType!, $objectType: String!, $applicationId: Int!, $applicationVersion: Int!){
    objectGroupMutation{
        grantAccessToObjectGroup(objectId:$objectId, objectGroup:$objectGroup, objectType:$objectType, applicationId:$applicationId, applicationVersion:$applicationVersion)
    }
}
`);
export const GRANTACCESS_GROUP = gql(`
mutation($objectId: String!, $objectGroup: [ObjectGroupInputType]!, $objectType: String!, $applicationId: Int!, $applicationVersion: Int!){
    objectGroupMutation{
        grantAccessToObjectGroups(objectId:$objectId, objectGroup:$objectGroup, objectType:$objectType, applicationId:$applicationId, applicationVersion:$applicationVersion)
    }
}
`);






