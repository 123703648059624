<template>
       <SummaryDebug v-if="getPropertyBooleanValue(ChartCircTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"

            />
    <Chart v-if="loaded && getPropertyBooleanValue(ChartCircTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(ChartCircTypeConst.ID)" 
        v-tooltip="getPropertyValue(ChartCircTypeConst.TOOLTIP)"
        :style="getPropertyValue(ChartCircTypeConst.STYLE)"
        :class="{ [getPropertyValue(ChartCircTypeConst.CLASS) ?? '']: true }"
        :name="getPropertyValue(ChartCircTypeConst.NAME)" 
        :disabled="getPropertyBooleanValue(ChartCircTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(ChartCircTypeConst.VISIBLE)"
        :placeholder="getPropertyValue(ChartCircTypeConst.PLACEHOLDER)" 
        :type="getCatalogValue(ChartCircTypeConst.TYPE)"
        :plugins="getPropertyValue(ChartCircTypeConst.PLUGINS)" 
        :width="getPropertyNumberValue(ChartCircTypeConst.WIDTH)"
        :height="getPropertyNumberValue(ChartCircTypeConst.HEIGHT)" 
        :data="chartData" 
        :options="chartOptions"
        v-model="vmodel">
        <!-- :usePointStyle="getPropertyBooleanValue(ChartCircTypeConst.USEPOINTSTYLE)"
        :color="getPropertyValue(ChartCircTypeConst.COLOR)"
        :backgroundColor="getPropertyValue(ChartCircTypeConst.BACKGROUNDCOLOR)"
        :hoverBackgroundColor="getPropertyValue(ChartCircTypeConst.HOVERBACKGROUNDCOLOR)"
        :cutout="getPropertyValue(ChartCircTypeConst.CUTOUT)" 
        :labelData="getCatalogValue(ChartTypeConst.LABELDATA)"
        -->
    </Chart>
</template>
<script lang="ts">


import { defineComponent, onMounted, ref, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import ChartCircTypeConst from '../../../../domain/Constants/ChartCircTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';
import ChartLabelDataConst from '../../../../domain/Constants/ChartLabelDataConst';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import DataSourceComp from '../../../../../crud/infrastructure/functions/dataSourceComp';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_chart_circ',
    components: {
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },    
        slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, loaded, getPropertyValue, getPropertyBooleanValue, getCatalogValue, getPropertyNumberValue, resolveExpressions, baseOnBeforeUnmount, fieldKey, fieldKeyComponentDataSourceId,
            getComponentDataSourceItemsFromId, getComponentDataSource
            ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);
        const { getFieldFromKeyCol } = DataSourceComp(props.container as any, props, context.emit , {}, {} as any, [], store, {} as any)
        const chartData = ref();
        const chartOptions = ref();
        const backgroundColor = ref();
        const hoverBackgroundColor = ref();
        const values = ref();
        const labels = ref();
        const data = ref();

      
        const setChartData = () => {

            //const documentStyle = getComputedStyle(document.body);

            return {
                labels: labels.value,
                datasets: [
                    {
                        data: values.value,
                        backgroundColor: backgroundColor.value,
                        hoverBackgroundColor: hoverBackgroundColor.value
                    }
                ]
            };

        };




        const setChartOptions = () => {

            return {
                plugins: {
                    legend: {
                        labels: {
                            cutout: getPropertyValue(ChartCircTypeConst.CUTOUT),
                            usePointStyle: getPropertyBooleanValue(ChartCircTypeConst.USEPOINTSTYLE),
                            color: getPropertyValue(ChartCircTypeConst.COLOR)
                        }
                    }
                }
            };

        }



        const getShortDescColumnName = (columnName: any) => {

            
            const cds = getComponentDataSource(ChartCircTypeConst.DATA);

            if (cds && cds.dataSource.iapDataSourceFields) {

                const dataBaseId = cds?.dataSource?.iapDataSourceDataBase?.idBaseDatos;

                const dsf = getFieldFromKeyCol(columnName,false,cds.dataSource.iapDataSourceFields)??getFieldFromKeyCol(columnName,true,cds.dataSource.iapDataSourceFields);

                if (dsf) {
                    return dsf.shortDescription ?? dsf.description ?? dsf.field;
                }


            }

            return columnName;
        }


        const getObjectFormatted = (_data: any) => {

            let newObject: any = {};

            for (var prop in _data) {
                if (_data.hasOwnProperty(prop)) {
                    newObject[getShortDescColumnName(prop)] = _data[prop];
                }
            }

            return newObject;
        }


        const getFirstColumnRowValues = (_data: any, colPosition: number) => {
            const rowValues =
                _data.map((obj: any) => {
                    const row = Object.values(obj)[colPosition];
                    return row;
                });


            return rowValues;
        }



        onMounted(() => {
            
            
            data.value = getComponentDataSourceItemsFromId(ChartCircTypeConst.DATA);
            if (data.value) {

                if (getCatalogValue(ChartCircTypeConst.LABELDATA) == ChartLabelDataConst.COL) {
                    const valueFormatted = getObjectFormatted(data.value[0]);
                    values.value = Object.values(valueFormatted);
                    labels.value = Object.keys(valueFormatted);
                }
                else if (getCatalogValue(ChartCircTypeConst.LABELDATA) ==  ChartLabelDataConst.ROW) {

                    labels.value = getFirstColumnRowValues(data.value, 0);
                    values.value = getFirstColumnRowValues(data.value, 1);

                }

            }
            backgroundColor.value = getPropertyValue(ChartCircTypeConst.BACKGROUNDCOLOR)?.split(';');
            hoverBackgroundColor.value = getPropertyValue(ChartCircTypeConst.HOVERBACKGROUNDCOLOR)?.split(';');
            chartData.value = setChartData();
            chartOptions.value = setChartOptions();


        })

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
            chartData.value=null;
            chartOptions.value=null;
            backgroundColor.value=null;
            hoverBackgroundColor.value=null;
            values.value=null;
            labels.value=null;
            data.value=null;
        })



        return {
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            ChartCircTypeConst,
            getPropertyNumberValue,
            loaded,
            BaseControlTypeConst,
            fieldKey,
            fieldKeyComponentDataSourceId,
            chartData,
            chartOptions,
            getCatalogValue,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey

        };
    },
});
</script>
<style scoped></style>
