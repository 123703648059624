import gql from 'graphql-tag';


export const GET_APPLICATIONCONNECTION_BY_ID = gql(`
query($applicationId: Int!, $version: Int!) {
  applicationConnectionQuery {
    getByApplicationId(applicationId:$applicationId, version:$version) {
      applicationId
      applicationVersion
      serverName
      dataBaseName
      idDataBaseType
      idDbType
      idEnvironmentType
      userName
      fcr
      ucr
      uum
      fum
      }
    }
}
`);

export const GET_CONN = gql(`
query($data: ApplicationConnectionInputType!){
  applicationConnectionQuery{
    getConnection(data:$data)
    }
}
`);


