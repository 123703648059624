import gql from 'graphql-tag';

export const SETUSEROPTION = gql(`
    mutation ($applicationId:Int!, $applicationVersion:Int!, $userId:Int!, $optionId:String!, $value:String!) {
        userOptionMutation {
            setUserOption (applicationId:$applicationId, applicationVersion:$applicationVersion, userId:$userId, optionId:$optionId, value:$value)
            {            
                userId
                optionId
                value
            }
        }
    }
`);

export const SETUSEROPTIONDATA = gql(`
    mutation ($applicationId:Int!, $applicationVersion:Int!, $data: UserOptionInputType!) {
        userOptionMutation {
            setUserOptionData (applicationId:$applicationId, applicationVersion:$applicationVersion, data:$data)
            {            
                userId
                optionId
                value
            }
        }
    }
`);

export const SETUSEROPTIONSDATA = gql(`
    mutation ($applicationId:Int!, $applicationVersion:Int!, $data: [UserOptionInputType]!) {
        userOptionMutation {
            setUserOptionsData (applicationId:$applicationId, applicationVersion:$applicationVersion, data:$data)
            {            
                userId
                optionId
                value
            }
        }
    }
`);

export const DELETEUSEROPTION = gql(`
    mutation ($applicationId:Int!, $applicationVersion:Int!, $userId:Int!, $optionId:String!) {
        userOptionMutation {
            deleteUserOption (applicationId:$applicationId, applicationVersion:$applicationVersion, optionId:$optionId)
        }
    }
`);

export const DELETEUSEROPTIONDATA = gql(`
    mutation ($applicationId:Int!, $applicationVersion:Int!, $data:UserOptionInputType!) {
        userOptionMutation {
            deleteUserOptionData (applicationId:$applicationId, applicationVersion:$applicationVersion, data:$data)
        }
    }
`);

export const DELETEUSEROPTIONSDATA = gql(`
    mutation ($applicationId:Int!, $applicationVersion:Int!, $data:[UserOptionInputType]!) {
        userOptionMutation {
            deleteUserOptionsData (applicationId:$applicationId, applicationVersion:$applicationVersion, data:$data)
        }
    }
`);

export const RESETUSEROPTIONSDATA = gql(`
    mutation ($applicationId:Int!, $applicationVersion:Int!, $data:[UserOptionInputType]!) {
        userOptionMutation {
            resetUserOptionsData (applicationId:$applicationId, applicationVersion:$applicationVersion, data:$data)
        }
    }
`);
