<template>
       <SummaryDebug v-if="getPropertyBooleanValue(TriStateCheckBoxTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel" 
            />
            
    <TriStateCheckBox 
        v-if ="loaded && (getPropertyBooleanValue(TriStateCheckBoxTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW))" 
        :key="componentKey"
        :id="getPropertyValue(TriStateCheckBoxTypeConst.ID)" 
        v-tooltip="getPropertyValue(TriStateCheckBoxTypeConst.TOOLTIP)"
        :style="getPropertyValue(TriStateCheckBoxTypeConst.STYLE)"
        :class="{ [getPropertyValue(TriStateCheckBoxTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted ,'p-readonly': getPropertyBooleanValue(TriStateCheckBoxTypeConst.READONLY)}"
        :name="getPropertyValue(TriStateCheckBoxTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(TriStateCheckBoxTypeConst.DISABLED) || (!canEdit)"
        :visible="getPropertyBooleanValue(TriStateCheckBoxTypeConst.VISIBLE)"
        :placeholder="getPropertyValue(TriStateCheckBoxTypeConst.PLACEHOLDER)"
        :binary="getPropertyBooleanValue(TriStateCheckBoxTypeConst.BINARY)"
        :readonly="getPropertyBooleanValue(TriStateCheckBoxTypeConst.READONLY) || !canDoOperation(ObjectGroupConst.EDIT)"
        :required="getPropertyBooleanValue(TriStateCheckBoxTypeConst.REQUIRED)"
        :tabIndex="getPropertyNumberValue(TriStateCheckBoxTypeConst.TABINDEX)" 
        v-model="vmodel" 
        @change="processEventComponent(CatalogEventConst.CHANGE)"   
        >
    </TriStateCheckBox>
    
    <div v-if="false" class="row">
        <div class="col-md-12">
        <label style="color:red;padding-left: 5px;" for="checkbox">{{ vmodel == null ? 'null' : vmodel }} </label>
        </div>
    </div>

    <CustomValidate v-if="loaded && getPropertyBooleanValue(TriStateCheckBoxTypeConst.VISIBLE)" v-model:submitted="submitted" v-model:vObject="v$" />
</template>
<script lang="ts">


import { defineComponent, onMounted, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import TriStateCheckBoxTypeConst from '../../../../domain/Constants/TriStateCheckBoxTypeConst';
import { Container } from 'inversify';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import CustomValidate from '../../shared/CustomValidate.vue';
import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_tri_state_check_box',
    components: {
        CustomValidate,
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },        
        slotProps: {
            type: Object,
            default: () => ({})
        },
       
    },
    setup(props, context) {
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue, loaded,  
            baseOnMounted, baseOnBeforeUnmount, v$, submitted, canEdit,isValidData,fieldKey,
            fieldKeyComponentDataSourceId,processEventComponent ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);
  
        onMounted(() => {
            baseOnMounted();
        })

        onBeforeUnmount(() => {
                baseOnBeforeUnmount();
        })
    

        return {
   
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            TriStateCheckBoxTypeConst,
            getPropertyNumberValue,
            loaded,
            v$,
            submitted,
            canEdit,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            processEventComponent,
            CatalogEventConst,
            ObjectGroupConst,
            canDoOperation,
            componentKey
        };
    },
});
</script>
<style scoped></style>
