
<template>

    <SummaryDebug v-if="getPropertyBooleanValue(TreeSelectTypeConst.DEBUG,Component)"
                :data="Component"
                :vmodel="vmodel"
                />
                
        <TreeSelect 
            v-if ="loaded && dataValue && (getPropertyBooleanValue(TreeSelectTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW))" 
            :key="componentKey"
            :id="getPropertyValue(TreeSelectTypeConst.ID)" 
            v-tooltip="getPropertyValue(TreeSelectTypeConst.TOOLTIP)"
            :style="getPropertyValue(TreeSelectTypeConst.STYLE)"
            :class="{[getPropertyValue(TreeSelectTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted ,'p-readonly': getPropertyBooleanValue(TreeSelectTypeConst.READONLY),'p-tree':true}"
            :name="getPropertyValue(TreeSelectTypeConst.NAME)"
            :disabled="getPropertyBooleanValue(TreeSelectTypeConst.DISABLED) || (!canEdit)"
            :visible="getPropertyBooleanValue(TreeSelectTypeConst.VISIBLE)"
            :placeholder="getPropertyValue(TreeSelectTypeConst.PLACEHOLDER)"
            :readonly="getPropertyBooleanValue(TreeSelectTypeConst.READONLY) || !canDoOperation(ObjectGroupConst.EDIT)"
            :required="getPropertyBooleanValue(TreeSelectTypeConst.REQUIRED)"
            :tabIndex="getPropertyNumberValue(TreeSelectTypeConst.TABINDEX)" 
            :options = "dataValue"
            :selectionMode = "getSelectionMode()"
            :metaKeySelection = "getPropertyBooleanValue(TreeSelectTypeConst.METAKEYSELECTION)"  
            :scrollHeight = "getPropertyValue(TreeSelectTypeConst.SCROLLHEIGHT)"
            :variant = "getVariant()"
            :fluid = "getPropertyBooleanValue(TreeSelectTypeConst.FLUID)"
            :emptymessage = "getPropertyValue(TreeSelectTypeConst.EMPTYMESSAGE)"
            :display = "getDisplay()"
            @node-expand="onNodeExpand"
            @node-collapse="onNodeCollapse"
            @node-select="onNodeSelect"
            @node-unselect="onNodeUnSelect"
            @update:modelValue="updateModelValue"
            v-model="modelValue"
            @change ="onChange()"
            >
        </TreeSelect>
        <CustomValidate v-if="loaded && getPropertyBooleanValue(TreeSelectTypeConst.VISIBLE)" v-model:submitted="submitted" v-model:vObject="v$" />
    
        
        <div v-if="false" class="row">
            <div class="col-md-12">
                <label style="color:red;padding-left: 5px;">vmodel:  {{ vmodel == null ? 'null' : vmodel }} </label><br/>
                <label style="color:blue;padding-left: 5px;" for="">modelValue:  {{ modelValue == null ? 'null' : modelValue }} </label><br/>
            </div>
        </div>
        
        
    </template>
    
    <script lang="ts">
    
    import { defineComponent, onMounted, ref, onBeforeUnmount, shallowRef, watchEffect } from 'vue';
    import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
    import TreeSelectTypeConst from '../../../../domain/Constants/TreeSelectTypeConst';
    import { Container } from 'inversify';
    import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
    import ControlTypeConst from '../../../../domain/Constants/ControlTypeConst';
    import CustomValidate from '../../shared/CustomValidate.vue';
    import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';
    import { useStore } from 'vuex';	
    import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
    import SummaryDebug from '../../shared/SummaryDebug.vue';
        
    export default defineComponent({
        name: 'dynamic_tree_select',
        emits: ['node-expand', 'node-collapse', 'update:modelValue', 'node-select', 'node-unselect'],
        components: {
            CustomValidate,
            SummaryDebug
        },
        props:
        {
            container: {
                type: Object as () => Container
            },
            Component: {
                type: Object as () => ComponentDataForm,
                default: () => ({})
            },

            slotProps: {
                type: Object,
                default: () => ({})
            },

           
        },
        setup(props, context) {
            const modelValue = ref([]);
            const store = useStore();
            const dataValue = shallowRef<any[] | undefined>(undefined)
            const setVModelToArray = () =>{    
                modelValue.value = (vmodel.value ?? '').split(',').filter(x => x !== '') as []
            }

            const { vmodel, canDoOperation, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue
                , getPropertyValueObjectOrFile, getCatalogValue
                , loaded, resolveExpressions, baseOnMounted, baseOnBeforeUnmount, v$, submitted, canEdit
                , isValidData, fieldKey, fieldKeyComponentDataSourceId, processEventComponent 
                ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store, setVModelToArray)
   
    
          
    
            const getOptions = ()=> {

                getPropertyValueObjectOrFile(TreeSelectTypeConst.DATAVALUE, TreeSelectTypeConst.DATAFILEVALUE, TreeSelectTypeConst.DATASOURCEVALUE,null, true)
                .then((values:any) =>{

                    if (values == null) return undefined;
                if (Array.isArray(values)) {
                    if (values.length>0) {
                        dataValue.value =  values;
                    } else {
                        dataValue.value = undefined;
                    }
                }
                else{
                // ZERO WIDTH NO-BREAK SPACE
                values = values?.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g,"");
                // Carriage Return and Line Feed
                values = values?.replace(/(\r\n|\n|\r)/gm,"");
                //
                values = values?.replace(/\'/g,'\"');
                


                let nodes:any = '';
                try { 
                    nodes = JSON.parse(values); 
                } 
                catch (e) { 
                    nodes = values; 
                }
                dataValue.value =  nodes;
                }

               

                })


                
            }
    
            const getSelectionMode = () : "multiple" | "checkbox" | "single" => {
                const value = getCatalogValue(TreeSelectTypeConst.SELECTIONMODE)
                const mode: "multiple" | "checkbox" | "single" = value as "multiple" | "checkbox" | "single";
                return mode ?? 'multiple';
            }
           
            const getVariant = () : "outlined" | "filled"  => {
                const value = getCatalogValue(TreeSelectTypeConst.VARIANT)
                const mode: "outlined" | "filled" = value as "outlined" | "filled";
                return mode ?? 'outlined';
            }
    
            const getDisplay = () : "comma" | "chip"  => {
                const value = getCatalogValue(TreeSelectTypeConst.DISPLAY)
                const mode: "comma" | "chip" = value as "comma" | "chip";
                return mode ?? 'comma';
            }
    
            const onNodeExpand = (node : any) => {
                context.emit('node-expand', node);
            }
            const onNodeCollapse = (node : any) => {
                context.emit('node-collapse', node);
            }
            const onNodeSelect = (node : any) => {
                context.emit('node-select', node);
            }
            const onNodeUnSelect = (node : any) => {
                context.emit('node-unselect', node);
            }
            const updateModelValue = (value : any) => {
                modelValue.value = [];
                if ((Object.keys(value ?? {})).length > 0) {
                    (Object.keys(value ?? {})).forEach(element => {
                        modelValue.value.push(element as never);
                    });
                    vmodel.value = JSON.stringify((Object.keys(value ?? {})).join(','))
                }
                else {
                    vmodel.value = null
                }
                context.emit('update:modelValue', value);
            }
            const onChange = () => {
                processEventComponent(CatalogEventConst.CHANGE)
            }

            onMounted(() => {
                baseOnMounted();
                watchEffect(() => {
                if (loaded.value == true) {
                    getOptions();
                }
            })
                
            })
    
            onBeforeUnmount(() => {
                dataValue.value = undefined;
                baseOnBeforeUnmount();
            })

            return {
                getPropertyValue,
                getPropertyBooleanValue,
                vmodel,
                TreeSelectTypeConst,
                ControlTypeConst,
                getPropertyNumberValue,
                loaded,
                v$,
                submitted,
                canEdit,
                isValidData,
                fieldKey,
                fieldKeyComponentDataSourceId,
                processEventComponent,
                CatalogEventConst,
                ObjectGroupConst,
                canDoOperation,
                dataValue,
                getSelectionMode,
                getVariant,
                getDisplay,
                onNodeExpand,
                onNodeCollapse,
                onNodeSelect,
                onNodeUnSelect,
                updateModelValue,
                onChange,
                modelValue,
                componentKey
            };
        },
    });
    </script>
    <style scoped></style>


    