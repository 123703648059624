<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
            
    <CustomRating v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(RatingTypeConst.ID)" v-tooltip="getPropertyValue(RatingTypeConst.TOOLTIP)"
        :style="getPropertyValue(RatingTypeConst.STYLE)"
        :class="{ [getPropertyValue(RatingTypeConst.CLASS) ?? '']: true,  'p-invalid': v$.vmodel.$error && submitted ,'p-readonly': getPropertyBooleanValue(RatingTypeConst.READONLY) && !canDoOperation(ObjectGroupConst.EDIT) }"
        :name="getPropertyValue(RatingTypeConst.NAME)"
        :readonly="getPropertyBooleanValue(RatingTypeConst.READONLY) || !canDoOperation(ObjectGroupConst.EDIT)"
        :stars="getPropertyNumberValue(RatingTypeConst.STARS)" :cancel="getPropertyBooleanValue(RatingTypeConst.CANCEL)"
        :cancelIcon="getPropertyValue(RatingTypeConst.CANCELICON)" :onIcon="getPropertyValue(RatingTypeConst.ONICON)"
        :offIcon="getPropertyValue(RatingTypeConst.OFFICON)" v-model="vmodel">

    </CustomRating>


    <CustomValidate v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE)" v-model:submitted="submitted" v-model:vObject="v$" />
</template>
<script lang="ts">


import { defineComponent, onMounted, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import RatingTypeConst from '../../../../domain/Constants/RatingTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import CustomValidate from '../../shared/CustomValidate.vue';
import CustomRating from '../../../../../../../common/infrastructure/componentes/base/common/controles/CustomRating.vue';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_rating',
    components: {
        CustomValidate,
        CustomRating,
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
      
        slotProps: {
            type: Object,
            default: () => ({})
        },
     
       

    },
    setup(props, context) {
        //
        	
        const store = useStore();
        const { vmodel,canDoOperation, loaded, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue,
             baseOnMounted, baseOnBeforeUnmount, v$, submitted, canEdit,isValidData 
             ,fieldKey,fieldKeyComponentDataSourceId,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);


       

        onMounted(() => {

            baseOnMounted();

        })

        onBeforeUnmount(() => {

            baseOnBeforeUnmount();
        })






        return {
            RatingTypeConst,
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,

            getPropertyNumberValue,
            loaded,
            BaseControlTypeConst,
            v$,
            submitted,
            canEdit,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey

        };
    },




});
</script>
<style scoped></style>
