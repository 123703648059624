import BaseControlTypeConst from "./BaseControlTypeConst"

export default class OrganizationChartTypeConst extends BaseControlTypeConst {

    static SELECTIONMODE = 'selectionMode'
    static TEMPLATETYPE = 'templateType'
    static NODEVALUE = 'nodevalue'
    static DATAFILEVALUE = 'datafilevalue'
    static DATASOURCEVALUE = 'datasourcevalue'

    static LAYOUT_MENU = 'layouttemp-orgchart-menu'
    static COLLAPSIBLE='collapsible'
}